import "../../Products/style/style.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CardsProducts({ title, description, icon, linkTo }) {
  return (
    <div className="cards border text-center">
      <div className="d-flex align-items-center gap-2">
        <div className="bg-icons d-flex align-items-center justify-content-center">
          {icon}
        </div>
        <Link className="link-unstyled" to={linkTo}>
          <h5 className="ml-3 mb-0">
            {title}
          </h5>
        </Link>
      </div>

      <div>
        <span>
          <Link className="link-unstyled" to={linkTo}>

            {description}
          </Link>

        </span>
      </div>
    </div>
  );
}
