import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    name: '',
    status: true,
    display: true
  }

export default function Categories() {
  const { tenant_id } = useParams()
  const [data, setData] = useState([])

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const setTableData = (payloads) => ({
        columns: ['Nome', 'Ativo'],
        rows: payloads.map(item => [item.name, (item.status ? 'Sim' : 'Não')]),
        payloads: payloads
    })

  const apiPath = `/tenants/${tenant_id}/management/course_program/categories`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setData(response.data)

      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, data) => {
    setIsEditing(isEditing)
    setFormData(data)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    value = ['status', 'display'].includes(name) ? !formData[name] : value
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  return (
    <>
      <Table 
          header='Categorias' data={setTableData(data)} 
          onNewClick={() => openModal(false, formDataDefault)} 
          onEditClick={(payload) => openModal(true, payload)}
          onDeleteClick={(payload) => openModalDelete(payload)}
      />

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Categoria' : 'Criar Categoria'} onSubmit={() => submitModalForm()} >
        <Form>
          <FormGroup>
            <Label for='name'>Nome:</Label>
            <Input type='text' name='name' id='name' value={formData.name} onChange={handleModalInputChange} required />
          </FormGroup>

            <FormGroup>
                <Label>Status:</Label>
                <FormGroup switch>
                    <Input type='switch' name='status' id='status' onChange={handleModalInputChange} checked={formData.status} />
                </FormGroup>
            </FormGroup>

            <FormGroup>
                <Label>Exibir Home:</Label>
                <FormGroup switch>
                    <Input type='switch' name='display' id='display' onChange={handleModalInputChange} checked={formData.display} />
                </FormGroup>
            </FormGroup>
          
        </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

