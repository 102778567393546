import React, { Fragment, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { Btn, H4, P } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import man from "../../../assets/images/dashboard/profile.png";
import { ToastContainer, toast } from "react-toastify";

import { API } from '../../../api/API/api';

const RegisterFrom = ({ logoClassMain }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [terms, setTerms] = useState(false);
  const history = useNavigate();

  const signupSubmit = async (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !password) {
      console.log('email ou senha não informado');
      return;
    }

    try {
      const params = {};
      params.name = firstName;
      params.last_name = lastName;
      params.email = email;
      params.password = password;
      params.password_confirmation = password;
      params.terms = terms;

      const response = await API.post("/auth/register", params);

      const data = response.data;

      localStorage.setItem("Name", data.user.name);
      localStorage.setItem("group_id", data.user.group_id);
      localStorage.setItem("profileURL", data.user.photo || man);
      localStorage.setItem("token", data.access_token);

      history(`/admin/dashboard`);
      toast.success("Cadastrado!..");

    } catch (error) {
      if (error.response) {
        if (error.response.status === 422 && error.response.data.errors && error.response.data.errors.email) {
          toast.error("Email já cadastrado. Por favor, verifique o email informado.");
        } else {
          toast.error("Ocorreu um erro ao cadastrar.");
        }
      } else {
        // The request was not made at all, e.g., a network error.
        console.log(error.message);
        toast.error("Ocorreu um erro ao fazer a requisição.");
      }
    }
  };
  return (
    <Fragment>
      <ToastContainer />
      <div className='login-card'>
        <div>
          {/* <div>
            <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
              <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
              <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
            </Link>
          </div> */}
          <div className='login-main'>
            <Form className='theme-form login-form'>
              <H4>Crie sua conta</H4>
              <P>Informe seus dados para criar sua conta</P>
              <FormGroup>
                <Label className='col-form-label m-0 pt-0'>Nome</Label>
                <Row className='g-2'>
                  <Col xs='6'>
                    <Input className='form-control' type='text' required='' onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                  </Col>
                  <Col xs='6'>
                    <Input className='form-control' type='text' required='' onChange={(e) => setLastName(e.target.value)} value={lastName} />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label className='col-form-label m-0 pt-0'>Email</Label>
                <Input className='form-control' type='email' required='' onChange={(e) => setEmail(e.target.value)} value={email} />
              </FormGroup>
              <FormGroup className='position-relative'>
                <Label className='col-form-label m-0 pt-0'>Senha</Label>
                <div className='position-relative'>
                  <Input className='form-control' type='password' required='' onChange={(e) => setPassword(e.target.value)} value={password} />
                  <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)} >
                    {togglePassword ? (
                      <i className="icofont icofont-eye eye-icon"></i>
                    ) : (
                      <i className="icofont icofont-eye-blocked eye-icon"></i>
                    )}
                  </div>
                </div>

              </FormGroup>

              <FormGroup className='m-0'>
                <div className='checkbox'>
                  <Input id='checkbox1' type='checkbox' onChange={(e) => setTerms(!terms)} />
                  <Label className='text-muted' for='checkbox1'>
                    Aceito os termos de <span>Políticas e Privacidade</span>
                  </Label>
                </div>
              </FormGroup>
              <FormGroup>
                <Btn attrBtn={{ className: 'd-block w-100', outline: true, color: 'primary', type: 'submit', disabled: !terms, onClick: (e) => signupSubmit(e) }}>Criar Conta</Btn>
              </FormGroup>
              {/* <div className='login-social-title'>
                <H6 attrH6={{ className: 'text-muted or mt-4' }}>Or Sign up with</H6>
              </div>
              <div className='social my-4 '>
                <div className='btn-showcase'>
                  <a className='btn btn-light' href='https://www.linkedin.com/login' rel='noreferrer' target='_blank'>
                    <Linkedin className='txt-linkedin' /> LinkedIn
                  </a>
                  <a className='btn btn-light' href='https://twitter.com/login?lang=en' rel='noreferrer' target='_blank'>
                    <Twitter className='txt-twitter' />
                    twitter
                  </a>
                  <a className='btn btn-light' href='https://www.facebook.com/' rel='noreferrer' target='_blank'>
                    <Facebook className='txt-fb' />
                    facebook
                  </a>
                </div>
              </div> */}
              <P attrPara={{ className: 'mb-0 text-start' }}>
                Já possui uma conta?
                <Link className='ms-2' to={`/admin/login`}>
                  Entrar
                </Link>
              </P>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterFrom;
