import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem, InputGroup, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, NavLink, TabContent, TabPane, Button, Accordion, AccordionBody, AccordionHeader, AccordionItem, Label, Table as Trs } from 'reactstrap'

import { Table } from '../../_Commons/Table/Index'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    requirement: '',
    type: '',
    student: '',
    created_at: '',
    updated_at: '',
    status: '',
    payment: {
        installments: [],
        total: 0
    },
    messages: []
}

const status = {
    'Aguardando Pagamento': 'awaiting_payment',
    'Aguardando Atendimento': 'awaiting_service',
    'Aguardando Aluno': 'awaiting_student',
    'Finalizado': 'finished'
}

const RequirementTab = ({ data, openModal }) => {
    const setTableData = (payloads) => ({
        columns: ['Requerimento', 'Tipo', 'Aluno', 'Atualizado em', 'Status'],
        rows: payloads.map(item => [
            item.requirement,
            item.type,
            item.student,
            item.updated_at,
            item.status
        ]),
        payloads: payloads
    })


    const viewButton = ({ payload }) => (
        <>
            <Button color='primary' outline={true} onClick={() => openModal(payload)}>
                <i class="fa fa-eye" />
            </Button>
        </>
    )


    return (
        <>
            <Table
                header='Requerimentos' data={setTableData(data)}
                customButtons={viewButton}
            />
        </>
    )
}


export default function Requirements() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        awaiting_payment: [],
        awaiting_service: [],
        awaiting_student: [],
        finished: []
    })
    const [formData, setFormData] = useState(formDataDefault)
    const [message, setMessage] = useState({
        text: '',
        status: 'Aguardando Atendimento'
    })

    const [activeTab, setActiveTab] = useState('awaiting_payment')
    const [messageStatusDropdown, setMessageStatusDropdown] = useState(false)

    const [modalVisible, setModalVisible] = useState(false)
    const [openAccordion, setOpenAccordion] = useState('1');

    const openModal = (data) => {
        setFormData(data)
        setModalVisible(true)
    }

    const toggleAccordion = (id) => openAccordion === id ? setOpenAccordion() : setOpenAccordion(id)
    const toggleMessageStatusDropdown = () => setMessageStatusDropdown((prevState) => !prevState)

    const apiPath = `/tenants/${tenant_id}/management/academic/requirements`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setMessage((prevMessageData) => ({
            ...prevMessageData,
            [name]: value,
        }))
    }

    const sendMessage = async (e) => {
        e.preventDefault()
        try {
            const { id } = formData
            const response = await API.post(`${apiPath}/${id}`, message)

            const array = response.data[status[message.status]]
            const item = array.find(item => item.id === id)

            setFormData(item)
            setData(response.data)
            setMessage(prevMessage => ({
                ...prevMessage,
                text: ''
            }))
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }
    }

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink active={activeTab === 'awaiting_payment'} onClick={() => setActiveTab('awaiting_payment')}>Aguardando Pagamento</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={activeTab === 'awaiting_service'} onClick={() => setActiveTab('awaiting_service')}>Aguardando Atendimento</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={activeTab === 'awaiting_student'} onClick={() => setActiveTab('awaiting_student')}>Aguardando Aluno</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={activeTab === 'finished'} onClick={() => setActiveTab('finished')}>Finalizado</NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId='awaiting_payment'>
                    <RequirementTab data={data.awaiting_payment} openModal={openModal} />
                </TabPane>
                <TabPane tabId='awaiting_service'>
                    <RequirementTab data={data.awaiting_service} openModal={openModal} />
                </TabPane>
                <TabPane tabId='awaiting_student'>
                    <RequirementTab data={data.awaiting_student} openModal={openModal} />
                </TabPane>
                <TabPane tabId='finished'>
                    <RequirementTab data={data.finished} openModal={openModal} />
                </TabPane>
            </TabContent>

            <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)} size='lg' centered>
                <ModalHeader toggle={() => setModalVisible(false)}>Requerimento</ModalHeader>

                <ModalBody>
                    <Form>
                        <Accordion open={openAccordion} toggle={toggleAccordion}>
                            <AccordionItem>
                                <AccordionHeader targetId="1">Informações</AccordionHeader>
                                <AccordionBody accordionId="1">
                                    <Label>Protocol: <strong>#{formData.id}</strong></Label><br />
                                    <Label>Aluno: <strong>{formData.student}</strong></Label><br />
                                    <Label>Requerido em: <strong>{formData.created_at}</strong></Label><br />
                                    <Label>Atualizado em: <strong>{formData.updated_at}</strong></Label><br />
                                    <Label>Status: <strong>{formData.status}</strong></Label><br />
                                    <Label>Valor: <strong>{formData.payment.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></Label><br />
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="2">Pagamentos</AccordionHeader>
                                <AccordionBody accordionId="2">
                                    <Trs>
                                        <thead>
                                            <tr>
                                                <th>Parcela</th>
                                                <th>Vencimento</th>
                                                <th>Valor</th>
                                                <th>Pago em</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formData.payment.installments.map((installment, index) => (
                                                <tr key={`requirement-installment-${index}`}>
                                                    <td className='align-middle'>{index + 1}/{formData.payment.installments.length}</td>
                                                    <td className='align-middle'>{installment.expiration}</td>
                                                    <td className='align-middle'>{installment.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td className='align-middle'>{installment.paid_in}</td>
                                                    <td className='align-middle'>{installment.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Trs>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="3">Tramitação</AccordionHeader>
                                <AccordionBody accordionId="3">
                                    <ListGroup flush>
                                        {formData.messages.map((message, index) => (
                                            <ListGroupItem className='my-3' key={`requirement-messages-${index}`}>
                                                <Label><strong>{message.user}</strong></Label>
                                                <Label className='text-muted mx-2'>{message.created_at}</Label><br />
                                                <span>{message.text}</span><br />
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                    {formData.status !== 'Aguardando Pagamento' &&
                                        <InputGroup>
                                            <ButtonDropdown isOpen={messageStatusDropdown} toggle={toggleMessageStatusDropdown}>
                                                <DropdownToggle color="primary">{formData.status}</DropdownToggle>
                                                <DropdownMenu style={{ zIndex: 10 }}>
                                                    <DropdownItem name='status' value='Aguardando Atendimento' onClick={handleInputChange}>
                                                        Aguardando Atendimento
                                                    </DropdownItem>
                                                    <DropdownItem name='status' value='Aguardando Aluno' onClick={handleInputChange}>
                                                        Aguardando Aluno
                                                    </DropdownItem>
                                                    <DropdownItem name='status' value='Finalizado' onClick={handleInputChange}>
                                                        Finalizado
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                            <Input name='text' value={message.text} onChange={handleInputChange} />
                                            <Button color='primary' onClick={sendMessage}>Enviar</Button>
                                        </InputGroup>}
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}