import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { API } from '../../../../api/API/api'

import { Navbar } from '../_Commons/Navbar/Index'
import Layout from "../Layout"
import Students from "./Tabs/Students/Index"
import Classes from "./Tabs/Classes"
import Teachers from "./Tabs/TeachingStaff/Teachers"
import Monitors from "./Tabs/TeachingStaff/Monitors"
import Coordinations from "./Tabs/TeachingStaff/Coordinations"
import Requirements from "./Tabs/Requirements"
import Doubts from "./Tabs/Doubts"
import Categories from "./Tabs/CourseProgram/Categories"
import Combos from "./Tabs/CourseProgram/Combos"
import LearningPaths from "./Tabs/CourseProgram/LearningPaths"
import Courses from "./Tabs/CourseProgram/Courses/Index"
import Subjects from "./Tabs/CourseProgram/Subjects/Subjects"
import Modules from "./Tabs/CourseProgram/Modules/Modules"
import Exams from "./Tabs/CourseProgram/Exams"
import Questions from "./Tabs/CourseProgram/Questions"
import Vimeo from "./Tabs/CourseProgram/Videos"

const items = [
  {
    id: 'students',
    label: 'Alunos',
    tabs: [
      {
        label: 'Alunos',
        component: Students
      }
    ]
  },
  {
    id: 'teaching_staff',
    label: 'Corpo Docente',
    tabs: [
      {
        label: 'Professores',
        component: Teachers
      },
      {
        label: 'Monitores',
        component: Monitors
      },
      {
        label: 'Coordenações',
        component: Coordinations
      }
    ],
  },
  {
    id: 'requirements',
    label: 'Requerimentos',
    tabs: [
      {
        label: 'Requerimentos',
        component: Requirements
      }
    ],
  },
  {
    id: 'doubts',
    label: 'Perguntas ao professor',
    tabs: [
      {
        label: 'Perguntas ao professor',
        component: Doubts
      }
    ],
  },
  {
    id: 'course_program',
    label: 'Programa de Cursos',
    tabs: [
      {
        label: 'Turmas',
        component: Classes
      },
      {
        label: 'Categorias',
        component: Categories
      },
      {
        label: 'Combos',
        component: Combos
      },
      {
        label: 'Trilhas de aprendizado',
        component: LearningPaths
      },
      {
        label: 'Cursos',
        component: Courses
      },
      {
        label: 'Disciplinas',
        component: Subjects
      },
      {
        label: 'Módulos',
        component: Modules
      },
      {
        label: 'Provas',
        component: Exams
      },
      {
        label: 'Banco de questões',
        component: Questions
      },
      {
        label: 'Vídeos',
        component: Vimeo
      }
    ],
  }
]

export default function Academic() {
  const { tenant_id } = useParams()

  const [formValues, setFormValues] = useState({
    tenant: {
      name: ''
    }
  })

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(`/tenants/${tenant_id}/management/general`)
        setFormValues(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [tenant_id])

  return (
    <Fragment>
      <Layout id="academic" site_name={formValues.tenant.name}>
        <Navbar items={items} />
      </Layout>
    </Fragment>
  )
}
