import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input } from 'reactstrap'

import { Table } from '../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../_Commons/Modal/Delete'
import { Image } from '../../_Commons/Inputs/Image'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    name: '',
    url: '',
    description: '',
    display_footer: true,
    is_active: true
}

export default function Partners() {
    const { tenant_id } = useParams()
    const [data, setData] = useState([])

    const [modalVisible, setModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const [userDelete, setUserDelete] = useState(0)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const setTableData = (payloads) => ({
        columns: ['Nome', 'URL'],
        rows: payloads.map(item => [
            item.name,
            item.url

        ]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/cms/partners`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (isEditing, data) => {
        setIsEditing(isEditing)
        setFormData(data)
        setModalVisible(true)
    }

    const openModalDelete = ({ id }) => {
        setUserDelete(id)
        setModalDeleteVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, image, footer_image, ...payload } = formData

            if (image || footer_image) {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }

                const form = new FormData()

                if (image) form.append('image', image)
                if (footer_image) form.append('footer_image', footer_image)
                
                const responseImg = await API.post(`${apiPath}/upload`, form, config)

                payload.image_url = responseImg.data.image_url
                payload.footer_image_url = responseImg.data.footer_image_url
            }

            const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    const submitModalDeleteForm = async (id) => {
        try {
            const response = await API.delete(`${apiPath}/${id}`)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Error')
        }

        setModalDeleteVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target

        value = ['image', 'footer_image'].includes(name) ? e.target.files[0] : value
        value = ['display_footer', 'is_active'].includes(name) ? !formData[name] : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    return (
        <>
            <Table
                header='Parceiros' data={setTableData(data)}
                onNewClick={() => openModal(false, formDataDefault)}
                onEditClick={(payload) => openModal(true, payload)}
                onDeleteClick={(payload) => openModalDelete(payload)}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Parceiro' : 'Criar Parceiro'} onSubmit={() => submitModalForm()} >
                <Form>

                    <FormGroup>
                        <Label for='name'>Nome:</Label>
                        <Input type='text' name='name' id='name' onChange={handleModalInputChange} value={formData.name} />
                    </FormGroup>

                    <FormGroup>
                        <Label for='url'>URL (com http://):</Label>
                        <Input type='text' name='url' id='url' onChange={handleModalInputChange} value={formData.url} />
                    </FormGroup>

                    <FormGroup>
                        <Label for='description'>Descrição:</Label>
                        <Input type='textarea' name='description' id='description' onChange={handleModalInputChange} value={formData.description} />
                    </FormGroup>

                    <Image label='Imagem:' id='image' name='image' src={formData.image_url} onChange={handleModalInputChange} />

                    <FormGroup>
                        <Label for='display_footer'>Exibir no rodapé:</Label>
                        <FormGroup switch>
                            <Input type='switch' name='display_footer' id='display_footer' onChange={handleModalInputChange} checked={formData.display_footer} />
                        </FormGroup>
                    </FormGroup>

                    <Image label='Imagem para o rodapé:' id='footer_image' name='footer_image' src={formData.footer_image_url} onChange={handleModalInputChange} />

                    <FormGroup>
                        <Label for='is_active'>Ativo:</Label>
                        <FormGroup switch>
                            <Input type='switch' name='is_active' id='is_active' onChange={handleModalInputChange} checked={formData.is_active} />
                        </FormGroup>
                    </FormGroup>

                </Form>
            </ModalCreateAndEdit>

            <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
        </>
    )
}

