import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input, Button, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    teacher: { id: 0, name: '' },
    instructions: '',
    questions: []
}


export default function Exams() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        exam: [],
        teachers: []
    })

    const [modalVisible, setModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const [userDelete, setUserDelete] = useState(0)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const [questionOpened, setQuestionOpened] = useState('');
    const toggleQuestionOpened = id => questionOpened === id ? setQuestionOpened() : setQuestionOpened(id);

    const [alternativeOpened, setAlternativeOpened] = useState('');
    const toggleAlternativeOpened = id => alternativeOpened === id ? setAlternativeOpened() : setAlternativeOpened(id);

    const setTableData = (payloads) => ({
        columns: ['Prova', 'Professor(a)'],
        rows: payloads.map(item => [item.title, item.teacher.name]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/course_program/exams`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                formDataDefault.teacher.id = response.data.teachers[0].id
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (isEditing, data) => {
        setIsEditing(isEditing)
        setFormData(data)
        setModalVisible(true)
    }

    const openModalDelete = ({ id }) => {
        setUserDelete(id)
        setModalDeleteVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, ...payload } = formData
            const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    const submitModalDeleteForm = async (id) => {
        try {
            const response = await API.delete(`${apiPath}/${id}`)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Error')
        }

        setModalDeleteVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target
        value = ['teacher'].includes(name) ? JSON.parse(e.target.value) : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const questionAdd = () => {
        const questions = formData.questions
        questions.push({
            description: '',
            alternatives: []
        })

        setFormData((prevFormData) => ({
            ...prevFormData,
            questions: questions
        }))
    }

    const alternativeAdd = (index) => {
        const questions = formData.questions
        questions[index].alternatives.push({
            description: '',
            correct: false
        })

        setFormData((prevFormData) => ({
            ...prevFormData,
            questions: questions
        }))
    }

    const changeQuestionData = (e, index) => {
        let { name, value } = e.target
        const questions = formData.questions

        questions[index][name] = value

        setFormData((prevFormData) => ({
            ...prevFormData,
            questions: questions
        }))
    }

    const changeAlternativeData = (e, questionIndex, alternativeIndex) => {
        let { name, value } = e.target
        const questions = formData.questions
        const alternative = questions[questionIndex].alternatives[alternativeIndex]

        value = ['correct'].includes(name) ? !alternative.correct : value
        alternative[name] = value

        setFormData((prevFormData) => ({
            ...prevFormData,
            questions: questions
        }))
    }

    return (
        <>
            <Table
                header='Provas' data={setTableData(data.exam)}
                onNewClick={() => openModal(false, formDataDefault)}
                onEditClick={(payload) => openModal(true, payload)}
                onDeleteClick={(payload) => openModalDelete(payload)}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Prova' : 'Criar Prova'} onSubmit={() => submitModalForm()} >
                <Form>
                    <FormGroup>
                        <Label for='title'>Título:</Label>
                        <Input type='text' name='title' id='title' value={formData.title} onChange={handleModalInputChange} required />
                    </FormGroup>

                    <FormGroup>
                        <Label for='instructions'>Instruções para realizar a prova:</Label>
                        <Input type='textarea' name='instructions' id='instructions' value={formData.instructions} onChange={handleModalInputChange} required />
                    </FormGroup>

                    <FormGroup>
                        <Label for='teacher'>Professor(a) :</Label>
                        <Input type='select' name='teacher' id='teacher' onChange={handleModalInputChange}>
                            {data.teachers.map((teacher, index) => (
                                <option key={`question-teacher-${index}`} value={JSON.stringify(teacher)} selected={teacher.id === formData.teacher.id}>
                                    {teacher.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label>Questões:</Label>
                        <br />
                        <Button color='primary' outline={true} onClick={() => questionAdd()}>+</Button>

                        <Accordion open={questionOpened} toggle={toggleQuestionOpened}>
                            {formData.questions.map((question, index) =>
                                <AccordionItem>
                                    <AccordionHeader targetId={index}>Questão {index + 1}</AccordionHeader>
                                    <AccordionBody accordionId={index}>
                                        <FormGroup>
                                            <Label for='description'>Descrição:</Label>
                                            <Input type='text' name='description' id='description' value={question.description} onChange={(e) => changeQuestionData(e, index)} required />
                                        </FormGroup>

                                        <Accordion open={alternativeOpened} toggle={toggleAlternativeOpened}>
                                            <Button color='primary' outline={true} onClick={() => alternativeAdd(index)}>+</Button>
                                            {question.alternatives.map((alternative, altIndex) =>
                                                <AccordionItem>
                                                    <AccordionHeader targetId={altIndex}>Alternativa {altIndex + 1}</AccordionHeader>
                                                    <AccordionBody accordionId={altIndex}>
                                                        <FormGroup>
                                                            <Label for='description'>Descrição:</Label>
                                                            <Input type='text' name='description' id='description' value={alternative.description} onChange={(e) => changeAlternativeData(e, index, altIndex)} required />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label for='correct'>Opção correta:</Label>
                                                            <FormGroup switch>
                                                                <Input type='switch' name='correct' id='correct' onChange={(e) => changeAlternativeData(e, index, altIndex)} checked={alternative.correct} />
                                                            </FormGroup>
                                                        </FormGroup>

                                                    </AccordionBody>
                                                </AccordionItem>
                                            )}
                                        </Accordion>

                                    </AccordionBody>
                                </AccordionItem>
                            )}
                        </Accordion>
                    </FormGroup>
                </Form>
            </ModalCreateAndEdit>

            <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
        </>
    )
}

