import React, { useState, useEffect } from "react";
import { Navbar, Row, Nav, NavDropdown, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../assets/images/logo/logo-tutor.png";
import fireImage from '../assets/images/giftools.gif';
import RightHeader from "./Header/RightHeader";
import BookmarkHeader from "./Header/RightHeader/BookmarkHeader";
import man from '../../src/assets/images/dashboard/profile.png';
import { LI, UL, P, Btn } from '../../src/AbstractElements';

import { LogIn, User } from "react-feather";
import { Admin } from "../Constant";
import CommonPopover from "../Components/UiKits/Popover/common/CommonPopover";
import SplitButton from "../Components/UiKits/Dropdown/SplitButton";
import { Dropdown, DropdownMenu, DropdownItem } from "reactstrap";

const Header2 = () => {
    const [profile, setProfile] = useState('');
    const [name, setName] = useState('Emay Walter');
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [testeToggle, setTesteToggle] = useState(false);
    const showCgMenu = localStorage.getItem("group_id") === "650";
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));

    const [isMobile, setIsMobile] = useState(false);
    const history = useNavigate();

    const toggleSubMenu = () => {
        setShowSubMenu(!showSubMenu);
    };

    const DirectionData = [

        {
            id: 6,
            placement: "bottom",
            Popoverbody: "Vivamus sagittis lacus vel augue laoreet rutrum faucibus.",
            btncolor: "primary",
            btntext: "<i style={{ fontSize: '1.6rem',color:'#595962' }} className='fa fa-cubes'></i>",
        },

    ];
    const Logout = () => {
        localStorage.removeItem('profileURL');
        localStorage.removeItem('token');
        localStorage.removeItem('auth0_profile');
        localStorage.removeItem('Name');
        localStorage.setItem('authenticated', false);
        history(`admin/login`);
    };
    const UserMenuRedirect = (redirect) => {
        history(redirect);
    };
    const toggle = () => {
        setTesteToggle(!testeToggle);

    }
    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);
        setName(localStorage.getItem('Name') ? localStorage.getItem('Name') : name);
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 899); // Altere o valor 768 conforme necessário
        };


        // Adiciona um listener para o evento de redimensionamento
        window.addEventListener('resize', handleResize);

        // Inicializa o estado de mobile com o tamanho atual da tela
        handleResize();
        // Remove o listener quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <nav
            className="navbar  navbar-light d-flex align-items-center justify-content-between shadow"
            style={{ backgroundColor: "#FFF", height: "4.5em", width: "100vw", flexDirection: 'row-reverse', position: 'fixed', zIndex: '10' }}
        >
            <div className="d-flex flex-row "
                style={{
                    //   backgroundColor:'red'
                    // marginLeft: showCgMenu ? (isMobile ? '37%' : '42%') : isMobile ? '50%' : '0%',
                    // alignContent:'flex-end'

                }}
            >
                <div
                    className={`d-flex flex-row gap-3  ${isMobile ? 'mobile-div' : ''}`}
                    style={{
                        alignItems: 'center',
                        // flexWrap: 'wrap',
                        marginRight: '1.5rem',
                        // marginTop: '1em'
                        // alignContent: 'space-between',
                    }}

                >
                    <div style={{ display: showCgMenu ? 'd-flex' : 'none' }}>
                        {DirectionData.map((popover) => {
                            return <CommonPopover key={popover.id} item={popover} />;
                        })}


                    </div>
                    <div className="d-flex">
                        <svg width="21" height="21" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.6252 17.232C18.3657 18.1174 16.9047 18.7655 15.3366 19.1345C13.7684 19.5035 12.1278 19.5851 10.5207 19.3741C8.90637 19.1666 7.36171 18.6658 5.98919 17.9049C4.61668 17.1439 3.44771 16.1402 2.55983 14.9604C1.18581 13.1188 0.543455 10.9425 0.729472 8.75915C0.915489 6.57583 1.91981 4.50365 3.59123 2.85459C4.68703 1.80467 6.00949 0.948432 7.4849 0.333615C7.87595 0.177588 8.30829 0.114412 8.73719 0.150623C9.16609 0.186835 9.57596 0.321121 9.9244 0.539583C10.2728 0.758045 10.5472 1.05275 10.719 1.3932C10.8909 1.73365 10.954 2.10747 10.9019 2.476C10.0369 7.56885 14.2993 11.5398 20.3281 11.3481C20.7602 11.3407 21.1868 11.4332 21.5633 11.6161C21.9398 11.7989 22.2526 12.0653 22.4691 12.3875C22.6856 12.7098 22.7979 13.0762 22.7942 13.4485C22.7906 13.8208 22.6712 14.1855 22.4485 14.5046C21.6809 15.5367 20.7279 16.4573 19.6252 17.232ZM6.21381 2.99695C5.79246 3.29218 5.39849 3.61528 5.0355 3.96331C3.64359 5.33592 2.80691 7.06088 2.6514 8.87854C2.49589 10.6962 3.02997 12.5082 4.17324 14.0418C4.9135 15.0274 5.88879 15.866 7.03429 16.5018C8.17979 17.1377 9.46928 17.5562 10.817 17.7297C12.7612 17.9533 14.739 17.686 16.5103 16.9603C18.2817 16.2346 19.7705 15.0817 20.7961 13.6414C20.839 13.5779 20.8617 13.5058 20.8618 13.4324C20.8619 13.3589 20.8395 13.2868 20.7968 13.2232C20.7541 13.1596 20.6926 13.1069 20.6187 13.0703C20.5447 13.0338 20.4609 13.0148 20.3756 13.0152C13.1934 13.2527 7.93819 8.33412 8.98336 2.2566C8.99466 2.18612 8.98292 2.11433 8.94946 2.04929C8.916 1.98425 8.86214 1.92854 8.79395 1.88842C8.72518 1.8433 8.64339 1.81524 8.55744 1.80727C8.47149 1.79931 8.38464 1.81175 8.30631 1.84324C7.55737 2.15374 6.85477 2.54112 6.21381 2.99695Z" fill="#52526C" fillOpacity="0.8" />
                        </svg>

                    </div>


                    <div className="d-flex">
                        <svg width="21" height="21" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1_2017)">
                                <path d="M18.4256 0.833008H4.88266C3.60034 0.834331 2.37098 1.27374 1.46425 2.05486C0.557513 2.83597 0.0474345 3.89501 0.0458984 4.99967L0.0458984 14.9997C0.0474345 16.1043 0.557513 17.1634 1.46425 17.9445C2.37098 18.7256 3.60034 19.165 4.88266 19.1663H18.4256C19.7079 19.165 20.9373 18.7256 21.844 17.9445C22.7507 17.1634 23.2608 16.1043 23.2623 14.9997V4.99967C23.2608 3.89501 22.7507 2.83597 21.844 2.05486C20.9373 1.27374 19.7079 0.834331 18.4256 0.833008ZM4.88266 2.49967H18.4256C19.0048 2.50066 19.5705 2.65094 20.0497 2.93118C20.529 3.21142 20.8999 3.6088 21.1148 4.07217L13.7068 10.4547C13.1617 10.9224 12.4236 11.1851 11.6541 11.1851C10.8847 11.1851 10.1466 10.9224 9.6014 10.4547L2.19342 4.07217C2.40832 3.6088 2.77926 3.21142 3.25852 2.93118C3.73778 2.65094 4.30342 2.50066 4.88266 2.49967ZM18.4256 17.4997H4.88266C4.11299 17.4997 3.37484 17.2363 2.83059 16.7674C2.28635 16.2986 1.9806 15.6627 1.9806 14.9997V6.24967L8.23356 11.633C9.14155 12.4132 10.3717 12.8514 11.6541 12.8514C12.9366 12.8514 14.1667 12.4132 15.0747 11.633L21.3276 6.24967V14.9997C21.3276 15.6627 21.0219 16.2986 20.4776 16.7674C19.9334 17.2363 19.1953 17.4997 18.4256 17.4997Z" fill="#52526C" fillOpacity="0.8" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_2017">
                                    <rect width="23.2164" height="20" fill="white" transform="translate(0.0458984)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="d-flex">

                        <svg width="21" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.9427 14.1752L21.3018 5.97553C20.7379 4.22152 19.5091 2.6804 17.8111 1.59745C16.113 0.514504 14.0433 -0.0480409 11.9313 -0.00065119C9.81925 0.0467385 7.78631 0.70134 6.15601 1.85897C4.52571 3.0166 3.39174 4.61073 2.93458 6.38765L0.891963 14.3064C0.793127 14.69 0.795537 15.0879 0.899018 15.4706C1.0025 15.8534 1.20441 16.2112 1.48985 16.5177C1.77529 16.8242 2.13698 17.0717 2.54824 17.2417C2.9595 17.4117 3.40982 17.5001 3.86599 17.5002H7.52969C7.76281 18.4892 8.38567 19.3783 9.29272 20.017C10.1998 20.6556 11.3352 21.0045 12.5067 21.0045C13.6782 21.0045 14.8137 20.6556 15.7207 20.017C16.6278 19.3783 17.2506 18.4892 17.4837 17.5002H21.0083C21.4777 17.5 21.9406 17.4064 22.3611 17.2267C22.7816 17.047 23.1482 16.786 23.4323 16.4642C23.7165 16.1423 23.9104 15.7683 23.9991 15.3713C24.0879 14.9742 24.0689 14.5649 23.9437 14.1752H23.9427ZM12.5067 19.2502C11.8787 19.2479 11.2669 19.0786 10.7549 18.7653C10.2429 18.4521 9.85577 18.0102 9.64645 17.5002H15.367C15.1577 18.0102 14.7705 18.4521 14.2585 18.7653C13.7465 19.0786 13.1347 19.2479 12.5067 19.2502ZM21.8158 15.4045C21.721 15.5127 21.5982 15.6004 21.4571 15.6604C21.316 15.7204 21.1606 15.7511 21.0032 15.7502H3.86599C3.7139 15.7501 3.56375 15.7207 3.42664 15.664C3.28952 15.6073 3.16894 15.5248 3.07378 15.4226C2.97863 15.3204 2.91134 15.2011 2.87687 15.0734C2.8424 14.9458 2.84164 14.8132 2.87465 14.6853L4.91726 6.76653C5.27718 5.37196 6.16792 4.12105 7.44788 3.21267C8.72784 2.30428 10.3236 1.79055 11.9814 1.75314C13.6392 1.71572 15.2639 2.15678 16.5973 3.00619C17.9307 3.85559 18.8963 5.0646 19.3405 6.44103L21.9813 14.6407C22.0245 14.7703 22.032 14.9068 22.0032 15.0394C21.9745 15.172 21.9103 15.297 21.8158 15.4045Z" fill="#52526C" fillOpacity="0.8" />
                        </svg>

                    </div>
                </div>

                <UL>
                    <li className={`profile-nav onhover-dropdown pe-0 py-0 ${isMobile ? 'mobile-profile' : ''}`}>
                        <div className='media profile-media'>
                            <Image
                                className='d-flex profile-redondo gap-2'
                                src={`${authenticated ? auth0_profile.picture : profile}`}
                                alt=''
                            />

                            <div className='media-body'
                                style={{ marginRight: '1.5em' }}>
                                <span style={{ fontSize: '1.2em' }}>{authenticated ? auth0_profile.name : name}</span>
                                <P attrPara={{ className: 'mb-0 font-roboto d-flex align-items-center gap-1' }}>
                                    <span style={{ fontSize: '0.8em' }}>{Admin}</span> <i className='middle fa fa-angle-down'></i>
                                </P>
                            </div>

                        </div>
                        <UL attrUL={{
                            className: 'simple-list profile-dropdown onhover-show-div'
                        }}>
                            <LI
                                attrLI={{
                                    onClick: () => UserMenuRedirect(`/admin/perfil`),
                                    className: 'd-flex align-items-center gap-2 px-4 py-2'
                                }}>
                                <User />
                                <span>{"Perfil"} </span>
                            </LI>
                            <LI attrLI={{
                                onClick: Logout,
                                className: 'd-flex align-items-center gap-2 px-4 py-2'
                            }}>
                                <LogIn />
                                <span>{"Sair"}</span>
                            </LI>
                        </UL>
                    </li>
                </UL>

            </div>
            <div
                style={{
                    display: isMobile ? 'block' : 'none', alignItems: 'center'
                }}>
                <div className="dropdown-basic dropdown-split">
                    <div className="btn-group">
                        <Dropdown className="separated-btn" toggle={toggle}>
                            <Btn attrBtn={{
                                color: 'primary',
                                className: "botao-hamburguer",
                            }} ><i className="fa fa-bars"></i></Btn>
                            <DropdownMenu className="dropdown-content digits">
                                <DropdownItem href="/admin/dashboard">Dashboard</DropdownItem>
                                <DropdownItem href="/admin/products">Produtos</DropdownItem>
                                <DropdownItem href="/admin/dashboard">Contatos</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div style={{

                display: isMobile ? 'none' : 'flex', alignItems: 'center'
            }}>
                <Navbar as={Link} to="/">
                    <Image src={logoImage} alt="Logo" style={{ maxHeight: "2em", marginLeft: '0.9em' }} />

                </Navbar>

                <div style={{ display: isMobile ? 'none' : 'block', marginLeft: '2rem' }}>
                <Image  src= {fireImage} alt= 'gif' 
                        style={{ maxHeight: '2.5em', marginBottom: '1.28em' }} />
                    <span style={{ fontSize: '1em', marginLeft: '0.5rem' }}><a href="#">Dicas de como vender online!</a> Clique para saber mais.</span>
                </div>
            </div>
        </nav>
    );
};

export default Header2;
