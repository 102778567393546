import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router'
import { Card, Row, Col, FormGroup, Label } from 'reactstrap';
import { toast } from "react-toastify";

import { API } from '../../../../../../api/API/api';
import '../../../_Commons/Styles/Styles.scss';

export default function Seo() {
  const token = localStorage.getItem("token");
  const {tenant_id} = useParams()

  const [formValues, setFormValues] = useState({
    tenant: {
      name: ''
    },
    meta_description: '',
    meta_keywords: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.put(`/tenants/${tenant_id}/management/seo`, formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      toast.success('Dados Atualizados com Sucesso!');
    } catch (error) {
      toast.error(`Erro ao atualizar dados: ${error.message}`);
      console.error(error);
    }
  };
  

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(`/tenants/${tenant_id}/management/seo`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }}
    )
      setFormValues(response.data)

      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [])


  return (
    <Fragment>

          <Card 
          style={{marginBottom:"8.5em"}}>
            <div className="card-header">
              <h5 className="card-title p-b-0 p-t-0">SEO</h5>
            </div>
            <form>
              <Row>
                {/* Coluna 1 */}
                <Col sm="12" md="6" style={{ margin: '2vw' }}>
                  {/* Seção: Informações Pessoais */}
                  <FormGroup>
                    <Label className="form-label">Meta description</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="meta_description"
                      value={formValues.meta_description}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">Meta Keywords</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="meta_keywords"
                      value={formValues.meta_keywords}
                      onChange={handleInputChange}
                    />
                  </FormGroup>

                </Col>
                <Row className="text-right rowCustom">
                  <Col className="text-right">
                    <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                      Salvar alterações
                    </button>
                  </Col>
                </Row>
              </Row>


            </form>
          </Card>
   
    </Fragment>
  )
}
