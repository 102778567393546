import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from "react-toastify"
import { Card, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
  id: 0,
  title: '',
  description: ''
}

export default function RequimentsTypes() {
  const { tenant_id } = useParams()
  const [data, setData] = useState([])

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const apiPath = `/tenants/${tenant_id}/management/requirements/types`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setData(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, plan) => {
    setIsEditing(isEditing)
    setFormData(plan)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    value = name == 'status' ? !formData.status : value
    value = !isNaN(value) ? +value : value

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')

      setData(response.data)
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  return (
    <>
      <Card className='col-12 p-20'>
        <Button color='primary' className='col-2 d-flex justify-content-center' outline={true} onClick={() => openModal(false, formDataDefault)}>
          Novo
        </Button>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Tipos de Requerimentos</h5>
        </div>
        <Table borderless>
            <thead>
                <tr>
                    <th>Título</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {data.map((requirement_type, index) => (
                <tr key={index}>
                    <td className='align-middle'>{requirement_type.title}</td>
                    <td>
                        <div className='buttonsCard'>
                            <Button color='primary' outline={true} onClick={() => openModal(true, requirement_type)}>
                                <i className='fa fa-edit fa-lg'></i>
                            </Button>
                            <Button color='secondary' outline={true} onClick={() => openModalDelete(requirement_type)}>
                                <i className='fa fa-trash-o fa-lg'></i>
                            </Button>
                        </div>
                    </td>
                </tr>
                ))}
            </tbody>
        </Table>
      </Card>

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar tipo de requerimento' : 'Criar tipo de requerimento'} onSubmit={() => submitModalForm()} >
        <Form>
          <FormGroup>
            <Label for='title'>Título:</Label>
            <Input type='text' name='title' id='title' value={formData.title} onChange={handleModalInputChange} required />
          </FormGroup>
          <FormGroup>
            <Label for='description'>Descrição:</Label>
            <Input type='textarea' name='description' id='description' value={formData.description} onChange={handleModalInputChange} />
          </FormGroup>
        </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

