import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from "react-toastify"
import { Card, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { Editor } from '../../../_Commons/Inputs/Editor'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    subject: '',
    body: '',
    cco: ''
}

export default function EmailTemplates() {
    const { tenant_id } = useParams()

    const [emailsTypes, setEmailsTypes] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const apiPath = `/tenants/${tenant_id}/management/emails/template`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setEmailsTypes(response.data || [])
            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (email) => {
        setFormData(email)
        setModalVisible(true)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const handleModalInputEmailChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            body: e.editor.getData(),
        }))
    }

    const submitModalForm = async () => {
        try {
            const { id, ...payload } = formData
            const response = await API.put(`${apiPath}/${id}`, payload)
            setEmailsTypes(response.data)
            toast.success('Sucesso!')

        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }


    return (
        <>
            <Card className='col-12 p-20'>
                <div className='card-header'>
                    <h5 className='card-title p-b-0 p-t-0'>E-mails</h5>
                </div>
                <div>
                    {emailsTypes.map((email, index) => (
                        <Row key={index} className='d-flex justify-content-center align-items-center mt-2' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                            <Col md='6'>{email.type}</Col>
                            <Col md='6'>
                                <div className='buttonsCard'>
                                    <Button color='primary' outline={true} onClick={() => openModal(email)}>
                                        <i className='fa fa-edit fa-lg'></i>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    ))}
                </div>
            </Card>

            <ModalCreateAndEdit size='xl' isOpen={modalVisible} setIsOpen={setModalVisible} title={formData.type} onSubmit={() => submitModalForm()} >
                <Form>
                    <FormGroup>
                        <Label for='subject'>Assunto:</Label>
                        <Input type='text' name='subject' id='subject' value={formData.subject} onChange={handleModalInputChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for='cco'>Cópia oculta (CCO):</Label>
                        <Input type='text' name='cco' id='cco' value={formData.cco} onChange={handleModalInputChange} />
                    </FormGroup>

                    <Editor label='Corpo do email:' value={formData.body} onChange={handleModalInputEmailChange} />
                </Form>
            </ModalCreateAndEdit>
        </>
    )
}

