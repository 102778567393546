import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'
import { Image } from '../../../../../_Commons/Inputs/Image'

export const CourseBasic = ({ data, onChange }) => {
    return (
        <>
            <Form>
                <Row style={{ marginTop: '25px' }}>
                    <Col>
                        <FormGroup>
                            <Label for='name'>Nome:</Label>
                            <Input type='text' name='name' id='name' value={data.name} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='value'>Valor do investimento:</Label>
                            <Input type='number' min='0' name='value' id='value' value={data.value} onChange={onChange} required />
                        </FormGroup>
                    </Col>
                </Row>

                <Image label='Imagem do curso 396x230:' id='course_image' name='course_image' src={data.course_image_url} onChange={onChange} />

                <FormGroup>
                    <Label for='status'>Status:</Label>
                    <FormGroup switch>
                        <Input type='switch' name='status' id='status' onChange={onChange} checked={data.status} />
                        <Label for='order'>{data.status ? 'Ativo' : 'Inativo'}</Label>
                    </FormGroup>
                </FormGroup>

            </Form>
        </>
    )
}