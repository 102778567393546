import React, { useEffect, useState } from "react";
import { Button, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import CardsProducts from "./component";
import "../Products/style/style.scss";
import { Container } from "react-bootstrap";
import { Breadcrumbs } from "../../../../../AbstractElements";
import { Fragment } from "react";
import { toast } from "react-toastify";
import { API } from "../../../../../api/API/api";
import CreatedCard from "../component/CreatedCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Products() {
  const navigation = useNavigate();
  const token = localStorage.getItem("token");
  const [step, setStep] = useState(1);

  const [userData, setUserData] = useState([]);
  const [reloadData, setReloadData] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (reloadData) {
      async function getData() {
        setLoading(true);
        try {
          const response = await API.get(`/tenants`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserData(response.data.sites|| []);
          console.table(response.data);

          setReloadData(false);
        } catch (error) {
          console.error(error);
          toast.error("Não foi possível exibir seus sites:", error);
        } finally {
          setLoading(false);
        }
      }
      getData();
    }
  }, [reloadData, token]);
  const handleNewLink = () => {
    navigation('/admin/meus-sites');
  };

  const getNumberOfCards = () => {
    return userData.length;
  };
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs mainTitle='Meus Produtos' parent='Home' title='Meus Produtos' />

        <Row xs="12" className="mb-4" style={{
          //  marginLeft: '1.5em', 
        }}>
          <div>
            <CreatedCard
              title="Meus Sites"
              nameButtom="Ver Sites"
              count={getNumberOfCards()}
              func={handleNewLink}
            />
          </div>
        </Row>
        <Button
          style={{
            marginTop: "2rem", marginBottom: "2rem",
            //  marginLeft: '1.5em' 
          }}
          className="purchase-btn btn btn-primary btn-hover-effect f-w-500"
          disabled
        >
          + Criar produto
        </Button>
        <div className="row gap-2"
          style={{
            height: '100%',
            display: 'flex',
            // justifyContent:'center',
            // marginLeft: '1.5em' ,
            alignItems: 'center',
            // alignContent: 'flex-start',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          <CardsProducts
            title="Criar Link da bio"
            description="Crie uma página de link na bio para fortalecer a sua presença online"
            icon={<i className="icofont icofont-rocket-alt-2"></i>}
            linkTo={'/admin/editorPage'}
          />

          <CardsProducts
            title="Página de captura"
            description="Crie uma página para capturar novos contatos."
            icon={<i className="fa fa-file-text"></i>}
          />

          <CardsProducts
            title="Criar Aula"
            description="Crie suas aulas e transforme seu conhecimento em um curso"
            icon={<i className="icofont icofont-graduate"></i>}
          />
            <CardsProducts
            title="Minhas Empresas"
            description="Crie uma empresa para poder aproveitar de todos os serviços da plataforma"
            icon={<i className="fa fa-building-o" aria-hidden="true"></i>}
            linkTo={'/admin/products/company/list'}

          />
           <CardsProducts
            title="Meus Serviços"
            description="Aqui encontram-se os serviços que você possui na nossa plataforma"
            icon={<i className="fa fa-file"></i>}
            linkTo={'/admin/products/services'}

          />

        </div>
      </Container>
    </Fragment>

  );
}
