import { Button, FormGroup, Input, Label } from "reactstrap";
import { Table } from "../../../../../_Commons/Table/Index"
import { useState } from "react";

export const Modules = ({
  subjectName,
  data,
  unlinked,
  bindModule,
  searchQuery,
  setSearchQuery,
  handleSearch,
  currentPage,
  setCurrentPage,
  handleChangePagination,
  itemsPerPage,
  setItemsPerPage,
  lastPage,
  handleSort,
  sortColumn,
  descendingSort,
}) => {

  const [selectedModule, setSelectedModule] = useState(null);

  const handleSelectChange = (event) => {
    setSelectedModule(event.target.value);
  };

  const setTableData = (payloads) => ({
    columns: ['Id', 'Nome', 'Professor'],
    rows: payloads.map(item => [item.id, item.name, item.teacher]),
    payloads: payloads
  });

  const handleModuleVincule = () => {
    bindModule(selectedModule);
    setSelectedModule(null);
  }

  return (
    <>
      <FormGroup style={{ width: '400px', marginLeft: '41px', marginTop: '25px', marginBottom: '-40px' }}>
        <Label>Vincular um módulo</Label>
        <div className="d-flex">
          <Input
            type="select" 
            name="select" 
            id="selectSubject" 
            value={selectedModule} 
            onChange={handleSelectChange}
            className="mr-2"
          >
            <option value="">Selecione um módulo</option>
            {unlinked && unlinked.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}
          </Input>

          <Button
            color="primary" 
            onClick={handleModuleVincule} 
            disabled={selectedModule === null}
          >
            Vincular
          </Button>
        </div>
      </FormGroup>
      <Table
        header={`Módulos da disciplina ${subjectName}`}
        data={setTableData(data)}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        search={true}
        handleSearch={handleSearch}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleChangePagination={handleChangePagination}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        pagination={true}
        lastPage={lastPage}
        handleSort={handleSort}
        sortColumn={sortColumn}
        sortDirection={descendingSort}
        onDeleteClick={(payload) => bindModule(payload.id)}
      />
    </>
  )
}