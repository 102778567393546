import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { FormGroup, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'

import { StudentProfile } from './ModalTabs/Profile'
import { StudentEnrollments } from './ModalTabs/Enrollments'
import { StudentRequests } from './ModalTabs/Requests'
import { StudentExtensions } from './ModalTabs/Extensions'
import { StudentRequirements } from './ModalTabs/Requirements'
import { StudentFrequencies } from './ModalTabs/Frequencies'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
  id: 0,
  name: '',
  email: '',
  cpf: '',
  rg: null,
  city: null,
  state: null,
  sex: null,
  date_birth: null,
  profession: null,
  cep: null,
  street_address: null,
  number: null,
  complement: null,
  neighborhood: null,
  phone_number: null,
  cell_number: null,
  region: null,
  sectional: null,
  access_url: '',
  agreement: null,
  enrollments: [],
  requests: [],
  extensions: [],
  requirements: [],
  frequencies: []
}

const listColunsStudents = ['id', 'name', 'email', 'cpf', 'city', 'state'];

export default function Classes() {
  const { tenant_id } = useParams()
  const [data, setData] = useState({
    students: [],
    professions: [],
    regions: [],
    sectionals: [],
    agreements: [],
    courses: []
  })
  const [formData, setFormData] = useState(formDataDefault)

  const [modalVisible, setModalVisible] = useState(false)
  const [activeTab, setActiveTab] = useState('profile')
  const [isEditing, setIsEditing] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [lastPage, setLastPage] = useState(0)
  const [sortColumn, setSortColumn] = useState('id')
  const [descendingSort, setDescendingSort] = useState(false)

  const setTableData = (payloads) => ({
    columns: ['Id', 'Nome', 'Email', 'CPF', 'Cidade', 'Estado'],
    rows: payloads.map(item => [item.id, item.name, item.email, item.cpf, item.city, item.state]),
    payloads: payloads
  })

  const apiPath = `/tenants/${tenant_id}/management/academic/students`
  
  const fetchData = async () => {
    try {
      const response = await API.get(`${apiPath}?page=${currentPage}&perpage=${itemsPerPage}&query=${searchQuery}&sorting=${sortColumn}&reversed=${descendingSort}`)
      setData(response.data)
      setLastPage(response.data.last_page)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentPage, searchQuery, itemsPerPage, descendingSort, sortColumn])

  const openModal = (isEditing, data) => {
    setIsEditing(isEditing)
    setFormData(data)
    setModalVisible(true)
  }

  const handleSort = (column, direction) => {
    const translatingColumn = listColunsStudents[column];
    setSortColumn(translatingColumn);
    direction === 'asc' ? setDescendingSort(false) : setDescendingSort(true)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    value = ['sectional'].includes(name) ? JSON.parse(e.target.value) : value

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleSearch = async () => {
    setCurrentPage(1)
    await fetchData()
  }

  const submitModalForm = async () => {
    try {
      const { id, ...payload } = formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)
      console.log('teste')
      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const handleChangePagination = async (e) => {
    setCurrentPage(Number(e.target.value))
  }

  return (
    <>
      <Table
        header='Alunos' data={setTableData(data.students)}
        onNewClick={() => openModal(false, formDataDefault)}
        onEditClick={(payload) => openModal(true, payload)}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        search={true}
        handleSearch={handleSearch}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleChangePagination={handleChangePagination}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        pagination={true}
        lastPage={lastPage}
        handleSort={handleSort}
        sortColumn={sortColumn}
        sortDirection={descendingSort}
      />

      <ModalCreateAndEdit size='lg' isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Aluno' : 'Criar Aluno'} onSubmit={() => submitModalForm()} onClose={() => setActiveTab('profile')} >
        {isEditing &&
          <>
            <Button color="primary" href={formData.access_url} target="_blank" tag="a">Acessar área do aluno</Button>
            <FormGroup>
              <Label for='agreement'>Convênio:</Label>
              <Input type='select' name='agreement' id='agreement' onChange={handleModalInputChange}>
                <option selected={!formData.agreement} hidden></option>
                {data.agreements.map((agreement, index) => (
                  <option key={`student-agreement-${index}`} value={JSON.stringify(agreement)} selected={formData.agreement ? agreement.id === formData.agreement.id : false}>
                    {agreement.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </>
        }
        <Nav tabs>
          <NavItem>
            <NavLink active={activeTab === 'profile'} onClick={() => setActiveTab('profile')}>Perfil</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'enrollments'} onClick={() => setActiveTab('enrollments')} disabled={!isEditing}>Matrículas</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'requests'} onClick={() => setActiveTab('requests')} disabled={!isEditing}>Pedidos</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'extensions'} onClick={() => setActiveTab('extensions')} disabled={!isEditing}>Extensōes</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'requirements'} onClick={() => setActiveTab('requirements')} disabled={!isEditing}>Requerimentos</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'frequencies'} onClick={() => setActiveTab('frequencies')} disabled={!isEditing}>Frequências</NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId='profile'>
            <StudentProfile data={formData} professions={data.professions} regions={data.regions} sectionals={data.sectionals} onChange={handleModalInputChange} />
          </TabPane>
          <TabPane tabId='enrollments'>
            <StudentEnrollments data={formData} courses={data.courses} setData={setData} setFormData={setFormData} />
          </TabPane>
          <TabPane tabId='requests'>
            <StudentRequests data={formData} />
          </TabPane>
          <TabPane tabId='extensions'>
            <StudentExtensions data={formData} />
          </TabPane>
          <TabPane tabId='requirements'>
            <StudentRequirements data={formData} />
          </TabPane>
          <TabPane tabId='frequencies'>
            <StudentFrequencies data={formData} />
          </TabPane>
        </TabContent>
      </ModalCreateAndEdit>
    </>
  )
}
