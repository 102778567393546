// //Samp lePage
import Dashboard from '../Components/Pages/Dashboard/Index';
import Planos from '../Components/Pages/Backoffice/Planos';
import Instancias from '../Components/Pages/Backoffice/Instancias';
import Clientes from '../Components/Pages/Backoffice/Clientes';
import ClienteProfile from '../Components/Pages/Backoffice/ClienteProfile';

// //User app
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import { Navigate } from 'react-router';
import ProfileTTC from '../Components/Application/Users/UsersEdit/ProfileTTC';
import UsersProfileContain from '../Components/Application/Users/UsersProfile';
import FileManagerContain from '../Components/Application/FileManager';
import Settings from '../Components/Pages/Tenants/Settings/Index'
import Academic from '../Components/Pages/Tenants/Academic/Index'
import Financial from '../Components/Pages/Tenants/Financial/Index'
import CMS from '../Components/Pages/Tenants/CMS/Index'
import Integrations from '../Components/Pages/Tenants/Settings/Tabs/General/Integrations'
import Seo from '../Components/Pages/Tenants/Settings/Tabs/General/Seo'
import Administradores from '../Components/Pages/Tenants/Administradores'
import EditorPages from '../Components/Pages/Dashboard/Components/LinkTree/editorPages';
import Products from '../Components/Pages/Dashboard/Components/Products';
import ErrorPage4 from '../Components/Pages/ErrorPages/ErrorPage404';
import EditLinktree from '../Components/Pages/Dashboard/Components/LinkTree/editLinktree';
import ServiceInvoice from '../Components/Pages/Serviços/ServiceInvoice';
import ServiceList from '../Components/Pages/Serviços/ServiceList';
import SitesInfo from '../Components/TutorCasts/Profile/SitesInfo';
import TenantContent from '../Components/Application/FileManager/TenantContent';
import Tenants from '../Components/Pages/Dashboard/Components/Tenants/Index';
import Cursos from '../Components/Pages/Tenants/Cursos';
import Company from '../Components/Pages/Dashboard/Components/Products/company';
import CompanyList from '../Components/Pages/Dashboard/Components/Products/companyList';
import CompanyEdit from '../Components/Pages/Dashboard/Components/Products/companyEdit';
import CompanyList2 from '../Components/Pages/Backoffice/companyList2';
import CompanyEdit2 from '../Components/Pages/Dashboard/Components/Products/companyEdit2';
import ProductsInvoices from '../Components/Pages/Backoffice/ProductsInvoices';
import ItemDescription from '../Components/Application/Ecommerce/Invoice/ItemDescription';
import Faturas from '../Components/Pages/Backoffice/Faturas';
import ChamadosList from '../Components/Pages/Backoffice/ChamadosList';
import PaymentSettings from '../Components/Pages/Backoffice/Paymentsettings';
import ServiceList2 from '../Components/Pages/Serviços/ServiceList2';
import ChamadosList2 from '../Components/Pages/Backoffice/ChamosList2';
import CheckOutContain from '../Components/Application/Ecommerce/CheckOut';
import Flat from '../Components/Pages/Tenants/Flat';

const showBackOfficeRoute = localStorage.getItem("group_id") === "650";
// Check if back office routes should be shown

// Define routes
export const routes = [
  { path: `/admin/dashboard`, Component: <Dashboard /> },
  { path: `/admin/sites/:tenant_id/settings`, Component: <Settings /> },
  { path: `/admin/sites/:tenant_id/academic`, Component: <Academic /> },
  { path: `/admin/sites/:tenant_id/financial`, Component: <Financial /> },
  { path: `/admin/sites/:tenant_id/flat`, Component: <Flat /> },
  { path: `/admin/sites/:tenant_id/cms`, Component: <CMS /> },
  { path: `/admin/editorPage`, Component: <EditorPages />},
  { path: `/admin/editorPage/:id/:slug`, Component: <EditLinktree />},
  { path: `/admin/chamados`, Component: <ChamadosList2 />},
  { path: `/admin/products`, Component: <Products />},
  { path: `/admin/product/checkout/:ordemId`, Component: <CheckOutContain />},
  { path: `/admin/products/company`, Component: <Company />},
  { path: `/admin/products/company/:companyId`, Component: <CompanyEdit />},
  { path: `/admin/products/company/list`, Component: <CompanyList />},
  { path: `/admin/meus-sites`, Component: <Tenants />},
  { path: `/admin/perfil/edit`, Component: <UsersEditContain /> },
  { path: `/admin/perfil`, Component: <UsersProfileContain /> },
  { path: `/admin/invoice`, Component: <ItemDescription /> },
  { path: `/admin/products/services`, Component: <ServiceList2 /> },
  { path: `/admin/Home`, Component: <Navigate to="/admin" /> },
  { path: `/admin/Perfil`, Component: <Navigate to="/admin/perfil" /> },
  { path: `*`, Component: <ErrorPage4 /> },

  ...(showBackOfficeRoute
    ? [
        { path: `/backoffice/instancias`, Component: <Instancias /> },
        { path: `/backoffice/planos`, Component: <Planos /> },
        { path: `/backoffice/configuracoes`, Component: <PaymentSettings /> },
        { path: `/backoffice/produtos_invoice`, Component: <ProductsInvoices /> },
        { path: `/backoffice/faturas`, Component: <ServiceList /> },
        { path: `/backoffice/faturas/nova`, Component: <ServiceInvoice /> },
        { path: `/backoffice/clientes`, Component: <Clientes /> },
        { path: '/backoffice/clientes/:clientId', Component: <ClienteProfile /> },
        { path: '/backoffice/clientes/:clientId/edit', Component: <UsersEditContain /> },
        { path: `/backoffice/instancias/:tenant_id/general`, Component: <Settings /> },
        { path: `/backoffice/instancias/:tenant_id/integrations`, Component: <Integrations /> },
        { path: `/backoffice/instancias/:tenant_id/seo`, Component: <Seo /> },
        { path: `/backoffice/instancias/:tenant_id/administradores`, Component: <Administradores /> },
        { path: `/backoffice/clientes-pj/`, Component: <CompanyList2 /> },
        { path: `/backoffice/chamados/`, Component: <ChamadosList /> },
        { path: `/backoffice/cliente-pj/:companyId`, Component: <CompanyEdit2 /> },
      
        // Include other back office routes here
      ]
    : []),
];
export default routes;
