import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { API } from "../../../../../api/API/api";
import { useForm } from "react-hook-form";
import "../Styles/TenantForm.scss"

export default function Step2({ setSteps, setFormdata, formdata }) {

  const [ loading, setLoading] = useState(false)
  const token = localStorage.getItem("token")

  const {
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    if (data) {
      setFormdata((prev) => ({ ...prev, ...data }));
      createTenant()
    }
  };

  const inputChange = (key, value) => {
    setFormdata({ ...formdata, [key]: value })
  }

  async function createTenant() {
    setLoading(true)
    try {
      const response = await API.post(`/tenants`, (formdata), {
        headers: {
          Authorization: `Bearer ${token}`,
        }});

      const tenant = response.data.sites.slice(-1)[0];
      if (formdata.price === 0) {
        setLoading(false)
        window.location.reload();
        toast.info("Seu site foi criado com sucesso!");
        return;
      };

      if (formdata.paymentMethod === 'boleto') {
        const { data } = await API.post('/tenants/payment/ticket', {
          payment_method_id: formdata.paymentMethodId,
          price_id: `${formdata.price}00`,
          customer_id: formdata.customerId,
          id: tenant.id,
          name: formdata.name,
          email: formdata.email
        });
  
  
        const link_pdf = data.boleto_display_details.pdf;
        window.open(link_pdf);

      } else {
        const setPaymentTenant = await API.post('/tenants/payment/create-subscription', {
          payment_method_id: formdata.paymentMethodId,
          price_id: formdata.priceId,
          customer_id: formdata.customerId,
          id: tenant.id,
          name: formdata.name,
          email: formdata.email
        });

        console.log(setPaymentTenant);
      }

    } catch (error) {
      console.log(error)
    }
    finally { 
      setLoading(false)
      window.location.reload();
      toast.info("Seu site foi criado com sucesso!");
    }
  }

  if(loading === false){
    return (
      <Fragment>
        <Row sm="12">
          <Col>
            <form autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup autocomplete="off">
                <Label>Nome de domínio</Label>
                <Input className='form-control' id='tenant_id' type='text' name='tenant_id' autoComplete='off' required onChange={(e) => inputChange('tenant_id', e.target.value)} />
                <p className="f-12 f-w-200 m-t-10">Ex.: nomedominio.tutorcasts.app (tutorcasts.app é o padrão, não escreva essa parte!)</p>
              </FormGroup>
              <FormGroup>
                <Label>Senha de administrador</Label>
                <Input className='form-control' id='tenant_password' type='password' autocomplete='off' onChange={(e) => inputChange("tenant_password", e.target.value)} />
              </FormGroup>
              <div className="text-end">
                <Button className="secondary me-2" onClick={() => {
                  if (formdata.price === 0) {
                    setSteps((pre) => pre - 2)
                  } else {
                    setSteps((pre) => pre - 1)
                  }
                }}>
                  Voltar
                </Button>
                <Button className="primary" type="submit">
                  Enviar
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Fragment>
    );
  }
  else {
    return(
      <Fragment>
        <Row>
          <Col sm="12">
            <div className='creating'>
              <div className='creating__text'>
                <h5>Aguarde um instante, seu site está sendo criado!</h5>
                <p>Isso pode levar alguns minutos...</p>
              </div>
              <div className='creating__spinner loader-box'>
                <Spinner attrSpinner={{ className: 'loader-3' }} />
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }
}
