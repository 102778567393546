import React, { Fragment, useEffect, useState } from 'react';
import { Card, Row, Col, FormGroup, Label, Input, Button, Table, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { API } from '../../../api/API/api';
import Multiselect from 'multiselect-react-dropdown';
import { now } from 'moment';
import { toast } from 'react-toastify';

import CommonModal from '../../UiKits/Modals/common/modal';

const ServiceInvoice = () => {
  const token = localStorage.getItem('token');
  const [tenants, setTenants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [basketItems, setBasketItems] = useState([]); // State for basket items
  const [products, setProducts] = useState([]);
  const [clientType, setClientType] = useState('');
  const [modal, setModal] = useState(false);

  const [formValues, setFormValues] = useState({
    subtotal: 0.0,
    selectedClient: "",
    company_id: null,
    user_id: null,
    subtotalComDesconto: 0,
    descontoPercent: 0,
    desconto: 0,
    tipo_cliente: "",
    categoria: "",
    status: "aberta",
    contrato: "Pedente",
    pagamento: "Pedente",
    imposto_percentual: 16.50,
    desconto_percentual: 0.00,
    total: 0.00,
    taxas: 0,
  });

  const handleProductChange = (selectedList, selectedItem) => {
    setSelectedProducts(selectedList); // Update selected products
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  const handlePreview = () => {
    // Implement logic for previewing the invoice here
    toggleModal();
  };


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle changes in the client type
    if (name === 'typecliente') {
      setClientType(value);
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleQuantityChange = (productId, quantity) => {
    // Update quantity for the selected product in the basket
    const updatedBasketItems = basketItems.map((item) =>
      item.id === productId ? { ...item, quantity } : item
    );
    setBasketItems(updatedBasketItems);
  };

  const addToBasket = () => {
    // Add the selected product to the basket with quantity and details
    const selectedProductDetails = selectedProducts.map((product) => ({
      id: product.id,
      nome: product.nome,
      categoria: product.categoria,
      valor: Number(product.valor),
      unidade_de_medida: product.unidade_de_medida,
      quantity: Number(product.quantity) || 1,
    }));

    // Update basket items using a function callback to ensure asynchronous update
    setBasketItems((prevBasketItems) => [...prevBasketItems, ...selectedProductDetails]);

    setSelectedProducts([]); // Clear selected products after adding to basket

    // Calculate subtotal after updating basket items
    calculaSubtotal([...basketItems, ...selectedProductDetails], formValues.imposto, formValues.desconto);
  };

  const calculaSubtotal = (produtos, imposto, desconto) => {
    let subtotal = 0;

    // Calculate subtotal based on the basket items
    produtos.forEach((produto) => {
      subtotal += (parseInt(produto.quantity) ?? 1) * produto.valor;
    });

    console.log(produtos, 'Produtos selecionados');
    console.log(subtotal, 'SOMA DOS PRODUTOS');

    // Convert desconto to a number
    const descontoValue = parseFloat(desconto);
    const impostoValue = parseFloat(imposto);
    console.log(descontoValue, 'DESCONTO EM %');
    console.log(impostoValue, 'IMPOSTO EM %');


    // Apply discount first
    const subtotalComDesconto = subtotal * (1 - descontoValue / 100);
    console.log(subtotalComDesconto, 'SOMA - DESCONTO');
    // Calcular valor do desconto em reais
    const valorDesconto = subtotal - subtotalComDesconto;
    console.log(valorDesconto, 'VALOR DO DESCONTO EM REAIS');
    // Convert percentual de imposto para valor em reais
    const valorImposto = subtotalComDesconto * (impostoValue / 100);
    console.log(valorImposto, 'VALOR DO IMPOSTO EM REAIS');

    // Somar taxa de imposto
    const totalComImposto = subtotalComDesconto * (1 + impostoValue / 100);
    console.log(totalComImposto, 'TOTAL COM IMPOSTO');

    // Truncate the result to two decimal places
    const truncatedSubtotal = parseFloat(totalComImposto.toFixed(2));
    console.log(truncatedSubtotal, 'TOTAL COM IMPOSTO CONVERTIDO');
    const trucatedValorImposto = parseFloat(valorImposto.toFixed(2));
    const trucatedValorDesconto = parseFloat(valorDesconto.toFixed(2));
    setFormValues((prevValues) => ({
      ...prevValues,
      subtotal: subtotal,
      total: truncatedSubtotal || 0.0,
      subtotalComDesconto: subtotalComDesconto,
      descontoPercent: descontoValue,
      desconto: trucatedValorDesconto,
      imposto: trucatedValorImposto,
    }));

    return truncatedSubtotal;
  };

  const removeItemFromBasket = (productId) => {
    // Remove the selected product from the basket
    const updatedBasketItems = basketItems.filter((item) => item.id !== productId);
    setBasketItems(updatedBasketItems);
  };




  const handleSubmit = async () => {
    const subtotal = calculaSubtotal(basketItems, formValues.imposto_percentual, formValues.desconto_percentual);
    try {
  
      const postData = {
        ordem_servico: {
          company_id: formValues.company_id,
          user_id: formValues.user_id,
          informacoes_do_servico: "-",
          status: "aberta",
          categoria: formValues.categoria,
          tipo_cliente: formValues.tipo_cliente,
          contrato: formValues.contrato,
          pagamento: formValues.pagamento,
          imposto_percentual: formValues.imposto_percentual,
          desconto_percentual: formValues.desconto_percentual,
          total: formValues.total,
        },
        basketItens: basketItems.map(item => ({
          id: item.id,
          nome: item.nome,
          categoria: item.categoria,
          valor: item.valor,
          unidade_de_medida: item.unidade_de_medida,
          quantity: item.quantity,
        })),
      };
      console.log(postData);
  
      // Make the POST request to your API endpoint
      const response = await API.post('/ordens_servico', postData, {
        headers: {
          Authorization: `Bearer ${token}`, // Assuming you have the token stored
        },
      });
  
      // Handle the response (you can log it or use it as needed)
      console.log('API Response:', response.data);
  
      if (response.data) {
        toast.success("Ordem Salva com Sucesso!");
        window.location.href = "/backoffice/faturas";
      } else {
        toast.error("Erro ao salvar a ordem de serviço.");
      }
  
      // Optionally, you can reset the form or perform any other actions after successful submission
      // ... other actions ...
    } catch (error) {
      toast.success("Ordem Salva com Sucesso!");
      window.location.href = "/backoffice/faturas";
      // Handle any errors that occurred during the request
      console.error('Error submitting data:', error);
    }
  };
  


  useEffect(() => {
    async function getTenants() {
      try {
        const endpoint = clientType === 'pj' ? 'backoffice/companies' : 'backoffice/users';

        const response = await API.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        console.log('Usuario:', data);
        setTenants(data);
      } catch (error) {
        console.log(error);
      }
    }

    async function getCategories() {
      try {
        const response = await API.get('backoffice/categorias', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        console.log('Categorias:', data);
        setCategories(data);
      } catch (error) {
        console.log(error);
      }
    }

    async function getProducts() {
      try {
        const response = await API.get('backoffice/products_invoices', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        console.log('Produtos:', data);
        setProducts(data);
      } catch (error) {
        console.log(error);
      }
    }

    getCategories();
    getProducts();
    getTenants();
  }, [clientType, token]);
  const formatarValorReais = (valor) => {
    if (!isNaN(valor)) {
      const valorFormatado = (valor / 1).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      return valorFormatado;
    } else {
      return 'Valor indisponível';
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Breadcrumbs parent="Home" title="Gerenciamento de Serviços" />
          <Card>
            <div className="card-header">
              <h5 className="card-title">Criar Novo Orçamento</h5>
            </div>
            <form>
              <Row className="justify-content-center col-12 d-flex align-items-center">
                <Col style={{ marginLeft: '1.5em', marginTop: '1.5em' }}>
                  <div className="section-title">
                    <strong>Informações do Serviço</strong>
                  </div>
                  <FormGroup>
                    <Label className="form-label">Categoria</Label>
                    <Input
                      className="form-control"
                      type="select"
                      onChange={handleInputChange}
                    >
                      <option value="">Selecione uma categoria</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">Produto</Label>
                    <Multiselect
                      options={products.map((product) => ({
                        nome: product.nome,
                        id: product.id,
                        categoria: product.categoria, // Include categoria property
                        valor: product.valor, // Include categoria property
                        unidade_de_medida: product.unidade_de_medida, // Include categoria property
                      }))}
                      selectedValues={selectedProducts}
                      onSelect={handleProductChange}
                      onRemove={handleProductChange}
                      groupBy="categoria" // Access categoria property for grouping
                      displayValue="nome"
                      emptyRecordMsg="Selecione um Produto"
                      placeholder="Selecione um Produto"
                    />
                    <Button
                      type="button"
                      color="primary"
                      className="btn mt-2"
                      onClick={addToBasket}
                    >
                      Adicionar ao Carrinho
                    </Button>
                  </FormGroup>
                  {basketItems.length > 0 && (
                    <Fragment>
                      <div className="card-header">
                        <h5 className="card-title">Carrinho</h5>
                      </div>
                      <Table>
                        <thead>
                          <tr>
                            <th>Produto</th>
                            <th>Categoria</th>
                            <th>Quantidade</th>
                            <th>Valor Unitario</th>
                            <th>Unidade de Medida</th>
                            <th>Remover</th>
                          </tr>
                        </thead>
                        <tbody>
                          {basketItems.map((item) => (
                            <tr key={item.id}>
                              <td>{item.nome}</td>
                              <td>{item.categoria}</td>
                              <td>
                                <Input
                                  type="text"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    handleQuantityChange(item.id, e.target.value)
                                  }
                                />
                              </td>
                              <td>{item.valor}</td>
                              <td>{item.unidade_de_medida}</td>

                              <td>
                                <Button
                                  color="danger"
                                  onClick={() => removeItemFromBasket(item.id)}
                                >
                                  Remover
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Fragment>
                  )}

                  <Row className="d-flex align-items-center">
                    <Col md={6} className="pr-0">
                      <FormGroup>
                        <Label className="form-label">Tipo Cliente</Label>
                        <Input
                          className="form-control"
                          type="select"
                          name="typecliente"
                          onChange={handleInputChange}
                        >
                          <option value="">Selecione o tipo de cliente</option>
                          <option value="pj">Pessoa Jurídica (PJ)</option>
                          <option value="pf">Pessoa Física (PF)</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6} className="pl-0">
                      <FormGroup>
                        <Label className="form-label">{clientType === 'pj' ? 'Empresa' : 'Cliente'}</Label>
                        <Input
                          type="select"
                          name={clientType === 'pj' ? "company_id" : "user_id"}
                          value={clientType === 'pj' ? formValues.company_id : formValues.user_id}
                          onChange={handleInputChange}
                        >
                          <option value="">
                            {clientType === 'pj' ? 'Selecione Empresa' : 'Selecione Cliente'}
                          </option>
                          {tenants.map((tenant) => (
                            <option key={tenant.id} value={clientType === 'pj' ? tenant.id : tenant.id}>
                              {clientType === 'pj' ? tenant.razao_social : tenant.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center">
                    <Col md={6} className="pr-0">
                      <FormGroup>
                        <Label className="form-label">Imposto (%)</Label>
                        <Input
                          className="form-control"
                          type="number"
                          step="0.01"
                          min="0"
                          name="imposto_percentual"
                          value={formValues.imposto_percentual}
                          disabled
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} className="pl-0">
                      <FormGroup>
                        <Label className="form-label">Desconto (%)</Label>
                        <Input
                          className="form-control"
                          type="number"
                          step="0.01"
                          min="0"
                          name="desconto_percentual"
                          value={formValues.desconto_percentual}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="text-right rowCustom">
                <Col className="text-right">
                  <Button
                    type="button"
                    color="outline-primary"
                    className="btn m-3"
                    onClick={handleSubmit}
                  >
                    Salvar alterações
                  </Button>
                  <Button
                    type="button"
                    color="outline-primary"
                    className="btn m-3"
                    onClick={handlePreview}
                  >
                    Preview Invoice
                  </Button>
                </Col>
              </Row>
            </form>
          </Card>
        </Col>
      </Row>
      <CommonModal isOpen={modal} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Invoice Preview</ModalHeader>
        <ModalBody>
          <h6>Cliente: {formValues.selectedClient}</h6>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Produto</th>
                <th>Categoria</th>
                <th>Quantidade</th>
                <th>Valor Unitário</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {basketItems.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.categoria}</td>
                  <td>{item.quantity}</td>
                  <td>{formatarValorReais(item.valor)}</td>
                  <td>{formatarValorReais(item.valor * item.quantity)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4" className="text-right">Subtotal:</td>
                <td>{formatarValorReais(formValues.subtotal)}</td>
              </tr>
              <tr>
                <td colSpan="4" className="text-right">Desconto:</td>
                <td>{formatarValorReais(formValues.desconto)}</td>
                <td>{formValues.descontoPercent}%</td>
              </tr>
              <tr>
                <td colSpan="4" className="text-right">Subtotal:</td>
                <td>{formatarValorReais(formValues.subtotalComDesconto)}</td>
              </tr>
              <tr>
                <td colSpan="4" className="text-right">Taxas:</td>
                <td>{formatarValorReais(formValues.imposto)}</td> {/* Corrigir esta linha conforme necessário */}
              </tr>
              <tr>
                <td colSpan="4" className="text-right">Total:</td>
                <td>{formatarValorReais(formValues.total)}</td>
              </tr>
            </tfoot>
          </table>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save Changes
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </CommonModal>
    </>
  );
};

export default ServiceInvoice;
