import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import RegisterFrom from './RegisterFrom';
// import { Image } from '../../../AbstractElements';
import Logo from "../../../assets/images/logo/tutorcasts_comp.png"
import "./Login.scss"

const RegisterVideo = () => {
  return (
    <Fragment>
      <section>
        <Container fluid={true} className='p-0'>
          <Row className='m-0'>
            <Col xl='7' className='b-center bg-size loginImg__bg'>
              <div className='loginImg'>
                <img className='loginImg__logo' src={Logo} alt="" />
              </div>
            </Col>
            <Col xl='5' className='p-0'>
              <RegisterFrom logoClassMain ="text-start"/>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default RegisterVideo;
