import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Form, Label, FormGroup, Button, Card, CardBody, Input } from 'reactstrap';
import { H6, UL, LI, H3, Btn } from '../../../../../AbstractElements';
import { API } from '../../../../../api/API/api';

import '../Styles/wizard.scss';

const Registration = ({ setSteps, setFormdata, formdata }) => {
  const token = localStorage.getItem('token');
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(formdata.plan || null);

  useEffect(() => {
    const getPlans = async () => {
      try {
        const response = await API.get('/backoffice/plans', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        console.log(data);

        setPlans(data);
      } catch (error) {
        console.log(error);
      }
    };
    getPlans();
  }, [token]);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFormdata((prev) => ({ ...prev, ...data }));
    setSteps((prev) => prev + 1);
  };

  const inputChange = (key, value) => {
    setFormdata({ ...formdata, [key]: value });
  };

  const selectPlan = (plan) => {
    inputChange('plan', plan.id);
    inputChange('price', plan.price);
    setSelectedPlan(plan.id);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
            <FormGroup className="mb-5">
              <Label>
                <H6>Qual será o nome do seu site?</H6>
              </Label>
              <input
                className={`form-control`}
                id="name"
                type="text"
                defaultValue={formdata.name || ''}
                name="name"
                onChange={(e) => inputChange('name', e.target.value)}
                required
              />
              <span className="text-danger">{errors.firstName && 'Campo Obrigatório'}</span>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label>
                <H6>Escolha seu plano</H6>
              </Label>
              <CardBody>
                <Row>
                  {plans.map((plan) => (
                    <Col lg='3' md='6'>
                      <div className='pricingtable' style={{ cursor: 'pointer', border: `${selectedPlan === plan.id ? '2px solid #007bff' : ''}`, padding: '5px' }} onClick={() => selectPlan(plan)}>
                        <div className='pricingtable-header'>
                          <H6 attrH3={{ className: 'title' }}>{plan.name}</H6>
                        </div>
                        <div className='price-value'>
                          <span className='currency'>{'$'}</span>
                          <span className='amount' style={{ fontSize: '25px' }}>{plan.price.toString().replace('.', ',')}</span>
                          <span className='duration'>{'/mês'}</span>
                        </div>
                        <UL attrUL={{ className: 'pricing-content flex-row' }}>
                          <LI attrLI={{ className: 'border-0' }}>{plan.max_storage} GB</LI>
                          <LI attrLI={{ className: 'border-0' }}>Total de Alunos: {plan.max_students}</LI>
                          <LI attrLI={{ className: 'border-0' }}>{plan.description}</LI>
                        </UL>
                        <div className='pricingtable-signup'>
                          <Button color='primary' type='button'>Selecionar</Button>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </FormGroup>
            <div className="text-end btn-mb">
              <Button className="primary">{"Avançar"}</Button>
            </div>
          </Form>

        </Col>
      </Row>
    </Fragment>
  );
};

export default Registration;
