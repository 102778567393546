import { useEffect, useState } from "react";
import "../styles/viewPageLinkTree.scss";
import { API } from "../../../../../../api/API/api";
import { useParams } from "react-router-dom";

const ViewPageLinkTree = () => {
  const [userData, setUserData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(`/linktree/page/${slug}`);
        console.log(response.data)
        setUserData(response.data);
      } catch (e) {
        console.error(e);
      }
    }
    getData();
  }, [slug]);

  function formatLink(link) {
    if (!link.startsWith("https://") && !link.startsWith("http://")) {
      return `https://${link}`;
    }
    return link;
  }

  return (
    userData && (
      <div className="bg" style={{ backgroundColor: userData.colorBackground }}>
        <main className="content">
          <div className="content-img-name">
            <div>
              {userData.image && (
                <img
                  src={
                    userData.image.startsWith("data:image")
                      ? userData.image
                      : `data:image/jpeg;base64,${userData.image}`
                  }
                  alt="Avatar"
                />
              )}
            </div>
            <div>
              <h2 style={{ color: userData.colorName }}>{userData.name}</h2>
            </div>
          </div>

          <div className="d-flex flex-column mt-4">
            {userData.links.map((link, index) => (
              <a
                key={`${link.linktree_id}_${index}`}
                href={formatLink(link.social_link)}
                target="_blank"
                rel="noopener noreferrer"
                className="list-link"
                style={{
                  color: userData.colorNameLink,
                  backgroundColor: userData.colorBackLink,
                }}
              >
                {link.social_name}
              </a>
            ))}
          </div>
        </main>

        <footer className="foot">
          <div className="footer-div">
            <span>
              Criado por:
              <br />
              <a
                href="http://tutorcasts.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tutorcasts
              </a>
            </span>
          </div>
        </footer>
      </div>
    )
  );
};

export default ViewPageLinkTree;
