import { Form, FormGroup, Label, Input, InputGroup, InputGroupText, Row, Col } from 'reactstrap'

export const Information = ({ formData, data, handleModalInputChange }) => {
  return (
    <Form style={{ padding: '5px', marginTop: '18px' }}>
      <Row>
        <Col>
          <FormGroup>
              <Label for='name'>Nome:</Label>
              <Input type='text' name='name' id='name' value={formData.name} onChange={handleModalInputChange} required />
          </FormGroup>
        </Col>

        <Col>
          <FormGroup>
            <Label for='code'>Código:</Label>
            <Input type='text' name='code' id='code' value={formData.code} onChange={handleModalInputChange} required />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
              <Label for='teacher_id'>Professor:</Label>
              <Input type='select' name='teacher_id' id='teacher_id' onChange={handleModalInputChange}>
                {data.teachers.map((teacher, index) => (
                    <option key={index} value={teacher.id} selected={teacher.id === formData.teacher_id}>
                        {teacher.name}
                    </option>
                ))}
              </Input>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for='workload'>Carga Horária:</Label>
            <Input type='number' min='0' name='workload' id='workload' value={formData.workload} onChange={handleModalInputChange} required />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for='quantity_encounters'>Quantidade de encontros:</Label>
            <Input type='number' min='0' name='quantity_encounters' id='quantity_encounters' value={formData.quantity_encounters} onChange={handleModalInputChange} required />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label for='description'>Conteúdo programatico:</Label>
        <Input type='text' name='description' id='description' value={formData.description} onChange={handleModalInputChange} required />
      </FormGroup>

      <FormGroup>
        <Label>Chatroom:</Label> 
        <InputGroup>
          <InputGroupText>
            <Input addon type='checkbox' name='chatroom_status' id='chatroom_status' onChange={handleModalInputChange} checked={formData.chatroom_status} />
          </InputGroupText>
            <Input type='textarea' name='chatroom' id='chatroom' value={formData.chatroom} onChange={handleModalInputChange} checked={formData.chatroom} disabled={!formData.chatroom_status} />
        </InputGroup>

      </FormGroup>  
    </Form>
  )
}