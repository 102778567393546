import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Card, Row, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Accordion, AccordionBody, AccordionHeader, AccordionItem, Alert } from 'reactstrap'
import { H6 } from '../../../../../../AbstractElements'

import { API } from '../../../../../../api/API/api';
import '../../../_Commons/Styles/Styles.scss';

const formDataDefault = {
    default: {
        document: null,
        name: null,
        email: null,
        type: null,
        status: null,
        bank: null,
        agency: null,
        agency_digit: null,
        account: null,
        account_digit: null,
        account_type: null
    },

    pagarme: {
        api_key: null,
        encryption_key: null,
        recipient_id: null
    }
}

const banks = [
    { value: '001', label: 'Banco do Brasil' },
    { value: '003', label: 'Banco da Amazônia' },
    { value: '004', label: 'Banco do Nordeste' },
    { value: '021', label: 'Banestes' },
    { value: '025', label: 'Banco Alfa' },
    { value: '027', label: 'Besc' },
    { value: '029', label: 'Banerj' },
    { value: '031', label: 'Banco Beg' },
    { value: '033', label: 'Banco Santander Banespa' },
    { value: '036', label: 'Banco Bem' },
    { value: '037', label: 'Banpará' },
    { value: '038', label: 'Banestado' },
    { value: '039', label: 'BEP' },
    { value: '040', label: 'Banco Cargill' },
    { value: '041', label: 'Banrisul' },
    { value: '044', label: 'BVA' },
    { value: '045', label: 'Banco Opportunity' },
    { value: '047', label: 'Banese' },
    { value: '062', label: 'Hipercard' },
    { value: '063', label: 'Ibibank' },
    { value: '065', label: 'Lemon Bank' },
    { value: '066', label: 'Banco Morgan Stanley Dean Witter' },
    { value: '069', label: 'BPN Brasil' },
    { value: '070', label: 'Banco de Brasília – BRB' },
    { value: '072', label: 'Banco Rural' },
    { value: '073', label: 'Banco Popular' },
    { value: '074', label: 'Banco J. Safra' },
    { value: '075', label: 'Banco CR2' },
    { value: '076', label: 'Banco KDB' },
    { value: '096', label: 'Banco BMF' },
    { value: '104', label: 'Caixa Econômica Federal' },
    { value: '107', label: 'Banco BBM' },
    { value: '116', label: 'Banco Único' },
    { value: '151', label: 'Nossa Caixa' },
    { value: '175', label: 'Banco Finasa' },
    { value: '184', label: 'Banco Itaú BBA' },
    { value: '204', label: 'American Express Bank' },
    { value: '208', label: 'Banco Pactual' },
    { value: '212', label: 'Banco Matone' },
    { value: '213', label: 'Banco Arbi' },
    { value: '214', label: 'Banco Dibens' },
    { value: '217', label: 'Banco Joh Deere' },
    { value: '218', label: 'Banco Bonsucesso' },
    { value: '222', label: 'Banco Calyon Brasil' },
    { value: '224', label: 'Banco Fibra' },
    { value: '225', label: 'Banco Brascan' },
    { value: '229', label: 'Banco Cruzeiro' },
    { value: '230', label: 'Unicard' },
    { value: '233', label: 'Banco GE Capital' },
    { value: '237', label: 'Bradesco' },
    { value: '241', label: 'Banco Clássico' },
    { value: '243', label: 'Banco Stock Máxima' },
    { value: '246', label: 'Banco ABC Brasil' },
    { value: '248', label: 'Banco Boavista Interatlântico' },
    { value: '249', label: 'Investcred Unibanco' },
    { value: '250', label: 'Banco Schahin' },
    { value: '252', label: 'Fininvest' },
    { value: '254', label: 'Paraná Banco' },
    { value: '263', label: 'Banco Cacique' },
    { value: '260', label: 'Nu. Pagamentos' },
    { value: '265', label: 'Banco Fator' },
    { value: '266', label: 'Banco Cédula' },
    { value: '300', label: 'Banco de la Nación Argentina' },
    { value: '318', label: 'Banco BMG' },
    { value: '320', label: 'Banco Industrial e Comercial' },
    { value: '356', label: 'ABN Amro Real' },
    { value: '341', label: 'Itau' },
    { value: '347', label: 'Sudameris' },
    { value: '351', label: 'Banco Santander' },
    { value: '353', label: 'Banco Santander Brasil' },
    { value: '366', label: 'Banco Societe Generale Brasil' },
    { value: '370', label: 'Banco WestLB' },
    { value: '376', label: 'JP Morgan' },
    { value: '389', label: 'Banco Mercantil do Brasil' },
    { value: '394', label: 'Banco Mercantil de Crédito' },
    { value: '399', label: 'HSBC' },
    { value: '409', label: 'Unibanco' },
    { value: '412', label: 'Banco Capital' },
    { value: '422', label: 'Banco Safra' },
    { value: '453', label: 'Banco Rural' },
    { value: '456', label: 'Banco Tokyo Mitsubishi UFJ' },
    { value: '464', label: 'Banco Sumitomo Mitsui Brasileiro' },
    { value: '477', label: 'Citibank' },
    { value: '479', label: 'Itaubank (antigo Bank Boston)' },
    { value: '487', label: 'Deutsche Bank' },
    { value: '488', label: 'Banco Morgan Guaranty' },
    { value: '492', label: 'Banco NMB Postbank' },
    { value: '494', label: 'Banco la República Oriental del Uruguay' },
    { value: '495', label: 'Banco La Provincia de Buenos Aires' },
    { value: '505', label: 'Banco Credit Suisse' },
    { value: '600', label: 'Banco Luso Brasileiro' },
    { value: '604', label: 'Banco Industrial' },
    { value: '610', label: 'Banco VR' },
    { value: '611', label: 'Banco Paulista' },
    { value: '612', label: 'Banco Guanabara' },
    { value: '613', label: 'Banco Pecunia' },
    { value: '623', label: 'Banco Panamericano' },
    { value: '626', label: 'Banco Ficsa' },
    { value: '630', label: 'Banco Intercap' },
    { value: '633', label: 'Banco Rendimento' },
    { value: '634', label: 'Banco Triângulo' },
    { value: '637', label: 'Banco Sofisa' },
    { value: '638', label: 'Banco Prosper' },
    { value: '643', label: 'Banco Pine' },
    { value: '652', label: 'Itaú Holding Financeira' },
    { value: '653', label: 'Banco Indusval' },
    { value: '654', label: 'Banco A.J. Renner' },
    { value: '655', label: 'Banco Votorantim' },
    { value: '707', label: 'Banco Daycoval' },
    { value: '719', label: 'Banif' },
    { value: '721', label: 'Banco Credibel' },
    { value: '734', label: 'Banco Gerdau' },
    { value: '735', label: 'Banco Pottencial' },
    { value: '738', label: 'Banco Morada' },
    { value: '739', label: 'Banco Galvão de Negócios' },
    { value: '740', label: 'Banco Barclays' },
    { value: '741', label: 'BRP' },
    { value: '743', label: 'Banco Semear' },
    { value: '745', label: 'Banco Citibank' },
    { value: '746', label: 'Banco Modal' },
    { value: '747', label: 'Banco Rabobank International' },
    { value: '748', label: 'Banco Cooperativo Sicredi' },
    { value: '749', label: 'Banco Simples' },
    { value: '751', label: 'Dresdner Bank' },
    { value: '752', label: 'BNP Paribas' },
    { value: '753', label: 'Banco Comercial Uruguai' },
    { value: '755', label: 'Banco Merrill Lynch' },
    { value: '756', label: 'Banco Cooperativo do Brasil' },
    { value: '757', label: 'KEB' },
]

export default function Providers() {
    const { tenant_id } = useParams();
    const [formData, setFormData] = useState(formDataDefault);

    const [open, setOpen] = useState(1);
    const toggle = (id) => open === id ? setOpen() : setOpen(id)

    const apiPath = `/tenants/${tenant_id}/management/payments/providers`

    useEffect(() => {
        async function getData() {
            try {
                const response = await API.get(apiPath);
                setFormData(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, [tenant_id]);


    const handleInputChange = (e, provider) => {
        const { name, value } = e.target

        setFormData((prevFormData) => ({
            ...prevFormData,
            [provider]: { ...prevFormData[provider], [name]: value },
        }))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await API.put(apiPath, formData);
            toast.success('Sucesso!')

        } catch (error) {
            console.log(error);
            toast.error('Error')
        }
    };

    return (
        <>
            <Card className='col-12' style={{ marginBottom: '8.5em' }}>
                <div className='card-header'>
                    <h5 className='card-title p-b-0 p-t-0'>Provedores de Pagamento</h5>
                </div>
                <Form>
                    <Row className='m-4'>

                        <Col md='12' className='mb-3'>
                            <Accordion open={open} toggle={toggle}>
                                <AccordionItem>
                                    <AccordionHeader targetId='1'>Pagarme (Marktplace - v5)</AccordionHeader>
                                    <AccordionBody accordionId='1'>
                                        <Alert color='light'>
                                            <p><strong>Sobre a parceria da Growth One Learn com o Pagarme</strong></p>
                                            <p>Receba com segurança pagamentos por cartão de crédito e boleto, aceitando as principais bandeiras do mercado sem a necessidade de abertura de conta em provedores de pagamentos.</p>

                                            <p><strong>Instruções de configuração:</strong></p>
                                            <ul>
                                                <li>Não é necessário nenhuma configuração adicional.</li>
                                            </ul>
                                            <br />
                                            <p class='m-5-0'>Ficou com dúvidas? <a href='https://suporte.g1learn.com' target='_blank'>Clique aqui e fale com o nosso suporte.</a>
                                            </p>
                                            <br />
                                            {/* <Row xs='3'>
                                                <div class='col-sm-2'>
                                                    <p class='m-5-0'><strong>Taxa de transação:</strong><br>0.00%</p>
                                                </div>
                                                <div class='col-sm-2'>
                                                    <p class='m-5-0'><strong>Taxa de parcelamento:</strong><br> 0.00% por parcela</p>
                                                </div>
                                                <div class='col-sm-2'>
                                                    <p class='m-5-0'><strong>Recebimento em:</strong><br>0 dias</p></div>
                                            </Row> */}
                                        </Alert>

                                        <H6>Tipo de recebedor:</H6>
                                        <FormGroup>
                                            <Input type='select' name='type' id='type' onChange={(e) => handleInputChange(e, 'default')}>
                                                <option value='individual' selected={formData.default ? ('individual' === formData.default.type) : false}>
                                                    Pessoa física
                                                </option>

                                                <option value='company' selected={formData.default ? ('company' === formData.default.type) : false}>
                                                    Pessoa jurídica
                                                </option>
                                            </Input>
                                        </FormGroup>

                                        <H6>CPF/CNPJ:</H6>
                                        <FormGroup>
                                            <Input type='text' name='document' value={formData.default ? formData.default.document : ''} onChange={(e) => handleInputChange(e, 'default')} />
                                        </FormGroup>

                                        <H6>Nome completo:</H6>
                                        <FormGroup>
                                            <Input type='text' name='name' value={formData.default ? formData.default.name : ''} onChange={(e) => handleInputChange(e, 'default')} />
                                        </FormGroup>

                                        <H6>Email:</H6>
                                        <FormGroup>
                                            <Input type='text' name='email' value={formData.default ? formData.default.email : ''} onChange={(e) => handleInputChange(e, 'default')} />
                                        </FormGroup>

                                        <H6>Banco:</H6>
                                        <FormGroup>
                                            <Input type='select' name='type' id='type' onChange={(e) => handleInputChange(e, 'default')}>
                                                {banks.map((bank, index) => (
                                                    <option key={`bank-${index}`} value={bank.value} selected={formData.default ? (bank.value === formData.default.bank) : false}>
                                                        {bank.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>

                                        <H6>Tipo de conta bancária:</H6>
                                        <FormGroup>
                                            <Input type='select' name='account_type' id='account_type' onChange={(e) => handleInputChange(e, 'default')}>
                                                <option value='checking' selected={formData.default ? ('checking' === formData.default.account_type) : false}>
                                                    Conta corrente
                                                </option>
                                                <option value='conjunct_checking' selected={formData.default ? ('conjunct_checking' === formData.default.account_type) : false}>
                                                    Conta corrente conjunta
                                                </option>
                                                <option value='savings' selected={formData.default ? ('savings' === formData.default.account_type) : false}>
                                                    Conta poupança
                                                </option>
                                                <option value='conjunct_savings' selected={formData.default ? ('conjunct_savings' === formData.default.account_type) : false}>
                                                    Conta poupança conjunta
                                                </option>
                                            </Input>
                                        </FormGroup>

                                        <H6>Agência:</H6>
                                        <FormGroup>
                                            <Input type='text' name='agency' value={formData.default ? formData.default.agency : ''} onChange={(e) => handleInputChange(e, 'default')} />
                                        </FormGroup>

                                        <H6>Dígito:</H6>
                                        <FormGroup>
                                            <Input type='text' name='agency_digit' value={formData.default ? formData.default.agency_digit : ''} onChange={(e) => handleInputChange(e, 'default')} />
                                        </FormGroup>

                                        <H6>Conta:</H6>
                                        <FormGroup>
                                            <Input type='text' name='agency' value={formData.default ? formData.default.account : ''} onChange={(e) => handleInputChange(e, 'default')} />
                                        </FormGroup>

                                        <H6>Dígito:</H6>
                                        <FormGroup>
                                            <Input type='text' name='agency_digit' value={formData.default ? formData.default.account_digit : ''} onChange={(e) => handleInputChange(e, 'default')} />
                                        </FormGroup>

                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId='2'>Pagarme (v5)</AccordionHeader>
                                    <AccordionBody accordionId='2'>
                                        <Alert color='light'>
                                            <p><strong>Sobre o Pagarme</strong></p>
                                            <p>Receba com segurança pagamentos no seu site e aplicativo por cartão de crédito e boleto, aceitando as principais bandeiras do mercado.</p>

                                            <p><strong>Instruções de configuração:</strong></p>
                                            <ul>
                                                <li>1. Criar uma conta no  <a href='https://pagar.me/ofertas/'
                                                    target='_blank' title='Consultar taxas no site do Pagarme'>Pagarme</a>.
                                                </li>
                                                <li>2. Faça login e ative sua conta no <a
                                                    href='https://pagar.me/ofertas/' target='_blank'
                                                    title='Consultar taxas no site do Pagarme'>Pagarme</a>.
                                                </li>
                                                <li>3. Crie uma chave de API privada e uma chave de API pública e insira no formulário de configuração de conta abaixo.
                                                </li>
                                                <li>4. Adicione um webhook com todos os itens de cobrança selecionados para a URL
                                                    <code>https://postback.g1learn.app/api/postback/pagarme/v5</code>.
                                                </li>
                                            </ul>
                                            <br />
                                            <p class='m-5-0'>
                                                Ficou com dúvidas? <a href='https://pagarme.zendesk.com/hc/pt-br' target='_blank'>Clique aqui e fale com o suporte do Pagarme.</a>
                                            </p>
                                            <br />
                                            <Row xs='4'>
                                                <p class='m-5-0'><strong>Taxa de cartão de crédito:</strong>
                                                    <br />
                                                    Definida por <a href='https://pagar.me/ofertas/' target='_blank' title='Consultar taxas no site do Pagarme'> Pagarme</a>
                                                </p>

                                                <p class='m-5-0'><strong>Taxa de parcelamento:</strong>
                                                    <br />
                                                    Definida por <a href='https://pagar.me/ofertas/' target='_blank' title='Consultar taxas no site do Pagarme'> Pagarme</a>
                                                </p>
                                                <p class='m-5-0'><strong>Taxa de boleto bancário:</strong>
                                                    <br />
                                                    Definida por <a href='https://pagar.me/ofertas/' target='_blank' title='Consultar taxas no site do Pagarme'> Pagarme</a>
                                                </p>
                                                <p class='m-5-0'><strong>Recebimento em:</strong>
                                                    <br />
                                                    Definida por <a href='https://pagar.me/ofertas/' target='_blank' title='Consultar taxas no site do Pagarme'> Pagarme</a>
                                                </p>

                                            </Row>
                                        </Alert>

                                        <H6>ID da conta:</H6>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupText><span class='fa fa-lock' /></InputGroupText>
                                                <Input type='text' name='recipient_id' value={formData.pagarme ? formData.pagarme.recipient_id : ''} onChange={(e) => handleInputChange(e, 'pagarme')} />
                                            </InputGroup>
                                        </FormGroup>

                                        <H6>Chave pública:</H6>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupText><span class='fa fa-key' /></InputGroupText>
                                                <Input type='text' name='api_key' value={formData.pagarme ? formData.pagarme.api_key : ''} onChange={(e) => handleInputChange(e, 'pagarme')} />
                                            </InputGroup>
                                        </FormGroup>

                                        <H6>Chave secreta:</H6>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupText><span class='fa fa-key' /></InputGroupText>
                                                <Input type='text' name='encryption_key' value={formData.pagarme ? formData.pagarme.encryption_key : ''} onChange={(e) => handleInputChange(e, 'pagarme')} />
                                            </InputGroup>
                                        </FormGroup>

                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </Col>
                    </Row>

                    <Row className='text-right rowCustom'>
                        <Col className='text-right'>
                            <button type='submit' className='btn btn-outline-primary m-3' onClick={handleSubmit}>
                                Salvar alterações
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    );
}