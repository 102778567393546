import { useState } from "react"
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Table } from "../../../../../_Commons/Table/Index";

export const Classes = ({
  teachers,
  registeringClass,
  moduleName,
  data,
  deleteClass,
  searchQuery,
  setSearchQuery,
  handleSearch,
  currentPage,
  setCurrentPage,
  handleChangePagination,
  itemsPerPage,
  setItemsPerPage,
  lastPage,
  handleSort,
  sortColumn,
  descendingSort
}) => {

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    video: '',
    teacher_id: ''
  });

  const [showForm, setShowForm] = useState(false); 

  const toggleForm = () => {
    setShowForm(prevState => !prevState);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFormData((prevState) => ({
        ...prevState,
        video: reader.result
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handleSubmitForm = (e) => {
    e.preventDefault();
    console.log('FormData:', formData);
    registeringClass(formData);
    setFormData({
      description: '',
      teacher_id: '',
      title: '',
      video: ''
    });
    toggleForm();
  }

  const setTableData = (payloads) => ({
    columns: ['Id', 'Título', 'Descrição', 'Professor'],
    rows: payloads.map(item => [item.id, item.title, item.description, item.teacher]),
    payloads: payloads
  });

  return (
    <>
      {
        !showForm && (
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <Button
                color="primary"
                type="button"
                onClick={toggleForm}
              >
                Adicionar Aula
              </Button>
            </Col>
          </Row>
        )
      }
      {
        showForm && (
          <Form style={{ marginTop: '18px' }} onSubmit={handleSubmitForm}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Título</Label>
                  <Input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Professor</Label>
                  <Input
                    type="select"
                    name="teacher_id"
                    value={formData.teacher_id}
                    onChange={handleInputChange}
                    className="mr-2"
                  >
                    <option value="">Selecione um professor</option>
                    {teachers && teachers.map((item) => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Descrição</Label>
                  <Input
                    type="textarea"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Adicionar vídeo</Label>
                  <Input
                    type="file"
                    name="video"
                    onChange={handleFileChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            
            <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
              <Button color="danger" type="button" onClick={() => toggleForm()}>Cancelar</Button>
              <Button color="primary" type="submit">Cadastrar</Button>
            </div>
          </Form>
        )
      }

      {
        !showForm && (
          <Table
            header={`Aulas do módulo ${moduleName}`}
            data={setTableData(data)}
            onDeleteClick={(payload) => deleteClass(payload.id)}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            search={true}
            handleSearch={handleSearch}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleChangePagination={handleChangePagination}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            pagination={true}
            lastPage={lastPage}
            handleSort={handleSort}
            sortColumn={sortColumn}
            sortDirection={descendingSort}
          />
        )
      }
    </>
  )
}