import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Slider } from 'primereact/slider';
import InputMask from 'react-input-mask';
import CommonModal from '../../UiKits/Modals/common/modal';
import { ToastContainer, toast } from "react-toastify";

import { Breadcrumbs, Btn } from '../../../AbstractElements';
import { API } from '../../../api/API/api';
import './pages.scss';
import './Planos.scss';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';

export default function Planos() {
    const token = localStorage.getItem('token');
    const [plans, setPlans] = useState([]);
    const [isAddPlanoModalVisible, setIsAddPlanoModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        id: null,
        name: '',
        description: '',
        price: 0,
        max_students: 0,
        extra_students: 0,
        gateway_percent: 0,
        extra_storage: 0,
        max_storage: 5,
        is_active: true,
    });

    const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
    const [planToDelete, setPlanToDelete] = useState(null);

    const getPlans = async () => {
        try {
            const response = await API.get('/backoffice/plans', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data;
            console.log(data);

            setPlans(data);
        } catch (error) {
            console.log(error);
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'is_active' ? value === 'true' : value,
        });
    };

    const getSeverity = (value) => {
        switch (value) {
            case 'activated':
                return 'success';

            case 'deactivating':
                return 'danger';

            default:
                return null;
        }
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <Tag
                value={rowData.is_active === true ? 'ativo' : 'inativo'}
                severity={getSeverity(rowData.is_active === true ? 'activated' : 'deactivating')}
            ></Tag>
        );
    };

    const espacoBodyTemplate = (rowData) => {
        if (rowData && typeof rowData.max_storage !== 'undefined') {
            const maxStorageInGB = rowData.max_storage;
            if (maxStorageInGB >= 1000) {
                const maxStorageInTB = (maxStorageInGB / 1000).toFixed(2);
                return maxStorageInTB + ' TB';
            } else {
                return maxStorageInGB + ' GB';
            }
        } else {
            return 'Valor indisponível';
        }
    };

    const formatarValorReais = (valor) => {
        if (!isNaN(valor)) {
            return 'R$ ' + valor.toString().replace('.', ',');
        } else {
            return 'Valor indisponível';
        }
    };



    const openAddPlanoModal = () => {
        setIsEditing(false);
        setIsAddPlanoModalVisible(true);
    };


    const closeAddPlanoModal = () => {
        setIsAddPlanoModalVisible(false);
        setIsEditing(false);
        setFormData({
            id: null,
            name: '',
            description: '',
            price: 0,
            max_students: 0,
            extra_students: 0,
            extra_storage: 0,
            max_storage: 5,
            is_active: true,
        });
    };

    useEffect(() => {
        async function getPlans() {
            try {
                const response = await API.get('/backoffice/plans', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response.data;
                setPlans(data);
            } catch (error) {
                console.error('Erro na exclusão do plano:', error);
                toast.error('Erro na exclusão do plano');
            }
        }
        getPlans();
    }, []);

    const renderActions = (rowData) => {
        return (
            <div className="row">
                <button type="button" className="buttns btn btn-outline-primary" onClick={() => handleEdit(rowData)}>
                    <i className="fa fa-edit fa-lg"></i>
                </button>
                <button type="button" className="buttns btn btn-outline-secondary custom-danger" onClick={() => openDeleteConfirmationModal(rowData)}>
                    <i className="fa fa-trash-o fa-lg"></i>
                </button>

            </div>
        );
    };


    const handleEdit = (rowData) => {
        setIsEditing(true);
        setIsAddPlanoModalVisible(true);
        setFormData({
            id: rowData.id,
            name: rowData.name,
            description: rowData.description,
            price: rowData.price,
            max_students: rowData.max_students,
            extra_students: rowData.extra_students,
            extra_storage: rowData.extra_storage,
            max_storage: rowData.max_storage,
            is_active: rowData.is_active,
        });
    };

    const handleDelete = (rowData) => {
        setIsEditing(false);
        setIsDeleteConfirmationModalVisible(true);
        setPlanToDelete(rowData);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(formData);

            if (isEditing) {
                // Faça a requisição PUT para atualizar o plano
                const response = await API.put(`/backoffice/plans/${formData.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log('Plano atualizado com sucesso!', response);
                toast.success('Plano atualizado com sucesso!');

            } else {
                // Faça a requisição POST para criar um novo plano
                const response = await API.post('/backoffice/plans', formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log('Plano criado com sucesso!', response);
                toast.success('Plano criado com sucesso!');

            }

            closeAddPlanoModal();
            getPlans();
        } catch (error) {
            console.error('Erro na requisição:', error);
            toast.error('Erro na requisição:', error);

        }
    };
    const openDeleteConfirmationModal = (rowData) => {
        setIsDeleteConfirmationModalVisible(true);
        setPlanToDelete(rowData);
    };

    const closeDeleteConfirmationModal = () => {
        setIsDeleteConfirmationModalVisible(false);
        setPlanToDelete(null);
    };

    const confirmDelete = async () => {
        try {
            // Faça a requisição DELETE para excluir o plano
            const response = await API.delete(`/backoffice/plans/${planToDelete.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Lógica para tratamento de sucesso e recarregamento dos dados
            console.log('Plano excluído com sucesso!', response);

            closeDeleteConfirmationModal();
            getPlans();
        } catch (error) {
            // Lógica para tratamento de erros
            console.error('Erro na exclusão do plano:', error);
        }
    };

    return (
        <Fragment>

            <Container fluid={true}>
            <Breadcrumbs mainTitle='Planos' parent='Home' title='Planos' style={{marginLeft:'1.9em'}} />
                
            <Btn
                attrBtn={{ 
                    color: 'primary', className: 'm-b-15 col-2 d-flex justify-content-center', outline: true,
                onClick: openAddPlanoModal }}
            >
                Novo
            </Btn>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <DataTable
                                value={plans}
                                paginator
                                rows={15}
                                sortMode='multiple'
                                selectionMode='single'
                                dataKey='id'
                                metaKeySelection={false}
                            >
                                <Column field='id' sortable style={{ width: '5%' }} header='Id' />
                                <Column field='name' sortable style={{ width: '15%' }} header='Nome' />
                                <Column field='price' sortable body={(rowData) => formatarValorReais(rowData.price)} style={{ width: '10%' }} header='Valor' />
                                <Column
                                    field='max_storage'
                                    body={espacoBodyTemplate}
                                    sortable
                                    style={{ width: '15%' }}
                                    header='Espaço'
                                />
                                <Column field='is_active' sortable body={statusBodyTemplate} style={{ width: '10%' }} header='Status' />
                                <Column field='max_students' sortable style={{ width: '10%' }} header='Qtd de Alunos' />
                                <Column field='extra_students' body={(rowData) => formatarValorReais(rowData.extra_students)} sortable style={{ width: '10%' }} header='Extra Alunos' />
                                <Column field='extra_storage' body={(rowData) => formatarValorReais(rowData.extra_storage)} sortable style={{ width: '10%' }} header='Extra GB' />
                                <Column header='Ações' body={renderActions} />
                            </DataTable>

                        </Card>
                    </Col>
                </Row>
            </Container>
            <CommonModal isOpen={isAddPlanoModalVisible} title="Cadastro de Plano" toggler={closeAddPlanoModal} size="lg">
                {/* Conteúdo do modal de cadastro */}
                <form onSubmit={handleFormSubmit} className="form-columns">
                    <div className="form-column">
                        <div className="form-group">
                            <h6
                                htmlFor="name"
                                className="card-title p-b-0 p-t-0" >
                                Informações Básicas
                            </h6>
                            <div className="basic-info">
                                <div className="form-group">
                                    <label htmlFor="name" className="form-label">Nome do Plano</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description" className="form-label">Descrição</label>
                                    <input
                                        type="text"
                                        id="description"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="price" className="form-label">Preço do Plano</label>
                                    <input
                                        type="number"
                                        id="price"
                                        name="price"
                                        value={formData.price}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="extra_students" className="form-label">Preço Aluno Extra</label>
                                    <input
                                        type="number"
                                        id="extra_students"
                                        name="extra_students"
                                        value={formData.extra_students}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="form-group">
                            <h6
                                htmlFor="extra_storage"
                                className="card-title p-b-0 p-t-0 mt-2" 
                            >
                                Recursos Adicionais
                            </h6>
                            <div className="additional-info">
                                <div className="form-group">
                                    <label htmlFor="extra_storage" className="form-label">Preço GB Extra</label>
                                    <input
                                        type="number"
                                        id="extra_storage"
                                        name="extra_storage"
                                        value={formData.extra_storage}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="gateway_percent" className="form-label">Percentual do Gateway</label>
                                    <input
                                        type="number"
                                        id="gateway_percent"
                                        name="gateway_percent"
                                        value={formData.gateway_percent}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="max_students" className="form-label">Máximo de Alunos</label>
                                    <input
                                        type="number"
                                        id="max_students"
                                        name="max_students"
                                        value={formData.max_students}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="max_storage" className="form-label">Espaço em Disco (em GB)</label>
                                    <div className="slider-value">
                                        <input
                                            type="number"
                                            id="max_storage"
                                            name="max_storage"
                                            value={formData.max_storage}
                                            onChange={handleInputChange}
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                    {/* <Slider
                                        min={1}
                                        max={100}
                                        step={1}
                                        value={formData.max_storage}
                                        className="slider"
                                        onChange={(e) => setFormData({ ...formData, max_storage: e.value })}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="is_active" className="form-label">Status</label>
                            <div className="status-info">
                                <div className="form-group">
                                    <label className="form-check-label">
                                        <input
                                            type="radio"
                                            name="is_active"
                                            value="true"
                                            className="form-check-input check-input"
                                            checked={formData.is_active === true}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        Ativo
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label className="form-check-label">
                                        <input
                                            type="radio"
                                            name="is_active"
                                            value="false"
                                            className="form-check-input check-input"
                                            checked={formData.is_active === false}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        Inativo
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="d-block w-100 mt-2 btn btn-outline-primary">
                        Salvar
                    </button>

                </form>

            </CommonModal>
            <CommonModal
                isOpen={isDeleteConfirmationModalVisible}
                title={`Confirmação de Exclusão - ${planToDelete && planToDelete.name}`}
                toggler={closeDeleteConfirmationModal}
            >
                <div>
                    <p>Tem certeza de que deseja excluir o plano: {planToDelete && planToDelete.name}?</p>
                </div>
                <div>
                    <button className="btn btn-outline-secondary" onClick={closeDeleteConfirmationModal}>
                        Cancelar
                    </button>
                    <button className="btn btn-outline-danger custom-danger" onClick={confirmDelete}>
                        Excluir
                    </button>
                </div>

            </CommonModal>




        </Fragment >
    );
}
