import React, { useState, useEffect } from 'react';
import { API } from '../../../../../../api/API/api';
import { useParams } from 'react-router-dom';  // Assuming you're using React Router
import Files from "react-files";
import { Btn, Breadcrumbs } from "../../../../../../AbstractElements";

import '../../LinkTree/styles/linktree.scss';
import {
    Container,
    Row,
    Col,
    Input,
    Card,
  } from "reactstrap";

export default function EditLinktree() {
    const [userData, setUserData] = useState(null);
    const { slug } = useParams();
    const [formData, setFormData] = useState({
        name: "Seu nome",
        image: "",
        colorBackground: "#00FEFF",
        colorName: "#000000",
        colorNameLink: "#000000",
        colorBackLink: "#FFFFFF",
    });
    useEffect(() => {
        async function getData() {
            try {
                const response = await API.get(`/linktree/page/${slug}`);
                console.log(response.data);
                setUserData(response.data);
                setFormData(response.data);
            } catch (e) {
                console.error(e);
            }
        }

        getData();
    }, [slug]);
    const handleImageChange = (files) => {
        const file = files[0];
    
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setFormData((prevData) => ({ ...prevData, image: reader.result }));
          };
          reader.readAsDataURL(file);
        }
      };
    
      const handleChange = (e) => {
        const inputValue = e.target.value;
        setFormData((prevData) => ({
          ...prevData,
          name: inputValue !== "" ? inputValue : "Seu nome",
        }));
      };
    
      const handleColorBackground = (event) => {
        const newColor = event.target.value;
        setFormData((prevData) => ({ ...prevData, colorBackground: newColor }));
      };
    
      const handleColorName = (event) => {
        const newColorName = event.target.value;
        setFormData((prevData) => ({ ...prevData, colorName: newColorName }));
      };
    
      const handleColorBackLink = (event) => {
        const newColorBackLink = event.target.value;
        setFormData((prevData) => ({
          ...prevData,
          colorBackLink: newColorBackLink,
        }));
      };
    
      const handleColorNameLink = (event) => {
        const newColorNameLink = event.target.value;
        setFormData((prevData) => ({
          ...prevData,
          colorNameLink: newColorNameLink,
        }));
      };
    return (
        <Row className="test" style={{ width: '100vw', minHeight: '100vh', background: '#f8f9fa' }}>
            <Breadcrumbs parent="Home" mainTitle="Edição link da bio" title="Edição Link Bio" subParent="Products" thirdRelative="EditorPage" />
            <Card className='col-5 p-20' style={{ marginRight: '20px', marginLeft: '20px' }}>
            <Col>
                <label className="LabelsText" htmlFor="Name">
                    Nome
                </label>
                <Input
                    placeholder="Seu nome"
                    name="name"
                    value={formData.name === "Seu nome" ? "" : formData.name}
                    onChange={handleChange}
                    autoComplete="name"
                    required
                />

                <label className="LabelsText" htmlFor="fileInput">
                    Sua foto
                </label>
                <div className="d-flex align-items-center gap-4 ">
                    {formData.image ? (
                        <img
                            src={formData.image}
                            alt="avatar"
                            className="avatar-input"
                        />
                    ) : (
                        <img
                            src="https://static-public.klickpages.com.br/uploads/media/file/5310958/foto-03.jpg"
                            alt="avatar"
                            name="fileInput"
                            className="avatar-input"
                        />
                    )}
                    <Files
                        className="files-dropzone fileContainer"
                        onChange={(files) => handleImageChange(files)}
                        accepts={["image/*"]}
                        multiple={false}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                    >
                        <div className="d-flex justify-content-center">
                            <Btn
                                attrBtn={{
                                    className: "mt-2",
                                    type: "button",
                                    color: "primary",
                                }}
                            >
                                Enviar foto
                            </Btn>
                        </div>
                    </Files>
                </div>
                <div>
                    <h5>Cores</h5>
                    <div className="d-flex flex-column gap-5">
                        <div className="d-flex gap-5">
                            <div className="d-flex flex-column">
                                <label htmlFor="bgColor">Cor de fundo:</label>
                                <input
                                    type="color"
                                    name="bgColor"
                                    value={formData.colorBackground}
                                    onChange={handleColorBackground}
                                />
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="colorName">Cor do nome:</label>
                                <input
                                    type="color"
                                    name="colorName"
                                    value={formData.colorName}
                                    onChange={handleColorName}
                                />
                            </div>
                        </div>
                        <div className="d-flex gap-4">
                            <div className="d-flex flex-column">
                                <label htmlFor="bottonColor">Cor dos botões:</label>
                                <input
                                    type="color"
                                    name="bottonColor"
                                    value={formData.colorBackLink}
                                    onChange={handleColorBackLink}
                                />
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="colorText">Cor do texto dos botões:</label>
                                <input
                                    type="color"
                                    name="colorText"
                                    value={formData.colorNameLink}
                                    onChange={handleColorNameLink}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            </Card>

            <Col className="mb-xxl-1 d-flex justify-content-start w-100">
                <div
                    className="repro-content"
                    style={{ backgroundColor: formData.colorBackground, marginBottom: '50px' }}
                >
                    <div className="NameAndAvatar">
                        {formData.image ? (
                            <img
                                src={formData.image}
                                alt="avatar"
                                className="avatar-img"
                            />
                        ) : (
                            <img
                                src="https://static-public.klickpages.com.br/uploads/media/file/5310958/foto-03.jpg"
                                alt="avatar"
                                className="avatar-img"
                            />
                        )}
                        <span style={{ color: formData.colorName }}>
                            {formData.name}
                        </span>
                    </div>
                    <div className="list">
                        <div
                            className="link-list"
                            style={{ backgroundColor: formData.colorBackLink }}
                        >
                            <span style={{ color: formData.colorNameLink }}>Link 01</span>
                        </div>
                        <div
                            className="link-list"
                            style={{ backgroundColor: formData.colorBackLink }}
                        >
                            <span style={{ color: formData.colorNameLink }}>Link 02</span>
                        </div>
                        <div
                            className="link-list"
                            style={{ backgroundColor: formData.colorBackLink }}
                        >
                            <span style={{ color: formData.colorNameLink }}>Link 03</span>
                        </div>
                        <div
                            className="link-list"
                            style={{ backgroundColor: formData.colorBackLink }}
                        >
                            <span style={{ color: formData.colorNameLink }}>Link 04</span>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
