import React from 'react';
import { Breadcrumbs, H6, P } from '../../../../AbstractElements';
import { Col, Label, Row, Table, Container, CardBody, Card } from 'reactstrap';
import InvoiceHeader from './InvoiceHeader';
import InvoiceDescription from './InvoiceDescription';
import PrintInvoice from './PrintInvoice';
import { getCartTotal } from '../../../../Services/Ecommerce.service';
import { Price, ProductName, Quantity, Sub_total } from '../../../../Constant';

const quantidade = 1;

const countRepeatedItems = (cart) => {
  const itemObjects = {};
  cart.forEach((item) => {
    const itemName = item.nome;
    if (itemObjects[itemName]) {
      itemObjects[itemName].quantidade += 1;
    } else {
      itemObjects[itemName] = {
        categoria: item.categoria,
        nome: itemName,
        unidade_de_medida: item.unidade_de_medida,
        valor: item.valor,
        quantidade: 1,
      };
    }
  });

  const resultArray = Object.values(itemObjects);
  console.log(resultArray);
  return resultArray;
};


const calcularValoresEmReais = (dadosGerais, produtos) => {
  // Lógica de cálculo aqui
  let subtotal = 0;

  // Calculate subtotal based on the basket items
  produtos.forEach((produto) => {
    subtotal += (parseInt(produto.quantidade) ?? 1) * produto.valor;
  });

  console.log(produtos, 'Produtos selecionados');
  console.log(subtotal, 'SOMA DOS PRODUTOS');

  const desconto_percentual = dadosGerais.desconto_percentual;
  const imposto_percentual = dadosGerais.imposto_percentual;

  // Convert desconto to a number
  const descontoValue = parseFloat(desconto_percentual);
  const impostoValue = parseFloat(imposto_percentual);
  console.log(descontoValue, 'DESCONTO EM %');
  console.log(impostoValue, 'IMPOSTO EM %');

  const subtotal_puro = subtotal;
  // Apply discount first
  const subtotalComDesconto = subtotal * (1 - descontoValue / 100);
  console.log(subtotalComDesconto, 'SOMA - DESCONTO');
  // Calcular valor do desconto em reais
  const valorDesconto = subtotal - subtotalComDesconto;
  console.log(valorDesconto, 'VALOR DO DESCONTO EM REAIS');
  // Convert percentual de imposto para valor em reais
  const valorImposto = subtotalComDesconto * (impostoValue / 100);
  console.log(valorImposto, 'VALOR DO IMPOSTO EM REAIS');

  // Somar taxa de imposto
  const totalComImposto = subtotalComDesconto * (1 + impostoValue / 100);
  console.log(totalComImposto, 'TOTAL COM IMPOSTO');

  // Truncate the result to two decimal places
  const truncatedSubtotal = parseFloat(totalComImposto.toFixed(2));
  console.log(truncatedSubtotal, 'TOTAL COM IMPOSTO CONVERTIDO');
  const trucatedValorImposto = parseFloat(valorImposto.toFixed(2));
  const trucatedValorDesconto = parseFloat(valorDesconto.toFixed(2));
  const total = truncatedSubtotal;
  const desconto = trucatedValorDesconto;
  const imposto = trucatedValorImposto;
  return {
    subtotal_puro,
    imposto_percentual,
    desconto_percentual,
    total,
    desconto,
    imposto,
    subtotalComDesconto
    // ... other values you want to return
  };
};
const formatarValorReais = (valor, casasDecimais) => {
  if (!isNaN(valor)) {
      return '' + valor.toLocaleString("pt-BR", {
          currency: "BRL",
          style: "currency",
          minimumFractionDigits: casasDecimais,
          maximumFractionDigits: casasDecimais,
      });
  } else {
      return 'Valor indisponível';
  }
};
const InvoiceTableItem = ({ item, symbol, index }) => (
  <tr key={index}>

    <td>
      <Label>{item.nome} </Label>
    </td>
    <td>
      <Label>{item.categoria}</Label>
    </td>
    <td>
      <P attrPara={{ className: 'itemtext digits' }}>{item.quantidade || quantidade}</P>
    </td>
    <td>
      <P attrPara={{ className: 'itemtext digits' }}>
        {symbol} {item.valor}{' '}
      </P>
    </td>
    <td className='payment digits'>
      <P attrPara={{ className: 'itemtext digits' }}>
        {symbol} {item.quantidade * item.valor || quantidade * item.valor}
      </P>
    </td>
  </tr>
);

const GenericInvoice = ({ cart, symbol, dadosGerais }) => {

  const uniqueProducts = countRepeatedItems(cart);
  const {
    subtotal_puro,
    imposto_percentual,
    desconto_percentual,
    total,
    desconto,
    imposto,
    subtotalComDesconto } = calcularValoresEmReais(dadosGerais, uniqueProducts);
  // Count repeated items based on their names


  return (
    <Row>
      <Col sm='12'>
        <Card>
          <CardBody>
            <div className='invoice'>
              <div>
                {/* ... Other components */}
                <InvoiceHeader numeroDaInvoice={dadosGerais.id} dataDeCriacao={dadosGerais.created_at} dataDeVencimento={dadosGerais.validade} />
                <InvoiceDescription nomeCliente={'João Vitor'} emailCliente={'joaovitormonsores@hotmail.com'} telefoneCliente={'(24) 98138-9459'} />
                <div className='table-responsive invoice-table' id='table'>
                  <Table bordered striped>

                    <thead>
                      <tr>
                        <td className='item'>
                          <H6 attrH6={{ className: 'p-2 mb-0' }}>{ProductName}</H6>
                        </td>
                        <td className='categoria'>
                          <H6 attrH6={{ className: 'p-2 mb-0' }}>Categoria</H6>
                        </td>
                        <td className='quantity'>
                          <H6 attrH6={{ className: 'p-2 mb-0' }}>Quantidade</H6>
                        </td>
                        <td className='Rate'>
                          <H6 attrH6={{ className: 'p-2 mb-0' }}>Valor Unitário</H6>
                        </td>
                        <td className='subtotal'>
                          <H6 attrH6={{ className: 'p-2 mb-0' }}>Total</H6>
                        </td>
                      </tr>
                    </thead>
                    <tbody>

                      {uniqueProducts.map((item, index) => (
                        <InvoiceTableItem key={index} item={item} symbol={symbol} />
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="4" className="text-right">Subtotal:</td>
                        <td>{formatarValorReais(subtotal_puro)}</td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="text-right">Desconto:</td>
                        <td>{formatarValorReais(desconto)}</td>
                        <td>{desconto_percentual}%</td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="text-right">Subtotal:</td>
                        <td>{formatarValorReais(subtotalComDesconto)}</td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="text-right">Taxas:</td>
                        <td>{formatarValorReais(imposto)}</td> {/* Corrigir esta linha conforme necessário */}
                      </tr>
                      <tr>
                        <td colSpan="4" className="itemtext digits">Total:</td>
                        <td>{formatarValorReais(total)}</td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
                {/* ... Other components */}
                <PrintInvoice idInvoice={dadosGerais.id} />

              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default GenericInvoice;

