import React from 'react'
import { FormGroup, Input } from 'reactstrap'


export function Switch(props) {
    return (
        <FormGroup switch>
            <Input type='switch' {...props} />
        </FormGroup>
    )
}

