import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { Badges, Breadcrumbs, Btn } from '../../../AbstractElements'
import { API } from '../../../api/API/api';

// import "../../../../Pages/"
import "primereact/resources/themes/lara-light-blue/theme.css"
import "primereact/resources/primereact.min.css"

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from 'react-router'
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CommonModal from '../../UiKits/Modals/common/modal';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';

export default function CompanyList2() {

    const history = useNavigate()

    const token = localStorage.getItem("token");
    const [tenants, setTenants] = useState([]);
    const [createdByName, setCreatedByName] = useState('');

    const [globalFilter, setGlobalFilter] = useState('');
    const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { tenant_id } = useParams();
    const [user, setUser] = useState({})
    const [formData, setFormData] = useState({
        name: '',
        email: '',   // Inicializado como uma string vazia
        last_name: '',   // Inicializado como uma string vazia
        password: '', // Inicializado como uma string vazia
    });
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);

    const getCompanies = async () => {
        try {
            // Ensure the user ID is set correctly in localStorage
            const user_id = localStorage.getItem('user_id');
            console.log('User ID from localStorage:', user_id);

            const response = await API.get("backoffice/companies", {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            const data = response.data;
            console.log('Clientes:', data);

            // Filter the data based on the condition
            const filteredData = data.filter(company => {
                console.log('created_by:', company.created_by);
                return String(company.created_by) === user_id;
            });

            console.log('Clientes Filtrados:', filteredData);
            setTenants(data);
        } catch (error) {
            console.error(error);
        }
    };
    const getUserById = async (id) => {
        try {
            // Ensure the user ID is set correctly in localStorage
            const user_id = localStorage.getItem('user_id');
            console.log('User ID from localStorage:', user_id);

            const response = await API.get("backoffice/users/" + id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            const data = response.data;
            console.log('Usuario:', data);



            setUser(data);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {

        getCompanies()
    }, [])

    const openAddPlanoModal = () => {
        history('/admin/products/company');
    };


    const closeAddPlanoModal = () => {
        setIsAddUserModalVisible(false);
        setIsEditing(false);
        setFormData({
            name: '',
            email: '',
            last_name: '',
            password: '',
        });
    };
    const toggleModal = (user) => {
        setIsEditing(true);
        setFormData({
            name: user.name,
            email: user.email,
            last_name: user.last_name,
            password: user.password
        });
        setIsAddUserModalVisible(true);
    };
    const [profileOptions, setProfileOptions] = useState([]);
    const handleDeleteClick = (user) => {
        setClientToDelete(user);
        setDisplayConfirmationModal(true);
    };
    const handleDeleteConfirm = async () => {
        if (clientToDelete) {
            await deleteUser(clientToDelete.id);
            setClientToDelete(null);
            setDisplayConfirmationModal(false);
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'profile') {
            const selectedProfile = profileOptions.find((option) => option.name === value);

            if (selectedProfile) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value,
                    profile_id: selectedProfile.id,
                }));
            } else {
                console.error('Perfil não encontrado:', value);
                toast.error('Perfil não encontrado');

                // Você pode adicionar lógica adicional aqui, como definir um valor padrão
            }
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };
    const handleEditUser = async () => {
        // Implemente a lógica para editar um usuário existente
        console.log(formData);
        try {
            const response = await API.put(`/products/tenants/${tenant_id}/management/admin/${formData.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Manipule o sucesso, por exemplo, mostre uma mensagem de sucesso
            console.log('Cliente editado com sucesso:', response.data);
            toast.success('Cliente atualizado com sucesso!');

        } catch (error) {
            // Manipule o erro, por exemplo, mostre uma mensagem de erro
            console.error('Erro ao editar usuário:', error);
            toast.error(error);

        }

        setIsAddUserModalVisible(false);
        setIsEditing(false);
    };

    const handleNewUser = async () => {
        try {
            const response = await API.post(`/backoffice/users`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Manipule o sucesso, por exemplo, mostre uma mensagem de sucesso
            // console.log('Cliente adicionado com sucesso:', response.data);
            toast.success('Cliente adicionado com sucesso!');
            getCompanies();

        } catch (error) {
            // Manipule o erro, por exemplo, mostre uma mensagem de erro
            console.error('Erro ao adicionar usuário:', error);
            toast.error('Erro ao adicionar usuário:', error);

        }

        setIsAddUserModalVisible(false);
    };
    const deleteUser = async (company_id) => {
        try {
            // Make a DELETE request to the API endpoint with the Authorization header
            const response = await API.delete(`/backoffice/companies/${company_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success("Empresa Excluida com Sucesso!");
            getCompanies();
            // Check if the request was successful
            if (response.status === 200) {
                // Handle success, e.g., show a success message
                console.log('User deleted successfully');
            } else {
                // Handle other status codes or error responses
                console.error('Failed to delete user. Status:', response.status);
            }
        } catch (error) {
            // Handle network errors or exceptions
            console.error('An error occurred while deleting user:', error.message);
        }
    };


    const renderActions = (rowData) => {
        return (
            <div className="row">

                <button type="button" className="buttns btn btn-outline-secondary custom-danger" onClick={() => handleDeleteClick(rowData)}>
                    <i className="fa fa-trash-o fa-lg"></i>
                </button>


            </div>
        );
    };
    const criadorBody = async (rowData) => {
        try {
            // Assuming you're fetching the user data here
            const userData = await getUserById(rowData.created_by);

            // Ensure that user data is available
            if (userData && userData.name) {
                return <>{userData.name}</>;
            } else {
                return <>Unknown User</>; // Handle the case where user data is not available
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            return <>Error</>; // Handle the error case
        }
    };


    return (
        <Fragment>
            <Breadcrumbs mainTitle='Empresas' parent='products' title='Empresas' />
            <Container fluid="true">
                <Card className='m-b-15 shadow'>

                    <Input
                        type="text"
                        placeholder="Buscar Empresa..."
                        defaultValue={globalFilter}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                </Card>
                <Btn
                    attrBtn={{
                        color: 'primary', className: 'm-b-15 col-2 d-flex justify-content-center', outline: true,
                        onClick: openAddPlanoModal
                    }}
                >
                    Nova
                </Btn>
                <CommonModal isOpen={isAddUserModalVisible} toggle={closeAddPlanoModal}>
                    <ModalHeader toggle={closeAddPlanoModal} style={{ height: '20px' }}>
                        {isEditing ? 'Editar Serviço' : 'Novo CLiente'}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for='name'>Nome:</Label>
                                <Input
                                    type='text'
                                    name='name'
                                    id='name'
                                    placeholder='Digite o nome do Cliente'
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for='name'>Sobrenome:</Label>
                                <Input
                                    type='text'
                                    name='last_name'
                                    id='last_name'
                                    placeholder='Digite o sobrenome do Cliente'
                                    value={formData.last_name}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for='name'>E-mail:</Label>
                                <Input
                                    type='email'
                                    name='email'
                                    id='email'
                                    placeholder='Digite o e-mail do usuário'
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    autoComplete='username'
                                />

                            </FormGroup>
                            <FormGroup>
                                <Label for='password'>Password:</Label>
                                <Input
                                    type='password'
                                    name='password'
                                    id='password'
                                    placeholder='Digite a senha do usuário'
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    autoComplete='current-password'
                                />

                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>

                        <Btn
                            attrBtn={{
                                color: 'primary', className: 'm-b-15 col-2 d-flex justify-content-center', outline: true,
                                onClick: isEditing ? handleEditUser : handleNewUser
                            }}
                        >
                            Salvar
                        </Btn>
                        <Btn
                            attrBtn={{
                                color: 'secondary', className: 'm-b-15 col-2 d-flex justify-content-center', outline: true,
                                onClick: closeAddPlanoModal
                            }}
                        >
                            Cancelar
                        </Btn>

                    </ModalFooter>
                </CommonModal>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <DataTable
                                value={tenants}
                                paginator
                                rows={15}
                                sortMode='multiple'
                                selectionMode="single"
                                onSelectionChange={(e) => history(`/backoffice/cliente-pj/${e.value.id}`)}
                                dataKey="id"
                                metaKeySelection={false}
                                globalFilter={globalFilter}
                            >
                                <Column field="dono" sortable style={{ width: '15%' }} header="Criador"></Column>  {/* Adjust width as needed */}
                                <Column field="cargo_criador" sortable style={{ width: '15%' }} header="Cargo do Criador"></Column>  {/* Adjust width as needed */}
                                <Column field="nome_fantasia" sortable style={{ width: '30%' }} header="Nome Fantasia"></Column>  {/* Adjust width as needed */}
                                <Column field="email" sortable style={{ width: '30%' }} header="Razao Social"></Column>  {/* Adjust width as needed */}
                                <Column field="cnpj" sortable style={{ width: '15%', textAlign: "center" }} header="Cnpj"></Column>  {/* Adjust width as needed */}
                                <Column header='Ações' body={renderActions} style={{ width: '10%' }}></Column>  {/* Adjust width as needed */}
                            </DataTable>

                        </Card>
                    </Col>
                </Row>
                <Dialog header={`Confirmar Exclusão de ${clientToDelete ? clientToDelete.nome_fantasia : ''}`} visible={displayConfirmationModal} style={{ width: '30vw' }} onHide={() => setDisplayConfirmationModal(false)}>
                    <div className='p-fluid'>
                        <div className='p-field'>
                            <label htmlFor='confirmationMessage'>Tem certeza de que deseja excluir permanentemente os dados da Empresa {clientToDelete ? `"${clientToDelete.nome_fantasia}"` : ''}? Essa ação não poderá ser desfeita.</label>
                        </div>
                        <div className='d-flex mt-2 gap-2'>
                            <Btn attrBtn={{ onClick: () => setDisplayConfirmationModal(false) }}>Não</Btn>
                            <Btn attrBtn={{ onClick: handleDeleteConfirm }}>Sim</Btn>
                        </div>
                    </div>
                </Dialog>

            </Container>
        </Fragment>
    )
}
