import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from "react-toastify"
import { Card, Table, Button, Input } from 'reactstrap'

import { API } from '../../../../../../api/API/api'

export default function RenegotiationsCommissions() {
  const { tenant_id } = useParams()
  const [commissions, setCommissions] = useState([])

  const [commissionsData, setCommissionsData] = useState({})

  const apiPath = `/tenants/${tenant_id}/management/renegotiations/commissions`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setCommissions(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const handleInputChange = (e) => {
    let { name, value } = e.target

    setCommissionsData((prevFormData) => ({
      ...prevFormData,
      [name]: +value,
    }))
  }

  const submitButton = async (user_id = null) => {
    try {
      const commission = user_id ? commissionsData[user_id] : commissionsData['all']
      const response = user_id ? await API.put(`${apiPath}/${user_id}`, {commission}) : await API.put(`${apiPath}`, {commission})
      
      if (!user_id) setCommissionsData({})

      setCommissions(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }
  }

  return (
    <>
      <Card className='col-12 p-20'>
        <div className="d-flex">
            <Input type='number' min='0' name='all' placeholder='Aplicar para todos' onChange={handleInputChange} />
            <Button color='primary' className='col-2 d-flex justify-content-center' outline={true} onClick={() => submitButton()}>
                Salvar
            </Button>
        </div>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Configurar comissão por usuário</h5>
        </div>
        <Table borderless>
            <thead>
                <tr>
                    <th>Nome</th>
                    <th>Comissão (<i class="fa fa-percent"></i>)</th>
                </tr>
            </thead>
            <tbody>
            {commissions.map((commission, index) => (
                <tr key={index}>
                    <td className='align-middle'>{commission.username}</td>
                    <td>
                        <div className='d-flex'>
                            <Input type='number' min='0' max='100' name={commission.user_id} value={commissionsData[`${commission.user_id}`] || commission.commission} onChange={handleInputChange} />
                            <Button color='primary' outline={true} onClick={() => submitButton(commission.user_id)}>Salvar</Button>
                        </div>
                    </td>
                </tr>
                ))}
            </tbody>
        </Table>
      </Card>
    </>
  )
}

