import { Table } from '../../../../_Commons/Table/Index'

export const StudentExtensions = ({ data }) => {
    const setTableData = (payloads) => ({
        columns: ['Curso', 'Extendido em', 'Período', 'Valor', 'Status'],
        rows: payloads.map(item => [
            item.course,
            item.extended_at,
            item.period,
            item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            item.status
        ]),
        payloads: payloads
    })

    return (
        <>
            <Table header='Extensōes' data={setTableData(data.extensions)} />
        </>
    )
}