import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Table, Button, Form, FormGroup, Label, Input, InputGroup, InputGroupText, Row, Col } from 'reactstrap'

import { ModalCreateAndEdit } from '../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../_Commons/Modal/Delete'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    coupon: '',
    attendant: '',
    attendant_id: null,
    course: '',
    course_id: null,
    discount: 0,
    commission: 0,
    used: 0,
    limit: 0,
    status: 'Ativo',
    free: true,
    amount: null
}

export default function Coupons() {
  const { tenant_id } = useParams()
  const [data, setData] = useState({
    coupons: [],
    attendants: [],
    courses: []
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const apiPath = `/tenants/${tenant_id}/management/coupons`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setData(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, data) => {
    setIsEditing(isEditing)
    data.free = !data.amount
    setFormData(data)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    
    value = name === 'status' ? (formData.status === 'Ativo' ? 'Inativo' : 'Ativo') : value
    value = !isNaN(value) ? +value : value

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      payload.amount = payload.free ? null : payload.amount
      console.log(payload.amount)
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')

      setData(response.data)
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  return (
    <>
      <Card className='col-12 p-20'>
        <Button color='primary' className='col-2 d-flex justify-content-center' outline={true} onClick={() => openModal(false, formDataDefault)}>
          Novo
        </Button>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Cupons</h5>
        </div>
        <Table borderless>
            <thead>
                <tr>
                    <th>Cupons</th>
                    <th>Atendente</th>
                    <th>Curso</th>
                    <th>Desconto</th>
                    <th>Comissão</th>
                    {/* <th>Utilizado</th> */}
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {data.coupons.map((coupon, index) => (
                <tr key={index}>
                    <td className='align-middle'>{coupon.coupon}</td>
                    <td className='align-middle'>{coupon.attendant}</td>
                    <td className='align-middle'>{coupon.course}</td>
                    <td className='align-middle'>{coupon.discount}%</td>
                    <td className='align-middle'>{coupon.commission}%</td>
                    {/* <td className='align-middle'>{coupon.used}</td> */}
                    <td className='align-middle'>{coupon.status}</td>
                    <td>
                        <div className='buttonsCard'>
                            <Button color='primary' outline={true} onClick={() => openModal(true, coupon)}>
                                <i className='fa fa-edit fa-lg'></i>
                            </Button>
                            <Button color='secondary' outline={true} onClick={() => openModalDelete(coupon)}>
                                <i className='fa fa-trash-o fa-lg'></i>
                            </Button>
                        </div>
                    </td>
                </tr>
                ))}
            </tbody>
        </Table>
      </Card>

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar cupom' : 'Criar cupom'} onSubmit={() => submitModalForm()} size={'lg'} >
        <Form>
          <FormGroup>
            <Label for='coupon'>Cupom:</Label>
            <Input type='text' name='coupon' id='coupon' value={formData.coupon} onChange={handleModalInputChange} required />
          </FormGroup>

          <Row>
            <Col>
              <FormGroup>
                <Label for='discount'>Desconto:</Label>
                <InputGroup>
                  <InputGroupText>%</InputGroupText>
                  <Input type='number' name='discount' id='discount' value={formData.discount} onChange={handleModalInputChange} />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label for='commission'>Comissão (opcional):</Label>
                <InputGroup>
                    <InputGroupText>%</InputGroupText>
                    <Input type='number' name='commission' id='commission' value={formData.commission} onChange={handleModalInputChange} />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label for='limit'>Limite de uso:</Label>
                <Input type='number' name='limit' id='limit' value={formData.limit} onChange={handleModalInputChange} />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for='attendant_id'>Usuário:</Label>
            <Input type='select' name='attendant_id' id='attendant_id' onChange={handleModalInputChange}>
                {data.attendants.map((attendant, index) => (
                    <option key={index} value={attendant.id} selected={attendant.id === formData.attendant_id}>
                        {attendant.name}
                    </option>
                ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for='course_id'>Curso:</Label>
            <Input type='select' name='course_id' id='course_id' onChange={handleModalInputChange}>
                <option value={null} selected={!formData.course_id}>
                    Todos
                </option>
                {data.courses.map((course, index) => (
                    <option key={index} value={course.id} selected={!course.id === formData.course_id}>
                        {course.name}
                    </option>
                ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label>Status</Label>
            <FormGroup switch>
              <Label for='status'>{formData.status}</Label>
              <Input type='switch' name='status' id='status' onChange={handleModalInputChange} checked={formData.status === 'Ativo'} />
            </FormGroup>
          </FormGroup>

        </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

