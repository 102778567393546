import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row, Card, Button } from 'reactstrap';
import { useNavigate } from 'react-router'
import SmallWidgets from '../../../Common/CommonWidgets/SmallWidgets';
import { API, API2 } from '../../../../api/API/api';

import WelcomeCard from './WelcomeCard';

const DashboardPanel = () => {
  const history = useNavigate()
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("Name");
  const [data, setData] = useState({});

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(`/tenants`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setData(response.data);

      } catch (error) {
        if (error.response && error.response.status === 401) {
          history('/admin/login');
        } else {
          console.log(error);
        }
      }
    }

    getData();
  }, [token, history]); // Include 'token' and 'history' in the dependency array

  const widgetsData = [
    {
      title: 'Contatos',
      color: 'primary',
      icon: 'stroke-user',
      total: data.students || 0,
    },
    {
      title: 'Sites Ativos',
      color: 'primary',
      icon: 'course-2',
      total: `${data.activated || 0}/${data.sites ? data.sites.length : 0}`,
    },
  ];

  return (

    // <Fragment>
    // <Container fluid={true} className='general-widget' style={{ zoom: zoom }}>

    <Row className='justify-content-between'>
      <Col xs='4'>
        <WelcomeCard username={username} />
      </Col>
      {/* <Col xs='6'>
        <Card style={{ width: 'max-content', margin: 'auto', padding: '20px', textAlign: 'center' }}>
          <h5 className="card-title">Espaço em Disco</h5>

          <div className="outer_circle text-center">
            <div className="inner_circle">
              <div className="inner_content">
                <p className="card-text-maior"><strong> {data.used_storage}</strong></p>
                <p className="card-text"> Total: 2 GB</p>
              </div>
            </div>
          </div>

          <Button color="danger" style={{ width: 'max-content', marginTop: '10px', marginLeft: '1.875em' }}>Upgrade</Button>
        </Card>

      </Col> */}
    </Row>

    // </Container>
    // </Fragment>
  );
};

export default DashboardPanel;
