import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function CardProduct(
  { date, index, image, name, url, funcDel, direction, user_id },
  ...args
) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const createdDateUTC = new Date(date);
  const brTimeZone = "America/Sao_Paulo";
  createdDateUTC.setHours(createdDateUTC.getHours() - 3);

  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: brTimeZone,
  };

  const createdDateLocal = createdDateUTC.toLocaleString("pt-BR", options);
  const createdTimeLocal = createdDateUTC.toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  function formatLink(link) {
    return `https://bio.tutorcasts.com/link/${link}`;
  }

  return (

    <div key={index} className="d-flex cardslink align-items-center gap-4 p-4">
      <div className="w-10 d-flex justify-content-center align-items-center">
        <img src={image} alt="avatar" className="imgAvatar" />
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <h5>{name}</h5>
          <a href={formatLink(url)} target="_blank" rel="noopener noreferrer">
            bio.tutorcast.com/li...
          </a>

          <div className="d-flex gap-2 mt-1 align-items-center">
            <span className="d-flex gap-2 align-items-center">
              <i style={{ color: "green" }} className="fa fa-calendar-o"></i>
              {new Date(date).toLocaleDateString("pt-BR", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </span>
            <span> | </span>
            <span className="d-flex gap-2 align-items-center">
              <i style={{ color: "red" }} className="fa fa-clock-o"></i>{" "}
              {createdTimeLocal}
            </span>
          </div>
        </div>
        <div>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            direction={direction}
          >

            <DropdownToggle style={{ marginLeft: 'auto' }} caret>Ações</DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem className="d-flex gap-2 align-items-start justify-content-start" style={{ marginLeft: '10px', flexDirection: 'column' }}>
                  <div>
                    <Link to={`/admin/editorPage/${user_id}/${url}`} className="edit-button d-flex justify-content-center align-items-center gap-2 h-20" style={{ width: '80px', padding: '3px' }}>
                      <i className="icon-pencil"></i>
                      <span style={{ color: 'black' }}>Editar</span>
                    </Link>

                  </div>
                  <div>
                    <a onClick={funcDel} className="del-button d-flex justify-content-center align-items-center gap-2 h-20" style={{ width: '80px', padding: '3px' }}>
                      <i className="icofont icofont-trash"></i>
                      <span style={{ color: 'black' }}>Deletar</span>
                    </a>

                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          <Modal
            isOpen={modal}
            toggle={toggle}
            {...args}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <ModalHeader toggle={toggle}>
              Tem certeza que deseja excluir?
            </ModalHeader>
            <ModalBody>
              Caso confirme, esta ação não poderá ser desfeita!
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle}>
                Cancelar
              </Button>
              <Button
                color="outline-danger"
                onClick={() => {
                  funcDel();
                  toggle();
                }}
              >
                Excluir
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  );
}
