import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input, InputGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    name: '',
    email: '',
    courses: []
  }


export default function Monitors() {
  const { tenant_id } = useParams()
  const [data, setData] = useState({
    monitors: [],
    courses: []
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  
  const setTableData = (payloads) => ({
        columns: ['Nome', 'Email'],
        rows: payloads.map(item => [item.name, item.email]),
        payloads: payloads
    })

  const apiPath = `/tenants/${tenant_id}/management/teaching_staff/monitors`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setData(response.data)

      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, data) => {
    setIsEditing(isEditing)
    setFormData(data)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleModalCoursesDropdown = (e) => {
    const value = JSON.parse(e.target.value)

    setFormData(prevFormData => {
      const courses = prevFormData.courses || []
      const index = courses.findIndex(course => course.id === value.id)
      index !== -1 ? courses.splice(index, 1) : courses.push(value)
      
      return {
        ...prevFormData,
        courses: courses,
      };
    });
  }

  const setInputCoursesValue = (courses) => {
    const names = courses.map(course => course.name);
    return names.join('\n')
  }

  return (
    <>
      <Table 
          header='Monitores' data={setTableData(data.monitors)} 
          onNewClick={() => openModal(false, formDataDefault)} 
          onEditClick={(payload) => openModal(true, payload)}
          onDeleteClick={(payload) => openModalDelete(payload)}
      />

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Monitor' : 'Criar Monitor'} onSubmit={() => submitModalForm()} >
        <Form>
          <FormGroup>
            <Label for='name'>Nome:</Label>
            <Input type='text' name='name' id='name' value={formData.name} onChange={handleModalInputChange} required />
          </FormGroup>

          <FormGroup>
            <Label for='email'>Email:</Label>
            <Input type='text' name='email' id='email' value={formData.email} onChange={handleModalInputChange} required />
          </FormGroup>

          <FormGroup>
            <Label for='password'>{isEditing ? 'Alterar Senha' : 'Senha'}:</Label>
            <Input type='password' name='password' id='password' value={formData.password} onChange={handleModalInputChange} required />
          </FormGroup>

          <FormGroup>
            <Label for='courses'>Cursos:</Label>

            <InputGroup>
              <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle split color="primary" />

                <DropdownMenu>
                {data.courses.map((course, index) => (
                  <DropdownItem key={index} value={JSON.stringify(course)} onClick={handleModalCoursesDropdown}>{course.name}</DropdownItem>
                ))}
                </DropdownMenu>
              </ButtonDropdown>
              <Input type='textarea' value={setInputCoursesValue(formData.courses)} disabled />
            </InputGroup>
          </FormGroup>
          
        </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

