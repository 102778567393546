import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input, InputGroup, ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem, Button, Row, Col } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'
import { Image } from '../../../_Commons/Inputs/Image'
import { Editor } from '../../../_Commons/Inputs/Editor'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    name: '',
    email: '',
    description: '',
    phone: '',
    phone2: '',
    resume: '',
    resume_file: '',
    video_url: '',
    publish_home: false,
    order: 1,
    medias_social: []
}

export default function Teachers() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        teachers: [],
        medias_social: []
    })

    const [modalVisible, setModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const [userDelete, setUserDelete] = useState(0)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const setTableData = (payloads) => ({
        columns: ['Nome', 'Email', 'Telefone'],
        rows: payloads.map(item => [
            item.name,
            item.email,
            item.phone,
        ]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/academic/teaching_staff/teachers`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (isEditing, data) => {
        setIsEditing(isEditing)
        setFormData(data)
        setModalVisible(true)
    }

    const openModalDelete = ({ id }) => {
        setUserDelete(id)
        setModalDeleteVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, medias_social, image, resume_file, ...payload } = formData

            if (image || resume_file) {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }

                const form = new FormData()

                if (image) form.append('image', image)
                if (resume_file) form.append('resume_file', resume_file)

                const responseImg = await API.post(`${apiPath}/upload`, form, config)

                payload.image = responseImg.data.image_url
                payload.resume_file = responseImg.data.resume_file_url
            }

            payload.medias_social = medias_social.filter(media => media.id !== 0)
            const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    const submitModalDeleteForm = async (id) => {
        try {
            const response = await API.delete(`${apiPath}/${id}`)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Error')
        }

        setModalDeleteVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target

        value = ['image', 'resume_file'].includes(name) ? e.target.files[0] : value
        value = ['publish_home'].includes(name) ? !formData[name] : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const handleEditorInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            resume: e.editor.getData(),
        }))
    }

    const mediaDropdownAdd = () => {
        const medias = formData.medias_social
        medias.push({
            id: 0,
            name: '',
            url: ''
        })

        setFormData((prevFormData) => ({
            ...prevFormData,
            medias: medias
        }))
    }

    const mediaDropdownHandler = (data, index) => {
        const medias = formData.medias_social
        medias[index] = data

        setFormData((prevFormData) => ({
            ...prevFormData,
            medias: medias
        }))
    }

    const mediaTeacherDelete = (index) => {
        const medias = formData.medias_social
        medias.splice(index, 1)

        setFormData((prevFormData) => ({
            ...prevFormData,
            medias: medias
        }))
    }

    const Dropdown = ({ teacherMedia, index }) => {
        const [mediasDropdown, setMediasDropdown] = useState(false)
        const toggleMediasDropdown = () => setMediasDropdown((prevState) => !prevState)

        return (
            <ButtonDropdown isOpen={mediasDropdown} toggle={toggleMediasDropdown}>
                <DropdownToggle color="primary">{teacherMedia.name}</DropdownToggle>
                <DropdownMenu style={{ zIndex: 10 }}>
                    {data.medias_social.map((media, i) => (
                        <DropdownItem key={`media-social-${i}`} value={JSON.stringify({ ...media, url: teacherMedia.url })} onClick={(e) => mediaDropdownHandler(JSON.parse(e.target.value), index)}>
                            {media.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </ButtonDropdown>
        )
    }

    return (
        <>
            <Table
                header='Professores' data={setTableData(data.teachers)}
                onNewClick={() => openModal(false, formDataDefault)}
                onEditClick={(payload) => openModal(true, payload)}
                onDeleteClick={(payload) => openModalDelete(payload)}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Professor' : 'Criar Professor'} onSubmit={() => submitModalForm()} size={'xl'} >
                <Form>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='name'>Nome:</Label>
                                <Input type='text' name='name' id='name' onChange={handleModalInputChange} value={formData.name} />
                            </FormGroup>
                        </Col>
                        
                        <Col>
                            <FormGroup>
                                <Label for='email'>Email:</Label>
                                <Input type='text' name='email' id='email' onChange={handleModalInputChange} value={formData.email} />
                            </FormGroup>
                            
                        </Col> 
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='phone'>Telefone:</Label>
                                <Input type='text' name='phone' id='phone' onChange={handleModalInputChange} value={formData.phone} />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for='phone2'>Celular:</Label>
                                <Input type='text' name='phone2' id='phone2' onChange={handleModalInputChange} value={formData.phone2} />
                            </FormGroup>
                        </Col>

                        <Col>
                            {!isEditing &&
                                <FormGroup>
                                    <Label for='password'>Senha:</Label>
                                    <Input type='password' name='password' id='password' onChange={handleModalInputChange} value={formData.password} />
                                </FormGroup>
                            }
                        </Col>

                    </Row>
                    
                    <FormGroup>
                        <Label for='description'>Descrição:</Label>
                        <Input type='textarea' name='description' id='description' onChange={handleModalInputChange} value={formData.description} />
                    </FormGroup>


                    <Editor label='Mini currículo:' value={formData.resume} onChange={handleEditorInputChange} />

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='resume_file'>Currículo:</Label>
                                <Input type='file' name='resume_file' id='resume_file' onChange={handleModalInputChange} />
                            </FormGroup>
                        </Col>

                        <Col>
                            <Image label='Foto 250x250:' id='image' name='image' src={formData.image_url} onChange={handleModalInputChange} />
                        </Col>
                    </Row> 

                    <FormGroup>
                        <Label for='video_url'>Vídeo de apresentação:</Label>
                        <Input type='text' name='video_url' id='video_url' onChange={handleModalInputChange} value={formData.video_url} />
                    </FormGroup>
                    
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='publish_home'>Publicar na página inicial:</Label>
                                <FormGroup switch>
                                    <Input type='switch' name='publish_home' id='publish_home' onChange={handleModalInputChange} checked={formData.publish_home} />
                                </FormGroup>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for='order'>Ordenação na página inicial:</Label>
                                <Input type='number' min='1' name='order' id='order' onChange={handleModalInputChange} value={formData.order} />
                            </FormGroup>
                        </Col>
                    </Row>                    

                    <FormGroup>
                        <Label>Mídias Sociais:</Label>
                        <br />
                        <Button color='primary' outline={true} onClick={() => mediaDropdownAdd()}>+</Button>

                        {formData.medias_social.map((teacherMedia, index) =>
                            <InputGroup key={`teacher-media-social-${index}`}>
                                <Dropdown teacherMedia={teacherMedia} index={index} />

                                <Input type='text' onChange={(e) => mediaDropdownHandler({ ...teacherMedia, url: e.target.value }, index)} value={teacherMedia.url} />

                                <Button color='secondary' onClick={() => mediaTeacherDelete(index)}>
                                    <i className='fa fa-trash-o fa-lg' />
                                </Button>
                            </InputGroup>
                        )}
                    </FormGroup>

                </Form>
            </ModalCreateAndEdit>

            <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
        </>
    )
}

