import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router'
import { Card, Row, Col, FormGroup, Label } from 'reactstrap';
import { toast } from "react-toastify";

import { API } from '../../../../../../api/API/api';
import '../../../_Commons/Styles/Styles.scss';

export default function Integrations() {
  const token = localStorage.getItem("token");
  const { tenant_id } = useParams()

  const [formValues, setFormValues] = useState({
    tenant: {
      name: ''
    },
    google_analytics_id: '',
    google_gtm_id: '',
    facebook_app_id: '',
    facebook_app_secret: '',
    affiliates_email: '',
    affiliates_api_key: '',
    scripts_extras: '',
    facebook_widget: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.put(`/tenants/${tenant_id}/management/integrations`, formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      toast.success('Dados Atualizados com Sucesso!');
      console.log(formValues);
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };
  

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(`/tenants/${tenant_id}/management/integrations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
        )
        setFormValues(response.data)

      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [])

  return (
    <Fragment>

          <Card 
          style={{marginBottom:"8.5em"}}>
            <div className="card-header">
              <h5 className="card-title p-b-0 p-t-0">Integrações</h5>
            </div>
            <form>
              <Row>
                {/* Coluna 1 */}
                <Col sm="6" md="6" style={{ margin: '2vw' }}>
                  {/* Seção: Informações Pessoais */}
                  <div className="section-title"><span className="fa fa-google"></span><strong>&nbsp;Google Analytics / TagManager</strong></div>
                  <FormGroup>
                    <Label className="form-label">Google Analytics</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="G-########## ou UA-#######-#"
                      name="google_analytics_id"
                      value={formValues.google_analytics_id || ''}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">Tagmanager ID</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="google_gtm_id"
                      placeholder="GTM-#######"
                      value={formValues.google_gtm_id || ''}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <div className="section-title"><span className="fa fa-facebook-square"></span><strong>&nbsp;Login com Facebook </strong></div>
                      <FormGroup>
                        <Label className="form-label">Link Widget</Label>
                        <input
                          className="form-control"
                          type="text"
                          name="facebook_widget"
                          value={formValues.facebook_widget || ''}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label">APP ID</Label>
                        <input
                          className="form-control"
                          type="text"
                          name="facebook_app_id"
                          value={formValues.facebook_app_id || ''}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label">Chave secreta APP</Label>
                        <input
                          className="form-control"
                          type="text"
                          name="facebook_app_secret"
                          value={formValues.facebook_app_secret || ''}
                          onChange={handleInputChange}
                        />
                      </FormGroup>

                    </Row>

                  </FormGroup>
                </Col>

                {/* Coluna 2 */}
                <Col style={{ margin: '2vw' }}>
                  {/* Seção: Endereço e Contato */}
                  <div className="section-title"><span className="fa fa-sitemap"></span><strong>&nbsp;Plataforma de afiliados</strong></div>
                  <FormGroup>
                    <Label className="form-label">E-mail</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="username@provedor.com"
                      name="affiliates_email"
                      value={formValues.affiliates_email}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">API KEY</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="affiliates_api_key"
                      value={formValues.affiliates_api_key}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <div className="section-title"><span className="fa fa-code"></span><strong>&nbsp;Incorporação de scripts</strong></div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="scripts_extras">
                      Tags
                      <code>&lt;script... </code>
                    </label>
                    &nbsp;&nbsp;
                    <small className="badge badge-danger" style={{ float: 'right' }}>
                      O código de incorporação será inserido antes do fechamento da tag body
                    </small>
                    <textarea
                      name="scripts_extras"
                      value={formValues.scripts_extras}
                      onChange={handleInputChange}
                      type=""
                      id="scripts_extras"
                      className="form-control"
                      rows="9"
                      placeholder='<script data-jsd-embedded data-base-url="https://tutorcasts.com" src="https://tutorcasts.com/index.js"></script>'
                    ></textarea>
                  </div>
                </Col>

                <Row className="text-right rowCustom">
                  <Col className="text-right">
                    <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                      Salvar alterações
                    </button>
                  </Col>
                </Row>
              </Row>


            </form>
          </Card>

    </Fragment>
  )
}
