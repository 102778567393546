import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Card, Row, Col, Form, FormGroup, Input } from 'reactstrap'
import { H6 } from '../../../../../../AbstractElements'

import { API } from '../../../../../../api/API/api';
import '../../../_Commons/Styles/Styles.scss';
import { toast } from "react-toastify";

const formDataDefault = {
    max_in_negociation: '',
    allows_future_installments: ''
}

export default function RenegotiationsSettings() {
  const { tenant_id } = useParams();
  const [formData, setFormData] = useState(formDataDefault);

  const apiPath = `/tenants/${tenant_id}/management/renegotiations/settings`

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(apiPath);
        setFormData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, [tenant_id]);


  const handleInputChange = (e) => {
    let { name, value } = e.target
    value = name == 'allows_future_installments' ? !formData.allows_future_installments : value

    setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
    }))
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.put(apiPath, formData);
        toast.success('Sucesso!')

    } catch (error) {
      console.log(error);
      toast.error('Error')
    }
  };

  return (
    <>
        <Card className='col-12' style={{ marginBottom: "8.5em" }}>
          <div className="card-header">
            <h5 className="card-title p-b-0 p-t-0">Configurações de Renegociações</h5>
          </div>
          <Form>
            <Row className="m-4">
                <Col md="12" className="mb-3">
                    <H6>Máximo de renegociações (Em negociação) / por antendente:</H6>
                    <FormGroup>
                    <Input type="number" min='1' name="max_in_negociation" value={formData.max_in_negociation} onChange={handleInputChange}/>
                    </FormGroup>
                </Col>
                <Col md="12" className="mb-3">
                    <H6>Permitir incluir parcelas não vencidas na renegociação:</H6>
                    <FormGroup>
                    <input className='ms-2' type='checkbox' name='allows_future_installments' value={formData.allows_future_installments} onChange={handleInputChange} checked={formData.allows_future_installments} />
                    </FormGroup>
                </Col>
            </Row>
            
            <Row className="text-right rowCustom">
              <Col className="text-right">
                <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                  Salvar alterações
                </button>
              </Col>
            </Row>
          </Form>
        </Card>
    </>
  );
}