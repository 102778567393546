import React, { Fragment, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { BillingDetails } from '../../../../Constant';
import CheckoutTableData from './CheckoutTableData';
import ProductPlaceOrder from './ProductPlaceOrder';
import HeaderCard from '../../../Common/Component/HeaderCard';
import { API } from '../../../../api/API/api';
import { useParams } from 'react-router';
import { useState } from 'react';

const CheckOutContain = () => {

  const [ordem, setOrdem] = useState({});
  const [uniqueProducts, setUniqueProducts] = useState([]);
  const { ordemId } = useParams();

  const token = localStorage.getItem("token");
  const quantidade = 1;

  const countRepeatedItems = (uniqueProducts) => {
    console.log(uniqueProducts,'count repeated');
    const itemObjects = {};
    uniqueProducts.forEach((item) => {
      const itemName = item.nome;
      if (itemObjects[itemName]) {
        itemObjects[itemName].quantidade += 1;
      } else {
        itemObjects[itemName] = {
          categoria: item.categoria,
          nome: itemName,
          unidade_de_medida: item.unidade_de_medida,
          valor: item.valor,
          quantidade: 1,
        };
      }
    });

    const resultArray = Object.values(itemObjects);
    setUniqueProducts(resultArray); // Define o estado de uniqueProducts
    return resultArray;
  };


  useEffect(() => {
    const getOrdem = async () => {
      try {
        const response = await API.get(`/ordens_servico/${ordemId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const data = response.data;
        const teste = countRepeatedItems(data.produtos);
        setOrdem(data);
      } catch (error) {
        console.error("Erro ao buscar ordem:", error);
      }
    };
  
    getOrdem();
  }, [ordemId, token]); // Add dependencies to the dependency array
  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  
  return (
    <Fragment>
      <Breadcrumbs parent='Ecommerce' title='Checkout' mainTitle='Checkout' />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className='checkout'>
              <HeaderCard title="Dados de Cobrança" />
              <CardBody>
                <Row>
                  <CheckoutTableData />
                  <ProductPlaceOrder carrinho={uniqueProducts} /> 
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CheckOutContain;
