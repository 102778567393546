import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from "react-toastify"
import { Card, Table, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'

import { ModalCreateAndEdit } from '../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../_Commons/Modal/Delete'
import { Editor } from '../../_Commons/Inputs/Editor'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
  id: 0,
  name: '',
  color: '',
  description: '',
  monthly_payment: 0,
  discount: 0,
  status: true
}

export default function Plans() {
  const { tenant_id } = useParams()
  const [plans, setPlans] = useState([])

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const apiPath = `/tenants/${tenant_id}/management/plans`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setPlans(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, plan) => {
    setIsEditing(isEditing)
    setFormData(plan)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    value = name == 'status' ? !formData.status : value
    value = !isNaN(value) ? +value : value

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleEditorInputChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: e.editor.getData(),
    }))
  }

  const submitModalForm = async () => {
    try {
      const { id, ...payload } = formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setPlans(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setPlans(response.data)
      toast.success('Sucesso!')

      setPlans(response.data)
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  return (
    <>
      <Card className='col-12 p-20'>
        <Button color='primary' className='col-2 d-flex justify-content-center' outline={true} onClick={() => openModal(false, formDataDefault)}>
          Novo
        </Button>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Planos de Assinaturas</h5>
        </div>
        <Table borderless>
          <thead>
            <tr>
              <th>Plano</th>
              <th>Desconto</th>
              <th>Mensalidade</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan, index) => (
              <tr key={index}>
                <td className='align-middle'>{plan.name}</td>
                <td className='align-middle'>{plan.discount}%</td>
                <td className='align-middle'>{plan.monthly_payment.toLocaleString('pt-BR')}</td>
                <td className='align-middle'>{plan.status ? 'Ativo' : 'Inativo'}</td>
                <td>
                  <div className='buttonsCard'>
                    <Button color='primary' outline={true} onClick={() => openModal(true, plan)}>
                      <i className='fa fa-edit fa-lg'></i>
                    </Button>
                    <Button color='secondary' outline={true} onClick={() => openModalDelete(plan)}>
                      <i className='fa fa-trash-o fa-lg'></i>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar plano' : 'Criar plano'} onSubmit={() => submitModalForm()} size={'lg'} >
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for='name'>Nome do plano:</Label>
                <Input type='text' name='name' id='name' value={formData.name} onChange={handleModalInputChange} required />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label for='color'>Cor:</Label>
                <Input type='color' style={{ height: '40px' }} name='color' id='color' value={formData.color} onChange={handleModalInputChange} required />
              </FormGroup>
            </Col>
          </Row>

          <Editor label='Descrição:' value={formData.description} onChange={handleEditorInputChange} />

          <Row>
            <Col>
              <FormGroup>
                <Label for='discount'>Desconto: (<i class="fa fa-percent"></i>)</Label>
                <Input type='number' min='0' max='100' name='discount' id='discount' value={formData.discount} onChange={handleModalInputChange} />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label for='monthly_payment'>Valor da mensalidade:</Label>
                <Input type='number' min='0' name='monthly_payment' id='monthly_payment' value={formData.monthly_payment} onChange={handleModalInputChange} />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup className='d-flex align-items-center'>
            <Label for='status'>Status:</Label>
            <input className='ms-2' type='checkbox' name='status' id='status' value={formData.status} onChange={(handleModalInputChange)} checked={formData.status} />
          </FormGroup>

        </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

