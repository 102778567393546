import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import { Button, Label, Input, FormGroup, Form } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../AbstractElements';
import { API } from '../../../api/API/api';
import { ToastContainer, toast } from 'react-toastify';
import CommonModal from '../../UiKits/Modals/common/modal';
import GenericList from '../Dashboard/Components/Products/List';

const Faturas = () => {
    const token = localStorage.getItem('token');
    const { tenant_id } = useParams();

    const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        email: '',
        password: '',
        profile: '',
        profile_id: 1,
    });

    const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const profileIdMapping = {
        Administrador: 1,
        Gerente: 2,
        Edição: 4,
        Produção: 5,
        Financeiro: 6,
        Parceiro: 8,
        Monitor: 9,
        // Adicione mais conforme necessário
    };

    const [profileOptions, setProfileOptions] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'profile') {
            const selectedProfile = profileOptions.find((option) => option.name === value);

            if (selectedProfile) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value,
                    profile_id: selectedProfile.id,
                }));
            } else {
                console.error('Perfil não encontrado:', value);
                toast.error('Perfil não encontrado');

                // Você pode adicionar lógica adicional aqui, como definir um valor padrão
            }
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
      
    }, [tenant_id]);

    const mockUsers = [
        { 
            id: 1001,
            company_name: 'Cliente 1',
            produto: 'Produto 1',
            subtotal: 'R$350,00',
            status: 'Aberta',
            contrato: 'Contrato 1',
            pagamento: 'Pagamento 1',
            alerta: 'vermelho',
            validade: '01/01/2024',
        },
        { 
            id: 1002,
            company_name: 'Cliente 2',
            produto: 'Produto 2',
            subtotal: 'R$250,00',
            status: 'Rejeitado',
            contrato: 'Assinado',
            pagamento: 'Pendente',
            alerta: 'laranja',
            validade: '13/02/2024',
        },
        { 
            id: 1003,
            company_name: 'Cliente 2',
            produto: 'Produto 2',
            subtotal: 'R$250,00',
            status: 'Rejeitado',
            contrato: 'Assinado',
            pagamento: 'Pendente',
            alerta: 'verde',
            validade: 'Validade 2',
        },
        // Add more mock data as needed
    ];
    
    const toggleModal = (user) => {
        setIsEditing(true);
        setFormData({
            name: user.name,
            email: user.email,
            last_name: user.last_name,
            password: user.password
        });
        setIsAddUserModalVisible(true);
    };

    const openDeleteConfirmationModal = (user) => {
        setUserToDelete(user);
        setIsDeleteConfirmationModalVisible(true);
    };
    const closeDeleteConfirmationModal = () => {
        setIsDeleteConfirmationModalVisible(false);
        setUserToDelete(null);
    };
    const confirmDelete = async () => {
        try {
            // Faça a requisição DELETE para excluir o usuário
            const response = await API.delete(`/products/tenants/${tenant_id}/management/admin/${userToDelete.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Lógica para tratamento de sucesso e recarregamento dos dados
            console.log('Usuário excluído com sucesso!', response);
            toast.success('Usuário excluído com sucesso!', response);

            closeDeleteConfirmationModal();
            // getData();
        } catch (error) {
            // Lógica para tratamento de erros
            console.error('Erro na exclusão do usuário:', error);
            toast.error('Erro na exclusão do usuário:', error);
        }
    };

    const columns = [
        { field: 'id', header: 'Numero' },
        { field: 'company_name', header: 'Cliente' },
        { field: 'produto', header: 'Produto' },
        { field: 'subtotal', header: 'Subtotal' },
        { field: 'status', header: 'Status' },
        { field: 'contrato', header: 'Contrato' },
        { field: 'pagamento', header: 'Pagamento' },
        { field: 'alerta', header: 'Alerta' },
        { field: 'validade', header: 'Validade' },
 

    ];

    const [isAddServiceModalVisible, setIsAddServiceModalVisible] = useState(false);

    const openAddServiceModal = () => {
        setIsEditing(false); // Certifique-se de que não está em modo de edição
        setFormData({
            id: 0,
            name: '',
            email: '',
            password: '',
            profile: '',
            profile_id: 1,
        });
        setIsAddServiceModalVisible(true);
    };
    const renderAlert = (alerta) => {
        let alertColor = '';
        switch (alerta) {
            case 'vermelho':
                alertColor = 'bg-danger';
                break;
            case 'laranja':
                alertColor = 'bg-warning';
                break;
            case 'verde':
                alertColor = 'bg-success';
                break;
            default:
                alertColor = 'bg-secondary'; // Default color
        }
        return <div className={`alert ${alertColor}`}>{alerta}</div>;
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Ordens de Serviços' parent='Home' title='Ordens de Serviços' style={{ marginLeft: '-2em' }} />

            <Btn
                attrBtn={{
                    color: 'primary', className: 'm-b-15 col-2 d-flex justify-content-center', outline: true,
                    onClick: openAddServiceModal  // Chame a função ao clicar no botão Novo
                }}
            >
                Novo
            </Btn>
            <GenericList
                data={mockUsers}
                columns={columns.map((col) => ({
                    ...col,
                    render: col.field === 'alerta' ? (rowData) => renderAlert(rowData[col.field]) : col.render,
                }))}
                searchName={'Serviços'}
            />

            {/* Adicione a lógica do modal de exclusão aqui */}
            <CommonModal
                title={isEditing ? 'Editar Serviço' : 'Adicionar Novo Serviço'}
                isOpen={isAddServiceModalVisible}
                toggler={() => setIsAddServiceModalVisible(!isAddServiceModalVisible)}
                headerClose // Adiciona o botão de fechar (X) no cabeçalho
            >
                {/* Conteúdo do modal, como formulário para descrever o serviço */}
                <Form>
                    {/* Adicione os campos necessários para descrever o serviço */}
                    <FormGroup>
                        <Label for="serviceName">Descreva aqui o serviço desejado</Label>
                        <Input
                            type="textarea"
                            name="serviceName"
                            id="serviceName"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <div className="mt-3 d-flex gap-2">

                        {/* Adicione mais campos conforme necessário */}
                        <Button color="primary" onClick={() => { }}>
                            Salvar Solicitação
                        </Button>

                        {/* Botão para fechar o modal */}
                        <Button color="secondary" onClick={() => setIsAddServiceModalVisible(false)}>
                            Fechar
                        </Button>
                    </div>
                </Form>
            </CommonModal>
        </Fragment>
    );
};

export default Faturas;
