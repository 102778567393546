import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { Table } from '../../../_Commons/Table/Index'
import { Switch } from '../../../_Commons/Inputs/Switch'

import { API } from '../../../../../../api/API/api'

export default function MenuSettings() {
    const { tenant_id } = useParams()
    const [data, setData] = useState([])

    const apiPath = `/tenants/${tenant_id}/management/cms/menus/settings`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])


    const handleSwitch = async (data) => {
        try {
            const { id, ...payload } = data
            payload.display = !payload.display

            const response = await API.put(`${apiPath}/${id}`, payload)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }
    }

    const setTableData = (payloads) => ({
        columns: ['Menu', 'Exibir'],
        rows: payloads.map(item => [
            item.name,
            (<Switch name='display' id='display' onChange={() => handleSwitch(item)} checked={item.display} />)
        ]),
        payloads: payloads
    })

    return (
        <>
            <Table
                header='Configurações de Menus' data={setTableData(data)}
            />
        </>
    )
}

