import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Row, Col, Form, Label, List } from 'reactstrap'

import { API } from '../../../../../../api/API/api'

const translation = {
    dashboard: 'Dashboard',
    financial: 'Financeiro',
    commissions: 'Comissões',
    academic: 'Acadêmico',
    students: 'Alunos',
    classes: 'Turmas',
    staff: 'Corpo Docente',
    teachers: 'Professorer',
    monitors: 'Monitores',
    coordination: 'Coordenações',
    requirements: 'Requerimentos',
    questions_to_the_teacher: 'Perguntas ao professor',
    course_schedule: 'Programa de Cursos',
    categories: 'Categorias',
    combos: 'Combos',
    'learning paths': 'Trilhas de aprendizado',
    courses: 'Cursos',
    disciplines: 'Disciplinas',
    modules: 'Módulos',
    exams: 'Provas',
    'questions bank': 'Banco de questões',
    videos: 'Vídeos',
    orders: 'Pedidos',
    defaulters: 'Inadimplentes',
    agreements: 'Acordos',
    cms: 'CMS',
    general: 'Geral',
    'home page': 'Página Inicial',
    setting: 'Configuração',
    'featured posts': 'Posts em Destaque',
    banners: 'Banners',
    'recent posts': 'Posts Recentes',
    menus: 'Menus',
    pages: 'Páginas',
    content_manager: 'Gerenciador de Conteúdo',
    articles: 'Artigos',
    campaigns: 'Campanhas',
    top_banner: 'Banner Topo',
    testimonials: 'Depoimentos',
    slides: 'Slides',
    speeches: 'Palestras',
    partners: 'Parceiros',
    faq: 'Faq',
    faqs: 'Faqs',
    schedule: 'Agenda',
    calendar: 'Calendário',
    settings: 'Configurações',
    emails: 'Emails',
    templates: 'Templates',
    social_networks: 'Redes Sociais',
    payments: 'Pagamentos',
    subscriptions: 'Planos de assinaturas',
    renegotiations: 'Renegociações',
    requirements_type: 'Tipos de Requerimentos',
    coupons: 'Cupons',
    users: 'Usuários',
    access_control: 'Controle de Acesso',
    campus: 'Campus',
    professions: 'Profissões',
    lti: 'LTI',
    consumers: 'Consumidores',
    applications: 'Aplicativos',
    terms: 'Termos',
    reports: 'Relatórios',
    'students general': 'Alunos Geral',
    graduates: 'Concluintes'
}

export default function MenusAdministrative() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        menus: [],
        actives: []
    })
    const [tree, setTree] = useState(null)
    const apiPath = `/tenants/${tenant_id}/management/cms/menus/administrative`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                const treePayload = {}

                response.data.menus.forEach(item => {
                    const keys = item.name.split('.')

                    let currentNode = treePayload

                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i] in translation ? translation[keys[i]] : keys[i];
                        if (!currentNode[key]) {
                            if (i === keys.length - 1) {
                                currentNode[key] = { ...item, name: key }
                            } else {
                                currentNode[key] = {}
                            }
                        }
                        currentNode = currentNode[key]
                    }
                });

                setTree(treePayload)
                setData(response.data)
            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const handleSubmit = (e) => {
        const id = parseInt(e.target.id)
        const actives = data.actives
        const index = actives.indexOf(id)

        actives.includes(id) ? actives.splice(index, 1) : actives.push(id)

        setData((prevFormData) => ({
            ...prevFormData,
            actives: actives,
        }))
    }

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            const response = await API.put(apiPath, {actives: data.actives})

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

    }

    const TreeCheckbox = ({ tree, marginLeft = 30 }) => (
        <List type='unstyled'>
            {Object.entries(tree).map(([key, value], index) => (
                <li key={`tree-menu-admin-${key}-${index}`} style={{ marginLeft: `${marginLeft}px` }}>
                    {!value.hasOwnProperty('id') ?
                        <>
                            <br />
                            <Label>{key}</Label>
                            <TreeCheckbox tree={value} marginLeft={marginLeft + 5} />
                        </> :
                        <>
                            <input onChange={handleSubmit} data-id={value.id} type='checkbox' id={value.id} checked={data.actives.includes(value.id)} />
                            {' '}
                            <Label>{key}</Label>
                        </>
                    }
                </li>
            ))}
        </List>
    )

    return (
        <>
            <Card className='col-12' style={{ marginBottom: "8.5em" }}>
                <div className="card-header">
                    <h5 className="card-title p-b-0 p-t-0">Itens do Menu</h5>
                </div>
                <Form>
                    {!!tree && <TreeCheckbox tree={tree} />}

                    <Row className="text-right rowCustom">
                        <Col className="text-right">
                            <button type="submit" className="btn btn-outline-primary m-3" onClick={submitForm}>
                                Salvar alterações
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

