import React, { Fragment, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { API } from '../../../../../../api/API/api';
import { FaCreditCard, FaReceipt } from 'react-icons/fa';

const StatesBrazil = [
    { nome: 'Acre', sigla: 'AC' },
    { nome: 'Alagoas', sigla: 'AL' },
    { nome: 'Amapá', sigla: 'AP' },
    { nome: 'Amazonas', sigla: 'AM' },
    { nome: 'Bahia', sigla: 'BA' },
    { nome: 'Ceará', sigla: 'CE' },
    { nome: 'Distrito Federal', sigla: 'DF' },
    { nome: 'Espírito Santo', sigla: 'ES' },
    { nome: 'Goiás', sigla: 'GO' },
    { nome: 'Maranhão', sigla: 'MA' },
    { nome: 'Mato Grosso', sigla: 'MT' },
    { nome: 'Mato Grosso do Sul', sigla: 'MS' },
    { nome: 'Minas Gerais', sigla: 'MG' },
    { nome: 'Pará', sigla: 'PA' },
    { nome: 'Paraíba', sigla: 'PB' },
    { nome: 'Paraná', sigla: 'PR' },
    { nome: 'Pernambuco', sigla: 'PE' },
    { nome: 'Piauí', sigla: 'PI' },
    { nome: 'Rio de Janeiro', sigla: 'RJ' },
    { nome: 'Rio Grande do Norte', sigla: 'RN' },
    { nome: 'Rio Grande do Sul', sigla: 'RS' },
    { nome: 'Rondônia', sigla: 'RO' },
    { nome: 'Roraima', sigla: 'RR' },
    { nome: 'Santa Catarina', sigla: 'SC' },
    { nome: 'São Paulo', sigla: 'SP' },
    { nome: 'Sergipe', sigla: 'SE' },
    { nome: 'Tocantins', sigla: 'TO' }
  ];

const CheckoutTableData = ({ setSteps, setFormDataTenants }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        cpf: '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'BR'
    });

    const { register, formState: { errors }, handleSubmit } = useForm();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const paymentTicket = async () => {
        try {
            const customer = await API.post('/create-customer', {
                email: formData.email,
                name: formData.firstName + ' ' + formData.lastName,
            });
        
            const customerId = customer.data.customer_id;
    
            const { data } = await API.post('/tenants/payment/create-setup-intent-boleto', {
              cpf: formData.cpf,
              name: formData.firstName + ' ' + formData.lastName,
              email: formData.email,
              address: formData.address,
              city: formData.city,
              state: formData.state,
              postal_code: formData.postalCode,
              country: formData.country
            });
    
            console.log('Resposta do backend', data);
            const paymentMethod = data;
        
            setFormDataTenants((prevState) => ({
                ...prevState,
                'customerId': customerId,
                'paymentMethod': 'boleto',
                'paymentMethodId': paymentMethod,
                'name': formData.firstName + ' ' + formData.lastName,
                'email': formData.email
            }))
            setSteps((prev) => prev + 1);
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            paymentTicket();
        } else {
            errors.alert('add data');
        }
    };

    return (
        <Fragment>
            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation" style={{ width: '70%' }} >
                    <Row>
                        <FormGroup className="mb-3 col-sm-6">
                            <input className="form-control" type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="Nome" />
                            <span style={{ color: '#ff5370' }}>{errors.firstName && 'First name is required'}</span>
                        </FormGroup>
                        <FormGroup className="mb-3 col-sm-6">
                            <input className="form-control" type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Sobre Nome" />
                            <span style={{ color: '#ff5370' }}>{errors.lastName && 'Last name is required'}</span>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="mb-3 col-sm-6">
                            <input className="form-control" type="text" name="cpf" value={formData.cpf} onChange={handleChange} placeholder="Cpf" />
                            <span style={{ color: '#ff5370' }}>{errors.cpf && 'Please enter number for cpf.'}</span>
                        </FormGroup>
                        <FormGroup className="mb-3 col-sm-6">
                            <input className="form-control" type="text" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                            <span style={{ color: '#ff5370' }}>{errors.email && 'Please enter proper email address .'}</span>
                        </FormGroup>
                    </Row>
                    <FormGroup className="mb-3">
                        <Input className="form-control" type="select" name="selectMulti" value={formData.country} onChange={handleChange}>
                            <option>Brasil</option>
                        </Input>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <input className="form-control" type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Endereço" />
                        <span style={{ color: '#ff5370' }}>{errors.address && 'Please right your address .'}</span>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <input className="form-control" type="text" name="city" value={formData.city} onChange={handleChange} placeholder="Cidade" />
                        <span style={{ color: '#ff5370' }}>{errors.city && 'select one city'}</span>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Input className="form-control" type="select" name="state" value={formData.state} onChange={handleChange} placeholder="Estado">
                            <option value="">Selecione o estado</option>
                            {StatesBrazil.map((estado) => (
                            <option key={estado.sigla} value={estado.sigla}>{estado.nome}</option>
                            ))}
                       </Input>
                       <span style={{ color: '#ff5370' }}>{errors.state && 'select one state'}</span>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <input className="form-control" type="text" name="postalCode" value={formData.postalCode} onChange={handleChange} placeholder="Cep" />
                        <span style={{ color: '#ff5370' }}>{errors.postalCode && 'Required integer'}</span>
                    </FormGroup>
                   
                    <Row className="text-end">
                      <Col>
                        <Button className="secondary me-2" onClick={() => setSteps((pre) => pre - 1)}>
                          Voltar
                        </Button>
                        <Button className="primary" type='submit'>
                          Próximo
                        </Button>
                      </Col>
                    </Row>
                </Form>
            </Col>
        </Fragment>
    );
};

export default CheckoutTableData;
