import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { CardBody, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import DashboardPanel from './Components/DashboardPanel';
import Tenants from './Components/Tenants/Index';
import ReactApexChart from 'react-apexcharts';
import { TodayProgressMessage, TodayProgressTitle } from '../../../Constant';
import { todayProgressData } from '../../../Data/OnlineCourse/ChartData';

const Dashboard = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs mainTitle='Dashboard' parent='Home' title='Dashboard' />
        <Row style={{ height: '100%' }}>
          <Col sm='12'>
            <DashboardPanel />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
