import React, { useState } from 'react'
import { Nav, TabContent, TabPane } from 'reactstrap'

import Item from './Item'
import Dropdown from './Dropdown'

export function Navbar({ items }) {
    const [activeTab, setActiveTab] = useState({
        root: items[0].id,
        id: `${items[0].id}-${items[0].tabs[0].label}`
    })

    const toggleTab = (root, newTab) => setActiveTab({
        root: root,
        id: `${root}-${newTab}`
    })

    const item = items.filter((item) => item.id === activeTab.root)[0]
    const Component = item.tabs.filter((tab) => `${item.id}-${tab.label}` === activeTab.id)[0].component

    return (
        <>
            <Nav tabs>
                {items.map(item => {
                    return (
                        item.tabs.length === 1 ?
                            <Item item={item} active={activeTab.root === item.id} onClick={toggleTab} /> :
                            <Dropdown item={item} active={activeTab.root === item.id} onClick={toggleTab} />
                    )
                })}
            </Nav>

            <TabContent activeTab={activeTab.id}>
                <Component />
            </TabContent>
        </>
    )
}
