import { Form, FormGroup, Label, Input } from 'reactstrap'

export const InformationsModules = ({ formData, handleModalInputChange, data }) => {
  return (
    <Form style={{ marginTop: '15px' }}>
      <FormGroup>
          <Label for='title'>Nome:</Label>
          <Input type='text' name='title' id='title' value={formData.title} onChange={handleModalInputChange} required />
      </FormGroup>

      <FormGroup>
          <Label for='teacher_id'>Professor:</Label>
          <Input type='select' name='teacher_id' id='teacher_id' onChange={handleModalInputChange}>
              {data.teachers.map((teacher, index) => (
                  <option key={index} value={teacher.id} selected={teacher.id === formData.teacher_id}>
                      {teacher.name}
                  </option>
              ))}
          </Input>
      </FormGroup>
    
      <FormGroup>
          <Label for='description'>Conteúdo programatico:</Label>
          <Input type='textarea' name='description' id='description' value={formData.description} onChange={handleModalInputChange} required />
      </FormGroup>

    </Form>
  )
}