import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import EditMyProfile from './EditmyProfile';
import MyProfileEdit from './MyProfile';
import UserTable from './UserTable';
import ReactGA from 'react-ga';

const UsersEditContain = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.href)
  },[])

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Editar Perfil' parent='Perfil' title='Editar' />
      <Container fluid={true}>
        <div className='edit-profile'>
          <Row>
            {/* <Col xl='4'>
              <MyProfileEdit />
            </Col> */}
            {/* <Col xl='8'> */}
            <Col xl='12'>
              <EditMyProfile />
              
            </Col>
            {/* <Col md='12'>
              <UserTable />
            </Col> */}
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default UsersEditContain;
