import React from 'react'
import { Label, FormGroup } from 'reactstrap'
import CKEditor from 'react-ckeditor-component'

export function Editor({ label, value, onChange }) {
  // const handleChange = (event) => {
  //   const content = event.editor.getData();
  //   onChange(content);
  // };

  return (
    <FormGroup>
      <Label>{label}</Label>

      <CKEditor
        content={value}
        events={{
          change: onChange
        }}
      />

    </FormGroup>
  )
}
