import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input, Button, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    teacher: { id: 0, name: '' },
    description: '',
    alternatives: []
}


export default function Questions() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        questions: [],
        teachers: []
    })

    const [modalVisible, setModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const [userDelete, setUserDelete] = useState(0)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const [alternativeOpened, setAlternativeOpened] = useState('');
    const toggleAlternativeOpened = id => alternativeOpened === id ? setAlternativeOpened() : setAlternativeOpened(id);

    const setTableData = (payloads) => ({
        columns: ['Questão', 'Professor(a)'],
        rows: payloads.map(item => [item.description, item.teacher.name]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/course_program/questions`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                formDataDefault.teacher.id = response.data.teachers[0].id
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (isEditing, data) => {
        setIsEditing(isEditing)
        setFormData(data)
        setModalVisible(true)
    }

    const openModalDelete = ({ id }) => {
        setUserDelete(id)
        setModalDeleteVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, ...payload } = formData
            const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    const submitModalDeleteForm = async (id) => {
        try {
            const response = await API.delete(`${apiPath}/${id}`)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Error')
        }

        setModalDeleteVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target
        value = ['teacher'].includes(name) ? JSON.parse(e.target.value) : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const alternativeAdd = () => {
        const alternatives = formData.alternatives
        alternatives.push({
            description: '',
            correct: false
        })

        setFormData((prevFormData) => ({
            ...prevFormData,
            alternatives: alternatives
        }))
    }

    const changeAlternativeData = (e, index) => {
        let { name, value } = e.target
        const alternatives = formData.alternatives

        value = ['correct'].includes(name) ? !alternatives[index].correct : value
        alternatives[index][name] = value

        setFormData((prevFormData) => ({
            ...prevFormData,
            alternatives: alternatives
        }))
    }

    return (
        <>
            <Table
                header='Banco de questões' data={setTableData(data.questions)}
                onNewClick={() => openModal(false, formDataDefault)}
                onEditClick={(payload) => openModal(true, payload)}
                onDeleteClick={(payload) => openModalDelete(payload)}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Questão' : 'Criar Questão'} onSubmit={() => submitModalForm()} >
                <Form>
                    <FormGroup>
                        <Label for='teacher'>Professor(a) :</Label>
                        <Input type='select' name='teacher' id='teacher' onChange={handleModalInputChange}>
                            {data.teachers.map((teacher, index) => (
                                <option key={`question-teacher-${index}`} value={JSON.stringify(teacher)} selected={teacher.id === formData.teacher.id}>
                                    {teacher.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for='description'>Descrição:</Label>
                        <Input type='textarea' name='description' id='description' value={formData.description} onChange={handleModalInputChange} required />
                    </FormGroup>

                    <FormGroup>
                        <Label>Alternativas:</Label>
                        <br />
                        <Button color='primary' outline={true} onClick={() => alternativeAdd()}>+</Button>


                        <Accordion open={alternativeOpened} toggle={toggleAlternativeOpened}>
                            {formData.alternatives.map((alternative, index) =>
                                <AccordionItem>
                                    <AccordionHeader targetId={index}>Alternativa {index + 1}</AccordionHeader>
                                    <AccordionBody accordionId={index}>
                                        <FormGroup>
                                            <Label for='description'>Descrição:</Label>
                                            <Input type='text' name='description' id='description' value={alternative.description} onChange={(e) => changeAlternativeData(e, index)} required />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for='correct'>Opção correta:</Label>
                                            <FormGroup switch>
                                                <Input type='switch' name='correct' id='correct' onChange={(e) => changeAlternativeData(e, index)} checked={alternative.correct} />
                                            </FormGroup>
                                        </FormGroup>

                                    </AccordionBody>
                                </AccordionItem>
                            )}
                        </Accordion>
                    </FormGroup>
                </Form>
            </ModalCreateAndEdit>

            <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
        </>
    )
}

