import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { Btn, H5, H6, P, Badges } from '../../../AbstractElements'
import "./Profile.scss"
import { API } from '../../../api/API/api'

export default function SitesInfo({tenants}) {
  const token = localStorage.getItem("token");
  return (
    <Fragment>
      <Container fluid={true}>
        <Col>
          <Card className='hovercard'>
            <CardHeader>
              <Row>
                <Col md="12">
                  <H5>Meus Sites</H5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className='p-t-15'>
              {tenants.map((t, i) => (
                <Card key={i} className='tenantCard shadow'>
                  <div className='tenantCard__pill m-l-10 b-r-10'></div>
                  <div className='tenantCard__info'>
                  <a href={`https://${t.domain}`} target="_blank" rel="noopener noreferrer">{t.domain}</a>
                    {/* <p>Criado em: 17/10/2023</p> */}
                  </div>
                  <div className="tenantCard__btn">
                    <Badges attrBadge={{ className: 'badge rounded-pill', color: `${t.status === "activated" ? "success" : "danger"}`, pill: true }}> </Badges>
                  </div>
                </Card>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  )
}
