import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Form, Label, Row, Table as Trs, Button } from 'reactstrap'

import { Table } from '../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../_Commons/Modal/CreateAndEdit'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    status: '',
    coupon: null,
    payment: '',
    date: '',
    type: '',
    student: {
        id: 0,
        name: '',
        email: '',
        cpf: null,
        phone1: null,
        phone2: null,

        public_place: null,
        neighborhood: null,
        city: null,
        state: null,
        cep: null
    },

    payment_method: '',
    placed_in: '',
    released_in: '',
    released_by: '',

    item: {
        id: 0,
        course_name: '',
        value: 0,
        discount: 0,
        extentions: []
    },
    payments: []
}

const toBRL = value => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export default function Requests() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        requests: [],
        total: 0,
        last_page: 0
    })

    const [modalVisible, setModalVisible] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [lastPage, setLastPage] = useState(0)
    const [sortColumn, setSortColumn] = useState('id')
    const [descendingSort, setDescendingSort] = useState(false)

    const setTableData = (payloads) => ({
        columns: ['Id', 'Nome', 'Email', 'Cupom', 'Status', 'Data do pedido', 'Valor total'],
        rows: payloads.map(item => [
            item.id,
            item.student.name,
            item.student.email,
            (item.coupon || ''),
            item.status,
            item.date,
            toBRL(item.item ? (
                item.item.value - (item.item.value * (item.item.discount / 100)) + item.item.extentions.reduce((acc, extention) => acc + extention.value, 0)
            ) : 0)
        ]),
        payloads: payloads
    })

    // const apiPath = `/tenants/${tenant_id}/management/financial/requests`
    const apiQuery = `/tenants/${tenant_id}/management/financial/requests?page=${currentPage}&perpage=${itemsPerPage}&query=${searchQuery}&sorting=${sortColumn}&reversed=${descendingSort}`
    
    const getData = async () => {
        try {
            const response = await API.get(apiQuery)
            setData(response.data)
            setLastPage(response.data.last_page)
        } catch (error) { console.error(error) }
    }

    useEffect(() => {
        getData()
    }, [currentPage, searchQuery, itemsPerPage, descendingSort, sortColumn])

    const openModal = (data) => {
        setFormData(data)
        setModalVisible(true)
    }

    const handleSearch = async () => {
        setCurrentPage(1)
        await getData();
    }

    const handleSort = (column, direction) => {
        let translatingColumn;
        switch (column) {
          case 0:
            translatingColumn = 'id'
            break;
          case 1:
            translatingColumn = 'name'
            break
          case 2:
            translatingColumn = 'email'
            break
          case 3:
            translatingColumn = 'coupon'
            break
          case 4:
            translatingColumn = 'status'
            break
          case 5:
            translatingColumn = 'date'
            break
          case 6:
            translatingColumn = 'payment'
            break
          default:
            break;
        }
        setSortColumn(translatingColumn);
        direction === 'asc' ? setDescendingSort(false) : setDescendingSort(true)
    }

    const viewButton = ({ payload }) => (
        <>
            <Button color='primary' outline={true} onClick={() => openModal(payload)}>
                <i class="fa fa-eye" />
            </Button>
        </>
    )

    return (
        <>
            <Table
                header='Pedidos' data={setTableData(data.requests)}
                customButtons={viewButton}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                search={true}
                handleSearch={handleSearch}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                pagination={true}
                lastPage={lastPage}
                handleSort={handleSort}
                sortColumn={sortColumn}
                sortDirection={descendingSort}
            />

            <ModalCreateAndEdit size='xl' isOpen={modalVisible} setIsOpen={setModalVisible} title={`Pedido #${formData.id}`} >
                <Form>
                    <Row xs='3'>
                        <div>
                            <Label>Aluno</Label><br />
                            <strong>Nome:</strong>
                            <span className='mx-2'>{formData.student.name}</span><br />
                            <strong>Email:</strong>
                            <span className='mx-2'>{formData.student.email}</span><br />
                            <strong>Telefone 1:</strong>
                            <span className='mx-2'>{formData.student.phone1 || ''}</span><br />
                            <strong>Telefone 2:</strong>
                            <span className='mx-2'>{formData.student.phone2 || ''}</span><br />
                            <strong>CPF:</strong>
                            <span className='mx-2'>{formData.student.cpf || ''}</span><br /><br />
                        </div>
                        <div>
                            <Label>Endereço</Label><br />
                            <strong>Logradouro:</strong>
                            <span className='mx-2'>{formData.student.public_place || ''}</span><br />
                            <strong>Bairro:</strong>
                            <span className='mx-2'>{formData.student.neighborhood || ''}</span><br />
                            <strong>Cidade:</strong>
                            <span className='mx-2'>{formData.student.city || ''}</span><br />
                            <strong>Estado:</strong>
                            <span className='mx-2'>{formData.student.state || ''}</span><br />
                            <strong>CEP:</strong>
                            <span className='mx-2'>{formData.student.cep || ''}</span><br /><br />
                        </div>
                        <div>
                            <Label>Dados</Label><br />
                            <strong>Forma de pagamento:</strong>
                            <span className='mx-2'>{formData.payment_method}</span><br />
                            <strong>Pedido realizado em:</strong>
                            <span className='mx-2'>{formData.placed_in}</span><br />
                            <strong>Liberado em:</strong>
                            <span className='mx-2'>{formData.released_in}</span><br />
                            <strong>Liberado por:</strong>
                            <span className='mx-2'>{formData.released_by}</span><br /><br />
                        </div>
                    </Row>

                    <Trs borderless>
                        <thead>
                            <tr>
                                <th>Curso</th>
                                <th>Valor</th>
                                <th>Desconto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.item &&
                                <tr>
                                    <td>
                                        <span>{formData.item.course_name}</span> <br />

                                        {formData.item.extentions.map((extention, index) => (
                                            <div key={`extention-${index}`}>
                                                <span>
                                                    +Extensão de {extention.period} dias ({toBRL(extention.value)})
                                                </span>
                                                <br />
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {toBRL(formData.item.value)}
                                    </td>
                                    <td>
                                        {toBRL(formData.item.value * (formData.item.discount / 100))} ({formData.item.discount}%)
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Trs>

                    <br />
                    {formData.item &&
                        <Row xs='4'>
                            <span>(=) Subtotal:
                                <strong className='mx-2'>{toBRL(formData.item.value)}</strong>
                            </span>

                            <span>(+) Extensões:
                                <strong className='mx-2'>{toBRL(
                                    formData.item.extentions.reduce((acc, extention) => acc + extention.value, 0)
                                )}</strong>
                            </span>

                            <span>(-) Descontos:
                                <strong className='mx-2'>{toBRL(
                                    formData.item.value * (formData.item.discount / 100)
                                )}</strong>
                            </span>

                            <span>(=):
                                <strong className='mx-2'>{toBRL(
                                    formData.item.value - (formData.item.value * (formData.item.discount / 100)) + formData.item.extentions.reduce((acc, extention) => acc + extention.value, 0)
                                )}</strong>
                            </span>
                        </Row>
                    }
                    <br />

                    <Trs borderless>
                        <thead>
                            <tr>
                                <th>Condição de Pagto.</th>
                                <th>Valor</th>
                                <th>Situação</th>
                                <th>Vencimento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.payments.map((extention, index) => (
                                <tr key={`extention-${index}`}>
                                    <td>{extention.condition}</td>
                                    <td>{toBRL(extention.value || 0)}</td>
                                    <td>{extention.status}</td>
                                    <td>{extention.due_date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Trs>
                </Form>
            </ModalCreateAndEdit>
        </>
    )
}

