export const MENUITEMS = [
  {
    // por sub para acessar os svg
    menutitle: "",
    menucontent: "Bouns Pages & Apps",
    Items: [
      { path: `/admin/dashboard`, icon: "widget", type: "link", active: false, title: "Dashboard" },
      { path: `/admin/products`, icon: "others", type: "link", active: false, title: "Produtos" },
      // { path: `/admin/products2`, icon: "user", type: "sub", active: false, title: "Produtos2" },
    ],
  },
];
