import { Fragment, useState } from "react";
import { Col, Row, Button, Label, FormGroup, Input, Form, Card, CardBody } from "reactstrap";
import { CardElement } from "@stripe/react-stripe-js";
import { H6, Image } from "../../../../../../AbstractElements";
import './CheckoutPaymentCard.css';
import cardImage from '../../../../../../assets/images/card.png';

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};


export default function CheckoutPaymentCard({ dataUser, handleChangeDataUser, setSteps, onSubmit }) {

  return (
    <Fragment>
      <Col xxl='8' className='box-col-12'>
        <Card>
          <CardBody>
            <Row>
              <Col md='7'>
                <Form className='theme-form mega-form' style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <Row>
                    <FormGroup className='mb-3'>
                      <Input
                        type="text"
                        name="name"
                        className='form-control'
                        value={dataUser.name}
                        onChange={(event) => handleChangeDataUser(event)}
                        placeholder="Nome"
                        required
                      />
                    </FormGroup>
                  </Row>

                  <Row>
                    <FormGroup className='mb-3'>
                      <Input
                        type="text"
                        name="email"
                        className='form-control'
                        value={dataUser.email}
                        onChange={(event) => handleChangeDataUser(event)}
                        placeholder="Email"
                        required
                      />
                    </FormGroup>
                  </Row>


                  <Row>
                    <CardElement options={cardStyle} />
                  </Row>

                  <Row className="text-end" style={{ marginTop: '10px' }}>
                    <Col>
                      <Button className="secondary me-2" onClick={() => setSteps((pre) => pre - 1)}>
                        Voltar
                      </Button>
                      <Button className="primary" onClick={() => onSubmit()}>
                        Próximo
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <Col md='5' className='text-center'>
                <Image
                  attrImage={{
                    className: 'img-fluid',
                    src: `${cardImage}`,
                    alt: '',
                  }}
                />
              </Col>
            </Row>
          </CardBody>

        </Card>

      </Col>
    </Fragment>
  )
}