import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import { Table } from '../../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../../_Commons/Modal/Delete'

import { API } from '../../../../../../../api/API/api'
import { CourseBasic } from './ModalTabs/Basic'
import { CourseAdvanced } from './ModalTabs/Advanced'
import { CourseCertificate } from './ModalTabs/Certificate'
import { CourseCertificateParticipation } from './ModalTabs/CertificateParticipation'
import { CourseCoproduction } from './ModalTabs/Coproduction'
import { StudentsCourse } from './ModalTabs/StudentsCourse'
import { Subjects } from './ModalTabs/Subjects'

const formDataDefault = {
  id: 0,
  name: '',
  value: 0,
  promotion: 0,
  status: true,

  goal: null,
  timeline: null,
  teaching_staff: null,
  target: null,
  demo_video_url: null,
  tcc: true,
  coordinator: { id: 0, name: '' },
  rules: null,
  course_duration: null,
  enrollment_duration: null,
  enrollment_unlimited: null,
  limit_students: null,
  categories: [],
  discount_promotion: 0,
  bill_discount: 0,
  sectional: null,
  redirect: null,
  lti: null,
  enrollment_extension: null,
  free_days: null,
  extensions: [],
  tag_new_course: false,
  tag_new_course_bg: null,
  tag_new_course_color: null,
  tag_new_course_days: null,
  tag_text: false,
  tag_text_bg: null,
  tag_text_color: null,
  tag_text_message: null,
  tag_time_course: false,
  tag_time_course_bg: null,
  tag_time_course_color: null,
  tag_time_course_hours: null,

  certificate: null,
  certificate_presence_min: null,
  certificate_grade_min: null,
  certificate_text: null,
  certificate_margin_top: null,
  certificate_margin_bottom: null,
  certificate_margin_left: null,
  certificate_margin_right: null,

  coproducers: []
}

const listColunsStudents = ['id', 'name', 'email', 'cpf', 'city', 'state'];
const listColumnCourses = ['id', 'name', 'value', 'promotion', 'status'];
const listColumnSubject = ['id', 'name', 'teacher']

export default function Courses() {
  const { tenant_id } = useParams()
  const [data, setData] = useState({
    courses: [],
    coordinators: [],
    categories: [],
    sectionals: []
  })

  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)
  const [students, setStudents] = useState([]);
  const [subjescts, setSubjects] = useState([]);
  const [unlinked, setUnlinked] = useState();

  const [modalVisible, setModalVisible] = useState(false)
  const [activeTab, setActiveTab] = useState('basic')

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [lastPage, setLastPage] = useState(0)
  const [sortColumn, setSortColumn] = useState('id')
  const [descendingSort, setDescendingSort] = useState(false)
  
  const [course_id, setCourseId] = useState();

  const [courseName, setCourseName] = useState('');

  const [currentStudentsPage, setCurrentStudentsPage] = useState(1);
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [searchStudents, setSearchStudents] = useState('');
  const [sortStents, setSortStudents] = useState('id');
  const [descendigStudentsSort, setDescendingStudentsSort] = useState(false);
  const [studentsLastPage, setStudentsLastPage] = useState(0);

  const [currentSubjectPage, setCurrentSubjectPage] = useState(1);
  const [subjectPerPage, setSubjectPerPage] = useState(10);
  const [searchSubject, setSearchSubject] = useState('');
  const [sortSubject, setSortSubject] = useState('id');
  const [descendigSubjectSort, setDescendingSubjectSort] = useState(false);
  const [subjectLastPage, setSubjectLastPage] = useState(0);

  const firstRender = useRef(true);
  const firstRenderSubject = useRef(true)

  const setTableData = (payloads) => ({
    columns: ['Id', 'Nome', 'Valor', 'Promoção', 'Status'],
    rows: payloads.map(item => [
      item.id,
      item.name,
      item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      `${item.promotion}%`,
      (item.status ? 'Ativo' : 'Inativo')
    ]),
    payloads: payloads
  })

  const apiPath = `/tenants/${tenant_id}/management/academic/course_program/courses?page=${currentPage}&perpage=${itemsPerPage}&query=${searchQuery}&sorting=${sortColumn}&reversed=${descendingSort}`;
  const apiQuery = `/tenants/${tenant_id}/management/academic/course_program/courses`;

  const getData = async () => {
    try {
      const response = await API.get(apiPath)
      formDataDefault.coordinator.id = response.data.coordinators[0].id
      setData(response.data)
      setLastPage(response.data.last_page)
    } catch (error) { console.error(error) }
  }

  const getStudents = async (id) => {
    if (id) {
      setCourseId(id);
    }

    try {
      const apiStudents = `/tenants/${tenant_id}/management/academic/course_program/courses/${id ? id : course_id}/students?page=${currentStudentsPage}&perpage=${studentsPerPage}&query=${searchStudents}&sorting=${sortStents}&reversed=${descendigStudentsSort}`
      const response = await API.get(apiStudents)
      setStudents(response.data.data)
      setStudentsLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const getSubjects = async (id) => {
    if (id) {
      setCourseId(id);
    }

    try {
      const apiSubject = `/tenants/${tenant_id}/management/academic/course_program/courses/${id ? id : course_id}/subjects?page=${currentSubjectPage}&perpage=${subjectPerPage}&query=${searchSubject}&sorting=${sortSubject}&reversed=${descendigSubjectSort}`
      const response = await API.get(apiSubject);
      setSubjects(response.data.linked);
      setUnlinked(response.data.unlinked);
      setSubjectLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData()
  }, [currentPage, searchQuery, itemsPerPage, descendingSort, sortColumn])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getStudents()
  }, [currentStudentsPage, searchStudents, studentsPerPage, descendigStudentsSort, sortStents])

  useEffect(() => {
    if (firstRenderSubject.current) {
      firstRenderSubject.current = false;
      return;
    }
    getSubjects();
  }, [currentSubjectPage, searchSubject, subjectPerPage, descendigSubjectSort, sortSubject]);

  const openModal = (isEditing, data) => {
    setIsEditing(isEditing);
    setFormData(data);
    setCourseName(data.name)
    setModalVisible(true);
    getStudents(data.id);
    getSubjects(data.id);
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    
    value = ['status', 'tcc', 'lti', 'enrollment_unlimited', 'enrollment_extension', 'accepts_questions',
      'tag_new_course', 'tag_text', 'tag_time_course', 'certificate'].includes(name) ? !formData[name] : value

    value = ['course_image', 'certificate_file', 'certificate_participation_file'].includes(name) ? e.target.files[0] : value
    value = ['coordinator', 'sectional'].includes(name) ? JSON.parse(e.target.value) : value

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const submitModalForm = async () => {
    try {
      const { id, course_image, certificate_file, certificate_participation_file, ...payload } = formData


      if (course_image || certificate_file || certificate_participation_file) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }

        const form = new FormData()

        if (course_image) form.append('course_image', course_image)
        if (certificate_file) form.append('certificate_file', certificate_file)
        if (certificate_participation_file) form.append('certificate_participation_file', certificate_participation_file)

        const responseImg = await API.post(`${apiQuery}/upload`, form, config)

        payload.course_image_url = responseImg.data.course_image_url
        payload.certificate_file_url = responseImg.data.certificate_file_url
        payload.certificate_participation_file_url = responseImg.data.certificate_participation_file_url
      }

      const response = isEditing ? await API.put(`${apiQuery}/${id}`, payload) : await API.post(apiQuery, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  const handleStudentsSort = (column, direction) => {
    const translatingColumn = listColunsStudents[column]
    setSortStudents(translatingColumn);
    direction === 'asc' ? setDescendingStudentsSort(false) : setDescendingStudentsSort(true)
  }

  const handleSubjectSort = (column, direction) => {
    const translatingColumn = listColumnSubject[column]
    setSortSubject(translatingColumn);
    direction === 'asc' ? setDescendingSubjectSort(false) : setDescendingSubjectSort(true)
  }

  const handleSort = (column, direction) => {
    const translatingColumn = listColumnCourses[column];
    setSortColumn(translatingColumn);
    direction === 'asc' ? setDescendingSort(false) : setDescendingSort(true)
  }

  const handleSearch = async () => {
    setCurrentPage(1)
    await getData();
  }

  const handleSearchStudent = async () => {
    setCurrentStudentsPage(1)
    await getStudents();
  }

  const handleSearchSubject = async () => {
    setCurrentSubjectPage(1);
    await getSubjects();
  }

  const handleChangePagination = async (e) => {
    setCurrentPage(Number(e.target.value))
  }

  const handleChangeStudentsPagination = async (e) => {
    setCurrentStudentsPage(Number(e.target.value))
  }

  const handleChangeSubjectPagination = async (e) => {
    setCurrentSubjectPage(Number(e.target.value));
  }

  const bindDiscipline = async (subjectId) => {
    try {
      const response = await API.put(`/tenants/${tenant_id}/management/academic/course_program/courses/${course_id}/subjects/${subjectId}`);
      setUnlinked(response.data.unlinked);
      setSubjects(response.data.linked);
      setSubjectLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Table
        header='Cursos' data={setTableData(data.courses)}
        onNewClick={() => openModal(false, formDataDefault)}
        onEditClick={(payload) => openModal(true, payload)}
        onDeleteClick={(payload) => openModalDelete(payload)}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        search={true}
        handleSearch={handleSearch}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleChangePagination={handleChangePagination}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        pagination={true}
        lastPage={lastPage}
        handleSort={handleSort}
        sortColumn={sortColumn}
        sortDirection={descendingSort}
      />

      <ModalCreateAndEdit fullscreen={true} size='lg' isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Curso' : 'Criar Curso'} onSubmit={() => submitModalForm()} onClose={() => setActiveTab('basic')} >
        <Nav tabs>
          <NavItem>
            <NavLink active={activeTab === 'basic'} onClick={() => setActiveTab('basic')}>Básico</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'advanced'} onClick={() => setActiveTab('advanced')} disabled={!isEditing}>Avançado</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'certificate'} onClick={() => setActiveTab('certificate')} disabled={!isEditing}>Certificado</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'certificate-of-participation'} onClick={() => setActiveTab('certificate-of-participation')} disabled={!isEditing}>Certificado de Participação</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'co-production'} onClick={() => setActiveTab('co-production')} disabled={!isEditing}>Co-produção de Participação</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'students'} onClick={() => setActiveTab('students')} disabled={!isEditing}>Estudantes</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'subjects'} onClick={() => setActiveTab('subjects')} disabled={!isEditing}>Disciplinas</NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId='basic'>
            <CourseBasic data={formData} onChange={handleModalInputChange} />
          </TabPane>
          <TabPane tabId='advanced'>
            <CourseAdvanced data={formData} coordinators={data.coordinators} categories={data.categories} sectionals={data.sectionals} onChange={handleModalInputChange} setFormData={setFormData} />
          </TabPane>
          <TabPane tabId='certificate'>
            <CourseCertificate data={formData} onChange={handleModalInputChange} setFormData={setFormData} />
          </TabPane>
          <TabPane tabId='certificate-of-participation'>
            <CourseCertificateParticipation data={formData} onChange={handleModalInputChange} setFormData={setFormData} />
          </TabPane>
          <TabPane tabId='co-production'>
            <CourseCoproduction data={formData} teachers={data.coordinators} setFormData={setFormData} />
          </TabPane>
          <TabPane tabId="students">
            <StudentsCourse
              data={students}
              searchQuery={searchStudents}
              setSearchQuery={setSearchStudents}
              handleSearch={handleSearchStudent}
              currentPage={currentStudentsPage}
              setCurrentPage={setCurrentStudentsPage}
              handleChangePagination={handleChangeStudentsPagination}
              itemsPerPage={studentsPerPage}
              setItemsPerPage={setStudentsPerPage}
              lastPage={studentsLastPage}
              handleSort={handleStudentsSort}
              sortColumn={sortStents}
              descendingSort={descendigStudentsSort}
              courseName={courseName}
            />
          </TabPane>
          <TabPane tabId='subjects'>
            <Subjects
              data={subjescts}
              searchQuery={searchSubject}
              setSearchQuery={setSearchSubject}
              handleSearch={handleSearchSubject}
              currentPage={currentSubjectPage}
              setCurrentPage={setCurrentSubjectPage}
              handleChangePagination={handleChangeSubjectPagination}
              itemsPerPage={subjectPerPage}
              setItemsPerPage={setSubjectPerPage}
              lastPage={subjectLastPage}
              handleSort={handleSubjectSort}
              sortColumn={sortSubject}
              descendingSort={descendigSubjectSort}
              courseName={courseName}
              unlinked={unlinked}
              bindDiscipline={bindDiscipline}
            />
          </TabPane>
        </TabContent>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

