import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'
import { Editor } from '../../../_Commons/Inputs/Editor'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    title: '',
    category_id: 0,
    content: ''
}


function FaqTab({ data, setData, categories, type }) {
    const { tenant_id } = useParams()

    const [modalVisible, setModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const [userDelete, setUserDelete] = useState(0)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const setTableData = (payloads) => ({
        columns: ['Título', 'Categoria'],
        rows: payloads.map(item => [item.title, '']),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/cms/faqs/questions`

    const openModal = (isEditing, data) => {
        setIsEditing(isEditing)
        setFormData(data)
        setModalVisible(true)
    }

    const openModalDelete = ({ id }) => {
        setUserDelete(id)
        setModalDeleteVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, ...payload } = formData
            const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(`${apiPath}/${type}`, payload)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    const submitModalDeleteForm = async (id) => {
        try {
            const response = await API.delete(`${apiPath}/${id}`)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Error')
        }

        setModalDeleteVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const handleEditorInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            content: e.editor.getData(),
        }))
    }

    return (
        <>
            <Table
                header='Faqs' data={setTableData(data)}
                onNewClick={() => openModal(false, formDataDefault)}
                onEditClick={(payload) => openModal(true, payload)}
                onDeleteClick={(payload) => openModalDelete(payload)}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Faq' : 'Criar Faq'} onSubmit={() => submitModalForm()} size={'lg'} >
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='title'>Título:</Label>
                                <Input type='text' name='title' id='title' value={formData.title} onChange={handleModalInputChange} required />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for='category_id'>Categoria:</Label>
                                <Input type='select' name='category_id' id='category_id' onChange={handleModalInputChange}>

                                    {categories.map((category, i) => (
                                        <option key={i} value={category.id} selected={category === formData.category_id}>{category.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>


                    <Editor label='Conteúdo:' value={formData.content} onChange={handleEditorInputChange} />


                </Form>
            </ModalCreateAndEdit>

            <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
        </>
    )
}

export default function Faqs() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        students: [],
        teachers: [],
        admin: [],
        categories: []
    })

    const [activeTab, setActiveTab] = useState('students')

    const apiPath = `/tenants/${tenant_id}/management/cms/faqs/questions`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)
                formDataDefault.category_id = response.data.categories[0].id

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    return (
        <>
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink active={activeTab === 'students'} onClick={() => setActiveTab('students')}>Alunos</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={activeTab === 'teachers'} onClick={() => setActiveTab('teachers')}>Professores</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={activeTab === 'admin'} onClick={() => setActiveTab('admin')}>Admin</NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId='students'>
                        <FaqTab data={data.students} setData={setData} categories={data.categories} type={'students'} />
                    </TabPane>
                    <TabPane tabId='teachers'>
                        <FaqTab data={data.teachers} setData={setData} categories={data.categories} type={'teachers'} />
                    </TabPane>
                    <TabPane tabId='admin'>
                        <FaqTab data={data.admin} setData={setData} categories={data.categories} type={'admin'} />
                    </TabPane>
                </TabContent>
            </div>
        </>
    )
}

