import React, { Fragment } from 'react';
import { H3, H4, P, Image } from '../../../../AbstractElements';
import { InvoiceHash, IssuedJan, PaymentDue } from '../../../../Constant';
import { Col, Media, Row } from 'reactstrap';
import logo1 from '../../../../assets/images/other-images/logo-login.png';

const InvoiceHeader = ({ numeroDaInvoice, dataDeCriacao, dataDeVencimento }) => {
  const converterDataVencimento = (data) => {
    console.log(data);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dataVencimento = new Date(data);
    return dataVencimento.toLocaleDateString('pt-BR', options);
  };
  return (
    <Fragment>
      <Row className='invo-header'>
        <Col sm='6'>
          <Media>
            <div className='media-left'>
              <Image attrImage={{ className: 'media-object img-60', src: 'https://www.tutorcasts.com/wp-content/uploads/2023/07/cropped-ICONE-32x32.png', alt: '' }} />
            </div>
            <Media body className='m-l-20 text-right'>
              <H4 attrH4={{ className: 'media-heading f-w-600' }}>TutorCasts</H4>
              <P>
                contato@tutorcasts.com.br
                <br />
                <span className='digits'>{'(21) 3199-0999'}</span>
              </P>
            </Media>
          </Media>
        </Col>
        <Col sm='6'>
          <div className='text-md-end text-xs-center'>
            <H3>
              {InvoiceHash}
              <span className='digits counter'>{numeroDaInvoice}</span>
            </H3>
            <P>
              {IssuedJan}
              <span className='digits'>{converterDataVencimento(dataDeCriacao)}</span>
              <br />
              {PaymentDue} <span className='digits'>{converterDataVencimento(dataDeVencimento)}</span>
            </P>
          </div>
        </Col>
      </Row>
      <hr />
    </Fragment>
  );
};
export default InvoiceHeader;
