import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import { Button, Label, Input, FormGroup, Form } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../AbstractElements';
import { API } from '../../../api/API/api';
import { ToastContainer, toast } from 'react-toastify';
import CommonModal from '../../UiKits/Modals/common/modal';
import GenericList from '../Dashboard/Components/Products/List';

const ServiceList2 = () => {
    const token = localStorage.getItem('token');
    const { tenant_id } = useParams();

    const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [clienteId] = localStorage.getItem('user_id');
    const [formData, setFormData] = useState({
        created_by: localStorage.getItem('user_id'), // Use diretamente localStorage
        tipo: 'Serviço',
        descricao: '',
        status: 'em aberto'
    });

    const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [serviceList, setServiceList] = useState([]);



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {

    }, [tenant_id]);

    const mockUsers = [
        {
            id: 1001,
            company_name: 'Cliente 1',
            produto: 'Produto 1',
            subtotal: 'R$350,00',
            status: 'Fechada',
            contrato: 'Assinado',
            pagamento: 'Pago',
            alerta: 'verde',
            validade: '01/01/2024',
        },
        {
            id: 1002,
            company_name: 'Cliente 2',
            produto: 'Produto 2',
            subtotal: 'R$250,00',
            status: 'Rejeitado',
            contrato: 'Assinado',
            pagamento: 'Pendente',
            alerta: 'vermelho',
            validade: '13/02/2024',
        },
        {
            id: 1003,
            company_name: 'Cliente 2',
            produto: 'Produto 2',
            subtotal: 'R$250,00',
            status: 'Rejeitado',
            contrato: 'Assinado',
            pagamento: 'Pendente',
            alerta: 'laranja',
            validade: '13/02/2024',
        },
        // Add more mock data as needed
    ];

    const toggleModal = (user) => {
        setIsEditing(true);
        setFormData({

            created_by: clienteId,
            tipo: 'Serviço',
            descricao: ''
        });
        setIsAddUserModalVisible(true);
    };

    const openDeleteConfirmationModal = (user) => {
        setUserToDelete(user);
        setIsDeleteConfirmationModalVisible(true);
    };
    const closeDeleteConfirmationModal = () => {
        setIsDeleteConfirmationModalVisible(false);
        setUserToDelete(null);
    };
    const confirmDelete = async () => {
        try {
            // Faça a requisição DELETE para excluir o usuário
            const response = await API.delete(`/products/tenants/${tenant_id}/management/admin/${userToDelete.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Lógica para tratamento de sucesso e recarregamento dos dados
            console.log('Usuário excluído com sucesso!', response);
            toast.success('Usuário excluído com sucesso!', response);

            closeDeleteConfirmationModal();
            // getData();
        } catch (error) {
            // Lógica para tratamento de erros
            console.error('Erro na exclusão do usuário:', error);
            toast.error('Erro na exclusão do usuário:', error);
        }
    };

    const columns = [
        { field: 'id', header: 'Numero' },
        { field: 'cliente', header: 'Cliente' },
        { field: 'produto', header: 'Produto' },
        { field: 'total', header: 'Total' },
        { field: 'status', header: 'Status' },
        { field: 'contrato', header: 'Contrato' },
        { field: 'pagamento', header: 'Pagamento' },
        { field: 'alerta', header: 'Alerta' },
        { field: 'validade', header: 'Validade' },


    ];

    const [isAddServiceModalVisible, setIsAddServiceModalVisible] = useState(false);

    const openAddServiceModal = () => {
        setIsEditing(false);
        setFormData((prevFormData) => ({
            ...prevFormData,
            created_by: localStorage.getItem('user_id'), // Atualize o created_by corretamente
        }));
        setIsAddServiceModalVisible(true);
    };
    const renderAlert = (alerta) => {
        let alertColor = '';
        switch (alerta) {
            case 'vermelho':
                alertColor = 'bg-danger';
                break;
            case 'laranja':
                alertColor = 'bg-warning';
                break;
            case 'verde':
                alertColor = 'bg-success';
                break;
            default:
                alertColor = 'bg-secondary'; // Default color
        }
        return <div className={`alert ${alertColor}`}>{alerta}</div>;
    };

    const handleSubmit = async () => {
        try {
            console.table(formData);
            const response = await API.post('/chamados/', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log('Serviço adicionado com sucesso!', response);
            toast.success('Serviço adicionado com sucesso!');

            setIsAddServiceModalVisible(false);
        } catch (error) {
            console.error('Erro ao adicionar serviço:', error);
            toast.error('Erro ao adicionar serviço:', error);
        }
    };
    const formatarValorReais = (valor) => {
        if (!isNaN(valor)) {
            return 'R$ ' + valor.toString().replace('.', ',');
        } else {
            return 'Valor indisponível';
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const user_id = localStorage.getItem('user_id');

                const ordem_response = await API.get(`/ordens_servico`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const company_response = await API.get("backoffice/companies", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const company_data = company_response.data;
                const ordem_data = ordem_response.data;
                console.log('Minhas OS sem filtros', ordem_data);

                // Filtrar empresas do usuário
                const filtered_companies = company_data.filter(company => String(company.created_by) === user_id);
                console.log('Minhas Empresas', filtered_companies);

                const companyIdsArray = filtered_companies.map(company => company.id);
                console.log('IDS', companyIdsArray);

                const filtered_ordem_data = ordem_data.filter(ordem => {
                    const isUserOrder = ordem.user_id == user_id
                    const isCompanyOrder = !ordem.user_id && companyIdsArray.includes(ordem.company_id);

                    // Retorna todas as ordens que são do usuário ou de empresas do usuário
                    return isUserOrder || isCompanyOrder;
                });




                console.log('Minhas Ordens de Serviço Filtradas', filtered_ordem_data);

                setServiceList(filtered_ordem_data);
            } catch (error) {
                setServiceList([]);
                toast.error('Erro ao buscar dados da API:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Ordens de Serviços' parent='Home' title='Ordens de Serviços' style={{ marginLeft: '-2em' }} />

            <Btn
                attrBtn={{
                    color: 'primary', className: 'm-b-15 col-2 d-flex justify-content-center', outline: true,
                    onClick: openAddServiceModal  // Chame a função ao clicar no botão Novo
                }}
            >
                Novo
            </Btn>
            <GenericList
                data={serviceList}
                columns={columns.map((col) => ({
                    ...col
                }))}
                searchName={'Serviços'}
            />


            {/* Adicione a lógica do modal de exclusão aqui */}
            <CommonModal
                title={isEditing ? 'Editar Serviço' : 'Adicionar Novo Serviço'}
                isOpen={isAddServiceModalVisible}
                toggler={() => setIsAddServiceModalVisible(!isAddServiceModalVisible)}
                headerClose // Adiciona o botão de fechar (X) no cabeçalho
            >
                {/* Conteúdo do modal, como formulário para descrever o serviço */}
                <Form>
                    {/* Adicione os campos necessários para descrever o serviço */}
                    <FormGroup>
                        <Label for="descricao">Descreva aqui o serviço desejado</Label>
                        <Input
                            type="textarea"
                            name="descricao"
                            id="descricao"
                            value={formData.descricao}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <div className="mt-3 d-flex gap-2">

                        {/* Adicione mais campos conforme necessário */}
                        <Button color="primary" onClick={handleSubmit}>
                            Salvar Solicitação
                        </Button>

                        {/* Botão para fechar o modal */}
                        <Button color="secondary" onClick={() => setIsAddServiceModalVisible(false)}>
                            Fechar
                        </Button>
                    </div>
                </Form>
            </CommonModal>
        </Fragment>
    );
};

export default ServiceList2;
