import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap'

export function Image({ id, name, label, src, onChange }) {
    return (
        <FormGroup>
            <Label for='image'>{label}</Label>
            {src && (
                <>
                    <br />
                    <img src={src} style={{ width: '100%' }} />
                    <br />
                </>
            )}
            <Input type='file' id={id} name={name} onChange={onChange} accept="image/gif, image/jpeg, image/png" />
        </FormGroup>
    )
}
