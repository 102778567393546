import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Table, Button, Form } from 'reactstrap'

import { ModalCreateAndEdit } from '../../_Commons/Modal/CreateAndEdit'
import { Editor } from '../../_Commons/Inputs/Editor'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
  id: 0,
  title: '',
  text: null
}

export default function Terms() {
  const { tenant_id } = useParams()
  const [data, setData] = useState([])

  const [modalVisible, setModalVisible] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const apiPath = `/tenants/${tenant_id}/management/terms`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setData(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (data) => {
    setFormData(data)
    setModalVisible(true)
  }

  const handleModalInputEmailChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      text: e.editor.getData(),
    }))
  }

  const submitModalForm = async () => {
    try {
      const { id, ...payload } = formData
      const response = await API.put(`${apiPath}/${id}`, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  return (
    <>
      <Card className='col-12 p-20'>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Termos</h5>
        </div>
        <Table borderless>
          <thead>
            <tr>
              <th>Título</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((term, index) => (
              <tr key={index}>
                <td className='align-middle'>{term.title}</td>
                <td>
                  <div className='buttonsCard'>
                    <Button color='primary' outline={true} onClick={() => openModal(term)}>
                      <i className='fa fa-edit fa-lg'></i>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <ModalCreateAndEdit size='xl' isOpen={modalVisible} setIsOpen={setModalVisible} title={`Editar ${formData.title}`} onSubmit={() => submitModalForm()} >
        <Form>
          <Editor label='Conteúdo:' value={formData.text} onChange={handleModalInputEmailChange} />
        </Form>
      </ModalCreateAndEdit>
    </>
  )
}

