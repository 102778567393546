import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, ListGroup, ListGroupItem, Label, InputGroup, Input, Button } from 'reactstrap'

import { Table } from '../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../_Commons/Modal/CreateAndEdit'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    protocol: '',
    student: { id: 0, name: '', email: '', phone: '', cpf: '' },
    attendant: { id: 0, name: '' },
    date: '',
    debit: 0,
    negotiated: 0,
    commission: 0,
    open: 0,
    status: '',
    messages: []
}

export default function Renegotiations() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        renegotiations: [],
        attendants: []
    })

    const [message, setMessage] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const setTableData = (payloads) => ({
        columns: ['Protocolo', 'Aluno', 'Débito', 'Negociado', 'Comissão', 'Aberto', 'Status'],
        // columns: ['Protocolo', 'Aluno', 'Negociador(a)', 'Data', 'Débito', 'Negociado', 'Comissão', 'Aberto', 'Status'],
        rows: payloads.map(item => [
            item.protocol,
            item.student.name,
            // item.attendant.name,
            // item.date,
            item.debit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            item.negotiated.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            item.commission.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            item.open.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            item.status
        ]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/financial/renegotiations`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (data) => {
        setFormData(data)
        setModalVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, ...payload } = formData
            const response = await API.put(`${apiPath}/${id}`, payload)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target
        value = ['attendant'].includes(name) ? JSON.parse(e.target.value) : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }


    const viewButton = ({ payload }) => (
        <>
            <Button color='primary' outline={true} onClick={() => openModal(payload)}>
                <i class="fa fa-eye" />
            </Button>
        </>
    )

    const handleInputChange = (e) => {
        const { value } = e.target
        setMessage(value)
    }

    const sendMessage = async (e) => {
        e.preventDefault()

        try {
            const response = await API.post(`${apiPath}/${formData.id}`, { text: message })

            const renegotiation = response.data.renegotiations.find(item => item.id === formData.id)
            console.log(renegotiation)
            setFormData(renegotiation)
            setData(response.data)
            setMessage('')

            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }
    }

    return (
        <>
            <Table
                header='Renegociações' data={setTableData(data.renegotiations)}
                customButtons={viewButton}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title='Renegociar' onSubmit={() => submitModalForm()} >
                <Form>
                    <Label>Dados do aluno</Label><br />

                    <span>Nome completo:</span>
                    <strong className='mx-2'>{formData.student.name}</strong><br />

                    <span>Email:</span>
                    <strong className='mx-2'>{formData.student.email}</strong><br />

                    <span>Telefone:</span>
                    <strong className='mx-2'>{formData.student.phone}</strong><br />

                    <span>CPF:</span>
                    <strong className='mx-2'>{formData.student.cpf}</strong><br /><br />

                    <FormGroup>
                        <Label for='attendant'>Alterar atendente:</Label>
                        <Input type='select' name='attendant' id='attendant' onChange={handleModalInputChange}>
                            <option value={null} selected={!formData.attendant}></option>
                            {data.attendants.map((attendant, index) => (
                                <option key={`renegotiation-attendant-${index}`} value={JSON.stringify(attendant)} selected={attendant.id === formData.attendant.id}>
                                    {attendant.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label>Histórico de contato:</Label>
                        <ListGroup flush>
                            {formData.messages.map((message, index) => (
                                <ListGroupItem className='my-3' key={`renegotiation-messages-${index}`}>
                                    <Label><strong>{message.attendant}</strong></Label>
                                    <Label className='text-muted mx-2'>{message.date}</Label><br />
                                    <span>{message.text}</span><br />
                                </ListGroupItem>
                            ))}
                        </ListGroup>

                        <InputGroup>
                            <Input name='text' value={message} onChange={handleInputChange} />
                            <Button color='primary' onClick={sendMessage}>Enviar</Button>
                        </InputGroup>
                    </FormGroup>

                </Form>
            </ModalCreateAndEdit>
        </>
    )
}

