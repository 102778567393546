import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Row } from 'reactstrap';
import { Image } from '../../../AbstractElements';
import "./profile.scss"
import { API } from '../../../api/API/api';
import { useParams } from 'react-router';

const UserProfile = () => {
  const { clientId } = useParams(); // Obtém a ID do cliente da URL

  const token = localStorage.getItem("token");
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    async function getProfile() {
      try {

        const currentURL = window.location.href;
        console.log(currentURL, clientId);

        if (clientId) {
          // Se o clientId estiver presente na URL
          const response = await API.get(`backoffice/users/${clientId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = response.data;
          console.log(data);
          setProfile(data);
        } else {
          // Se o clientId não estiver na URL, tente obter do localStorage
          const localStorageClientId = localStorage.getItem("user_id");
          if (localStorageClientId) {
            const response = await API.get(`backoffice/users/${localStorageClientId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const data = response.data;
            console.log(data);

            setProfile(data);
          } else {
            console.log("clientId não encontrado");
          }
        }
      } catch (error) {
        console.log(error);
      }

    }
    getProfile()
  }, [])

  const [photo, setPhoto] = useState('');
  // const readUrl = (event) => {
  //   if (event.target.files.length === 0) return;
  //   var mimeType = event.target.files[0].type;

  //   if (mimeType.match(/image\/*/) == null) {
  //     return;
  //   }
  //   var reader = new FileReader();
  //   reader.readAsDataURL(event.target.files[0]);
  //   reader.onload = (_event) => {
  //     setUrl(reader.result);
  //   };
  // };
  return (
    <Fragment>
      <Col sm='12'>
        <Card className='hovercard text-center'>
          <CardHeader className='headerProfile'></CardHeader>
          <div className='user-image'>
            <div className='avatar'>
              <Image
                attrImage={{
                  className: 'step1',
                  alt: '',
                  src: profile.photo ? profile.photo : require('../../../assets/images/user/7.jpg')
                }}
              />
            </div>
          </div>
          <div className='info'>
            <Row className='step3' data-intro='This is the your details'>
              <Col sm='12' lg='12' className='order-sm-0 order-xl-1'>
                <div className='user-designation'>
                  <div className='title'>
                     <h3> {profile.name} {profile.last_name}</h3>
                  </div>
                  {/* <div className='desc mt-2'>{"Administrador"}</div> */}
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default UserProfile;
