import { Form, FormGroup, Label, Input, InputGroup, InputGroupText, Row, Col, Button } from 'reactstrap'

const contractDuration = [
    {
        value: 30,
        label: '1 mês'
    },
    {
        value: 60,
        label: '2 mês'
    },
    {
        value: 90,
        label: '3 mês'
    },
    {
        value: 180,
        label: '6 mês'
    },
    {
        value: 365,
        label: '1 ano'
    },
    {
        value: 0,
        label: 'Nunca expira'
    }
]

export const CourseCoproduction = ({ data, teachers, setFormData }) => {
    const coproducerAdd = () => {
        const coproducers = data.coproducers
        coproducers.push({
            user_id: teachers[0].id,
            comission: 0,
            duration: contractDuration[0].value
        })

        setFormData((prevFormData) => ({
            ...prevFormData,
            coproducers: coproducers
        }))
    }

    const changeCoproducersData = (e, index) => {
        let { name, value } = e.target
        const coproducers = data.coproducers
        coproducers[index][name] = parseInt(value)

        setFormData((prevFormData) => ({
            ...prevFormData,
            coproducers: coproducers
        }))
    }

    return (
        <>
            <Form>
                <Label>Co-produtores</Label><br />
                <Button color='primary' outline={true} onClick={coproducerAdd}>+</Button>

                {data.coproducers.map((coproducer, index) => (
                    <FormGroup>
                        <Row key={`course-coproducers-${index}`}>
                            <Col>
                                <Input type='select' name='user_id' id='user_id' onChange={(e) => changeCoproducersData(e, index)}>
                                    <option selected={!coproducer.user_id}></option>
                                    {teachers.map((teacher, i) => (
                                        <option key={`course-coproducer-${i}`} value={teacher.id} selected={coproducer.user_id === teacher.id}>
                                            {teacher.name}
                                        </option>
                                    ))}
                                    /</Input>
                            </Col>
                            <Col>
                                <Input type='select' name='duration' id='duration' onChange={(e) => changeCoproducersData(e, index)}>
                                    {contractDuration.map((duration, index) => (
                                        <option key={`course-coproducer-contract-duration-${index}`} value={duration.value} selected={coproducer.duration === duration.value}>
                                            {duration.label}
                                        </option>
                                    ))}
                                    /</Input>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <Input type='number' min='1' name='comission' id='comission' value={coproducer.comission} onChange={(e) => changeCoproducersData(e, index)} required />
                                    <InputGroupText>%</InputGroupText>
                                </InputGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                ))}
            </Form>
        </>
    )
}