import { useState } from 'react'
import { Form, FormGroup, Label, Input, InputGroup, InputGroupText, Button, Accordion, AccordionBody, AccordionHeader, AccordionItem, Row, Col } from 'reactstrap'

import { Dropdown } from '../../../../../_Commons/Dropdown/Index.jsx'
import { Editor } from '../../../../../_Commons/Inputs/Editor'

const discountPromotionOptios = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
const billDiscountOptios = [0, 5, 10, 20, 30, 40, 50]

export const CourseAdvanced = ({ data, coordinators, categories, sectionals, onChange, setFormData }) => {
    const [tagOpen, setTagOpen] = useState('');
    const tagToggle = (id) => tagOpen === id ? setTagOpen() : setTagOpen(id);

    const setPlaceholder = (array) => {
        const items = array.map(item => item.name);
        return items.join('\n')
    }

    const handleDropdown = (e) => {
        const { name } = e.target
        const value = JSON.parse(e.target.value)

        setFormData(prevFormData => {
            const payload = prevFormData[name] || []
            const index = payload.findIndex(item => item.id === value.id)
            index !== -1 ? payload.splice(index, 1) : payload.push(value)

            return {
                ...prevFormData,
                [name]: payload,
            };
        });
    }

    const extensionAdd = () => {
        const extensions = data.extensions
        extensions.push({
            period: 0,
            amount: 0
        })

        setFormData((prevFormData) => ({
            ...prevFormData,
            extensions: extensions
        }))
    }

    const changeExtensionData = (e, index) => {
        let { name, value } = e.target
        const extensions = data.extensions
        extensions[index][name] = value

        setFormData((prevFormData) => ({
            ...prevFormData,
            extensions: extensions
        }))
    }


    const handleEditorInputChange = (e, name) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: e.editor.getData(),
        }))
    }

    return (
        <>
            <Form style={{ marginTop: '25px' }}>
                <Editor label='Objetivo:' value={data.goal} onChange={(e) => handleEditorInputChange(e, 'goal')} />

                <Editor label='Cronograma:' value={data.timeline} onChange={(e) => handleEditorInputChange(e, 'timeline')} />
                
                <FormGroup>
                    <Label for='teaching_staff'>Corpo docente:</Label>
                    <Input type='textarea' name='teaching_staff' id='teaching_staff' value={data.teaching_staff} onChange={onChange} required />
                </FormGroup>

                <FormGroup>
                    <Label for='target'>Público alvo:</Label>
                    <Input type='textarea' name='target' id='target' value={data.target} onChange={onChange} required />
                </FormGroup>

                <FormGroup>
                    <Label for='demo_video_url'>Vídeo demonstrativo:</Label>
                    <Input type='text' name='demo_video_url' id='demo_video_url' value={data.demo_video_url} onChange={onChange} required />
                </FormGroup>

                <FormGroup>
                    <Label for='tcc'>Requer apresentação de TCC:</Label>
                    <FormGroup switch>
                        <Input type='switch' name='tcc' id='tcc' onChange={onChange} checked={data.tcc} />
                        <Label for='tcc'>{data.tcc ? 'Sim' : 'Não'}</Label>
                    </FormGroup>
                </FormGroup>

                <FormGroup>
                    <Label for='coordinator'>Coordenador:</Label>
                    <Input type='select' name='coordinator' id='coordinator' onChange={onChange}>
                        {coordinators.map((coordinator, index) => (
                            <option key={`course-coordinator-${index}`} value={JSON.stringify(coordinator)} selected={data.coordinator ? (coordinator.id === data.coordinator.id) : false}>
                                {coordinator.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for='rules'>Regras gerais:</Label>
                    <Input type='text' name='rules' id='rules' value={data.rules} onChange={onChange} required />
                </FormGroup>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='course_duration'>Duração do curso (dias):</Label>
                            <Input type='text' name='course_duration' id='course_duration' value={data.course_duration} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='enrollment_duration'>Duração matrículas (dias):</Label>
                            <Input type='number' min='0' name='enrollment_duration' id='enrollment_duration' value={data.enrollment_duration} onChange={onChange} required />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label>Limite de alunos:</Label>
                    <InputGroup>
                        <InputGroupText>
                            <input type="checkbox" name='enrollment_unlimited' id='enrollment_unlimited' checked={data.enrollment_unlimited} onChange={onChange} />
                        </InputGroupText>
                        <Input type='number' min='0' name='limit_students' id='limit_students' value={data.limit_students} onChange={onChange} disabled={data.enrollment_unlimited} required />
                    </InputGroup>
                </FormGroup>

                <Dropdown id='categories' name='categories' label='Categorias' items={categories} selectedItems={setPlaceholder(data.categories)} onClick={handleDropdown} />

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='discount_promotion'>Desconto promoção:</Label>
                            <Input type='select' name='discount_promotion' id='discount_promotion' onChange={onChange}>
                                {discountPromotionOptios.map((number, index) => (
                                    <option key={`course-discount-promotion-${index}`} value={number} selected={number === data.discount_promotion}>
                                        {number}%
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='bill_discount'>Desconto boleto:</Label>
                            <Input type='select' name='bill_discount' id='bill_discount' onChange={onChange}>
                                {billDiscountOptios.map((number, index) => (
                                    <option key={`course-bill-discount-${index}`} value={number} selected={number === data.bill_discount}>
                                        {number}%
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label>Bloqueia parcelamento boleto:</Label>
                            <FormGroup switch>
                                <Input type='switch' checked={false} disabled />
                                <Label>{false ? 'Sim' : 'Não'}</Label>
                            </FormGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label for='accepts_questions'>Aceita perguntas:</Label>
                    <FormGroup switch>
                        <Input type='switch' name='accepts_questions' id='accepts_questions' onChange={onChange} checked={data.accepts_questions} />
                        <Label for='accepts_questions'>{data.accepts_questions ? 'Sim' : 'Não'}</Label>
                    </FormGroup>
                </FormGroup>

                <FormGroup>
                    <Label for='sectional'>Campus:</Label>
                    <Input type='select' name='sectional' id='sectional' onChange={onChange}>
                        <option value={null} selected={!data.sectional}></option>
                        {sectionals.map((sectional, index) => (
                            <option key={`course-sectional-${index}`} value={JSON.stringify(sectional)} selected={data.sectional ? (sectional.id === data.sectional.id) : false}>
                                {sectional.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for='redirect'>Redirecionar para:</Label>
                    <Input type='text' name='redirect' id='redirect' value={data.redirect} onChange={onChange} required />
                </FormGroup>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='lti'>Disponibilizar curso via LTI:</Label>
                            <FormGroup switch>
                                <Input type='switch' name='lti' id='lti' onChange={onChange} checked={data.lti} />
                                <Label for='lti'>{data.lti ? 'Sim' : 'Não'}</Label>
                            </FormGroup>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='enrollment_extension'>Permitir Extensão de matricula:</Label>
                            <FormGroup switch>
                                <Input type='switch' name='enrollment_extension' id='enrollment_extension' onChange={onChange} checked={data.enrollment_extension} />
                                <Label for='enrollment_extension'>{data.enrollment_extension ? 'Sim' : 'Não'}</Label>
                            </FormGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label for='free_days'>Quantidade de dias gratuito:</Label>
                    <Input type='select' name='free_days' id='free_days' onChange={onChange} disabled={!data.enrollment_extension}>
                        <option value={0} selected={data.free_days === 0}>
                            sem dias gratuito
                        </option>

                        {[30, 60, 90].map((number, index) => (
                            <option key={`course-free-days-${index}`} value={number} selected={number === data.free_days}>
                                {number} dias
                            </option>
                        ))}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for='extensions'>Opções de extensões:</Label>
                    <Button color='primary' outline={true} onClick={() => extensionAdd()}>+</Button>

                    {data.extensions.map((extension, index) =>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <Input type='number' min='0' name='period' value={extension.period} onChange={(e) => changeExtensionData(e, index)} required />
                                    <InputGroupText>dias</InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroupText>R$</InputGroupText>
                                    <Input type='number' min='0' name='amount' value={extension.amount} onChange={(e) => changeExtensionData(e, index)} required />
                                </InputGroup>
                            </Col>
                        </Row>
                    )}
                </FormGroup>
                
                <Label>Etiquetas</Label>
                <Accordion open={tagOpen} toggle={tagToggle}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">Etiqueta identificando que o curso é novo</AccordionHeader>

                        <AccordionBody accordionId="1">
                            <FormGroup switch>
                                <Input type='switch' name='tag_new_course' id='tag_new_course' onChange={onChange} checked={data.tag_new_course} />
                                <Label for='tag_new_course'>{data.tag_new_course ? 'Ativo' : 'Inativo'}</Label>
                            </FormGroup>

                            <FormGroup>
                                <Label for="tag_new_course_bg">Cor de fundo da etiqueta</Label>
                                <Input type="color" name="tag_new_course_bg" id="tag_new_course_bg" onChange={onChange} value={data.tag_new_course_bg} disabled={!data.tag_new_course} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="tag_new_course_color">Cor do texto</Label>
                                <Input type="color" name="tag_new_course_color" id="tag_new_course_color" onChange={onChange} value={data.tag_new_course_color} disabled={!data.tag_new_course} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="tag_new_course_days">Exibir por quantos dias</Label>
                                <Input type="number" min='1' name="tag_new_course_days" id="tag_new_course_days" onChange={onChange} value={data.tag_new_course_days} disabled={!data.tag_new_course} />
                            </FormGroup>
                        </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionHeader targetId="2">Etiqueta de texto pensonalizado</AccordionHeader>

                        <AccordionBody accordionId="2">
                            <FormGroup switch>
                                <Input type='switch' name='tag_text' id='tag_text' onChange={onChange} checked={data.tag_text} />
                                <Label for='tag_text'>{data.tag_text ? 'Ativo' : 'Inativo'}</Label>
                            </FormGroup>
                
                            <FormGroup>
                                <Label for="tag_text_bg">Cor de fundo da etiqueta</Label>
                                <Input type="color" name="tag_text_bg" id="tag_text_bg" onChange={onChange} value={data.tag_text_bg} disabled={!data.tag_text} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="tag_text_color">Cor do texto</Label>
                                <Input type="color" name="tag_text_color" id="tag_text_color" onChange={onChange} value={data.tag_text_color} disabled={!data.tag_text} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="tag_text_message">Texto da etiqueta</Label>
                                <Input type="text" name="tag_text_message" id="tag_text_message" onChange={onChange} value={data.tag_text_message} disabled={!data.tag_text} />
                            </FormGroup>
                        </AccordionBody>
                    </AccordionItem>

                    
                    <AccordionItem>
                        <AccordionHeader targetId="3">Etiqueta de tempo do curso</AccordionHeader>
                        <AccordionBody accordionId="3">
                            <FormGroup switch>
                                <Input type='switch' name='tag_time_course' id='tag_time_course' onChange={onChange} checked={data.tag_time_course} />
                                <Label for='tag_time_course'>{data.tag_time_course ? 'Ativo' : 'Inativo'}</Label>
                            </FormGroup>
                
                            <FormGroup>
                                <Label for="tag_time_course_bg">Cor de fundo da etiqueta</Label>
                                <Input type="color" name="tag_time_course_bg" id="tag_time_course_bg" onChange={onChange} value={data.tag_time_course_bg} disabled={!data.tag_time_course} />
                            </FormGroup>
                            
                            <FormGroup>
                                <Label for="tag_time_course_color">Cor do texto</Label>
                                <Input type="color" name="tag_time_course_color" id="tag_time_course_color" onChange={onChange} value={data.tag_time_course_color} disabled={!data.tag_time_course} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="tag_time_course_hours">Carga horária</Label>
                                <Input placeholder='0 para automática' type="text" name="tag_time_course_hours" id="tag_time_course_hours" onChange={onChange} value={data.tag_time_course_hours} disabled={!data.tag_time_course} />
                            </FormGroup>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </Form>
        </>
    )
}