export const setToken = (value) => {
  try {
    localStorage.setItem("token", value)
  } catch (error) {
    console.log(error)
  }
}

export const logout = () => {
  try {
      localStorage.removeItem("token")
      localStorage.removeItem("email")
      localStorage.removeItem("username")
      window.location.href = '/admin/login'
  } catch (error) {
      console.log(error);
  }
}

export const getToken = () => {
  return localStorage.getItem("token")}