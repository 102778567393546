import React, { Fragment, useState } from 'react';
import { Btn } from '../../../../../../AbstractElements';
import CommonModal from '../../../../../UiKits/Modals/common/modal';
import { API } from '../../../../../../api/API/api';
import { ReactComponent as WarningIcon } from "../../../../../../assets/tutorcastsIcon/warning.svg"

const DeactivatingBtn = ({ id, timestamp }) => {
  const token = localStorage.getItem("token")
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const reactivate = async (tenant_id) => {
    try {
      console.log(`ID: ${tenant_id}, Token: ${token}`);
      const status = 'activated'
      const response = await API.patch(`/tenants/${tenant_id}`, null, {
        params: {
          status: 'activated',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log('Reloading the page...');
        window.location.reload(true);
      }

      console.log('Request:', response.config);
      console.log('Response:', response.data);
    } catch (error) {
      if (error.response) {
        console.log('Error Response Data:', error.response.data);
        console.log('Error Response Status:', error.response.status);
        console.log('Error Response Headers:', error.response.headers);
      } else if (error.request) {
        console.log('Error Request:', error.request);
      } else {
        console.log('Error Message:', error.message);
      }
    }
  }

  const disabledTenantAt = (timestamp) => {
    if (!timestamp) return null
    const now = new Date();
    const disabled_at = new Date(timestamp * 1000);

    const difference = disabled_at - now;

    if (difference <= 0) return null

    const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
    const oneHourInMilliseconds = 1000 * 60 * 60;

    const days = Math.floor(difference / oneDayInMilliseconds);
    const hours = Math.floor((difference % oneDayInMilliseconds) / oneHourInMilliseconds);

    if (days >= 1) {
      return `${days} dia${days > 1 ? 's' : ''}`;
    } else if (hours >= 1) {
      return `${hours} hora${hours > 1 ? 's' : ''}`;
    } else {
      return 'menos de 1 hora';
    }
  }

  return (
    <Fragment>
      <div title="desfazer deletar" className='buttonsCard'>
        <span>{disabledTenantAt(timestamp)} restantes</span>
        <Btn attrBtn={{ className: 'buttns', color: 'success', onClick: toggle, outline: true }}>
          <i className="fa fa-undo fa-lg"></i>
        </Btn>
      </div>

      <CommonModal isOpen={modal} title="" toggler={toggle}>
        <div className='warning'>
          <div className='warning__text'>
            <h4>Seu site será reativado!</h4>
            <p>Ao clicar em <strong>Concordo</strong> seu site será reativado. Deseja prosseguir com a reativação?</p>
          </div>
          <div className='warning__buttonCont'>
            <Btn attrBtn={{ color: 'success', outline: true, className: "warning__buttonCont-btn", onClick: () => reactivate(id) }}>Concordo</Btn>
          </div>
        </div>
      </CommonModal>
    </Fragment>
  );
};

export default DeactivatingBtn;
