import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import { Button, FormGroup, Form, Label, Input } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../AbstractElements';
import { API } from '../../../api/API/api';
import { ToastContainer, toast } from 'react-toastify';
import CommonModal from '../../UiKits/Modals/common/modal';
import GenericList from '../Dashboard/Components/Products/List';

const ChamadosList2 = () => {
    const token = localStorage.getItem('token');
    const { tenant_id } = useParams();
    const [chamados, setChamados] = useState([]);

    const [user, setUser] = useState({});
    const [users, setUsers] = useState([]);
    // State para controlar a visibilidade do modal de adição/editação
    const [isAddChamadoModalVisible, setIsAddChamadoModalVisible] = useState(false);

    // State para controlar se está em modo de edição ou não
    const [isEditing, setIsEditing] = useState(false);

    const [formData, setFormData] = useState({
        created_by: 0, // Use diretamente localStorage
        tipo: '',
        descricao: '',
        status: 'em aberto'
    });
    const onDelete = async (chamado) => {
        const endpoint = `/chamados/${chamado.id}`;
        try {
            const response = await API.delete(endpoint, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (response.status === 200) {
                // O chamado foi excluído com sucesso
                toast.success('Chamado excluído com sucesso!');
                // Chame a função para buscar os chamados após a exclusão bem-sucedida
                fetchChamados();
            } else {
                // O chamado não foi excluído com sucesso
                toast.error('Erro ao excluir o chamado.');
            }
        } catch (error) {
            // Lógica para tratamento de erros
            console.error('Erro ao excluir chamado:', error);
            toast.error('Erro ao excluir chamado:', error);
        }
    };



    // Outros states conforme necessário...

    // Função para lidar com a mudança nos inputs do formulário
    // Função para lidar com a mudança nos inputs do formulário
    const handleInputChange = (name, value) => {
        // Atualize o state do formulário com os novos valores
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const fetchChamados = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            const response = await API.get('/chamados/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // console.log('Response data:', response.data);
            // console.log('User ID:', userId);

            // Ensure that the 'data' variable is declared before using it
            const data = response.data;

            // Filter the data based on the condition
            const chamadosFiltrados = data.filter(chamado => chamado.created_by === Number(userId));

            // console.log('All chamados data:', data);
            // console.log('Filtered chamados:', chamadosFiltrados);

            setChamados(chamadosFiltrados);
            // console.log('Chamados recuperados com sucesso!', response);
        } catch (error) {
            console.error('Erro ao recuperar chamados:', error);
            // Add error handling logic here (e.g., display an error message to the user)
        }
    };


    const getUsers = async () => {
        try {


            const response = await API.get(`backoffice/users`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = response.data;
            setUsers(data);
        } catch (error) {
            console.error("Falha  ao buscar  lista de usuarios:", error);
        }
    };
    // UseEffect ou outras lógicas conforme necessário...
    useEffect(() => {


        // Chame a função para buscar os chamados
        getUsers();
        fetchChamados();
    }, [tenant_id, token]); // Dependências para acionar o efeito quando mudam


    // Função para enviar o chamado para a API
    // Função para enviar o chamado para a API
    const handleSubmit = async () => {
        try {
            console.log(formData);
            const userId = localStorage.getItem('user_id');
            // Certifique-se de incluir explicitamente o campo 'status' em formData
            const dataToSend = {
                ...formData,
                created_by: userId,
                status: 'em aberto',
            };

            // Faça a requisição POST para adicionar/editar o chamado
            const response = await API.post('/chamados/', dataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            // Lógica para tratamento de sucesso
            console.log('Chamado adicionado/editado com sucesso!', response);
            toast.success('Chamado adicionado/editado com sucesso!');
            fetchChamados();
            // Feche o modal após o sucesso
            setIsAddChamadoModalVisible(false);

            // Atualize os dados se necessário
            // getData();
        } catch (error) {
            if (error.response) {
                console.error('Erro ao adicionar/editar chamado:', error);
                console.error('Dados da resposta de erro:', error.response.data);
                // Lógica adicional de tratamento de erro se necessário
            } else {
                console.error('Erro ao adicionar/editar chamado:', error);
                // Lógica adicional de tratamento de erro se necessário
            }
            toast.error('Erro ao adicionar/editar chamado:', error);
        }
    };

    const columns = [
        { field: 'id', header: 'Numero' },
        { field: 'cliente', header: 'Cliente' },
        { field: 'descricao', header: 'Descricao' },
        { field: 'status', header: 'Status' },



    ];
    // Lógica de renderização e outros componentes...

    return (
        <Fragment>
            {/* Componentes de navegação e outros elementos visuais conforme necessário */}
            <Breadcrumbs mainTitle='Chamados' parent='Home' title='Chamados' />

            {/* Botão para abrir o modal de adição/editação */}
            <Btn
                attrBtn={{
                    color: 'primary',
                    className: 'm-b-15 col-2 d-flex justify-content-center',
                    outline: true,
                    onClick: () => {
                        setIsEditing(false); // Certifique-se de que não está em modo de edição
                        setFormData({}); // Limpe os dados do formulário ao abrir o modal
                        setIsAddChamadoModalVisible(true);
                    },
                }}
            >
                Novo Chamado
            </Btn>

            {/* Lista de chamados (pode usar GenericList ou outro componente adequado) */}
            <GenericList
                data={chamados} // Use os chamados recuperados do estado
                columns={columns}
                onDelete={onDelete}

                searchName={'Chamados'}
            />

            {/* Modal de adição/editação de chamado */}
            <CommonModal
                title={isEditing ? 'Editar Chamado' : 'Adicionar Novo Chamado'}
                isOpen={isAddChamadoModalVisible}
                toggler={() => setIsAddChamadoModalVisible(!isAddChamadoModalVisible)}
                headerClose
            >
                {/* Formulário para adicionar/editar chamado */}
                <Form>
                    {/* Adicione os campos necessários para descrever o chamado */}

                    <FormGroup>
                        <Label className="form-label">Tipo</Label>
                        <Input
                            className="form-control"
                            type="select"
                            value={formData.tipo}
                            onChange={(e) => handleInputChange('tipo', e.target.value)}
                        >
                            <option value="">Selecione</option>
                            <option value="problemas">Problemas</option>
                            <option value="plataforma">Plataforma</option>
                            <option value="serviços">Serviços</option>
                            <option value="sugestões">Sugestões</option>
                        </Input>
                    </FormGroup>


                    <FormGroup>
                        <Label className="form-label">Descrição</Label>
                        <Input
                            className="form-control"
                            type="textarea"
                            value={formData.descricao}
                            onChange={(e) => handleInputChange('descricao', e.target.value)}
                        />
                    </FormGroup>

                    {/* Adicione mais campos conforme necessário */}

                    {/* Botões para salvar e fechar o modal */}
                    <div className="mt-3 d-flex gap-2">
                        <Button color="primary" onClick={handleSubmit}>
                            Salvar Chamado
                        </Button>
                        <Button color="secondary" onClick={() => setIsAddChamadoModalVisible(false)}>
                            Fechar
                        </Button>
                    </div>
                </Form>
            </CommonModal>
        </Fragment>
    );
};

export default ChamadosList2;
