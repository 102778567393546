import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'
import { Editor } from '../../../_Commons/Inputs/Editor'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
  id: 0,
  type_id: 0,
  title: '',
  description: '',
  amount: null,
  status: true,
  automation_enable: false,
  automation_html: '',
  free: true
}

export default function Requiments() {
  const { tenant_id } = useParams()
  const [data, setData] = useState({
    requirements: [],
    types: []
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const apiPath = `/tenants/${tenant_id}/management/requirements`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setData(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, data) => {
    setIsEditing(isEditing)
    data.free = !data.amount
    setFormData(data)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target

    const keysToCheck = ['status', 'free', 'automation_enable']
    value = keysToCheck.includes(name) ? !formData[name] : value
    value = !isNaN(value) ? +value : value

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleModalInputEmailChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      automation_html: e.editor.getData(),
    }))
  }

  const submitModalForm = async () => {
    try {
      const { id, ...payload } = formData
      payload.amount = payload.free ? null : payload.amount
      console.log(payload.amount)
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')

      setData(response.data)
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  return (
    <>
      <Card className='col-12 p-20'>
        <Button color='primary' className='col-2 d-flex justify-content-center' outline={true} onClick={() => openModal(false, formDataDefault)}>
          Novo
        </Button>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Requerimentos</h5>
        </div>
        <Table borderless>
          <thead>
            <tr>
              <th>Título</th>
              <th>Tipo</th>
              <th>Valor</th>
              <th>Atualizado em</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.requirements.map((requirement, index) => (
              <tr key={index}>
                <td className='align-middle'>{requirement.title}</td>
                <td className='align-middle'>{requirement.type}</td>
                <td className='align-middle'>{requirement.amount ? requirement.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Grátis'}</td>
                <td className='align-middle'>{requirement.updated_at}</td>
                <td className='align-middle'>{requirement.status ? 'Ativo' : 'Inativo'}</td>
                <td>
                  <div className='buttonsCard'>
                    <Button color='primary' outline={true} onClick={() => openModal(true, requirement)}>
                      <i className='fa fa-edit fa-lg'></i>
                    </Button>
                    <Button color='secondary' outline={true} onClick={() => openModalDelete(requirement)}>
                      <i className='fa fa-trash-o fa-lg'></i>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <ModalCreateAndEdit size='xl' isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar tipo de requerimento' : 'Criar tipo de requerimento'} onSubmit={() => submitModalForm()} >
        <Form>
          <FormGroup>
            <Label for='type_id'>Tipo de requerimento:</Label>
            <Input type='select' name='type_id' id='type_id' onChange={handleModalInputChange}>
              {data.types.map((type, index) => (
                <option key={index} value={type.id} selected={type.id === formData.type_id}>
                  {type.title}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for='title'>Título:</Label>
            <Input type='text' name='title' id='title' value={formData.title} onChange={handleModalInputChange} required />
          </FormGroup>
          <FormGroup>
            <Label for='description'>Descrição:</Label>
            <Input type='textarea' name='description' id='description' value={formData.description} onChange={handleModalInputChange} />
          </FormGroup>

          <FormGroup switch>
            <Label for='free'>Gratuito</Label>
            <Input type='switch' name='free' id='free' onChange={handleModalInputChange} checked={formData.free} />
          </FormGroup>
          <FormGroup>
            <Label for='amount'>Valor:</Label>
            <Input type='number' name='amount' id='amount' value={formData.amount} onChange={handleModalInputChange} disabled={formData.free} />
          </FormGroup>

          <FormGroup>
            <Label>Status</Label>
            <FormGroup switch>
              <Label for='status'>{formData.status ? 'Ativo' : 'Inativo'}</Label>
              <Input type='switch' name='status' id='status' onChange={handleModalInputChange} checked={formData.status} />
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Label>Habilitar automação</Label>
            <FormGroup switch>
              <Input type='switch' name='automation_enable' id='automation_enable' onChange={handleModalInputChange} checked={formData.automation_enable} />
            </FormGroup>
          </FormGroup>

          <Editor label='Documento a ser gerado:' value={formData.automation_html} onChange={handleModalInputEmailChange} />
        </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

