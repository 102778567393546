import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    name: '',
    public_key: '',
    private_key: '',
    url: '',
    description: ''
}

export default function LtApplications() {
  const { tenant_id } = useParams()
  const [data, setData] = useState([])

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const apiPath = `/tenants/${tenant_id}/management/lti/providers`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setData(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, data) => {
    setIsEditing(isEditing)
    console.log(data)
    setFormData(data)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')

      setData(response.data)
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }
  
  return (
    <>
      <Card className='col-12 p-20'>
        <Button color='primary' className='col-2 d-flex justify-content-center' outline={true} onClick={() => openModal(false, {...formDataDefault, private_key: data.md5})}>
          Novo
        </Button>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Aplicativos LTI</h5>
        </div>
        <Table borderless>
            <thead>
                <tr>
                    <th>Nome</th>
                    <th>Chave pública</th>
                    <th>Chave privada</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {data.map((provider, index) => (
                <tr key={index}>
                    <td className='align-middle'>{provider.name}</td>
                    <td className='align-middle'>{provider.public_key}</td>
                    <td className='align-middle'>{provider.private_key}</td>
                    <td>
                        <div className='buttonsCard'>
                            <Button color='primary' outline={true} onClick={() => openModal(true, provider)}>
                                <i className='fa fa-edit fa-lg'></i>
                            </Button>
                            <Button color='secondary' outline={true} onClick={() => openModalDelete(provider)}>
                                <i className='fa fa-trash-o fa-lg'></i>
                            </Button>
                        </div>
                    </td>
                </tr>
                ))}
            </tbody>
        </Table>
      </Card>

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Aplicativo LTI' : 'Criar Aplicativo LTI'} onSubmit={() => submitModalForm()} >
        <Form>
          <FormGroup>
            <Label for='name'>Nome:</Label>
            <Input type='text' name='name' id='name' value={formData.name} onChange={handleModalInputChange} required />
          </FormGroup>

          <FormGroup>
            <Label for='public_key'>Consumer Key:</Label>
            <Input type='text' name='public_key' id='public_key' value={formData.public_key} onChange={handleModalInputChange} required />
          </FormGroup>
          <FormGroup>
            <Label for='private_key'>Secret:</Label>
            <Input type='text' name='private_key' id='private_key' value={formData.private_key} onChange={handleModalInputChange} required />
          </FormGroup>
          <FormGroup>
            <Label for='url'>URL de inicialização:</Label>
            <Input type='text' name='url' id='url' value={formData.url} onChange={handleModalInputChange} required />
          </FormGroup>
          <FormGroup>
            <Label for='description'>Descrição:</Label>
            <Input type='textarea' name='description' id='description' value={formData.description} onChange={handleModalInputChange} required />
          </FormGroup>
        </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

