import React from 'react';
import CommonTooltip from '../../Components/UiKits/Tooltip/Direction/CommonTooltip';
import { Btn, ToolTip } from '../../../src/AbstractElements';
import { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';


export default function Sidebar2() {
    const [isMobile, setIsMobile] = useState(false);
    const [tooltipDashboard, setTooltipDashboard] = useState(false);
    const [tooltipProdutos, setTooltipProdutos] = useState(false);
    const [tooltipContatos, setTooltipContatos] = useState(false);
    const [tooltipSuporte, setTooltipSuporte] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 899); // Altere o valor 768 conforme necessário
        };

        // Adiciona um listener para o evento de redimensionamento
        window.addEventListener('resize', handleResize);

        // Inicializa o estado de mobile com o tamanho atual da tela
        handleResize();

        // Remove o listener quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleDashboard = () => setTooltipDashboard(!tooltipDashboard);
    const toggleProdutos = () => setTooltipProdutos(!tooltipProdutos);
    const toggleContatos = () => setTooltipContatos(!tooltipContatos);
    const toggleSuporte = () => setTooltipSuporte(!tooltipSuporte);

    const handleDivClick = () => {
        // toggle();
    };
    const isActive = (targetRoute) => {
        return location.pathname === targetRoute;
    };

    return (
        <div style={{ display: isMobile ? 'none' : 'block', backgroundColor: '#FFF', width: '4.813rem', height: '100%', marginTop: '4.5em', zIndex: '1030' }} className="sidebar-wrapper shadow">
               <div className="d-flex flex-column align-items-center justify-content-center w-100 ">
                    <ul>
                        <li className={`link-unstyled sidebar-link sidebar-title link-nav ${isActive("/admin/dashboard") ? 'active' : ''}`}>
                            <Link className={`link-unstyled sidebar-link sidebar-title link-nav ${isActive("/admin/dashboard") ? 'active' : ''}`} to="/admin/dashboard">
                                <div
                                    className="botao-hint"
                                    onClick={handleDivClick}
                                    id="Tooltip-Dashboard"
                                    style={{ cursor: 'pointer', marginTop: '1.5rem', marginBottom: '0.5rem', width: '4vw' , backgroundColor:'red' }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ marginLeft:'7px',width: '2rem', height: '2rem' }}>
                                        <path d="M16.5 6.2025V3.1725C16.5 1.98 16.02 1.5 14.8275 1.5H11.7975C10.605 1.5 10.125 1.98 10.125 3.1725V6.2025C10.125 7.395 10.605 7.875 11.7975 7.875H14.8275C16.02 7.875 16.5 7.395 16.5 6.2025Z" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.875 6.39V2.985C7.875 1.9275 7.395 1.5 6.2025 1.5H3.1725C1.98 1.5 1.5 1.9275 1.5 2.985V6.3825C1.5 7.4475 1.98 7.8675 3.1725 7.8675H6.2025C7.395 7.875 7.875 7.4475 7.875 6.39Z" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.875 14.8275V11.7975C7.875 10.605 7.395 10.125 6.2025 10.125H3.1725C1.98 10.125 1.5 10.605 1.5 11.7975V14.8275C1.5 16.02 1.98 16.5 3.1725 16.5H6.2025C7.395 16.5 7.875 16.02 7.875 14.8275Z" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <g opacity="0.4">
                                            <path d="M10.875 13.125H15.375" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" />
                                            <path d="M13.125 15.375V10.875" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" />
                                        </g>
                                    </svg>
                                </div>
                            </Link>
                            <ToolTip

                                attrToolTip={{
                                    innerClassName: 'tooltip-hint',
                                    placement: 'right',
                                    isOpen: tooltipDashboard,
                                    target: `Tooltip-Dashboard`,
                                    toggle: toggleDashboard
                                }}
                            >
                                Dashboard
                            </ToolTip>
                        </li>
                        <li className={`link-unstyled sidebar-link sidebar-title link-nav ${isActive("/admin/products") ? 'active' : ''}`}>
                            <Link className={`link-unstyled sidebar-link sidebar-title link-nav ${isActive("/admin/products") ? 'active' : ''}`} to="/admin/products">
                                <div
                                    className="botao-hint"
                                    onClick={handleDivClick}
                                    id="Tooltip-1"
                                    style={{ cursor: 'pointer', marginTop: '1.5rem', marginBottom: '0.5rem', width: '4vw' }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{marginLeft:'7px',width: '2rem', height: '2rem' }}>
                                        <path d="M10.2994 2.31167L14.9702 4.38584C16.316 4.97959 16.316 5.96125 14.9702 6.555L10.2994 8.62917C9.76896 8.86667 8.89812 8.86667 8.36771 8.62917L3.69688 6.555C2.35104 5.96125 2.35104 4.97959 3.69688 4.38584L8.36771 2.31167C8.89812 2.07417 9.76896 2.07417 10.2994 2.31167Z" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.4" d="M2.375 8.70837C2.375 9.37337 2.87375 10.1413 3.48333 10.4105L8.85875 12.8013C9.27042 12.9834 9.7375 12.9834 10.1413 12.8013L15.5167 10.4105C16.1263 10.1413 16.625 9.37337 16.625 8.70837" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.4" d="M2.375 12.6666C2.375 13.4029 2.81042 14.0679 3.48333 14.3687L8.85875 16.7595C9.27042 16.9416 9.7375 16.9416 10.1413 16.7595L15.5167 14.3687C16.1896 14.0679 16.625 13.4029 16.625 12.6666" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </Link>
                            <ToolTip
                                attrToolTip={{
                                    innerClassName: 'tooltip-hint',
                                    placement: 'right',
                                    isOpen: tooltipProdutos,
                                    target: `Tooltip-1`,
                                    toggle: toggleProdutos
                                }}
                            >
                                Produtos
                            </ToolTip>
                        </li>
                        <div><hr /></div>
                        <li className={`link-unstyled sidebar-link sidebar-title link-nav ${isActive("/admin/contacts") ? 'active' : ''}`}>
                            <Link className={`link-unstyled sidebar-link sidebar-title link-nav ${isActive("/admin/contacts") ? 'active' : ''}`} to="/admin/dashboard">
                                <div
                                    id="Tooltip-Contatos"
                                    className="botao-hint"
                                    onClick={handleDivClick}
                                    style={{ cursor: 'pointer', marginTop: '1.5rem', marginBottom: '0.5rem', width: '4vw' }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{marginLeft:'7px', width: '2rem', height: '2rem' }}>
                                        <path d="M7.25177 8.60541C7.17261 8.5975 7.07761 8.5975 6.99052 8.60541C5.10636 8.54208 3.61011 6.99833 3.61011 5.09833C3.61011 3.15875 5.17761 1.58333 7.12511 1.58333C9.06469 1.58333 10.6401 3.15875 10.6401 5.09833C10.6322 6.99833 9.13594 8.54208 7.25177 8.60541Z" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.4" d="M12.9913 3.16667C14.5272 3.16667 15.7622 4.40958 15.7622 5.9375C15.7622 7.43375 14.5747 8.65292 13.0943 8.70833C13.0309 8.70042 12.9597 8.70042 12.8884 8.70833" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M3.29332 11.5267C1.37749 12.8092 1.37749 14.8992 3.29332 16.1737C5.4704 17.6304 9.04082 17.6304 11.2179 16.1737C13.1337 14.8913 13.1337 12.8013 11.2179 11.5267C9.04874 10.0779 5.47832 10.0779 3.29332 11.5267Z" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.4" d="M14.5193 15.8333C15.0893 15.7146 15.6276 15.485 16.071 15.1446C17.306 14.2183 17.306 12.6904 16.071 11.7642C15.6355 11.4317 15.1051 11.21 14.543 11.0833" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </Link>
                            <ToolTip
                                attrToolTip={{
                                    innerClassName: 'tooltip-hint',
                                    placement: 'right',
                                    isOpen: tooltipContatos,
                                    target: `Tooltip-Contatos`,
                                    toggle: toggleContatos
                                }}
                            >
                                Contatos
                            </ToolTip>
                        </li>

                        <li className={`link-unstyled sidebar-link sidebar-title link-nav ${isActive("/admin/contacts") ? 'active' : ''}`}>
                            <Link className={`link-unstyled sidebar-link sidebar-title link-nav ${isActive("/admin/contacts") ? 'active' : ''}`} to="/admin/chamados">
                                <div
                                    id="Tooltip-Suporte"
                                    className="botao-hint"
                                    onClick={handleDivClick}
                                    style={{ cursor: 'pointer', marginBottom: '0.5rem', width: '4vw' }}
                                >
                                    <hr />
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{marginLeft:'7px', width: '2rem', height: '2rem' }}>
                                        <path d="M9.47624 17.4167C13.8485 17.4167 17.3929 13.8723 17.3929 9.50004C17.3929 5.12779 13.8485 1.58337 9.47624 1.58337C5.10398 1.58337 1.55957 5.12779 1.55957 9.50004C1.55957 13.8723 5.10398 17.4167 9.47624 17.4167Z" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.5 13.0625C11.4675 13.0625 13.0625 11.4675 13.0625 9.5C13.0625 7.53249 11.4675 5.9375 9.5 5.9375C7.53249 5.9375 5.9375 7.53249 5.9375 9.5C5.9375 11.4675 7.53249 13.0625 9.5 13.0625Z" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.4" d="M3.87915 3.90289L6.68165 6.69748" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.4" d="M3.87915 15.097L6.68165 12.3024" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.4" d="M15.0813 15.097L12.2788 12.3024" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path opacity="0.4" d="M15.0813 3.90289L12.2788 6.69748" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </Link>
                            <ToolTip
                                attrToolTip={{
                                    innerClassName: 'tooltip-hint-suporte',
                                    placement: 'right',
                                    isOpen: tooltipSuporte,
                                    target: `Tooltip-Suporte`,
                                    toggle: toggleSuporte
                                }}
                            
                            >
                                Suporte/Chamados
                            </ToolTip>

                        </li>
                    </ul>
                </div>
        </div>
    );
}
