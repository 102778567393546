import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'

const states = [
    {
        label: 'Acre',
        value: 'AC'
    },
    {
        label: 'Alagoas',
        value: 'AL'
    },
    {
        label: 'Amapá',
        value: 'AP'
    },
    {
        label: 'Amazonas',
        value: 'AM'
    },
    {
        label: 'Bahia',
        value: 'BA'
    },
    {
        label: 'Ceará',
        value: 'CE'
    },
    {
        label: 'Distrito Federal',
        value: 'DF'
    },
    {
        label: 'Espírito Santo',
        value: 'ES'
    },
    {
        label: 'Goiás',
        value: 'GO'
    },
    {
        label: 'Maranhão',
        value: 'MA'
    },
    {
        label: 'Mato Grosso',
        value: 'MT'
    },
    {
        label: 'Mato Grosso do Sul',
        value: 'MS'
    },
    {
        label: 'Minas Gerais',
        value: 'MS'
    },
    {
        label: 'Pará',
        value: 'PA'
    },
    {
        label: 'Paraíba',
        value: 'PB'
    },
    {
        label: 'Paraná',
        value: 'PR'
    },
    {
        label: 'Pernambuco',
        value: 'PE'
    },
    {
        label: 'Piauí',
        value: 'PI'
    },
    {
        label: 'Rio de Janeiro',
        value: 'RJ'
    },
    {
        label: 'Rio Grande do Norte',
        value: 'RN'
    },
    {
        label: 'Rondônia',
        value: 'RO'
    },
    {
        label: 'Roraima',
        value: 'RR'
    },
    {
        label: 'Santa Catarina',
        value: 'SC'
    },
    {
        label: 'São Paulo',
        value: 'SP'
    },
    {
        label: 'Sergipe',
        value: 'SE'
    },
    {
        label: 'Tocantins',
        value: 'TO'
    }
]

export const StudentProfile = ({ data, professions, regions, sectionals, onChange }) => {
    return (
        <>
            <Form>
                <Row style={{ marginTop: '20px' }}>
                    <Col>
                        <FormGroup>
                            <Label for='name'>Nome:</Label>
                            <Input type='text' name='name' id='name' value={data.name} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='email'>Email:</Label>
                            <Input type='text' name='email' id='email' value={data.email} onChange={onChange} required />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='cpf'>CPF:</Label>
                            <Input type='text' name='cpf' id='cpf' value={data.cpf} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='rg'>RG:</Label>
                            <Input type='text' name='rg' id='rg' value={data.rg} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='date_birth'>Data de Nascimento:</Label>
                            <Input type='date' name='date_birth' id='date_birth' value={data.date_birth} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='sex'>Sexo:</Label>
                            <Input type='select' name='sex' id='sex' onChange={onChange}>
                                <option selected={!data.sex} hidden></option>
                                <option value='Masculino' selected={data.sex === 'Masculino'}>Masculino</option>
                                <option value='Feminino' selected={data.sex === 'Feminino'}>Feminino</option>
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='profession'>Profissão:</Label>
                            <Input type='select' name='profession' id='profession' onChange={onChange}>
                                <option selected={!data.profession} hidden></option>
                                {professions.map((profession, index) => (
                                    <option key={`student-profession-${index}`} value={JSON.stringify(profession)} selected={data.profession ? profession.id === data.profession.id : false}>
                                        {profession.name}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Label for='password'>Senha:</Label>
                    <Input type='password' name='password' id='password' value={data.password} onChange={onChange} required />
                </FormGroup>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='cep'>CEP:</Label>
                            <Input type='text' name='cep' id='cep' value={data.cep} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='city'>Cidade:</Label>
                            <Input type='text' name='city' id='city' value={data.city} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='state'>Estado:</Label>
                            <Input type='select' name='state' id='state' onChange={onChange}>
                                <option selected={!data.state} hidden></option>
                                {states.map((state, index) => (
                                    <option key={`student-state-${index}`} value={state.value} selected={data.state ? state.value === data.state : false}>
                                        {state.label}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='neighborhood'>Bairro:</Label>
                            <Input type='text' name='neighborhood' id='neighborhood' value={data.neighborhood} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='street_address'>Logradouro:</Label>
                            <Input type='text' name='street_address' id='street_address' value={data.street_address} onChange={onChange} required />
                        </FormGroup>
                    </Col>
                </Row>
                
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for='name'>Número:</Label>
                            <Input type='text' name='number' id='number' value={data.number} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col md={8}>
                        <FormGroup>
                            <Label for='complement'>Complemento:</Label>
                            <Input type='text' name='complement' id='complement' value={data.complement} onChange={onChange} required />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='phone_number'>Telefone:</Label>
                            <Input type='text' name='phone_number' id='phone_number' value={data.phone_number} onChange={onChange} required />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='cell_number'>Celular:</Label>
                            <Input type='text' name='cell_number' id='cell_number' value={data.cell_number} onChange={onChange} required />
                        </FormGroup>
                    </Col>
                </Row>


                <FormGroup>
                    <Label for='region'>Região:</Label>
                    <Input type='select' name='region' id='region' onChange={onChange}>
                        <option selected={!data.region} hidden></option>
                        {regions.map((region, index) => (
                            <option key={`student-region-${index}`} value={JSON.stringify(region)} selected={data.region ? region.id === data.region.id : false}>
                                {region.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for='sectional'>Campus:</Label>
                    <Input type='select' name='sectional' id='sectional' onChange={onChange}>
                        <option selected={!data.sectional} hidden></option>
                        {sectionals.map((sectional, index) => (
                            <option key={`student-sectional-${index}`} value={JSON.stringify(sectional)} selected={data.sectional ? sectional.id === data.sectional.id : false}>
                                {sectional.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            </Form >
        </>
    )
}