import { Table } from '../../../../_Commons/Table/Index'

export const StudentRequests = ({ data }) => {
    const setTableData = (payloads) => ({
        columns: ['Data', 'Situação', 'Valor'],
        rows: payloads.map(item => [item.date, item.situation, item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })]),
        payloads: payloads
    })

    return (
        <>
            <Table header='Pedidos' data={setTableData(data.requests)} />
        </>
    )
}