import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { Badges, Breadcrumbs, Btn } from '../../../../../AbstractElements'
import { API } from '../../../../../api/API/api';

// import "../../../../Pages/"
import "primereact/resources/themes/lara-light-blue/theme.css"
import "primereact/resources/primereact.min.css"

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from 'react-router'
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';

export default function CompanyList() {

  const history = useNavigate()

  const token = localStorage.getItem("token")
  const [tenants, setTenants] = useState([])
  const [globalFilter, setGlobalFilter] = useState('');
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { tenant_id } = useParams();
  const [formData, setFormData] = useState({

    name: '',
    email: '',   // Inicializado como uma string vazia
    last_name: '',   // Inicializado como uma string vazia
    password: '', // Inicializado como uma string vazia
  });
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const getCompanies = async () => {
    try {
      // Ensure the user ID is set correctly in localStorage
      const user_id = localStorage.getItem('user_id');
      console.log('User ID from localStorage:', user_id);
  
      const response = await API.get("backoffice/companies", {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
  
      const data = response.data;
      // console.log('Empresas:', data);
  
      // Filter the data based on the condition
      const filteredData = data.filter(company => {
        // console.log('created_by:', company.created_by);
        return String(company.created_by) === user_id;
      });
  
      // console.log('Empresas Filtradas:', filteredData);
      setTenants(filteredData);
    } catch (error) {
      console.error(error);
    }
  };
  
  
  
  useEffect(() => {

    getCompanies()
  }, [])

  const openAddPlanoModal = () => {
    history('/admin/products/company');
  };
  

  const closeAddPlanoModal = () => {
    setIsAddUserModalVisible(false);
    setIsEditing(false);
    setFormData({
      name: '',
      email: '',
      last_name: '',
      password: '',
    });
  };
  const toggleModal = (user) => {
    setIsEditing(true);
    setFormData({
      name: user.name,
      email: user.email,
      last_name: user.last_name,
      password: user.password
    });
    setIsAddUserModalVisible(true);
  };
  const [profileOptions, setProfileOptions] = useState([]);
  const handleDeleteClick = (user) => {
    setClientToDelete(user);
    setDisplayConfirmationModal(true);
  };
  const handleDeleteConfirm = async () => {
    if (clientToDelete) {
      await deleteUser(clientToDelete.id);
      setClientToDelete(null);
      setDisplayConfirmationModal(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'profile') {
      const selectedProfile = profileOptions.find((option) => option.name === value);

      if (selectedProfile) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
          profile_id: selectedProfile.id,
        }));
      } else {
        console.error('Perfil não encontrado:', value);
        toast.error('Perfil não encontrado');

        // Você pode adicionar lógica adicional aqui, como definir um valor padrão
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  
  const deleteUser = async (company_id) => {
    try {
      // Make a DELETE request to the API endpoint with the Authorization header
      const response = await API.delete(`/backoffice/companies/${company_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Empresa Excluida com Sucesso!");
      getCompanies();
      // Check if the request was successful
      if (response.status === 200) {
        // Handle success, e.g., show a success message
        console.log('User deleted successfully');
      } else {
        // Handle other status codes or error responses
        console.error('Failed to delete user. Status:', response.status);
      }
    } catch (error) {
      // Handle network errors or exceptions
      console.error('An error occurred while deleting user:', error.message);
    }
  };


  const renderActions = (rowData) => {
    return (
      <div className="row">

        <button type="button" className="buttns btn btn-outline-secondary custom-danger" onClick={() => handleDeleteClick(rowData)}>
          <i className="fa fa-trash-o fa-lg"></i>
        </button>


      </div>
    );
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Empresas' parent='Products' title='Empresas' />
      <Container fluid="true">
        <Card className='m-b-15 shadow'>

          <Input
            type="text"
            placeholder="Buscar Empresa..."
            defaultValue={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </Card>
        <Btn
          attrBtn={{
            color: 'primary', className: 'm-b-15 col-2 d-flex justify-content-center', outline: true,
            onClick: openAddPlanoModal
          }}
        >
          Nova
        </Btn>
      
        <Row>
          <Col sm='12'>
            <Card>
              <DataTable value={tenants} paginator rows={15} sortMode='multiple' selectionMode="single" onSelectionChange={(e) => history(`/admin/products/company/${e.value.id}`)} dataKey="id" metaKeySelection={false}
                globalFilter={globalFilter}
              >
                <Column field="cargo_criador" sortable style={{ width: '15%' }} header="Cargo do Criador"></Column>
                <Column field="nome_fantasia" sortable style={{ width: '30%' }} header="Nome Fantasia"></Column>
                <Column field="email" sortable style={{ width: '30%' }} header="Razao Social"></Column>
                <Column field="cnpj" sortable style={{ width: '15%', textAlign: "center" }} header="Cnpj"></Column>
                <Column header='Ações' body={renderActions} style={{ width: '10%' }}></Column>

              </DataTable>
            </Card>
          </Col>
        </Row>
        <Dialog header={`Confirmar Exclusão de ${clientToDelete ? clientToDelete.nome_fantasia : ''}`} visible={displayConfirmationModal} style={{ width: '30vw' }} onHide={() => setDisplayConfirmationModal(false)}>
          <div className='p-fluid'>
            <div className='p-field'>
              <label htmlFor='confirmationMessage'>Tem certeza de que deseja excluir permanentemente os dados da Empresa {clientToDelete ? `"${clientToDelete.nome_fantasia}"` : ''}? Essa ação não poderá ser desfeita.</label>
            </div>
            <div className='d-flex mt-2 gap-2'>
              <Btn attrBtn={{ onClick: () => setDisplayConfirmationModal(false) }}>Não</Btn>
              <Btn attrBtn={{ onClick: handleDeleteConfirm }}>Sim</Btn>
            </div>
          </div>
        </Dialog>

      </Container>
    </Fragment>
  )
}
