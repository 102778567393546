import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Table, Button, Form, FormGroup, Label, List } from 'reactstrap'

import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    permissions: []
}

const tree = [
    {
        label: 'Login',
        icon: '',
        perms: ['login'],
        children: []
    },
    {
        label: 'Acadêmico',
        icon: '',
        perms: ['academic', 'academic_course_schedule', 'academic_course_schedule_videos'],
        children: [
            {
                label: 'Programa de cursos',
                icon: '',
                perms: ['academic', 'academic_course_schedule', 'academic_course_schedule_videos'],
                children: [
                    {
                        label: 'Vídeos',
                        icon: '',
                        perms: ['academic', 'academic_course_schedule', 'academic_course_schedule_videos'],
                        children: []
                    }
                ]
            }
        ]
    },
    {
        label: 'Configurações',
        icon: '',
        perms: ['settings', 'settings_users', 'settings_users_list', 'settings_users_add', 'settings_users_edit', 'settings_users_delete', 'settings_access_control'],
        children: [
            {
                label: 'Usuários',
                icon: '',
                perms: ['settings_users', 'settings_users_list', 'settings_users_add', 'settings_users_edit', 'settings_users_delete'],
                children: [
                    {
                        label: 'Lista de usuários',
                        icon: '',
                        perms: ['settings_users_list'],
                        children: []
                    },
                    {
                        label: 'Adicionar usuário',
                        icon: '',
                        perms: ['settings_users_add'],
                        children: []
                    },
                    {
                        label: 'Editar usuário',
                        icon: '',
                        perms: ['settings_users_edit'],
                        children: []
                    },
                    {
                        label: 'Remover usuário',
                        icon: '',
                        perms: ['settings_users_delete'],
                        children: []
                    }
                ]
            },
            {
                label: 'Controle de Acesso',
                icon: '',
                perms: ['settings_access_control'],
                children: []
            }
        ]
    }
]

export default function Profiles() {
  const { tenant_id } = useParams()
  const [data, setData] = useState([])

  const [modalVisible, setModalVisible] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const apiPath = `/tenants/${tenant_id}/management/profiles`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setData(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (data) => {
    setFormData(data)
    setModalVisible(true)
  }

  const hasPermission = (perms) => perms.every(item => formData.permissions.includes(item))

  const handleModalInputChange = (e) => {
    const perms = e.target.getAttribute('data-perms').split(',')

    let value = hasPermission(perms) ? formData.permissions.filter(item => !perms.includes(item)) : formData.permissions.concat(perms)
    value = Array.from(new Set(value))
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      permissions: value,
    }))
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = await API.put(`${apiPath}/${id}`, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }


  const TreeCheckbox = ({tree, marginLeft = 10}) => (
        <List type='unstyled'>
            {tree.map((branch) => (
                <li key={branch.perms}  style={{ marginLeft: `${marginLeft}px` }}>
                    <input data-perms={branch.perms} type='checkbox' id={branch.perms} onChange={handleModalInputChange} checked={hasPermission(branch.perms)} />
                    {' '}
                    <Label>{branch.label}</Label>

                    {!!branch.children && <TreeCheckbox tree={branch.children} marginLeft={marginLeft + 10} />}
                </li>
            ))}
        </List>
    )

  return (
    <>
      <Card className='col-12 p-20'>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Controle de Acesso</h5>
        </div>
        <Table borderless>
            <thead>
                <tr>
                    <th>Perfil</th>
                    <th>Atualizado em</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {data.map((profile, index) => (
                <tr key={index}>
                    <td className='align-middle'>{profile.name}</td>
                    <td className='align-middle'>{profile.updated_at}</td>
                    <td>
                        <div className='buttonsCard'>
                            <Button color='primary' outline={true} onClick={() => openModal(profile)}>
                                <i className='fa fa-edit fa-lg'></i>
                            </Button>
                        </div>
                    </td>
                </tr>
                ))}
            </tbody>
        </Table>
      </Card>

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={'Editar Permissões'} onSubmit={() => submitModalForm()} >
        <Form>
            <FormGroup>
                <TreeCheckbox tree={tree} />
            </FormGroup>
        </Form>
      </ModalCreateAndEdit>
    </>
  )
}

