import { Table } from '../../../../_Commons/Table/Index'

export const StudentFrequencies = ({ data }) => {
    const setTableData = (payloads) => ({
        columns: ['Curso', 'Frequência'],
        rows: payloads.map(item => [
            item.course,
            `${item.frequency}%`,
        ]),
        payloads: payloads
    })

    return (
        <>
            <Table header='Frequências' data={setTableData(data.frequencies)} />
        </>
    )
}