import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Card, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { H6 } from '../../../../../../AbstractElements'

import { API } from '../../../../../../api/API/api';
import '../../../_Commons/Styles/Styles.scss';
import { toast } from "react-toastify";


const formDataDefault = {
    company_name: '',
    email_from: '',
    email_user: '',
    email_password: '',
    email_host: '',
    email_port: ''
}

export default function EmailSettings() {
  const { tenant_id } = useParams();

  const [formData, setFormData] = useState(formDataDefault);

  const apiPath = `/tenants/${tenant_id}/management/emails/settings`

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(apiPath);
        setFormData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, [tenant_id]);


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
    }))
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.put(apiPath, formData);
        toast.success('Sucesso!')

    } catch (error) {
      console.log(error);
      toast.error('Error')
    }
  };

  return (
    <>
        <Card className='col-12' style={{ marginBottom: "8.5em" }}>
          <div className="card-header">
            <h5 className="card-title p-b-0 p-t-0">Configurações de E-mail</h5>
          </div>
          <Form>
            <Row className="m-4">
                <Col md="12" className="mb-3">
                    <H6>Nome da empresa:</H6>
                    <FormGroup>
                    <Input type="text" name="company_name" value={formData.company_name} onChange={handleInputChange}/>
                    </FormGroup>
                </Col>
                <Col md="12" className="mb-3">
                    <H6>E-mail (para):</H6>
                    <FormGroup>
                    <Input type="text" name="email_from" value={formData.email_from} onChange={handleInputChange}/>
                    </FormGroup>
                </Col>
                <Col md="12" className="mb-3">
                    <H6>Usuário SMTP:</H6>
                    <FormGroup>
                    <Input type="text" name="email_user" value={formData.email_user} onChange={handleInputChange}/>
                    </FormGroup>
                </Col>
                <Col md="12" className="mb-3">
                    <H6>Senha:</H6>
                    <FormGroup>
                    <Input type="text" name="email_password" value={formData.email_password} onChange={handleInputChange}/>
                    </FormGroup>
                </Col>


                <Col md="9" className="mb-3">
                    <H6>Host STMP:</H6>
                    <FormGroup>
                    <Input type="text" name="email_host" value={formData.email_host} onChange={handleInputChange}/>
                    </FormGroup>
                </Col>


                <Col md="3" className="mb-3">
                    <H6>Porta:</H6>
                    <FormGroup>
                        <Input type="text" name="email_port" value={formData.email_port} onChange={handleInputChange}/>
                    </FormGroup>
                </Col>
            </Row>
            
            <Row className="text-right rowCustom">
              <Col className="text-right">
                <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                  Salvar alterações
                </button>
              </Col>
            </Row>
          </Form>
        </Card>
    </>
  );
}