import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input, Badge, CloseButton, Row, Col } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'
import { Dropdown } from '../../../_Commons/Dropdown/Index'
import { Image } from '../../../_Commons/Inputs/Image'
import { Editor } from '../../../_Commons/Inputs/Editor'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    title: '',
    content: '',
    date: '',
    categories: [],
    author: {
        id: 0,
        name: ''
    },
    tags: [],
    courses: [],
    articles: []
}

export default function Articles() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        posts: [],
        categories: [],
        users: [],
        courses: [],
        articles: []
    })

    const [tagInputValue, setTagInputValue] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const [userDelete, setUserDelete] = useState(0)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const setTableData = (payloads) => ({
        columns: ['Título', 'Categorias'],
        rows: payloads.map(item => [item.title, item.categories.map(cat => cat.name).join(', ')]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/cms/content_manager/articles`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                formDataDefault.author.id = response.data.users[0].id
                setData(response.data)
            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (isEditing, data) => {
        setIsEditing(isEditing)
        setFormData(data)
        setModalVisible(true)
    }

    const openModalDelete = ({ id }) => {
        setUserDelete(id)
        setModalDeleteVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, cover, ...payload } = formData

            if (cover) {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }

                const form = new FormData()
                form.append('image', cover)
                const responseImg = await API.post(`${apiPath}/upload`, form, config)

                payload.cover_url = responseImg.data.image_url
            }

            const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setFormData(formDataDefault)
        setModalVisible(false)
    }

    const submitModalDeleteForm = async (id) => {
        try {
            const response = await API.delete(`${apiPath}/${id}`)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Error')
        }

        setModalDeleteVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target
        value = ['cover'].includes(name) ? e.target.files[0] : value
        value = ['author'].includes(name) ? JSON.parse(e.target.value) : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const handleModalDropdown = (e) => {
        const { name } = e.target
        const value = JSON.parse(e.target.value)

        setFormData(prevFormData => {
            const payload = prevFormData[name] || []
            const index = payload.findIndex(item => item.id === value.id)
            index !== -1 ? payload.splice(index, 1) : payload.push(value)

            return {
                ...prevFormData,
                [name]: payload,
            };
        });
    }

    const handleEditorInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            content: e.editor.getData(),
        }))
    }

    const setPlaceholder = (array) => {
        const items = array.map(item => item.name);
        return items.join('\n')
    }

    const handlerTagsInput = (e) => {
        if (e.key !== 'Enter' || !tagInputValue.trim()) return

        const payload = { ...formData };
        payload.tags.push(tagInputValue)
        setTagInputValue('')
    }


    const removeTag = (index) => {
        const payload = { ...formData };
        payload.tags.splice(index, 1);
        setFormData(payload);
    }


    return (
        <>
            <Table
                header='Artigos' data={setTableData(data.posts)}
                onNewClick={() => openModal(false, formDataDefault)}
                onEditClick={(payload) => openModal(true, payload)}
                onDeleteClick={(payload) => openModalDelete(payload)}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Artigo' : 'Criar Artigo'} onSubmit={() => submitModalForm()} size={'lg'} >
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='title'>Título:</Label>
                                <Input type='text' name='title' id='title' value={formData.title} onChange={handleModalInputChange} required />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Dropdown id='categories' name='categories' label='Categorias' items={data.categories} selectedItems={setPlaceholder(formData.categories)} onClick={handleModalDropdown} />

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='author'>Autor:</Label>
                                <Input type='select' name='author' id='author' onChange={handleModalInputChange}>
                                    {data.users.map((user, index) => (
                                        <option key={`article-author-${index}`} value={JSON.stringify(user)} selected={user.id === formData.author.id}>
                                            {user.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for='tags'>Tags:</Label>
                                <div>
                                    {formData.tags.map((tag, index) => (
                                        <Badge color="primary" key={`article-tag-${index}`}>
                                            <CloseButton onClick={() => removeTag(index)} variant="white" />
                                            <Label>{tag}</Label>
                                        </Badge>
                                    ))}
                                </div>

                                <Input type='text' name='tags' id='tags' value={tagInputValue} onChange={e => setTagInputValue(e.target.value)} onKeyDown={handlerTagsInput} required />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Dropdown id='courses' name='courses' label='Cursos relacionados' items={data.courses} selectedItems={setPlaceholder(formData.courses)} onClick={handleModalDropdown} />

                    <Editor label='Conteúdo:' value={formData.content} onChange={handleEditorInputChange} />

                    <Dropdown id='articles' name='articles' label='Artigos relacionados' items={data.articles.filter(item => item.id !== formData.id)} selectedItems={setPlaceholder(formData.articles)} onClick={handleModalDropdown} />

                    <Image label='Imagem destacada 1600x500:' id='cover' name='cover' src={formData.cover_url} onChange={handleModalInputChange} />
                </Form>
            </ModalCreateAndEdit>

            <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
        </>
    )
}

