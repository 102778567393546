import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import { Table } from '../../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../../_Commons/Modal/Delete'

import { API } from '../../../../../../../api/API/api'
import { Information } from './ModalTabs/Information'
import { Modules } from './ModalTabs/Modules'
import { Evidences } from './ModalTabs/Evidences'
import { ComplementaryMaterial } from './ModalTabs/ComplementaryMaterial'

const formDataDefault = {
  id: 0,
  code: '',
  name: '',
  teacher: '',
  teacher_id: 0,
  description: '',
  workload: 0,
  quantity_encounters: 0,
  chatroom_status: false,
  chatroom: ''
}

const listColumnModule = ['id', 'name', 'teacher'];
const listColumnEvidences = ['id', 'title', 'teacher']

const genCode = () => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const random = (length, func) => Array.from({ length: length }, func).join('')
  const randomLetters = random(3 , () => letters.charAt(Math.floor(Math.random() * letters.length)))
  const randomNumbers = random(4 , () => Math.floor(Math.random() * 10))

  return `${randomLetters}${randomNumbers}`
}

export default function Subjects() {
  const { tenant_id } = useParams()
  const [data, setData] = useState({
    subjects: [],
    teachers: []
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const [subjectId, setSubjectId] = useState(0);
  const [activeTab, setActiveTab] = useState('information')

  const [moduleLinked, setModuleLinked] = useState([]);
  const [moduleUnlinkedd, setModuleUnlinked] = useState([]);

  const [evidencesLinked, setEvidencesLinked] = useState([]);
  const [evidencesUnlinked, setEvidencesUnlinked] = useState([]);

  const [materials, setMaterials] = useState([]);

  const [currentModulePage, setCurrentModulePage] = useState(1);
  const [modulePerPage, setModulePerPage] = useState(10);
  const [searchModule, setSearchModule] = useState('');
  const [sortModule, setSortModule] = useState('id');
  const [descendigModuleSort, setDescendingModuleSort] = useState(false);
  const [moduleLastPage, setModuleLastPage] = useState(1);

  const [currentEvidencesPage, setCurrentEvidencesPage] = useState(1);
  const [evidencesPerPage, setEvidencesPerPage] = useState(10);
  const [searchEvidences, setSearchEvidences] = useState('');
  const [sortEvidences, setSortEvidences] = useState('id');
  const [descendigEvidencesSort, setDescendingEvidencesSort] = useState(false);
  const [evidencesLastPage, setEvidencesLastPage] = useState(1);

  const [materialsLastPage, setMaterialsLastPage] = useState(1);

  const firstRender = useRef(true);
  const firstRenderEvidences = useRef(true);

  const setTableData = (payloads) => ({
        columns: ['Código', 'Disciplina', 'Professor(a)', 'Carga horária'],
        rows: payloads.map(item => [item.code, item.name, item.teacher, item.workload]),
        payloads: payloads
    })

  const apiPath = `/tenants/${tenant_id}/management/academic/course_program/subjects`

  const getData = async () => {
    try {
      const response = await API.get(apiPath)
      formDataDefault.teacher_id = response.data.teachers[0].id
      setData(response.data)

    } catch (error) { console.error(error) }
  }

  const getModuleList = async (id) => {

    if (id) setSubjectId(id);

    try {
      const response = await API.get(`/tenants/${tenant_id}/management/academic/course_program/subjects/${id ? id : subjectId}/modules?page=${currentModulePage}&perpage=${modulePerPage}&query=${searchModule}&sorting=${sortModule}&reversed=${descendigModuleSort}`);
      setModuleLinked(response.data.linked);
      setModuleUnlinked(response.data.unlinked);
      setModuleLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const getEvidences = async (id) => {

    if (id) setSubjectId(id);

    try {
      const response = await API.get(`/tenants/${tenant_id}/management/academic/course_program/subjects/${id ? id : subjectId}/exams?page=${currentEvidencesPage}&perpage=${evidencesPerPage}&query=${searchEvidences}&sorting=${sortEvidences}&reversed=${descendigEvidencesSort}`);
      setEvidencesLinked(response.data.linked);
      setEvidencesUnlinked(response.data.unlinked);
      setEvidencesLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const getMaterials = async (id) => {
    if (id) setSubjectId(id);
    try {
      const response = await API.get(`/tenants/${tenant_id}/management/academic/course_program/subjects/${id ? id : subjectId}/materials`);
      setMaterials(response.data.data);
      setMaterialsLastPage(response.data.last_page);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    getModuleList();
  }, [currentModulePage, searchModule, modulePerPage, descendigModuleSort, sortModule]);

  useEffect(() => {

    if (firstRenderEvidences.current) {
      firstRenderEvidences.current = false;
      return;
    }
    getEvidences();
  }, [currentEvidencesPage, searchEvidences, evidencesPerPage, descendigEvidencesSort, sortEvidences]);

  useEffect(() => {
    getData()
  }, [])

  const openModal = (isEditing, data) => {
    setIsEditing(isEditing)
    data.code = !data.code ? genCode() : data.code
    setFormData(data)
    setModalVisible(true)
    getModuleList(data.id)
    getEvidences(data.id);
    getMaterials(data.id);
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    
    const isChatroomStatus = name === 'chatroom_status'
    value = isChatroomStatus ? !formData[name] : value
    
    setFormData((prevFormData) => {
      const chatroomValue = isChatroomStatus && !value ? '' : prevFormData.chatroom
      console.log(chatroomValue)

      return {
      ...prevFormData,
      chatroom: chatroomValue,
      [name]: value,
    }})
  }

  const handleModuleSorte = (column, direction) => {
    const translatingColumn = listColumnModule[column]
    setSortModule(translatingColumn);
    direction === 'asc' ? setDescendingModuleSort(false) : setDescendingModuleSort(true)
  }

  const handleEvidencesSorte = (column, direction) => {
    const translatingColumn = listColumnEvidences[column]
    setSortEvidences(translatingColumn);
    direction === 'asc' ? setDescendingEvidencesSort(false) : setDescendingEvidencesSort(true)
  }

  const bindModule = async (moduleId) => {
    try {
      const response = await API.put(`/tenants/${tenant_id}/management/academic/course_program/subjects/${subjectId}/modules/${moduleId}`);
      setModuleLinked(response.data.linked);
      setModuleUnlinked(response.data.unlinked);
      setModuleLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const bindEvidences = async (evidenceId) => {
    try {
      const response = await API.put(`/tenants/${tenant_id}/management/academic/course_program/subjects/${subjectId}/exams/${evidenceId}`);
      setEvidencesLinked(response.data.linked);
      setEvidencesUnlinked(response.data.unlinked);
      setEvidencesLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearchModule = async () => {
    setCurrentModulePage(1);
    await getModuleList();
  }

  const handleSearchEvidences = async () => {
    setCurrentEvidencesPage(1);
    await getEvidences();
  }

  const handleChangeModulePagination = (e) => {
    setCurrentModulePage(Number(e.target.value));
  }

  const handleChangeEvidencesPagination = (e) => {
    setCurrentEvidencesPage(Number(e.target.value));
  }

  const handleCreateMaterial = async (formData) => {
    try {
      const response = await API.post(`/tenants/${tenant_id}/management/academic/course_program/subjects/${subjectId}/materials`, formData);
      setMaterials(response.data.data);
      setMaterialsLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const handleDeleteMaterial = async (materialId) => {
    try {
      const response = await API.delete(`/tenants/${tenant_id}/management/academic/course_program/subjects/${subjectId}/materials/${materialId}`);
      setMaterials(response.data.data);
      setMaterialsLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Table 
          header='Disciplinas' data={setTableData(data.subjects)} 
          onNewClick={() => openModal(false, formDataDefault)} 
          onEditClick={(payload) => openModal(true, payload)}
          onDeleteClick={(payload) => openModalDelete(payload)}
      />

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Disciplina' : 'Criar Disciplina'} onSubmit={() => submitModalForm()} size={'xl'} >
        <Nav tabs>

          <NavItem>
            <NavLink active={activeTab === 'information'} onClick={() => setActiveTab('information')}>Informações</NavLink>
          </NavItem>

          <NavItem>
            <NavLink active={activeTab === 'modules'} onClick={() => setActiveTab('modules')}>Módulos</NavLink>
          </NavItem>

          <NavItem>
            <NavLink active={activeTab === 'evidences'} onClick={() => setActiveTab('evidences')}>Provas</NavLink>
          </NavItem>

          <NavItem>
            <NavLink active={activeTab === 'material'} onClick={() => setActiveTab('material')}>Materiais Complementares</NavLink>
          </NavItem>

        </Nav>

        <TabContent activeTab={activeTab}>

          <TabPane tabId="information">
            <Information data={data} formData={formData} handleModalInputChange={handleModalInputChange} />
          </TabPane>

          <TabPane tabId="modules">
            <Modules
              data={moduleLinked}
              searchQuery={searchModule}
              setSearchQuery={setSearchModule}
              handleSearch={handleSearchModule}
              currentPage={currentModulePage}
              setCurrentPage={setCurrentModulePage}
              handleChangePagination={handleChangeModulePagination}
              itemsPerPage={modulePerPage}
              setItemsPerPage={setModulePerPage}
              lastPage={moduleLastPage}
              handleSort={handleModuleSorte}
              sortColumn={sortModule}
              descendingSort={descendigModuleSort}
              subjectName={formData.name}
              unlinked={moduleUnlinkedd}
              bindModule={bindModule} />
          </TabPane>

          <TabPane tabId="evidences">
            <Evidences
              data={evidencesLinked}
              searchQuery={searchEvidences}
              setSearchQuery={setSearchEvidences}
              handleSearch={handleSearchEvidences}
              currentPage={currentEvidencesPage}
              setCurrentPage={setCurrentEvidencesPage}
              handleChangePagination={handleChangeEvidencesPagination}
              itemsPerPage={evidencesPerPage}
              setItemsPerPage={setEvidencesPerPage}
              lastPage={evidencesLastPage}
              handleSort={handleEvidencesSorte}
              sortColumn={sortEvidences}
              descendingSort={descendigEvidencesSort}
              subjectName={formData.name}
              unlinked={evidencesUnlinked}
              bindEvidences={bindEvidences}
            />
          </TabPane>

          <TabPane tabId="material">
            <ComplementaryMaterial
              handleCreateMaterial={handleCreateMaterial}
              data={materials}
              disciplinaName={formData.name}
              handleDeleteMaterial={handleDeleteMaterial}
            />
          </TabPane>

        </TabContent>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

