import React, { Fragment, useContext, useState } from 'react';
import { H4, LI, UL, Image } from '../../../../AbstractElements';
import { getCartTotal } from '../../../../Services/Ecommerce.service';
import CartContext from '../../../../_helper/Ecommerce/Cart';
import paypal from '../../../../assets/images/checkout/paypal.png';
import { Col, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaypal, faPix } from '@fortawesome/free-brands-svg-icons';
import QRCode from 'qrcode.react';

const ProductPlaceOrder = ({ carrinho }) => {
  const cart = carrinho;
  const [showPaymentDetailsPaypal, setShowPaymentDetailsPaypal] = useState(false);
  const [showPaymentDetailsPix, setShowPaymentDetailsPix] = useState(false);
  const [showPaymentDetailsBoleto, setShowPaymentDetailsBoleto] = useState(false);
  const [valorPix, setValorPix] = useState('');

  const handlePaymentMethodChange = (event) => {
    const paymentMethod = event.target.value;

    // Esconder detalhes de pagamento por padrão
    setShowPaymentDetailsPix(false);
    setShowPaymentDetailsBoleto(false);
    setShowPaymentDetailsPaypal(false);

    // Mostrar detalhes apenas para o método selecionado
    switch (paymentMethod) {
      case 'pix':
        setShowPaymentDetailsPix(true);
        break;
      case 'boleto':
        setShowPaymentDetailsBoleto(true);
        break;
      case 'PayPal':
        setShowPaymentDetailsPaypal(true);
        break;
      // Adicione mais casos conforme necessário
    }
  };
  const gerarQrCode = () => {
    // Verifique se o valor do PIX está presente
    if (valorPix.trim() !== '') {
      // Ative a exibição dos detalhes do pagamento
      setShowPaymentDetailsPix(true);
    } else {
      alert('Por favor, insira um valor válido para o PIX.');
    }
  };
  // Função para formatar valores em reais
  const formatarValorReais = (valor, casasDecimais) => {
    if (!isNaN(valor)) {
      return '' + valor.toLocaleString("pt-BR", {
        currency: "BRL",
        style: "currency",
        minimumFractionDigits: casasDecimais,
        maximumFractionDigits: casasDecimais,
      });
    } else {
      return 'Valor indisponível';
    }
  };

  return (
    <Fragment>
      <Col xl='6' sm='12'>
        <div className='checkout-details'>
          <div className='order-box'>
            <div className='title-box'>
              <div className='checkbox-title'>
                <H4>Produtos </H4>
                <span>Total</span>
              </div>
            </div>
            {cart && cart.map((item) => (
              <UL attrUL={{ className: 'simple-list border-x-0 border-t-0 qty' }} key={item.id}>
                <LI attrLI={{ className: 'border-0' }}>
                  {item.nome} x {item.quantidade}
                  <span>{formatarValorReais(item.valor)}</span>
                </LI>
              </UL>
            ))}
            <UL attrUL={{ className: 'simple-list border-0  sub-total' }}>
              <LI attrLI={{ className: 'border-0 bg-transparent' }}>
                Subtotal <span className='count'>{formatarValorReais(getCartTotal(cart))}</span>
              </LI>
            </UL>

            <UL attrUL={{ className: 'simple-list sub-total total' }}>
              <LI attrLI={{ className: 'border-0 bg-transparent ' }}>
                Total <span className='count'>{formatarValorReais(getCartTotal(cart))}</span>
              </LI>
            </UL>
            <div className='animate-chk'>
              <Row>
                <Col>
                  {/* Adicione outros métodos de pagamento com eventos onChange correspondentes */}
                  <Label className='d-flex align-items-center' htmlFor='edo-ani2'>
                    <Input
                      className='radio_animated'
                      id='edo-ani2'
                      type='radio'
                      name='rdo-ani'
                      data-original-title=''
                      title=''
                      value='pix'
                      onChange={handlePaymentMethodChange}
                    />
                    <FontAwesomeIcon icon={faPix} />   &nbsp; Pix
                  </Label>
                  {showPaymentDetailsPix && (
                    <div className="payment-details-container">
                      <QRCode value={valorPix} size={128} />
                      <textarea
                        id="pix-input"
                        rows="4"
                        cols="50"
                        value={valorPix}
                        onChange={(e) => setValorPix(e.target.value)}
                      ></textarea>
                      <button onClick={gerarQrCode}>Gerar QR Code</button>
                      <div>
                        {/* Exibir o QR Code usando a biblioteca qrcode.react */}
                      </div>
                    </div>
                  )}
                  <Label className='d-flex align-items-center' htmlFor='edo-ani2'>
                    <Input
                      className='radio_animated'
                      id='edo-ani2'
                      type='radio'
                      name='rdo-ani'
                      data-original-title=''
                      title=''
                      value='PayPal'
                      onChange={handlePaymentMethodChange}
                    />
                    <FontAwesomeIcon icon={faPaypal} />  &nbsp; PayPal
                    {/* <Image attrImage={{ className: 'img-paypal', src: `${paypal}`, alt: '' }} /> */}
                  </Label>
                  
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default ProductPlaceOrder;
