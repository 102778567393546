import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import { Table } from '../../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../../_Commons/Modal/Delete'

import { API } from '../../../../../../../api/API/api'
import { InformationsModules } from './ModalTabs/InformationsModules'
import { EvidencesModules } from './ModalTabs/EvidencesModules'
import { Materials } from './ModalTabs/Materials'
import { Classes } from './ModalTabs/Classes'

const formDataDefault = {
    id: 0,
    title: '',
    description: '',
    teacher: '',
    teacher_id: 0
  }

const listColumnEvidences = ['id', 'title', 'teacher'];
const listColumnClasses = ['id', 'title', 'description', 'teacher']

export default function Modules() {
  const { tenant_id } = useParams()
  const [data, setData] = useState({
    modules: [],
    teachers: []
  })

  const [activeTab, setActiveTab] = useState('information');

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [moduleId, setModuleId] = useState(0);

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const [evidencesModulesLinked, setEvidencesModulesLinked] = useState([]);
  const [evidencesModulesUnlinked, setEvidencesModulesUnlinked] = useState([]);
  const [currentEvidencesPage, setCurrentEvidencesPage] = useState(1);
  const [evidencesPerPage, setEvidencesPerPage] = useState(10);
  const [searchEvidences, setSearchEvidences] = useState('');
  const [sortEvidences, setSortEvidences] = useState('id');
  const [descendigEvidencesSort, setDescendingEvidencesSort] = useState(false);
  const [evidencesLastPage, setEvidencesLastPage] = useState(1);

  const [materials, setMaterials] = useState([]);
  const [materialsLastPage, setMaterialsLastPage] = useState(1);

  const [classes, setClasses] = useState([]);
  const [currentClassesPage, setCurrentClassesPage] = useState(1);
  const [classesPerPage, setClassesPerPage] = useState(10);
  const [searchClasses, setSearchClasses] = useState('');
  const [sortClasses, setSortClasses] = useState('id');
  const [descendigClassesSort, setDescendingClassesSort] = useState(false);
  const [classesLastPage, setClassesLastPage] = useState(0);

  const setTableData = (payloads) => ({
        columns: ['Módulo', 'Professor(a)'],
        rows: payloads.map(item => [item.title, item.teacher]),
        payloads: payloads
    })

  const firstRenderEvidences = useRef(true);
  const firstRenderClasses = useRef(true);

  const apiPath = `/tenants/${tenant_id}/management/academic/course_program/modules`

  const getData = async () => {
    try {
      const response = await API.get(apiPath)
      formDataDefault.teacher_id = response.data.teachers[0].id
      setData(response.data)
    } catch (error) { console.error(error) }
  }

  const getClasses = async (id) => {
    if (id) setModuleId(id);

    try {
      const response = await API.get(`/tenants/${tenant_id}/management/academic/course_program/modules/${id ? id : moduleId}/lessons?page=${currentClassesPage}&perpage=${classesPerPage}&query=${searchClasses}&sorting=${sortClasses}&reversed=${descendigClassesSort}`);
      setClasses(response.data.data);
      setClassesLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const getEvidences = async (id) => {

    if (id) setModuleId(id);

    try {
      const response = await API.get(`/tenants/${tenant_id}/management/academic/course_program/modules/${id ? id : moduleId}/exams?page=${currentEvidencesPage}&perpage=${evidencesPerPage}&query=${searchEvidences}&sorting=${sortEvidences}&reversed=${descendigEvidencesSort}`);
      setEvidencesModulesLinked(response.data.linked);
      setEvidencesModulesUnlinked(response.data.unlinked);
      setEvidencesLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const getMaterials = async (id) => {
    if (id) setModuleId(id);
    try {
      const response = await API.get(`/tenants/${tenant_id}/management/academic/course_program/modules/${id ? id : moduleId}/materials`);
      setMaterials(response.data.data);
      setMaterialsLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {

    if (firstRenderClasses.current) {
      firstRenderClasses.current = false;
      return;
    }

    getClasses();
  }, [currentClassesPage, searchClasses, classesPerPage, descendigClassesSort, sortClasses]);

  useEffect(() => {

    if (firstRenderEvidences.current) {
      firstRenderEvidences.current = false;
      return;
    }
    getEvidences();
  }, [currentEvidencesPage, searchEvidences, evidencesPerPage, descendigEvidencesSort, sortEvidences]);


  const openModal = (isEditing, data) => {
    setIsEditing(isEditing)
    setFormData(data)
    setModalVisible(true)
    getEvidences(data.id)
    getMaterials(data.id)
    getClasses(data.id);
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
    getMaterials(data.id)
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const bindEvidences = async (evidenceId) => {
    try {
      const response = await API.put(`/tenants/${tenant_id}/management/academic/course_program/modules/${moduleId}/exams/${evidenceId}`);
      setEvidencesModulesLinked(response.data.linked);
      setEvidencesModulesUnlinked(response.data.unlinked);
      setEvidencesLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearchEvidences = async () => {
    setCurrentEvidencesPage(1);
    await getEvidences();
  }

  const handleSearchClasses = async () => {
    setCurrentClassesPage(1);
    await getClasses();
  }

  const handleChangeEvidencesPagination = (e) => {
    setCurrentEvidencesPage(Number(e.target.value));
  }

  const handleChangeClassesPagination = (e) => {
    setCurrentClassesPage(Number(e.target.value));
  }

  const handleEvidencesSorte = (column, direction) => {
    const translatingColumn = listColumnEvidences[column]
    setSortEvidences(translatingColumn);
    direction === 'asc' ? setDescendingEvidencesSort(false) : setDescendingEvidencesSort(true)
  }

  const handleClassesSorte = (column, direction) => {
    const translatingColumn = listColumnClasses[column]
    setSortClasses(translatingColumn);
    direction === 'asc' ? setDescendingClassesSort(false) : setDescendingClassesSort(true)
  }

  const handleCreateMaterial = async (formData) => {
    try {
      const response = await API.post(`/tenants/${tenant_id}/management/academic/course_program/modules/${moduleId}/materials`, formData);
      setMaterials(response.data.data);
      setMaterialsLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const handleDeleteMaterial = async (materialId) => {
    try {
      const response = await API.delete(`/tenants/${tenant_id}/management/academic/course_program/modules/${moduleId}/materials/${materialId}`);
      setMaterials(response.data.data);
      setMaterialsLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const registeringClass = async (formData) => {
    try {
      const response = await API.post(`/tenants/${tenant_id}/management/academic/course_program/modules/${moduleId}/lessons`, formData);
      setClasses(response.data.data);
      setClassesLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  const deleteClass = async (classeId) => {
    try {
      const response = await API.delete(`/tenants/${tenant_id}/management/academic/course_program/modules/${moduleId}/lessons/${classeId}`);
      setClasses(response.data.data);
      setClassesLastPage(response.data.last_page);
    } catch (error) {
      console.log(error);
    }
  }
 
  return (
    <>
      <Table 
          header='Módulos' data={setTableData(data.modules)} 
          onNewClick={() => openModal(false, formDataDefault)} 
          onEditClick={(payload) => openModal(true, payload)}
          onDeleteClick={(payload) => openModalDelete(payload)}
      />

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Módulo' : 'Criar Módulo'} onSubmit={() => submitModalForm()} size="lg" >
        <Nav tabs>

          <NavItem>
            <NavLink active={activeTab === 'information'} onClick={() => setActiveTab('information')}>Informações</NavLink>
          </NavItem>

          <NavItem>
            <NavLink active={activeTab === 'evidences'} onClick={() => setActiveTab('evidences')}>Provas</NavLink>
          </NavItem>

          <NavItem>
            <NavLink active={activeTab === 'materials'} onClick={() => setActiveTab('materials')}>Materiais Complementares</NavLink>
          </NavItem>

          <NavItem>
            <NavLink active={activeTab === 'classes'} onClick={() => setActiveTab('classes')}>Aulas</NavLink>
          </NavItem>

        </Nav>

        <TabContent activeTab={activeTab}>

          <TabPane tabId="information">
            <InformationsModules data={data} formData={formData} handleModalInputChange={handleModalInputChange} />
          </TabPane>

          <TabPane tabId="evidences">
            <EvidencesModules
              data={evidencesModulesLinked}
              searchQuery={searchEvidences}
              setSearchQuery={setSearchEvidences}
              handleSearch={handleSearchEvidences}
              currentPage={currentEvidencesPage}
              setCurrentPage={setCurrentEvidencesPage}
              handleChangePagination={handleChangeEvidencesPagination}
              itemsPerPage={evidencesPerPage}
              setItemsPerPage={setEvidencesPerPage}
              lastPage={evidencesLastPage}
              handleSort={handleEvidencesSorte}
              sortColumn={sortEvidences}
              descendingSort={descendigEvidencesSort}
              moduloName={formData.title}
              unlinked={evidencesModulesUnlinked}
              bindEvidences={bindEvidences}
            />
          </TabPane>
          
          <TabPane tabId="materials">
            <Materials
               handleCreateMaterial={handleCreateMaterial}
               data={materials}
               moduleName={formData.title}
               handleDeleteMaterial={handleDeleteMaterial}
            />
          </TabPane>

          <TabPane tabId="classes">
            <Classes
              teachers={data.teachers}
              registeringClass={registeringClass}
              data={classes}
              moduleName={formData.title}
              deleteClass={deleteClass}
              searchQuery={searchClasses}
              setSearchQuery={setSearchClasses}
              handleSearch={handleSearchClasses}
              currentPage={currentClassesPage}
              setCurrentPage={setCurrentClassesPage}
              handleChangePagination={handleChangeClassesPagination}
              itemsPerPage={classesPerPage}
              setItemsPerPage={setClassesPerPage}
              lastPage={classesLastPage}
              handleSort={handleClassesSorte}
              sortColumn={sortClasses}
              descendingSort={descendigClassesSort}
            />
          </TabPane>

        </TabContent>

      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

