import { useState } from 'react'
import { Form, FormGroup, Input, Label, Button } from 'reactstrap'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { Table } from '../../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../../_Commons/Modal/CreateAndEdit'

import { API } from '../../../../../../../api/API/api'

const enrollmentDataDefault = {
    free: true,
    period: 0,
    value: null,
    installment: null
}

const months = Array.from({ length: 12 }, (_, index) => index + 1)

export const StudentEnrollments = ({ data, courses, setData, setFormData }) => {
    const { tenant_id } = useParams()
    const [modalVisible, setModalVisible] = useState(false)
    const [enrollmentData, setEnrollmentData] = useState(enrollmentDataDefault)

    const setTableData = (payloads) => ({
        columns: ['Curso', 'Situação', 'Data expiração'],
        rows: payloads.map(item => [
            item.course,
            item.situation,
            item.expires_at
        ]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/academic/students/${data.id}/enrollments`

    const addCourse = async (e) => {
        try {
            const { id } = JSON.parse(e.target.value)
            const response = await API.post(`${apiPath}/${id}`)

            const student = response.data.students.find(item => item.id === data.id)
            setFormData(student)

            setData(response.data)

            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }
    }

    const openModal = (payload) => {
        setEnrollmentData({...payload.payload, ...enrollmentDataDefault})
        setModalVisible(true)
    }

    const extendButton = (payload) => (
        <>
            <Button color='primary' outline={true} onClick={() => openModal(payload)}>
                <i class="fa fa-calendar-plus-o" />
            </Button>
        </>
    )

    const handleModalInputChange = (e) => {
        let { name, value } = e.target
        value = ['free'].includes(name) ? !enrollmentData[name] : value
        value = ['value'].includes(name) ? parseInt(value) : value
        
        setEnrollmentData(prevEnrollmentData => ({
            ...prevEnrollmentData,
            [name]: value,
        }))
    }

    const submitModalForm = async () => {
        try {
            const { course_id, ...payload } = enrollmentData
            const response = await API.put(`${apiPath}/${course_id}`, payload)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    return (
        <>
            <Form>
                <FormGroup>
                    <Label for='course'>Adicionar curso:</Label>
                    <Input type='select' onChange={addCourse}>
                        <option selected hidden></option>
                        {courses.map((course, index) => (
                            <option key={`student-enrollment-${index}`} value={JSON.stringify(course)}>{course.name}</option>
                        ))}
                    </Input>
                </FormGroup>
            </Form>

            <Table header='Matrículas' data={setTableData(data.enrollments)} customButtons={extendButton} />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title='Extensão de matricula' onSubmit={() => submitModalForm()} >

                <FormGroup>
                    <Label for='free'>Gratuita:</Label>
                    <FormGroup switch>
                        <Input type='switch' name='free' id='free' onChange={handleModalInputChange} checked={enrollmentData.free} />
                        <Label for='free'>{enrollmentData.free ? 'Sim' : 'Não'}</Label>
                    </FormGroup>
                </FormGroup>

                <FormGroup>
                    <Label for='period'>Nova expiração:</Label>
                    <Input type='select' name='period' id='period' onChange={handleModalInputChange}>
                        <option selected hidden></option>
                        {months.map((month, index) => (
                            <option key={`enrollment-extension-period-${index}`} value={month * 30}>
                                {month} {index === 0 ? 'Mês' : 'Meses'}
                            </option>
                        ))}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for='value'>Valor:</Label>
                    <Input type='number' min='0' name='value' id='value' onChange={handleModalInputChange} disabled={enrollmentData.free} />
                </FormGroup>

                <FormGroup>
                    <Label for='installment'>Parcelamento:</Label>
                    <Input type='select' name='installment' id='installment' onChange={handleModalInputChange} disabled={enrollmentData.free}>
                        <option selected hidden></option>
                        {Array.from({ length: enrollmentData.period / 30 }, (_, i) => i + 1).map((installment, index) => (
                            <option key={`enrollment-extension-installment-${index}`} value={installment}>
                                {installment}x de  {(enrollmentData.value / installment).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </option>
                        ))}
                    </Input>
                </FormGroup>

            </ModalCreateAndEdit>
        </>
    )
}