import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, Table as Trs, Label, InputGroup, Input, Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap'

import { Table } from '../../_Commons/Table/Index'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    name: '',
    pending: [],
    responded: [],
    closed: []
}

const questionDefault = {
    id: 0,
    user: '',
    title: '',
    text: '',
    date: '',
    answers: []
}

const DoubtsTab = ({ questions, onOpen }) => {
    return (
        <>
            <Form className='mt-5'>
                <Trs borderless>
                    <tbody>
                        {questions.map((question, index) => (
                            <tr key={`doubts-questions-${index}`}>
                                <td>
                                    <ListGroupItem>
                                        <Label><strong>{question.user}</strong></Label>
                                        <Label className='text-muted mx-2'>{question.date}</Label><br />
                                        <span>{question.title}</span>
                                    </ListGroupItem>
                                </td>
                                <td>
                                    <Button color='primary' outline={true} onClick={() => onOpen(question)}>
                                        <i class="fa fa-eye" />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Trs>
            </Form>
        </>
    )
}

export default function Doubts() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        doubts: [],
        total: 0,
        last_page: 0
    })

    const [formData, setFormData] = useState(formDataDefault)
    const [question, setQuestion] = useState(questionDefault)
    const [message, setMessage] = useState('')

    const [modalVisible, setModalVisible] = useState(false)
    const [questionModalVisible, setQuestionModalVisible] = useState(false)
    const [activeTab, setActiveTab] = useState('pending')
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [lastPage, setLastPage] = useState(0)
    const [sortColumn, setSortColumn] = useState('id')
    const [descendingSort, setDescendingSort] = useState(false)

    const setTableData = (payloads) => ({
        columns: ['Id', 'Disciplina', 'Pendente', 'Respondida', 'Fechada'],
        rows: payloads.map(item => [item.id, item.name, item.pending.length, item.responded.length, item.closed.length]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/academic/doubts`
    const apiQuery = `/tenants/${tenant_id}/management/academic/doubts?page=${currentPage}&perpage=${itemsPerPage}&query=${searchQuery}&sorting=${sortColumn}&reversed=${descendingSort}`
    
    const getData = async () => {
        try {
            const response = await API.get(apiQuery)
            setData(response.data)
            setLastPage(response.data.last_page)
        } catch (error) { console.error(error) }
    }

    useEffect(() => {
        getData()
    }, [currentPage, searchQuery, itemsPerPage,  descendingSort, sortColumn])

    const openModal = (data) => {
        setFormData(data)
        setModalVisible(true)
    }

    const openMessagesModal = (messages) => {
        setQuestion(messages)
        setQuestionModalVisible(true)
    }

    const handleInputChange = (e) => {
        const { value } = e.target
        setMessage(value)
    }

    const sendMessage = async (e, isClosed) => {
        e.preventDefault()
        try {
            const response = await API.post(`${apiPath}/${question.id}/answer`, { text: message, closed: isClosed })

            if (!isClosed) {
                const doubt = response.data.find(item => item.id === formData.id)
                const key = isClosed ? 'closed' : 'responded'
                const currentQuestion = doubt[key].find(item => item.id === question.id)
                setFormData(doubt)
                setQuestion(currentQuestion)
            } else {
                setModalVisible(false)
                setQuestionModalVisible(false)
            }

            setData(response.data)
            setMessage('')
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }
    }

    const handleSearch = async () => {
        setCurrentPage(1)
        await getData()
    }

    const handleChangePagination = async (e) => {
        setCurrentPage(Number(e.target.value))
    }

    const handleSort = (column, direction) => {
        let translatingColumn;
        switch (column) {
          case 0:
            translatingColumn = 'id'
            break;
          case 1:
            translatingColumn = 'subjects'
            break
          case 2:
            translatingColumn = 'pending'
            break
          case 3:
            translatingColumn = 'responded'
            break
          case 4:
            translatingColumn = 'closed'
            break
          default:
            break;
        }
        setSortColumn(translatingColumn);
        direction === 'asc' ? setDescendingSort(false) : setDescendingSort(true)
      }

    const viewButton = ({ payload }) => (
        <>
            <Button color='primary' outline={true} onClick={() => openModal(payload)}>
                <i class="fa fa-eye" />
            </Button>
        </>
    )

    return (
        <>
            <Table
                header='Perguntas ao professor' data={setTableData(data.doubts)}
                customButtons={viewButton}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                search={true}
                handleSearch={handleSearch}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleChangePagination={handleChangePagination}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                pagination={true}
                lastPage={lastPage}
                handleSort={handleSort}
                sortColumn={sortColumn}
                sortDirection={descendingSort}
            />

            <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)} centered>
                <ModalHeader toggle={() => setModalVisible(false)}>Dúvidas</ModalHeader>

                <ModalBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink active={activeTab === 'pending'} onClick={() => setActiveTab('pending')}>Pendente</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={activeTab === 'responded'} onClick={() => setActiveTab('responded')}>Respondidas</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink active={activeTab === 'closed'} onClick={() => setActiveTab('closed')}>Fechadas</NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId='pending'>
                            <DoubtsTab questions={formData.pending} onOpen={openMessagesModal} />
                        </TabPane>
                        <TabPane tabId='responded'>
                            <DoubtsTab questions={formData.responded} onOpen={openMessagesModal} />
                        </TabPane>
                        <TabPane tabId='closed'>
                            <DoubtsTab questions={formData.closed} onOpen={openMessagesModal} />
                        </TabPane>
                    </TabContent>
                </ModalBody>
            </Modal>


            <Modal isOpen={questionModalVisible} toggle={() => setQuestionModalVisible(false)} centered>
                <ModalHeader toggle={() => setQuestionModalVisible(false)}>{question.title}</ModalHeader>

                <ModalBody>
                    <ListGroup flush>
                        <ListGroupItem className='my-3'>
                            <Label><strong>{question.user}</strong></Label>
                            <Label className='text-muted mx-2'>{question.date}</Label><br />
                            <span>{question.text}</span><br />
                        </ListGroupItem>

                        {question.answers.map((answer, index) => (
                            <ListGroupItem className='my-3' key={`requirement-messages-${index}`}>
                                <Label><strong>{answer.user}</strong></Label>
                                <Label className='text-muted mx-2'>{answer.date}</Label><br />
                                <span>{answer.text}</span><br />
                            </ListGroupItem>
                        ))}
                    </ListGroup>

                    <InputGroup>
                        <Input name='text' value={message} onChange={handleInputChange} />
                        <Button color='primary' onClick={(e) => sendMessage(e, false)}>Enviar</Button>
                    </InputGroup>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={(e) => sendMessage(e, true)}>Encerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

