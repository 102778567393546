import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap'
import { H6 } from '../../../../../../AbstractElements'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    order: false
}

export default function SlidesSettings() {
    const { tenant_id } = useParams();
    const [formData, setFormData] = useState(formDataDefault);

    const apiPath = `/tenants/${tenant_id}/management/cms/slides/settings`

    useEffect(() => {
        async function getData() {
            try {
                const response = await API.get(apiPath);
                setFormData(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, [tenant_id]);


    const handleInputChange = (e) => {
        let { value } = e.target
        value = !formData['order']

        setFormData({order: value})
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await API.put(apiPath, formData);
            toast.success('Sucesso!')

        } catch (error) {
            console.log(error);
            toast.error('Error')
        }
    };

    return (
        <>
            <Card className='col-12' style={{ marginBottom: "8.5em" }}>
                <div className="card-header">
                    <h5 className="card-title p-b-0 p-t-0">Configurações Slides</h5>
                </div>
                <Form>
                    <Row className="m-4">
                        <Col md="12" className="mb-3">
                            <FormGroup>
                                <H6>Ordem de exibição:</H6>
                                <FormGroup switch>
                                    <Input type='switch' name='order' id='order' onChange={handleInputChange} checked={formData.order} />
                                    <Label for='order'>{formData.order ? 'Ordenado' : 'Aleatório'}</Label>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="text-right rowCustom">
                        <Col className="text-right">
                            <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                                Salvar alterações
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

