import { Button } from "reactstrap";
import Eccomerce from "../../../../../../src/assets/images/ecommerce/eccomerce.png";
import "../component/createdCard.scss"

export default function CreatedCard({ title, count,  nameButtom, func }) {
  return (
    <div className="Created-card d-flex align-items-center justify-content-center">
      <div>
        <span>{title}</span>
        <h5>{count}</h5>
        
          <Button onClick={func} className="purchase-btn btn btn-primary btn-hover-effect f-w-500">
            {nameButtom}
          </Button>
        
      </div>
      <div>
        <img src={Eccomerce} alt="assets" />
      </div>
    </div>
  );
}
