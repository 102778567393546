import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { H6, UL, LI, Btn, P } from "../../../../../../AbstractElements";
import { API } from "../../../../../../api/API/api";
import { ModalCreateAndEdit } from "../../../_Commons/Modal/CreateAndEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import StorageUsedChart from "./Graphic/StorageUsedCart";
import StudentData from "./Graphic/StudentData";

export default function DataFlat() {
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState({
    id: 0,
    description: '',
    name: '',
    price: 0,
    max_storage: 0,
    max_students: 0,
    extra_storage: 0,
    extra_students: 0,
    total_students: 0,
    is_active: true,
    used_storage: '',
    total_active_students: 0
  });
  const [plan_id, setPlan_id] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  const { tenant_id } = useParams();
  const token = localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      try {
        const [planResponse, plansResponse] = await Promise.all([
          API.get(`/tenants/${tenant_id}/plan`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }),
          API.get('/backoffice/plans')
        ]);
        setPlan(planResponse.data);
        setPlan_id(planResponse.data.id)
        console.log(plansResponse.data)
        setPlans(plansResponse.data);
      } catch (error) {
        console.log(error);
      }
    })()
  }, []);

  const handleChangePlan = (e) => {
    e.preventDefault();
    setModalVisible(true);
  }

  const submitModalDeleteForm = async () => {
    if (plan_id === 0) return;
    const apiPutPlan = `/backoffice/tenants/${tenant_id}/plan`;
    try {
      const response = await API.put(apiPutPlan, {
        plan_id: plan_id,
      });

      setPlan(response.data);
      setModalVisible(false);
      toast.success('Sucesso!');
    } catch (error) {
      console.log('Error ao editar plano', error);
      toast.success('Sucesso!');
    }
  }

  const inputChange = (idNewPlan) => {
    setPlan_id(idNewPlan);
  }

  return (
    <Fragment>
      <Card className='col-12' style={{ marginBottom: "8.5em" }}>
        <div className="card-header">
          <h5 className="card-title p-b-0 p-t-0">Dados do Plano</h5>
        </div>
        <form>
          <Row style={{ marginLeft: '2rem', marginBottom: '10px' }}>
            <Col md="4" sm="6" xs="12" className="mb-3">
              <H6>Nome do plano</H6>

              <Col className="d-flex justify-content-center align-items-center">
                <input type="text" value={plan.name} className="form-control me-2" />
                <Button color='primary' style={{ height: '35px', width: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} size="sm" onClick={(e) => handleChangePlan(e)}>
                  <FontAwesomeIcon icon={faExchangeAlt} className="me-1" />
                </Button>
              </Col>
            </Col>

            <Col md="4" sm="6" xs="12">
              <H6>Descrição</H6>
              <p>{plan.description}</p>
            </Col>

            <Col md="4" sm="6" xs="12">
              <H6>Preço</H6>
              <p>{plan.price} R$</p>
            </Col>
          </Row>

          <Row style={{ marginLeft: '2rem', marginBottom: '10px' }}>
            <Col md="4" sm="6" xs="12">
              <H6>Máximo de estudantes</H6>
              <p>{plan.max_students}</p>
            </Col>

            <Col md="4" sm="6" xs="12">
              <H6>Total de Alunos</H6>
              <p>{plan.total_students}</p>
            </Col>

            <Col md="4" sm="6" xs="12" style={{   }}>
              <H6>Alunos Ativos</H6>
              <p>{plan.total_active_students}</p>

              {/* <div style={{ width: '400px' }}>
                <StudentData activeStudents={plan.total_active_students} totalStudents={plan.max_students} />
              </div> */}
            </Col>

            {/* <Col md="6" sm="6" xs="12">
              <H6>Capacidade de Armazenamento</H6>
              <p>{plan.max_storage} GB</p>
            </Col> */}
          </Row>

          <Row style={{ marginLeft: '2rem', marginBottom: '10px' }}>
            <Col md="4" sm="6" xs="12" style={{ marginRight: '5px' }}>
              <StorageUsedChart maxStorage={Number(plan.max_storage)} usedStorage={plan.used_storage} />
            </Col>
          </Row>
        </form>
      </Card> 

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} onSubmit={() => submitModalDeleteForm()}>
        <Card>
          <h5 style={{ textAlign: 'center' }}>Escolha seu plano</h5>

          <CardBody>
            <Row>
              {
                plans.map((itemPlan) => (
                  <Col>
                    <div className='pricingtable' style={{ cursor: 'pointer', border: `${plan_id === itemPlan.id ? '2px solid #007bff' : ''}`, padding: '5px' }} onClick={() => inputChange(itemPlan.id)}>
                      <div className='pricingtable-header'>
                        <H6 attrH3={{ className: 'title' }}>{itemPlan.name}</H6>
                      </div>
                      <div className='price-value'>
                        <span className='currency'>{'$'}</span>
                        <span className='amount' style={{ fontSize: '25px' }}>{itemPlan.price.toString().replace('.', ',')}</span>
                        <span className='duration'>{'/mês'}</span>
                      </div>
                      <UL attrUL={{ className: 'pricing-content flex-row' }}>
                        <LI attrLI={{ className: 'border-0' }}>{itemPlan.max_storage} GB</LI>
                        <LI attrLI={{ className: 'border-0' }}>Total de Alunos: {itemPlan.max_students}</LI>
                        <LI attrLI={{ className: 'border-0' }}>{itemPlan.description}</LI>
                      </UL>
                      <div className='pricingtable-signup'>
                        <Btn attrBtn={{ color: 'primary', size: 'lg' }}>Selecionar</Btn>
                      </div>
                    </div>
                  </Col>
                ))
              }
            </Row>
          </CardBody>

        </Card>
      </ModalCreateAndEdit>
    </Fragment>
  )
}