import { useState } from "react"
import { Button, FormGroup, Form, Label, Input, Row, Col } from "reactstrap"
import { Table } from "../../../../../_Commons/Table/Index";

export const Materials = ({
  handleCreateMaterial,
  moduleName,
  data,
  handleDeleteMaterial
}) => {

  const [formData, setFormData] = useState({
    title: '',
    file: ''
  });
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(prevState => !prevState);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFormData((prevState) => ({
        ...prevState,
        file: reader.result
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateMaterial(formData);
    setFormData({
      file: '',
      title: ''
    });
    setShowForm(false);
  }

  const setTableData = (payloads) => ({
    columns: ['Id', 'Título'],
    rows: payloads.map(item => [item.id, item.title]),
    payloads: payloads
  });

  const handleDownload = (fileUrl) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileUrl.split('/').pop();
    link.click();
  };

  return (
    <>
      {
        !showForm && (
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <Button
                color="primary"
                type="button"
                onClick={toggleForm}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        )
      }
      {showForm && (
        <Form onSubmit={handleSubmit} style={{ marginTop: '18px' }}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="materialTitle">Título</Label>
                <Input
                  type="text"
                  name="title"
                  id="materialTitle"
                  value={formData.title}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
          <Col>
              <FormGroup>
                <Label for="materialFile">Arquivo</Label>
                <Input
                  type="file"
                  name="file"
                  id="materialFile"
                  onChange={handleFileChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          
          <Button color="danger" type="button" onClick={() => setShowForm(false)} style={{ marginRight: '15px' }}>
            Cancelar
          </Button>
          <Button color="primary" type="submit" disabled={formData.file === '' || formData.title === ''}>
            Enviar
          </Button>
        </Form>
      )}
      {!showForm && (
        <Table
          header={`Materiais Complementares do módulo ${moduleName}`}
          data={setTableData(data)}
          onDeleteClick={(payload) => handleDeleteMaterial(payload.id)}
          onDownloadClick={(payload) => handleDownload(payload.file)}
        />
      )}
    </>
  )
}