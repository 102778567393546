import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Card, Row, Col, Button, Form, FormGroup, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { API } from '../../../api/API/api';
import Layout from './Layout';
import CommonModal from '../../UiKits/Modals/common/modal';
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const Administradores = () => {
  const token = localStorage.getItem('token');
  const { tenant_id } = useParams();
  const [users, setUsers] = useState([]);

  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    email: '',   // Inicializado como uma string vazia
    password: '', // Inicializado como uma string vazia
    profile: '',
    profile_id:1
  });


  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const profileIdMapping = {
    'Administrador': 1,
    'Gerente': 2,
    'Edição': 4,
    'Produção': 5,
    'Financeiro': 6,
    'Parceiro': 8,
    'Monitor': 9,
    // Adicione mais conforme necessário
  };
  const [profileOptions, setProfileOptions] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'profile') {
      const selectedProfile = profileOptions.find((option) => option.name === value);

      if (selectedProfile) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
          profile_id: selectedProfile.id,
        }));
      } else {
        console.error('Perfil não encontrado:', value);
        toast.error('Perfil não encontrado');

        // Você pode adicionar lógica adicional aqui, como definir um valor padrão
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const getData = async () => {
    try {
      const response = await API.get(`/tenants/${tenant_id}/management/administrators`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data.users || []);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    const getProfileOptions = async () => {
      try {
        const response = await API.get(`/tenants/${tenant_id}/management/administrators`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data.profiles);
        setProfileOptions(response.data.profiles || []);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
    getProfileOptions();

  }, []);
  

  const openAddPlanoModal = () => {
    setIsEditing(false);
    setIsAddUserModalVisible(true);
  };

  const closeAddPlanoModal = () => {
    setIsAddUserModalVisible(false);
    setIsEditing(false);
    setFormData({
      id: 0,
      name: '',
      email: '',
      password: '',
      profile: '',
      profile_id: 1,
    });
  };

  const toggleModal = (user) => {
    setIsEditing(true);
    setFormData({
      id: user.id,
      name: user.name,
      email: user.email,
      password: user.password,
      profile: user.profile,
      profile_id: user.profile_id,
    });
    setIsAddUserModalVisible(true);
  };

  const handleEditUser = async () => {
    // Implemente a lógica para editar um usuário existente
    console.log(formData);
    try {
      const response = await API.put(`/tenants/${tenant_id}/management/administrators/${formData.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Manipule o sucesso, por exemplo, mostre uma mensagem de sucesso
      console.log('Usuário editado com sucesso:', response.data);
      toast.success('Usuário atualizado com sucesso!');
      getData();


    } catch (error) {
      // Manipule o erro, por exemplo, mostre uma mensagem de erro
      console.error('Erro ao editar usuário:', error);
      toast.error(error);

    }

    setIsAddUserModalVisible(false);
    setIsEditing(false);
  };

  const handleNewUser = async () => {
    try {
      const response = await API.post(`/tenants/${tenant_id}/management/administrators`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Manipule o sucesso, por exemplo, mostre uma mensagem de sucesso
      console.log('Usuário adicionado com sucesso:', response.data);
      toast.success('Usuário adicionado com sucesso!');
      getData();

    } catch (error) {
      // Manipule o erro, por exemplo, mostre uma mensagem de erro
      console.error('Erro ao adicionar usuário:', error);
      toast.error('Erro ao adicionar usuário:',error);

    }

    setIsAddUserModalVisible(false);
  };

  const openDeleteConfirmationModal = (user) => {
    setUserToDelete(user);
    setIsDeleteConfirmationModalVisible(true);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalVisible(false);
    setUserToDelete(null);
  };

  const confirmDelete = async () => {
    try {
      // Faça a requisição DELETE para excluir o usuário
      const response = await API.delete(`/tenants/${tenant_id}/management/administrators/${userToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Lógica para tratamento de sucesso e recarregamento dos dados
      console.log('Usuário excluído com sucesso!', response);
      toast.success('Usuário excluído com sucesso!', response);


      closeDeleteConfirmationModal();
      getData();

      // getData();
    } catch (error) {
      // Lógica para tratamento de erros
      console.error('Erro na exclusão do usuário:', error);
      toast.error('Erro na exclusão do usuário:', error);
    }
  };

  return (
    <Layout id="administrativo" site_name="Administradores">
      <Card className='col-12' style={{ paddingTop: '20px', paddingBottom: '20px',paddingLeft:'20px' }}>
        <Btn
          attrBtn={{ color: 'primary', className: 'col-2 d-flex justify-content-center', outline: true, onClick: openAddPlanoModal }}
        >
          Novo
        </Btn>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Lista de Usuários</h5>
        </div>

        <div>
          {users.map((user, index) => (
            <Row key={index} className='mb-2' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
              <Col md='3'>{user.name}</Col>
              <Col md='3'>{user.email}</Col>
              <Col md='3'>{user.profile}</Col>
              <Col md='3'>
                <div className='buttonsCard'>
                  <Btn attrBtn={{ color: 'primary', outline: true, onClick: () => toggleModal(user) }}>
                    <i className='fa fa-edit fa-lg'></i>
                  </Btn>
                  <Btn attrBtn={{ className: '', color: 'secondary', outline: true, onClick: () => openDeleteConfirmationModal(user) }}>
                    <i className='fa fa-trash-o fa-lg'></i>
                  </Btn>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      </Card>

      <CommonModal title={`Confirmação de Exclusão - ${userToDelete && userToDelete.name}`}
        isOpen={isDeleteConfirmationModalVisible} toggle={() => setIsDeleteConfirmationModalVisible(false)}>
        <div>
          <p>Tem certeza de que deseja excluir o usuário: {userToDelete && userToDelete.name}?</p>
        </div>
        <div>
          <button className="btn btn-outline-secondary" onClick={closeDeleteConfirmationModal}>
            Cancelar
          </button>
          <button className="btn btn-outline-danger custom-danger" onClick={confirmDelete}>
            Excluir
          </button>
        </div>
      </CommonModal>

      <CommonModal isOpen={isAddUserModalVisible} toggle={closeAddPlanoModal}>
        <ModalHeader toggle={closeAddPlanoModal}>
          {isEditing ? 'Editar Usuário' : 'Adicionar Usuário'}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for='name'>Nome:</Label>
              <Input
                type='text'
                name='name'
                id='name'
                placeholder='Digite o nome do usuário'
                value={formData.name}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
            <Label for='name'>E-mail:</Label>
              <Input
                type='email'
                name='email'
                id='email'
                placeholder='Digite o e-mail do usuário'
                value={formData.email}
                onChange={handleInputChange}
                autoComplete='username'
              />

            </FormGroup>
            <FormGroup>
              <Label for='password'>Password:</Label>
              <Input
                type='password'
                name='password'
                id='password'
                placeholder='Digite a senha do usuário'
                value={formData.password}
                onChange={handleInputChange}
                autoComplete='current-password'
              />

            </FormGroup>
            <FormGroup>
              <Label for='profile_id'>Perfil:</Label>
              <select
                className="form-control"
                name="profile_id"
                id="profile_id"
                value={formData.profile_id}
                onChange={handleInputChange}
              >
                {profileOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>

            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={isEditing ? handleEditUser : handleNewUser}>
            {isEditing ? 'Editar Usuário' : 'Adicionar Usuário'}
          </Button>
          <Button color='secondary' onClick={closeAddPlanoModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </CommonModal>
    </Layout >
  );
};

export default Administradores;
