import React, { Fragment, useState, useContext } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import { EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from "../../../Constant";

import { useNavigate } from "react-router-dom";
import man from "../../../assets/images/dashboard/profile.png";

import CustomizerContext from "../../../_helper/Customizer";
import OtherWay from "./OtherWay";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { API } from "../../../api/API/api";

const Signin = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const loginAuth = async (e) => {
    e.preventDefault();
    if (!email || !password ) {
      console.log('email ou senha não informado')
      return
    }
  
    try {
      const response = await API.post(`/auth/login`, {email, password})

      const data = response.data

      localStorage.setItem("Name", data.user.name);
      // console.table(data.user);
      localStorage.setItem("user_id", data.user.id);
      localStorage.setItem("group_id", data.user.group_id);
      localStorage.setItem("profileURL", data.user.photo || man);
      localStorage.setItem("token", data.access_token);

      history(`/admin/dashboard`);
      toast.success("Logado com sucesso!..");

    } catch (error){
      console.log(error)
      toast.sucess("Email ou Senha incorretos..");
    }
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>{selected === "simpleLogin" ? "" : "Sign In With Simple Login"}</H4>
                  <P>{"Informe seu email e sua senha para efetuar o login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} value={password} />
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "Mostrar"}></span>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <div className="checkbox">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        Lembrar senha
                      </Label>
                    </div>
                    <a className="link" href="#javascript">
                      Esqueceu sua senha?
                    </a>
                    <Btn attrBtn={{ color: "primary", className: "d-block w-100 mt-2", onClick: (e) => loginAuth(e) }}>Entrar</Btn>
                  </div>
                  <OtherWay />
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
