import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Database } from 'react-feather';
import { UL, LI, P } from "../../../../../../../AbstractElements";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);


export default function StorageUsedChart({ usedStorage, maxStorage }) {
  const storageUsed = parseFloat(usedStorage.replace(" GB", ""));

  const storageUsedPercentage = (storageUsed / maxStorage) * 100;

  const data = {
    labels: ["Utilizado", "Disponível"],
    datasets: [
      {
        data: [storageUsedPercentage, 100 - storageUsedPercentage],
        backgroundColor: ["#FF6384", "blue"],
        hoverBackgroundColor: ["#FF6384", "blue"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}: ${value.toFixed(2)}%`;
          }
        }
      },
    },
  };


  return (
    // <div style={{ width: '160px', height: '160px', position: 'relative', }}>
    //   <Doughnut data={data} options={options} />
    //   <div style={{
    //     position: 'absolute',
    //     top: '35%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     fontSize: '14px',
    //     fontWeight: '400',
    //     textAlign: 'center',
    //     lineHeight: '200px'
    //   }}>
    //     {storageUsedPercentage.toFixed(2)}%
    //   </div>
    // </div>
    <UL attrUL={{ className: 'simple-list border-0' }}>
      <LI attrLI={{ className: 'border-0' }}>
        <div className='btn btn-outline-primary' style={{ display: 'flex', alignItems: 'center', gap: '5px'}}>
          <Database />
          Armazenamento
        </div>
        <div className='m-t-15'>
          <div className='progress sm-progress-bar mb-1' style={{ backgroundColor: '#afaeae' }}>
            <div
              className='progress-bar bg-primary'
              role='progressbar'
              style={{ width: `${storageUsedPercentage}%`, backgroundColor: '#007bff' }}
              aria-valuenow={storageUsedPercentage}
              aria-valuemin='0'
              aria-valuemax={Number(maxStorage)}
            ></div>
          </div>
          <P style={{ color: '#fff' }}>{`${usedStorage} de ${maxStorage} GB usados`}</P>
        </div> 
      </LI>
    </UL>
  )
}