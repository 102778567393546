import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, Btn, H5 } from "../../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Row, Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Card, CardHeader, Container } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useParams } from "react-router";
import { API } from "../../../../../api/API/api";
import axios from "axios";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';


// ... rest of your code



const CompanyEdit2 = () => {
    const history = useNavigate();

    const [user, setUser] = useState({});
    const [users, setUsers] = useState([]);
    const [company, setCompany] = useState({});
    const { companyId } = useParams();
    const token = localStorage.getItem("token");
    const { handleSubmit, formState: { errors } } = useForm();
    const [cidades, setCidades] = useState([]);
    const userId = localStorage.getItem("user_id");

    const onCreateSubmit = async () => {
        const companyData = {
            "cargo_criador": company.cargo_criador || "",
            "razao_social": company.razao_social || "",
            "nome_fantasia": company.nome_fantasia || "",
            "cnpj": company.cnpj || "",
            "insc_estadual": company.insc_estadual || "",
            "insc_municipal": company.insc_municipal || "",
            "telefone": company.telefone || "",
            "email": company.email || "",
            "endereco": company.endereco || "",
            "numero": company.numero || "",
            "complemento": company.complemento || "",
            "bairro": company.bairro || "",
            "cep": company.cep || "",
            "cidade": company.cidade || "",
            "estado": company.estado || "",
            "pais": company.pais || "",
            "created_by": company.created_by
        };

        try {
            await API.put("/backoffice/companies/" + companyId, companyData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            });
            toast.success("Empresa Editada com Sucesso");
            history('/backoffice/clientes-pj'); // Change this line to the correct usage based on your setup

        } catch (error) {
            console.log(error);
        }
    };


    const inputChange = (key, value) => {
        setCompany({ ...company, [key]: value });
    };
    const [ufs, setUfs] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedUf, setSelectedUf] = useState("0");
    const [selectedCity, setSelectedCity] = useState("0");

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/");
                const sortedStates = response.data.sort((a, b) => a.nome.localeCompare(b.nome));
                setUfs(sortedStates);
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };

        fetchStates();
    }, []); // Empty dependency array to run oncecy array to run once

    useEffect(() => {
        if (selectedUf === "0") {
            return;
        }

        const fetchCities = async () => {
            try {
                const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`);
                
                // Sort cities alphabetically by name
                const sortedCities = response.data.sort((a, b) => a.nome.localeCompare(b.nome));
        
                setCities(sortedCities);
            } catch (error) {
                console.error("Error fetching cities:", error);
            }
        };

        fetchCities();
    }, [selectedUf]);

    function handleSelectUf(event) {
        const uf = event.target.value;
        inputChange('estado', uf);

        setSelectedUf(uf);
    }

    function handleSelectCity(event) {
        const city = event.target.value;
        setSelectedCity(city);
    }

    useEffect(() => {
        const fetchCidades = async () => {
            try {
                const response = await axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/municipios/");
                const data = response.data;
                setCidades(data);
            } catch (error) {
                console.error("Error fetching cidades:", error);
            }
        };

        const getUser = async () => {
            try {
                let userId = companyId || localStorage.getItem("user_id");

                if (!userId) {
                    console.log("User ID not found");
                    return;
                }

                const response = await API.get(`backoffice/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const data = response.data;
                setUser(data);
            } catch (error) {
                console.error("Error fetching user:", error);
            }
        };
        const getUsers = async () => {
            try {
            

                const response = await API.get(`backoffice/users`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const data = response.data;
                setUsers(data);
            } catch (error) {
                console.error("Falha  ao buscar  lista de usuarios:", error);
            }
        };
        const getCompany = async () => {
            try {

                const response = await API.get(`backoffice/companies/${companyId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const data = response.data;
                setCompany(data);
            } catch (error) {
                console.error("Error fetching user:", error);
            }
        };
        fetchCidades();
        getUser();
        getUsers();
        getCompany();
    }, [companyId, token]); // Add dependencies to the dependency array if needed

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Criar Empresa" parent="Produtos" title="Criar Empresa" />
            <Container fluid={true}>
                <Form className="card" onSubmit={handleSubmit(onCreateSubmit)}>
                    <CardHeader>
                        <H5 attrH5={{ className: "card-title p-b-0 p-t-0" }}>{"Informações da Empresa"}</H5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                        <Col md="3">
                                <FormGroup>
                                    <Label className="form-label">Criador</Label>
                                    <Input
                                        className="form-control"
                                        type="select"
                                        value={company.created_by}
                                        onChange={(e) => inputChange('created_by', e.target.value)}
                                    >
                                       <option value="">Selecione</option>
                                        {users.map((user) => (
                                            <option key={user.id} value={user.id}>
                                                {user.name}
                                            </option>
                                        ))}
                                        </Input>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label className="form-label">Cargo do Criador</Label>
                                    <InputMask
                                        className="form-control"
                                        mask=""
                                        value={company.cargo_criador}
                                        onChange={(e) => inputChange('cargo_criador', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>


                            <Col md="6">
                                <FormGroup>
                                    <Label className="form-label">Razão Social</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={company.razao_social}
                                        onChange={(e) => inputChange('razao_social', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="form-label">Nome Fantasia</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={company.nome_fantasia}
                                        onChange={(e) => inputChange('nome_fantasia', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={company.email}
                                        onChange={(e) => inputChange('email', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="form-label">Telefone</Label>
                                    <InputMask
                                        className="form-control"
                                        id="telefone"
                                        mask={company.telefone && company.telefone.length === 10 ? "(99) 9999-9999" : "(99) 99999-9999"}
                                        value={company.telefone}
                                        onChange={(e) => inputChange('telefone', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="form-label">CNPJ</Label>
                                    <InputMask
                                        className="form-control"
                                        mask="99.999.999/9999-99"
                                        value={company.cnpj}
                                        onChange={(e) => inputChange('cnpj', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="form-label">Insc Municipal</Label>
                                    <InputMask
                                        className="form-control"
                                        mask="9999999999"
                                        value={company.insc_municipal}
                                        onChange={(e) => inputChange('insc_municipal', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="4">
                                <FormGroup>
                                    <Label className="form-label">Insc Estadual</Label>
                                    <InputMask
                                        className="form-control"
                                        mask="999.999.999.999"
                                        value={company.insc_estadual}
                                        onChange={(e) => inputChange('insc_estadual', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <CardHeader>
                                <H5 attrH5={{ className: "card-title p-b-0 p-t-0" }}>{"Endereço"}</H5>
                            </CardHeader>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="form-label">CEP</Label>
                                    <InputMask
                                        className="form-control"
                                        mask="99999-999"
                                        value={company.cep}
                                        onChange={(e) => inputChange('cep', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="form-label">Logradouro</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={company.endereco}
                                        onChange={(e) => inputChange('endereco', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label className="form-label">Número</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={company.numero}
                                        onChange={(e) => inputChange('numero', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <Label className="form-label">Complemento</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={company.complemento}
                                        onChange={(e) => inputChange('complemento', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label className="form-label">Bairro</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={company.bairro}
                                        onChange={(e) => inputChange('bairro', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>


                            <Col md="3">
                                <FormGroup>
                                    <Label className="form-label">Estado</Label>
                                    <Input type="select" name="select" value={company.estado} onChange={handleSelectUf} className="form-control btn-square digits">
                                        <option value="">Selecione</option>
                                        {ufs.map((uf) => (
                                            <option key={uf.id} value={uf.sigla}>
                                                {uf.nome}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            
                            <Col md="3">
                                <FormGroup>
                                    <Label className="form-label">Cidade</Label>
                                    <Input
                                        className="form-control"
                                        type="select"
                                        value={company.cidade}
                                        onChange={(e) => inputChange('cidade', e.target.value)}
                                    >
                                        <option value="">Selecione</option>
                                        {cities.map((city) => (
                                            <option key={city.id} value={city.nome}>
                                                {city.nome}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label className="form-label">País</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={company.pais}
                                        onChange={(e) => inputChange('pais', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <CardFooter className="text-end">
                            <Btn attrBtn={{ color: "primary", outline: true, type: "submit" }}>{"Salvar alterações"}</Btn>
                        </CardFooter>
                    </CardBody>

                </Form>
            </Container>
        </Fragment>
    );
};

export default CompanyEdit2;
