import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

export function ModalDelete({ isOpen, setIsOpen, onSubmit }) {
    const closeModal = () => setIsOpen(false)

    return (
        <Modal isOpen={isOpen} toggle={closeModal} centered>
            <ModalHeader toggle={closeModal}>Confirmar exclusão</ModalHeader>

            <ModalBody>
                <p>Tem certeza de que deseja excluir?</p>
            </ModalBody>

            <ModalFooter>
                <Button color='primary' onClick={closeModal}>Cancelar</Button>
                <Button color='danger' onClick={onSubmit}>Excluir</Button> 
            </ModalFooter>
        </Modal>
    )
}
