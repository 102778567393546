import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Input, Button } from 'reactstrap'
import { Card, Table } from 'reactstrap'

import { API } from '../../../../../../api/API/api'

export default function Vimeo() {
    const { tenant_id } = useParams()
    const [data, setData] = useState([])

    const apiPath = `/tenants/${tenant_id}/management/course_program/videos`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])


    const uploadVideo = async (e) => {
        try {
            const video = e.target.files[0]

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }

            const form = new FormData()
            form.append('video', video)

            const response = await API.post(apiPath, form, config)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }
    }

    const copyUrl = url => {
        navigator.clipboard.writeText(url)
        toast.success('Copiado')
    }


    const deleteVideo = async (video_id) => {
        try {
            const response = await API.delete(`${apiPath}/${video_id.toString()}`)
            setData(response.data)
            toast.success('Sucesso!')

        } catch (error) {
            console.log(error)
            toast.error('Error')
        }
    }

    return (
        <>
            <Card className='col-12 p-20'>
                <Form>
                    <FormGroup>
                        <Input type='file' name='video' onChange={uploadVideo} accept="video/*" />
                    </FormGroup>
                </Form>

                <div className='card-header'>
                    <h5 className='card-title p-b-0 p-t-0'>Vídeo Aulas</h5>
                </div>
                <Table borderless>
                    <thead>
                        <tr>
                            <th>Vídeo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((video, index) => (
                            <tr key={index}>
                                <td className='align-middle'>{video.title}</td>
                                <td>
                                    <div className='buttonsCard'>

                                        <Button color='primary' outline={true} onClick={() => copyUrl(video.url)}>
                                            <i className='fa fa-copy' />
                                        </Button>

                                        <Button color='secondary' outline={true} onClick={() => deleteVideo(video.id)}>
                                            <i className='fa fa-trash-o fa-lg' />
                                        </Button>

                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card>
        </>
    )
}

