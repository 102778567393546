import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { API } from '../../../../api/API/api'

import { Navbar } from '../_Commons/Navbar/Index'
import Layout from "../Layout"
import General from "./Tabs/General"
import Settings from "./Tabs/Home/Settings"
import Posts from "./Tabs/Home/Posts"
import Courses from "./Tabs/Home/Courses"
import Banners from "./Tabs/Home/Banners"
import PostsRecent from "./Tabs/Home/PostsRecent"
import MenuSettings from "./Tabs/Menus/Settings"
import MenuPages from "./Tabs/Menus/Pages"
import MenusAdministrative from "./Tabs/Menus/Administrative"
import Pages from "./Tabs/Pages"
import Categories from "./Tabs/ContentManager/Categories"
import Articles from "./Tabs/ContentManager/Articles"
import Campaigns from "./Tabs/ContentManager/Campaigns"
import BannerTop from "./Tabs/BannerTop"
import Attests from "./Tabs/Attests"
import Slides from "./Tabs/Slides/Index"
import SlidesSettings from "./Tabs/Slides/Settings"
import Lectures from "./Tabs/Lectures"
import Partners from "./Tabs/Partners"
import Faqs from "./Tabs/Faqs/Index"
import FaqCategories from "./Tabs/Faqs/Categories"

const items = [
  {
    id: 'general',
    label: 'Geral',
    tabs: [
      {
        label: 'Geral',
        component: General,
      }
    ],
  },
  {
    id: 'homepage',
    label: 'Página Inicial',
    tabs: [
      {
        label: 'Configuração',
        component: Settings
      },
      {
        label: 'Posts em Destaque',
        component: Posts
      },
      {
        label: 'Banners',
        component: Banners
      },
      {
        label: 'Cursos',
        component: Courses
      },
      {
        label: 'Posts Recentes',
        component: PostsRecent
      }
    ],
  },
  {
    id: 'menus',
    label: 'Menus',
    tabs: [
      {
        label: 'Configuração',
        component: MenuSettings
      },
      {
        label: 'Páginas',
        component: MenuPages
      },
      {
        label: 'Administrativo',
        component: MenusAdministrative
      },
    ],
  },
  {
    id: 'pages',
    label: 'Páginas',
    tabs: [
      {
        label: 'Páginas',
        component: Pages
      }
    ],
  },
  {
    id: 'campaigns',
    label: 'Gerenciador de Conteúdo',
    tabs: [
      {
        label: 'Artigos',
        component: Articles
      },
      {
        label: 'Categorias',
        component: Categories
      },
      {
        label: 'Campanhas',
        component: Campaigns
      }
    ],
  },
  {
    id: 'bannertop',
    label: 'Banner Topo',
    tabs: [
      {
        label: 'Banner Topo',
        component: BannerTop
      }
    ],
  },
  {
    id: 'attests',
    label: 'Depoimentos',
    tabs: [
      {
        label: 'Depoimentos',
        component: Attests
      }
    ],
  },
  {
    id: 'slides',
    label: 'Slides',
    tabs: [
      {
        label: 'Slides',
        component: Slides
      },
      {
        label: 'Configuraçōes',
        component: SlidesSettings
      }
    ],
  },
  {
    id: 'lectures',
    label: 'Palestras',
    tabs: [
      {
        label: 'Palestras',
        component: Lectures
      }
    ],
  },
  {
    id: 'partners',
    label: 'Parceiros',
    tabs: [
      {
        label: 'Parceiros',
        component: Partners
      }
    ],
  },
  {
    id: 'faqs',
    label: 'Faq',
    tabs: [
      {
        label: 'Faqs',
        component: Faqs
      },
      {
        label: 'Categorias',
        component: FaqCategories
      }
    ],
  }
]

export default function CMS() {
  const { tenant_id } = useParams()

  const [formValues, setFormValues] = useState({
    tenant: {
      name: ''
    }
  })

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(`/tenants/${tenant_id}/management/general`)
        setFormValues(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [tenant_id])

  return (
    <Fragment>
      <Layout id="cms" site_name={formValues.tenant.name}>
        <Navbar items={items} />
      </Layout>
    </Fragment>
  )
}
