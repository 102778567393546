import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from "react-toastify";
import { FaCalendarAlt } from "react-icons/fa"
import { Card, Row, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Accordion, AccordionBody, AccordionHeader, AccordionItem, Alert } from 'reactstrap'
import { H6 } from '../../../../../../AbstractElements'

import { API } from '../../../../../../api/API/api';
import '../../../_Commons/Styles/Styles.scss';

const formDataDefault = {
    description_card: '',
    max_recurring_installments: '',
    max_installments_card: '',
    max_installments_ticket: '',
    minimum_value: '',
    ticket_due_date: '',
    days_block: '',
    days_block_extension: '',
    scholarship: '',
    ticket: '',
    credit_card: '',
    recurrent: '',
    number_attempts: '',
    retry_interval: '',
    self_renegotiation: '',
    self_renegotiation_penalty: '',
    self_renegotiation_interest: '',
    special_charge: '',
    special_charge_number_refuse_enable: '',
    special_charge_usage_limit: '',
    ctc_status: '',
    ctc_only_defaulters: '',
    ctc_min_days_defaulting: '',
    ctc_off: '',
    ctc_late: '',
    anticipate_pay: '',
    anticipate_pay_off: '',
    card_check: ''
}

const payment_installments = Array.from({ length: 47 }, (_, i) => i + 2)
const max_installments_card = Array.from({ length: 11 }, (_, i) => i + 2)
const max_installments_ticket = Array.from({ length: 17 }, (_, i) => i + 2)

const ctc_lates = [
    {text: 'Somente pagamento único', option: 'single'},
    {text: 'Somente diluir débito nas parcelas futuras', option: 'diluted'},
    {text: 'Opção do aluno', option: 'all'},
    {text: 'Desabilitar (o aluno será orientado a entrar em contato)', option: 'disabled'}
]

export default function General() {
    const { tenant_id } = useParams();
    const [formData, setFormData] = useState(formDataDefault);

    const [open, setOpen] = useState(1);
    const toggle = (id) => open === id ? setOpen() : setOpen(id)
  
    const apiPath = `/tenants/${tenant_id}/management/payments/general`
  
    useEffect(() => {
      async function getData() {
        try {
          const response = await API.get(apiPath);
          
          console.log(response.data.max_recurring_installments)
          
          setFormData(response.data);
        } catch (error) {
          console.log(error);
        }
      }
      getData();
    }, [tenant_id]);
  
  
    const handleInputChange = (e) => {
      let { name, value } = e.target

      value = ['ctc_off', 'anticipate_pay_off'].includes(name) ? (value/100).toString() : value

      if (e.currentTarget.type === 'checkbox') {
        value = formData[name] === '1' ? '0' : '1'
      }
  
      setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
      }))
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await API.put(apiPath, formData);
          toast.success('Sucesso!')
  
      } catch (error) {
        console.log(error);
        toast.error('Error')
      }
    };
  
    return (
      <>
          <Card className='col-12' style={{ marginBottom: "8.5em" }}>
            <div className="card-header">
              <h5 className="card-title p-b-0 p-t-0">Configurações de Pagamentos Gerais</h5>
            </div>
            <Form>
              <Row className="m-4">
                  <Col md="12" className="mb-3">
                      <H6>Descrição na fatura:</H6>
                      <FormGroup>
                      <Input type="text" name="description_card" value={formData.description_card} onChange={handleInputChange}/>
                      </FormGroup>
                  </Col>

                    <Col md="12" className="mb-3">
                        <H6>Parcelamento cartão recorrente:</H6>
                        <FormGroup>

                        <Input type='select' name='max_recurring_installments' id='max_recurring_installments' onChange={handleInputChange}>
                            <option value={0} selected={formData.max_recurring_installments === '0'}>
                                Desabilitado
                            </option>

                            {payment_installments.map((i) => (
                                <option key={i} value={i} selected={i == formData.max_recurring_installments}>
                                    {i} Vezes
                                </option>
                            ))}
                        </Input>
                        </FormGroup>
                    </Col>

                    <Col md="12" className="mb-3">
                      <H6>Parcelamento cartão:</H6>
                      <FormGroup>

                        <Input type='select' name='max_installments_card' id='max_installments_card' onChange={handleInputChange}>
                            <option value={1} selected={formData.max_installments_card === '1'}>
                                1 Vez
                            </option>

                            {max_installments_card.map((i) => (
                                <option key={i} value={i} selected={i == formData.max_installments_card}>
                                    {i} Vezes
                                </option>
                            ))}
                        </Input>
                      </FormGroup>
                  </Col>

                    <Col md="12" className="mb-3">
                    <H6>Parcelamento boleto:</H6>
                    <FormGroup>

                        <Input type='select' name='max_installments_ticket' id='max_installments_ticket' onChange={handleInputChange}>
                            <option value={1} selected={formData.max_installments_ticket === '1'}>
                                1 Vez
                            </option>

                            {max_installments_ticket.map((i) => (
                                <option key={i} value={i} selected={i == formData.max_installments_ticket}>
                                    {i} Vezes
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    </Col>

                  <Col md="12" className="mb-3">
                      <H6>Valor mínimo da parcela:</H6>
                      <FormGroup>
                        <InputGroup>
                            <InputGroupText>R$</InputGroupText>
                            <Input type="number" name="minimum_value" value={formData.minimum_value} onChange={handleInputChange}/>
                        </InputGroup>
                      </FormGroup>
                  </Col>

                    <Col md="12" className="mb-3">
                        <H6>Número dias vencimento boleto:</H6>
                        <FormGroup>
                        <InputGroup>
                            <InputGroupText><FaCalendarAlt /></InputGroupText>
                            <Input type="number" name="ticket_due_date" value={formData.ticket_due_date} onChange={handleInputChange}/>
                        </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col md="12" className="mb-3">
                        <H6>Número dias bloquear curso:</H6>
                        <FormGroup>
                        <InputGroup>
                            <InputGroupText><FaCalendarAlt /></InputGroupText>
                            <Input type="number" name="days_block" value={formData.days_block} onChange={handleInputChange}/>
                        </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col md="12" className="mb-3">
                        <H6>Número dias bloquear extensão do curso:</H6>
                        <FormGroup>
                        <InputGroup>
                            <InputGroupText><FaCalendarAlt /></InputGroupText>
                            <Input type="number" name="days_block_extension" value={formData.days_block_extension} onChange={handleInputChange}/>
                        </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col md="3" className="mb-3">
                        <H6>Habilitar solicitação de bolsa:</H6>
                        <FormGroup switch>
                          <Input type='switch' name='scholarship' onChange={handleInputChange} checked={formData.scholarship === '1'} />
                        </FormGroup>
                    </Col>

                    <Col md="3" className="mb-3">
                        <H6>Aceitar boleto bancário:</H6>
                        <FormGroup switch>
                        <Input type='switch' name='ticket' onChange={handleInputChange} checked={formData.ticket === '1'} />
                        </FormGroup>
                    </Col>

                    <Col md="3" className="mb-3">
                        <H6>Aceitar cartão de crédito:</H6>
                        <FormGroup switch>
                        <Input type='switch' name='credit_card' onChange={handleInputChange} checked={formData.credit_card === '1'} />
                        </FormGroup>
                    </Col>

                    <Col md="3" className="mb-3">
                        <H6>Aceitar cartão de crédito recorrente:</H6>
                        <FormGroup switch>
                        <Input type='switch' name='recurrent' onChange={handleInputChange} checked={formData.recurrent === '1'} />
                        </FormGroup>
                    </Col>

                    <Col md="12" className="mb-3">
                        <Accordion open={open} toggle={toggle}>
                            <AccordionItem>
                                <AccordionHeader targetId="1">Cobrança recorrente</AccordionHeader>
                                <AccordionBody accordionId="1">
                                    <H6>Quantidade de tentativas após recusado:</H6>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupText><FaCalendarAlt /></InputGroupText>
                                            <Input type="number" name="number_attempts" value={formData.number_attempts} onChange={handleInputChange}/>
                                        </InputGroup>
                                    </FormGroup>

                                    <H6>Intervalo entre tentativas (em dias):</H6>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupText><FaCalendarAlt /></InputGroupText>
                                            <Input type="number" name="retry_interval" value={formData.retry_interval} onChange={handleInputChange}/>
                                        </InputGroup>
                                    </FormGroup>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="2">Auto acordo</AccordionHeader>
                                <AccordionBody accordionId="2">
                                    <Alert color="primary">O auto acordo permite o próprio aluno renegociar débitos utilizando cartão de crédito.</Alert>
                                    
                                    <H6>Habilitar:</H6>
                                    <FormGroup switch>
                                        <Input type='switch' name='self_renegotiation' onChange={handleInputChange} checked={formData.self_renegotiation === '1'} />
                                    </FormGroup>

                                    <H6>Multa:</H6>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupText>%</InputGroupText>
                                            <Input type="number" name="self_renegotiation_penalty" value={formData.self_renegotiation_penalty} onChange={handleInputChange}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <H6>Juros (ao mês):</H6>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupText>%</InputGroupText>
                                            <Input type="number" name="self_renegotiation_interest" value={formData.self_renegotiation_interest} onChange={handleInputChange}/>
                                        </InputGroup>
                                    </FormGroup>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="3">Cobrança especial</AccordionHeader>
                                <AccordionBody accordionId="3">
                                <Alert color="primary">A cobrança especial, permite oferecer ao aluno o pagamento de uma ou mais parcelas no boleto bancário após uma quantidade especifica de tentativas de pagamentos recusadas. Você poderá limitar o uso desse recurso por matricula.</Alert>
                                    <H6>Habilitar:</H6>
                                    <FormGroup switch>
                                        <Input type='switch' name='special_charge' onChange={handleInputChange} checked={formData.special_charge === '1'} />
                                    </FormGroup>

                                    <H6>Quantidade de recusas para habilitar este recurso:</H6>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupText><FaCalendarAlt/></InputGroupText>
                                            <Input type="number" name="special_charge_number_refuse_enable" value={formData.special_charge_number_refuse_enable} onChange={handleInputChange}/>
                                        </InputGroup>
                                    </FormGroup>
                                    <H6>Limite de uso por matrícula:</H6>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupText><FaCalendarAlt/></InputGroupText>
                                            <Input type="number" name="special_charge_usage_limit" value={formData.special_charge_usage_limit} onChange={handleInputChange}/>
                                        </InputGroup>
                                    </FormGroup>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="4">Troca de meio de pagamento para cartão de crédito recorrente</AccordionHeader>
                                <AccordionBody accordionId="4">
                                    <H6>Habilitar:</H6>
                                    <FormGroup switch>
                                        <Input type='switch' name='ctc_status' onChange={handleInputChange} checked={formData.ctc_status === '1'} />
                                    </FormGroup>
                                    <H6>Habilitar apenas para inadimplentes:</H6>
                                    <FormGroup switch>
                                        <Input type='switch' name='ctc_only_defaulters' onChange={handleInputChange} checked={formData.ctc_only_defaulters === '1'} />
                                    </FormGroup>

                                    <H6>Mínimo de dias inadimplente:</H6>
                                    <FormGroup>
                                        <Input type="number" name="ctc_min_days_defaulting" value={formData.ctc_min_days_defaulting} onChange={handleInputChange}/>
                                    </FormGroup>

                                    <H6>Desconto de incentivo à troca de meio de pagamento:</H6>
                                    <FormGroup>
                                        <Input type="number" name="ctc_off" value={formData.ctc_off*100} onChange={handleInputChange}/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Input type='select' name='ctc_late' id='ctc_late' onChange={handleInputChange}>
                                            {ctc_lates.map((i, index) => (
                                                <option key={index} value={i.option} selected={i.option == formData.ctc_late}>
                                                    {i.text}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="5">Antecipação total de pagamento</AccordionHeader>
                                <AccordionBody accordionId="5">
                                    <H6>Habilitar:</H6>
                                    <FormGroup switch>
                                        <Input type='switch' name='anticipate_pay' onChange={handleInputChange} checked={formData.anticipate_pay === '1'} />
                                    </FormGroup>
                                    
                                    <H6>Desconto de incentivo à antecipação:</H6>
                                    <FormGroup>
                                        <Input type="number" name="anticipate_pay_off" value={formData.anticipate_pay_off*100} onChange={handleInputChange}/>
                                    </FormGroup>
                                </AccordionBody>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionHeader targetId="6">Verificação de cartão de crédito</AccordionHeader>
                                <AccordionBody accordionId="6">
                                    <H6>Habilitar:</H6>
                                    <FormGroup switch>
                                        <Input type='switch' name='card_check' onChange={handleInputChange} checked={formData.card_check === '1'} />
                                    </FormGroup>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </Col>
              </Row>
              
              <Row className="text-right rowCustom">
                <Col className="text-right">
                  <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                    Salvar alterações
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
      </>
    );
  }