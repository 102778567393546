import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Btn } from '../../../../AbstractElements';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { API } from '../../../../api/API/api';
import { toast } from 'react-toastify';
import CustomizerContext from '../../../../_helper/Customizer';
import { CountryMenu, Email, Address, StateCountry, TownCity, PostalCode, PlaceOrder } from '../../../../Constant';

const CheckoutTableData = () => {
    const { layoutURL } = useContext(CustomizerContext);
    const history = useNavigate();
    const { ordemId } = useParams();
    const [ordem, setOrdem] = useState({});
    const token = localStorage.getItem('token');
    const { register, formState: { errors }, handleSubmit } = useForm();

    useEffect(() => {
        const ordemServicoId = ordemId;
        console.log(ordemServicoId);
        if (isNaN(ordemServicoId)) {
            console.error("Invalid ordemServicoId:", ordemServicoId);
            return null; // or handle it appropriately
        }

        const fetchOrder = async () => {
            try {
                const response = await API.get(`/ordens_servico/${ordemServicoId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const data = response.data;
                setOrdem(data);
            } catch (error) {
                console.error("Error fetching order:", error);
            }
        };

        fetchOrder();
    }, [ordemId, token]);

    const onSubmit = async (data) => {
        try {
            const cliente = {
                nome: data.firstName + ' ' + data.lastName,
                telefone: data.phone,
                email: data.email,
                pais: data.country,
                endereco: data.address,
                estado: data.state,
                cep: data.pincode,
            };
            const ordemServicoId = ordemId;

            const objeto_submitted = {
                email_do_cliente: cliente.email,
                ordem_servico_id: ordemServicoId,
                currency: 'brl'
            };

            const queryString = Object.entries(objeto_submitted)
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&');

            console.log("Request Payload:", objeto_submitted);

            const postData = await API.post(`/stripe/criar_fatura?${queryString}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success("Ordem de Pagamento Efetuado com Sucesso");

            const linkPagamento = await API.get(`/stripe/buscar_fatura_por_id?invoice_id=${ordem?.stripe_pagamento_id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const link = linkPagamento['data']['fatura']['hosted_invoice_url'];
            window.location.href=link;
        } catch (error) {
            console.error("Error submitting data:", error);
            toast.error("Error submitting data. Please try again.");
        }
    }
    return (
        <Fragment>
            <Col xl="6" sm="12">
                <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation" >
                    <Row>
                        <FormGroup className="mb-3 col-sm-6">
                            <Label>Nome</Label>
                            <input className="form-control" type="text" name="firstName" {...register('firstName', { required: true })} />
                            <span style={{ color: '#ff5370' }}>{errors.firstName && 'First name is required'}</span>
                        </FormGroup>
                        <FormGroup className="mb-3 col-sm-6">
                            <Label>Sobrenome</Label>
                            <input className="form-control" type="text" name="lastName" {...register('lastName', { required: true })} />
                            <span style={{ color: '#ff5370' }}>{errors.lastName && 'Last name is required'}</span>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="mb-3 col-sm-6">
                            <Label>Telefone</Label>
                            <input className="form-control" type="text" name="phone" {...register('phone', { required: true })} />
                            <span style={{ color: '#ff5370' }}>{errors.phone && 'Please enter number for phone.'}</span>
                        </FormGroup>
                        <FormGroup className="mb-3 col-sm-6">
                            <Label>País</Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={{ value: '', label: 'Selecione um país' }}
                                isSearchable
                                name="selectMulti"
                                options={CountryMenu.map((country) => ({ value: country, label: country }))}
                            />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="mb-3 col-sm-6">
                            <Label for="inputEmail">{Email}</Label>
                            <input className="form-control" type="text" name="email" {...register('email', { required: true, min: 20, max: 120 })} />
                            <span style={{ color: '#ff5370' }}>{errors.email && 'Please right your email .'}</span>
                        </FormGroup>
                        <FormGroup className="mb-3 col-sm-6">
                            <Label for="inputAddress">{Address}</Label>
                            <input className="form-control" type="text" name="address" {...register('address', { required: true, min: 20, max: 120 })} />
                            <span style={{ color: '#ff5370' }}>{errors.address && 'Please right your address .'}</span>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="mb-3 col-sm-6">
                            <Label for="inputCity">{TownCity}</Label>
                            <input className="form-control" type="text" name="city" {...register('city', { required: true })} />
                            <span style={{ color: '#ff5370' }}>{errors.city && 'select one city'}</span>
                        </FormGroup>
                        <FormGroup className="mb-3 col-sm-6">
                            <Label for="inputAddress2">{StateCountry}</Label>
                            <input className="form-control" type="text" name="state" {...register('state', { required: true })} />
                            <span style={{ color: '#ff5370' }}>{errors.state && 'select one state'}</span>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="mb-3 col-sm-6">
                            <Label for="inputAddress6">{PostalCode}</Label>
                            <input className="form-control" type="text" name="pincode" {...register('pincode', { pattern: /\d+/ })} />
                            <span style={{ color: '#ff5370' }}>{errors.pincode && 'Required integer'}</span>
                        </FormGroup>
                    </Row>
                    {/* <FormGroup className="mb-3">
                        <div className="form-check">
                            <Input type="checkbox" className="form-check-input checkbox_animated" />
                            <Label>{CheckMeOut}</Label>
                        </div>
                    </FormGroup> */}
                    <div>
                        <div className="text-end"><Btn attrBtn={{ type: 'submit', color: 'primary' }}>{PlaceOrder}</Btn> </div>
                    </div>
                </Form>
            </Col>
        </Fragment>
    );
};

export default CheckoutTableData;
