import React, { useState } from 'react'
import { FormGroup, Label, Input, InputGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

export function Dropdown({ id, name, label, onClick, selectedItems, items, itemNameKey = 'name'}) {
    const [open, setOpen] = useState(false)
    const toggle = () =>  setOpen((prevState) => !prevState)

    return (
        <FormGroup>
            <Label for={id}>{label}:</Label>

            <InputGroup>
                <ButtonDropdown id={id} isOpen={open} toggle={toggle}>
                    <DropdownToggle split color="primary" />

                    <DropdownMenu>
                        {items.map((item, index) => (
                            <DropdownItem key={`${label} ${index}`} name={name} value={JSON.stringify(item)} onClick={onClick}>
                                {item[itemNameKey]}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </ButtonDropdown>
                <Input type='textarea' value={selectedItems} disabled />
            </InputGroup>
        </FormGroup>
    )
}
