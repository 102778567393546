import React from 'react'
import { NavItem, NavLink } from 'reactstrap'
import './Hover.scss';

export default function Item({ item, active, onClick }) {
    
    return (
        <>
            <NavItem>
                <NavLink className='hover' active={active} onClick={() => onClick(item.id, item.tabs[0].label)}>
                    {item.tabs[0].label}
                </NavLink>
            </NavItem>
        </>
    )
}
