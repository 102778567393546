import React, { Fragment, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import H3 from '../Headings/H3Element';
import CustomizerContext from '../../_helper/Customizer';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

const Breadcrumbs = (props) => {
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <Fragment>
      <div className='page-title' style={{ backgroundColor: 'black!important', width: '100vw' }}>
        <Row style={{ marginTop: '3.4em', marginLeft: '-4.5em', background: 'white', padding: '15px' }}>
          <Col xs='5' style={{ marginLeft: '25px' }}>
            <H3 >{props.mainTitle}</H3>
          </Col> 
          <Col xs='6'>
            <ol className='breadcrumb' style={{ marginRight: '15px' }}>
              <li className='breadcrumb-item'>
                <Link to={`/admin`}>
                  <SvgIcon iconId='stroke-home' />
                </Link>
              </li>
              <li className='breadcrumb-item'><a style={{ color: "#2c323f" }} href={`/admin/${props.parent}`}>{props.parent}</a></li>
              {
                props.subParent
                ? <li className='breadcrumb-item'>
                    <a href={`/admin/${props.subParent}`} style={{ color: "#2c323f" }}>{props.subParent}</a>
                  </li>
                : ''
              }
              {
                props.thirdRelative
                ? <li className='breadcrumb-item'>
                    <a href={`/admin/${props.thirdRelative}`} style={{ color: "#2c323f" }}>{props.thirdRelative}</a>
                  </li>
                : ''
              }
              <li className='breadcrumb-item active'>{props.title}</li>
            </ol>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Breadcrumbs;
