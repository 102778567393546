import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input, InputGroup, InputGroupText, Button } from 'reactstrap'
import { FaArrowsRotate } from 'react-icons/fa6'

import { Table } from '../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../_Commons/Modal/Delete'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    name: '',
    description: '',
    url: '', 
    invite_token: '',
    student_limit: null
  }

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
const genInviteToken = () => Array.from({ length: 15 }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('')
const isNull = (value) => value === null

export default function Classes() {
  const { tenant_id } = useParams()
  const [data, setData] = useState({
    columns: [],
    rows: [],
    payloads: []
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
  
  const setTableData = (payloads) => setData({
        columns: ['Nome'],
        rows: payloads.map(item => [item.name]),
        payloads: payloads
    })

  const apiPath = `/tenants/${tenant_id}/management/classes`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setTableData(response.data)

      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, data) => {
    data.invite_token = isEditing ? data.invite_token : genInviteToken()

    setIsEditing(isEditing)
    setFormData(data)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    value = value === 'on' ? e.target.getAttribute('data-value') : value
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleModalUrlButton = () => {
    const invite_token = genInviteToken()
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      invite_token: invite_token,
      url: prevFormData.url.replace(prevFormData.invite_token, invite_token)
    }))
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setTableData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setTableData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  return (
    <>
        <Table 
            header='Turmas' data={data} 
            onNewClick={() => openModal(false, formDataDefault)} 
            onEditClick={(payload) => openModal(true, payload)}
            onDeleteClick={(payload) => openModalDelete(payload)}
        />

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Turma' : 'Criar Turma'} onSubmit={() => submitModalForm()} >
        <Form>
          <FormGroup>
            <Label for='name'>Nome:</Label>
            <Input type='text' name='name' id='name' value={formData.name} onChange={handleModalInputChange} required />
          </FormGroup>

          <FormGroup>
            <Label for='description'>Descrição da turma:</Label>
            <Input type='textarea' name='description' id='description' value={formData.description} onChange={handleModalInputChange} required />
          </FormGroup>

          {isEditing &&
            <FormGroup>
                <Label for='description'>Link de convite:</Label>
                <InputGroup>
                    <Input type='text' name='description' id='description' value={formData.url} disabled />
                    <Button color='primary' onClick={handleModalUrlButton}><FaArrowsRotate /></Button>
                </InputGroup>
            </FormGroup>
          }

          <FormGroup>
            <Label for='student_limit'>Limite de alunos:</Label>
            <InputGroup>
                <InputGroupText>
                    <Input addon type="checkbox" name='student_limit' data-value={isNull(formData.student_limit) ? 1 : null} onChange={handleModalInputChange} />
                </InputGroupText>
                <Input type='number' min='1' name='student_limit' id='student_limit' value={isNull(formData.student_limit) ? '' : formData.student_limit} disabled={isNull(formData.student_limit)} placeholder={isNull(formData.student_limit) ? 'Ilimitado' : ''} onChange={handleModalInputChange} />
            </InputGroup>
        </FormGroup>
          
        </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

