import axios from "axios";
import { getToken } from "./auth";

const apiUrl = process.env.REACT_APP_API_URL || 'https://api.tutorcasts.app/api'

export const API =  axios.create({
    baseURL: apiUrl, 
    }
)
export const API2 =  axios.create({
  baseURL: apiUrl, 
  }
)

API.interceptors.request.use(async config => {
    const token = getToken()
    if (token)
        config.headers.Authorization = `Bearer ${token}`

    return config
})

API.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        if( window.location.pathname !== '/admin/login') {
            window.location.href = '/admin/login'
        }
        console.log(error.response)
    }
    return Promise.reject(error);
});

export async function verifyToken(token){
  try {
    const response = await axios.get(`${apiUrl}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      // O token é válido, o servidor respondeu com sucesso
      return true;
    } else {
      // O token é inválido ou expirou, o servidor respondeu com erro
      return false;
    }
  } catch (error) {
    // Algum erro ocorreu durante a requisição (ex: servidor fora do ar)
    console.error('Erro ao verificar token:', error);
    return false;
  }
}
