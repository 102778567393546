import { Form, FormGroup, Label, Input, InputGroup, InputGroupText, Row, Col } from 'reactstrap'
import { Image } from '../../../../../_Commons/Inputs/Image'
import { Editor } from '../../../../../_Commons/Inputs/Editor'

export const CourseCertificate = ({ data, onChange, setFormData }) => {
    const handleEditorInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            certificate_text: e.editor.getData(),
        }))
    }

    return (
        <>
            <Form style={{ marginTop: '25px' }}>
                <FormGroup>
                    <Label for='certificate'>Habilitar emissão do certificado:</Label>
                    <FormGroup switch>
                        <Input type='switch' name='certificate' id='certificate' onChange={onChange} checked={data.certificate} />
                        <Label for='order'>{data.certificate ? 'Ativo' : 'Inativo'}</Label>
                    </FormGroup>
                </FormGroup>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='certificate_presence_min'>Presença minima requerida:</Label>
                            <InputGroup>
                                <Input type='number' min='1' name='certificate_presence_min' id='certificate_presence_min' value={data.certificate_presence_min} onChange={onChange} required />
                                <InputGroupText>%</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='certificate_grade_min'>Nota mínima de aprovação:</Label>
                            <InputGroup>
                                <Input type='number' min='1' name='certificate_grade_min' id='certificate_grade_min' value={data.certificate_grade_min} onChange={onChange} required />
                                <InputGroupText>%</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <Editor label='Bloco de texto:' value={data.certificate_text} onChange={handleEditorInputChange} />

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='certificate_margin_top'>Margem topo:</Label>
                            <InputGroup>
                                <Input type='number' min='1' name='certificate_margin_top' id='certificate_margin_top' value={data.certificate_margin_top} onChange={onChange} required />
                                <InputGroupText>MM</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='certificate_margin_bottom'>Margem rodapé:</Label>
                            <InputGroup>
                                <Input type='number' min='1' name='certificate_margin_bottom' id='certificate_margin_bottom' value={data.certificate_margin_bottom} onChange={onChange} required />
                                <InputGroupText>MM</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='certificate_margin_left'>Margem esquerda:</Label>
                            <InputGroup>
                                <Input type='number' min='1' name='certificate_margin_left' id='certificate_margin_left' value={data.certificate_margin_left} onChange={onChange} required />
                                <InputGroupText>MM</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for='certificate_margin_right'>Margem direita:</Label>
                            <InputGroup>
                                <Input type='number' min='1' name='certificate_margin_right' id='certificate_margin_right' value={data.certificate_margin_right} onChange={onChange} required />
                                <InputGroupText>MM</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <Image label='Certificado:' id='certificate_file' name='certificate_file' src={data.certificate_file_url} onChange={onChange} />

            </Form>
        </>
    )
}