import { Table } from '../../../../_Commons/Table/Index'

export const StudentRequirements = ({ data }) => {
    const setTableData = (payloads) => ({
        columns: ['Requerimento', 'Tipo', 'Atualizado em', 'Status', 'Valor'],
        rows: payloads.map(item => [
            item.title,
            item.type,
            item.updated_at,
            item.status,
            item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })]),
        payloads: payloads
    })

    return (
        <>
            <Table header='Requerimentos' data={setTableData(data.requirements)} />
        </>
    )
}