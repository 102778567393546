import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { Breadcrumbs, LI, UL } from '../../../AbstractElements';
import { Card, CardBody, Row, Col } from 'reactstrap';

const SideButtons = [
  {
    id: '#',
    title: 'Dashboard',
    route: '#'
  },
  {
    id: 'academic',
    title: 'Acadêmico',
    route: 'academic'
  },
  {
    id: 'financial',
    title: 'Financeiro',
    route: 'financial'
  },
  {
    id: 'cms',
    title: 'CMS',
    route: 'cms'
  },
  {
    id: '#',
    title: 'Agenda',
    route: '#'
  },
  {
    id: 'general',
    title: 'Configurações',
    route: 'settings'
  },
  {
    id: '#',
    title: 'Relatorios',
    route: '#'
  },
  {
    id: 'instance-plan',
    title: 'Plano',
    route: 'flat'
  }
];

export default function Layout(props) {
  const { tenant_id } = useParams();

  return (
    <Fragment>
      <Row>
        <Col xl='12'>
          <Breadcrumbs mainTitle={`Gerenciamento De ${props.site_name}`} parent='Home' title='Gerenciamento' subParent="Products" thirdRelative="Meus-Sites" />
        </Col>
      </Row>
      <Row style={{ background: '#f8f9fa' }}>
        <Col xl='3' className='box-col-3 pe-0 file-spacing'>
          <div className=''>
            <div className={`md-sidebar-aside job-left-aside custom-scrollbar open`}>
              <div className='file-sidebar'>
                <Card>
                  <CardBody>
                    <UL attrUL={{ className: 'simple-list' }}>
                      {SideButtons.map((item) => (
                        <a className='border-0' href={`/admin/sites/${tenant_id}/${item.route}`} key={item.id}>
                          <LI attrLI={{ className: 'border-0' }}>
                            <div className={`btn ${item.id === props.id ? 'btn-primary' : 'btn-light'}`}>
                              {item.title}
                            </div>
                          </LI>
                        </a>
                      ))}
                    </UL>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </Col>

        <Col xl='8' md='12' className='box-col-12'>
          {props.children}
        </Col>
      </Row>
    </Fragment>
  );
}
