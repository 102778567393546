import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { API } from '../../../../api/API/api'

import { Navbar } from '../_Commons/Navbar/Index'
import Layout from "../Layout"
import Company from "./Tabs/General/Company"
import Integrations from './Tabs/General/Integrations'
import Seo from './Tabs/General/Seo'
import EmailTemplates from './Tabs/Emails/Templates'
import EmailSettings from './Tabs/Emails/Settings'
import Social from './Tabs/Social'
import General from './Tabs/Payments/General'
import Providers from './Tabs/Payments/Providers'
import Plans from './Tabs/Plans'
import RenegotiationsCommissions from './Tabs/Renegotiations/Commissions'
import RenegotiationsSettings from './Tabs/Renegotiations/Settings'
import Requiments from './Tabs/Requirements/Index'
import RequimentsTypes from './Tabs/Requirements/Types'
import Coupons from './Tabs/Coupons'
import UsersAdministrators from './Tabs/Users/Index'
import UsersProfiles from './Tabs/Users/Profiles'
import Sectionals from './Tabs/Sectionals'
import Regions from './Tabs/Regions'
import Professions from './Tabs/Professions'
import LtConsumers from './Tabs/Lt/Consumers'
import LtApplications from './Tabs/Lt/Applications'
import Terms from './Tabs/Terms'

const items = [
  {
    id: 'general',
    label: 'Geral',
    tabs: [
      {
        label: 'Dados da Empresa',
        component: Company
      },
      {
        label: 'Integrações',
        component: Integrations
      },
      {
        label: 'SEO',
        component: Seo
      }
    ]
  },
  {
    id: 'emails',
    label: 'Emails',
    tabs: [
      {
        label: 'Configuração',
        component: EmailSettings
      },
      {
        label: 'Templates',
        component: EmailTemplates
      }
    ],
  },
  {
    id: 'social',
    label: 'Redes Sociais',
    tabs: [
      {
        label: 'Redes Sociais',
        component: Social
      }
    ],
  },
  {
    id: 'payments',
    label: 'Pagamentos',
    tabs: [
      {
        label: 'Configurações Gerais',
        component: General
      },
      {
        label: 'Provedores de Pagamento',
        component: Providers
      }
    ],
  },
  {
    id: 'plans',
    label: 'Planos de Assinaturas',
    tabs: [
      {
        label: 'Planos de Assinaturas',
        component: Plans
      }
    ],
  },
  {
    id: 'renegotiation',
    label: 'Renegociações',
    tabs: [
      {
        label: 'Comissões',
        component: RenegotiationsCommissions
      },

      {
        label: 'Configurações',
        component: RenegotiationsSettings
      }
    ],
  },
  {
    id: 'requiments',
    label: 'Requerimentos',
    tabs: [
      {
        label: 'Requerimentos',
        component: Requiments
      },
      {
        label: 'Tipos de Requerimentos',
        component: RequimentsTypes
      }
    ],
  },
  {
    id: 'coupons',
    label: 'Cupons',
    tabs: [
      {
        label: 'Cupons',
        component: Coupons
      }
    ],
  },
  {
    id: 'users',
    label: 'Usuários',
    tabs: [
      {
        label: 'Usuários',
        component: UsersAdministrators
      },
      {
        label: 'Controle de Acesso',
        component: UsersProfiles
      }
    ],
  },
  {
    id: 'sectionals',
    label: 'Campus',
    tabs: [
      {
        label: 'Campus',
        component: Sectionals
      }
    ],
  },
  {
    id: 'regions',
    label: 'Regiões',
    tabs: [
      {
        label: 'Regiões',
        component: Regions
      }
    ],
  },
  {
    id: 'professions',
    label: 'Profissões',
    tabs: [
      {
        label: 'Profissões',
        component: Professions
      }
    ],
  },
  {
    id: 'lti',
    label: 'LTI',
    tabs: [
      {
        label: 'Consumidores',
        component: LtConsumers
      },
      {
        label: 'Aplicativos',
        component: LtApplications
      }
    ],
  },
  {
    id: 'terms',
    label: 'Termos',
    tabs: [
      {
        label: 'Termos',
        component: Terms
      }
    ],
  }
]

export default function Settings() {
  const { tenant_id } = useParams()
  const token = localStorage.getItem("token")

  const [formValues, setFormValues] = useState({
    tenant: {
      name: '',
      domain: '',
      users: 0,
      status: ''
    },
    name: '',
    phone: '',
    is_whatsApp: false,
    whatsapp_message: '',
    address: '',
    zipcode: '',
    cnpj: '',
    email: '',
    service: ''
  })

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(`/tenants/${tenant_id}/management/general`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setFormValues(response.data)
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [tenant_id, token])

  return (
    <Fragment>
      <Layout id="general" site_name={formValues.tenant.name}>
        <Navbar items={items} />
      </Layout>
    </Fragment>
  )
}
