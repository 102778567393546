import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import LoginForm from './LoginForm';
// import { Image } from '../../../AbstractElements';
import Logo from "../../../assets/images/logo/tutorcasts_comp.png"
import "./Login.scss"
import ReactGA from 'react-ga';

const LoginTwo = () => {
  
  useEffect(() => {
    ReactGA.pageview(window.location.href)
  },[])

  return (
    <Fragment>
      <section>
        <Container fluid={true}>
          <Row>
            <Col xl='7' className='b-center bg-size loginImg__bg' >
              <div className='loginImg'>
                <img className='loginImg__logo' src={Logo} alt="" />
              </div>
            </Col>
            <Col xl='5 p-0'>
              <LoginForm logoClassMain='text-start' />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default LoginTwo;
