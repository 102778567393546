import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from "react-toastify"
import { Card, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import { ModalCreateAndEdit } from '../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../_Commons/Modal/Delete'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
  id: 0,
  name: '',
  icon: ''
}

export default function Social() {
  const { tenant_id } = useParams()
  const [socialMedias, setSocialMedias] = useState([])

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(`/tenants/${tenant_id}/management/social`)
        setSocialMedias(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, media) => {
    setIsEditing(isEditing)
    setFormData(media)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const handleModalInputChange = (e) => {
    const { name, value } = e.target

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const apiPath = `/tenants/${tenant_id}/management/social`

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setSocialMedias(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setSocialMedias(response.data)
      toast.success('Sucesso!')

      setSocialMedias(response.data)
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  return (
    <>
      <Card className='col-12 p-20'>
        <Button color='primary' className='col-2 d-flex justify-content-center' outline={true} onClick={() => openModal(false, formDataDefault)}>
          Novo
        </Button>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Redes Sociais</h5>
        </div>
        <div>
          {socialMedias.map((media, index) => (
          <Row key={index} className='d-flex justify-content-center align-items-center mt-2' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
            <Col md='4'>{media.name}</Col>
            <Col md='4'>{media.icon}</Col>
            <Col md='4'>
            <div className='buttonsCard'>
              <Button color='primary' outline={true} onClick={() => openModal(true, media)}>
              <i className='fa fa-edit fa-lg'></i>
              </Button>
              <Button color='secondary' outline={true} onClick={() => openModalDelete(media)}>
              <i className='fa fa-trash-o fa-lg'></i>
              </Button>
            </div>
            </Col>
          </Row>
          ))}
        </div>
      </Card>

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Usuário' : 'Adicionar Usuário'} onSubmit={() => submitModalForm()} >
        <Form>
          <FormGroup>
            <Label for='name'>Nome:</Label>
            <Input type='text' name='name' id='name' placeholder='Digite o nome do ícone' value={formData.name} onChange={handleModalInputChange} />
          </FormGroup>
          <FormGroup>
            <Label for='icon'>Icone:</Label>
            <Input type='text' name='icon' id='icon' placeholder='Digite o Código do ícone' value={formData.icon} onChange={handleModalInputChange} />
          </FormGroup>
          </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

