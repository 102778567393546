import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useParams } from 'react-router-dom';  // Importe o hook useParams
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import UserProfile from "../../../Bonus-Ui/Tour/UserProfile";
import UserInfo from "../../../TutorCasts/Profile/UserInfo";
import SitesInfo from "../../../TutorCasts/Profile/SitesInfo";
import { API } from '../../../../api/API/api';

const UsersProfileContain = () => {
  const { clientId } = useParams(); // Obtém a ID do cliente da URL
  const [cliente, setCliente] = useState({});
  const token = localStorage.getItem("token");

  useEffect(() => {
    async function getCliente() {
      try {
        
        const currentURL = window.location.href;
        console.log(currentURL, clientId);

        if (clientId) {
          // Se o clientId estiver presente na URL
          const response = await API.get(`backoffice/users/${clientId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = response.data;
          console.log(data);
          setCliente(data);
        } else {
          // Se o clientId não estiver na URL, tente obter do localStorage
          const localStorageClientId = localStorage.getItem("user_id");
          if (localStorageClientId) {
            const response = await API.get(`backoffice/users/${localStorageClientId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const data = response.data;
            console.log(data);

            setCliente(data);
          } else {
            console.log("clientId não encontrado");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    getCliente();
  }, [clientId, token]); // Certifique-se de incluir `clientId` e `token` na lista de dependências



  const userProfile = {
    name: cliente.name,
    last_name: cliente.last_name || '',
    role: "Administrador",
    photo: "user-photo.jpg",
  };

  const userInfo = {
    email: cliente.email,
    document: cliente.document,
    address_line: cliente.address_line,
    zip_code: cliente.zip_code,
  };

  const tenants = cliente.tenants || [];
  console.log(cliente);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Perfil" parent="Home" title="Perfil" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <UserProfile profileData={userProfile} />
          </Row>
          <Row>
            <Col sm="12" md="12" lg="6">
              <UserInfo userInfo={userInfo} />
            </Col>
            <Col sm="12" md="12" lg="6">
              <SitesInfo tenants={tenants} />
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default UsersProfileContain;
