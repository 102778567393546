import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Table, Button, Form, FormGroup, Label, Input, InputGroup, InputGroupText } from 'reactstrap'

import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    name: '',
    email: '',
    profile: '',
    profile_id: 1,
    password: ''
  }

export default function Administrators() {
  const { tenant_id } = useParams()
  const [data, setData] = useState({
    users: [],
    profiles: []
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)

  const [userDelete, setUserDelete] = useState(0)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

  const apiPath = `/tenants/${tenant_id}/management/administrators`

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(apiPath)
        setData(response.data || [])
      } catch (error) { console.error(error) }
    }

    getData()
  }, [])

  const openModal = (isEditing, data) => {
    setIsEditing(isEditing)
    data.free = !data.amount
    setFormData(data)
    setModalVisible(true)
  }

  const openModalDelete = ({ id }) => {
    setUserDelete(id)
    setModalDeleteVisible(true)
  }

  const handleModalInputChange = (e) => {
    let { name, value } = e.target
    
    value = name === 'status' ? (formData.status === 'Ativo' ? 'Inativo' : 'Ativo') : value
    value = !isNaN(value) && name !== 'password' ? +value : value

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const submitModalForm = async () => {
    try {
      const {id, ...payload} =  formData
      const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

      setData(response.data)
      toast.success('Sucesso!')
    } catch (error) {
      console.log(error)
      toast.error('Error')
    }

    setModalVisible(false)
  }

  const submitModalDeleteForm = async (id) => {
    try {
      const response = await API.delete(`${apiPath}/${id}`)
      setData(response.data)
      toast.success('Sucesso!')

      setData(response.data)
    } catch (error) {
      console.error(error)
      toast.error('Error')
    }

    setModalDeleteVisible(false)
  }

  return (
    <>
      <Card className='col-12 p-20'>
        <Button color='primary' className='col-2 d-flex justify-content-center' outline={true} onClick={() => openModal(false, formDataDefault)}>
          Novo
        </Button>
        <div className='card-header'>
          <h5 className='card-title p-b-0 p-t-0'>Usuários</h5>
        </div>
        <Table borderless>
            <thead>
                <tr>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Perfil</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {data.users.map((user, index) => (
                <tr key={index}>
                    <td className='align-middle'>{user.name}</td>
                    <td className='align-middle'>{user.email}</td>
                    <td className='align-middle'>{user.profile}</td>
                    <td>
                        <div className='buttonsCard'>
                            <Button color='primary' outline={true} onClick={() => openModal(true, user)}>
                                <i className='fa fa-edit fa-lg'></i>
                            </Button>
                            <Button color='secondary' outline={true} onClick={() => openModalDelete(user)}>
                                <i className='fa fa-trash-o fa-lg'></i>
                            </Button>
                        </div>
                    </td>
                </tr>
                ))}
            </tbody>
        </Table>
      </Card>

      <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Usuário' : 'Criar Usuário'} onSubmit={() => submitModalForm()} >
        <Form>
          <FormGroup>
            <Label for='name'>Nome:</Label>
            <Input type='text' name='name' id='name' value={formData.name} onChange={handleModalInputChange} required />
          </FormGroup>

          <FormGroup>
            <Label for='email'>Email:</Label>
            <Input type='email' name='email' id='email' value={formData.email} onChange={handleModalInputChange} required />
          </FormGroup>

          <FormGroup>
            <Label for='password'>Senha:</Label>
            <Input type='password' name='password' id='password' value={formData.password} onChange={handleModalInputChange} required />
          </FormGroup>
          
          <FormGroup>
            <Label for='profile_id'>Usuário:</Label>
            <Input type='select' name='profile_id' id='profile_id' onChange={handleModalInputChange}>
                {data.profiles.map((profile, index) => (
                    <option key={index} value={profile.id} selected={profile.id === formData.profile_id}>
                        {profile.name}
                    </option>
                ))}
            </Input>
          </FormGroup>

        </Form>
      </ModalCreateAndEdit>

      <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
    </>
  )
}

