import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';
import ViewPageLinkTree from '../Components/Pages/Dashboard/Components/LinkTree/viewPage';

const LayoutRoutes = () => {

  return (
    <>
      <Routes>
        {routes.map(({ path, Component }, i) => (
          <Fragment key={i}>
            <Route element={<AppLayout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          </Fragment>
        ))}
        <Route path='/bio/:slug' element={<ViewPageLinkTree />}/>
      </Routes>
    </>
  );
};

export default LayoutRoutes;