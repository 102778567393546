import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Slider } from 'primereact/slider';
import InputMask from 'react-input-mask';
import CommonModal from '../../UiKits/Modals/common/modal';
import { ToastContainer, toast } from "react-toastify";

import { Breadcrumbs, Btn } from '../../../AbstractElements';
import { API } from '../../../api/API/api';
import './pages.scss';
import './Planos.scss';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';

export default function ProductsInvoices() {
    const token = localStorage.getItem('token');
    const [products_invoices, setPlans] = useState([]);
    const [isAddPlanoModalVisible, setIsAddPlanoModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [categoriasExistente, setCategoriasExistente] = useState([]);
    const [formData, setFormData] = useState({
        id: null,
        categoria: '',
        nome: '',
        unidade_de_medida: '',
        valor: 0,
        novaCategoria: '',  // Adicione esse campo ao estado
    });

    const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
    const [planToDelete, setPlanToDelete] = useState(null);
    // Adicione um novo estado para controlar a visibilidade do modal de nova categoria
    const [isNewCategoriaModalVisible, setIsNewCategoriaModalVisible] = useState(false);
    const [novaCategoria, setNovaCategoria] = useState('');

    // Função para abrir o modal de nova categoria
    const openNewCategoriaModal = () => {
        setIsNewCategoriaModalVisible(true);
    };

    // Função para fechar o modal de nova categoria
    const closeNewCategoriaModal = () => {
        setIsNewCategoriaModalVisible(false);
    };
    const getPlans = async () => {
        try {
            const response = await API.get('/backoffice/products_invoices', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data;
            console.log(data);

            setPlans(data);
        } catch (error) {
            console.log(error);
        }
    };

    const getCategorias = async () => {
        try {
            const response = await API.get('/backoffice/categorias', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const categorias = response.data;
            setCategoriasExistente(categorias);
        } catch (error) {
            console.error('Falha ao Buscar Categorias:', error);
            toast.error('Falha ao Buscar Categorias');
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'is_active' ? value === 'true' : value,
        });
    };
    const handleAddCategoria = async () => {

        openNewCategoriaModal();

    };

    const formatarValorReais = (valor) => {
        if (!isNaN(valor)) {
            return 'R$ ' + valor.toString().replace('.', ',');
        } else {
            return 'Valor indisponível';
        }
    };
    // Função para salvar a nova categoria
    // Função para salvar a nova categoria
    const saveNovaCategoria = async () => {
        try {
            // Faça a requisição POST para adicionar a nova categoria
            const response = await API.post('/backoffice/categorias', { name: formData.novaCategoria }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });


            console.log('Nova categoria adicionada com sucesso!', response);
            toast.success('Nova categoria adicionada com sucesso!');
            getCategorias();
            closeNewCategoriaModal();
        } catch (error) {
            console.error('Erro ao adicionar nova categoria:', error);
            toast.error('Erro ao adicionar nova categoria');
        }
    
};



const openAddPlanoModal = () => {
    setIsEditing(false);
    setIsAddPlanoModalVisible(true);
};


const closeAddPlanoModal = () => {
    setIsAddPlanoModalVisible(false);
    setIsEditing(false);
    setFormData({
        id: null,
        categoria: '',
        nome: '',
        unidade_de_medida: '',
        valor: 0,
    });
};

useEffect(() => {
    async function getPlans() {
        try {
            const response = await API.get('/backoffice/products_invoices', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data;
            setPlans(data);
        } catch (error) {
            console.error('Falha ao Buscar Produtos:', error);
            toast.error('Falha ao Buscar Produtos');
        }
    }
    getCategorias();
    getPlans();
}, []);

const renderActions = (rowData) => {
    return (
        <div className="row">
            <button type="button" className="buttns btn btn-outline-primary" onClick={() => handleEdit(rowData)}>
                <i className="fa fa-edit fa-lg"></i>
            </button>
            <button type="button" className="buttns btn btn-outline-secondary custom-danger" onClick={() => openDeleteConfirmationModal(rowData)}>
                <i className="fa fa-trash-o fa-lg"></i>
            </button>

        </div>
    );
};


const handleEdit = (rowData) => {
    setIsEditing(true);
    setIsAddPlanoModalVisible(true);
    setFormData({
        id: rowData.id,
        nome: rowData.nome,
        categoria: rowData.categoria,
        unidade_de_medida: rowData.unidade_de_medida,
        valor: rowData.valor
    });
};

const handleDelete = (rowData) => {
    setIsEditing(false);
    setIsDeleteConfirmationModalVisible(true);
    setPlanToDelete(rowData);
};

const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
        console.log(formData);

        if (isEditing) {
            // Faça a requisição PUT para atualizar o plano
            const response = await API.put(`/backoffice/products_invoices/${formData.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Produto  atualizado com sucesso!', response);
            toast.success('Produto atualizado com sucesso!');
            getPlans();

        } else {
            // Faça a requisição POST para criar um novo plano
            const response = await API.post('/backoffice/products_invoices', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            getPlans();
            console.log('Produto criado com sucesso!', response);
            toast.success('Produto criado com sucesso!');

        }

        closeAddPlanoModal();
        getPlans();
    } catch (error) {
        console.error('Erro na requisição:', error);
        toast.error('Erro na requisição:', error);

    }
};
const openDeleteConfirmationModal = (rowData) => {
    setIsDeleteConfirmationModalVisible(true);
    setPlanToDelete(rowData);
};

const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalVisible(false);
    setPlanToDelete(null);
};

const confirmDelete = async () => {
    try {
        // Faça a requisição DELETE para excluir o plano
        const response = await API.delete(`/backoffice/products_invoices/${planToDelete.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Lógica para tratamento de sucesso e recarregamento dos dados
        console.log('Produto excluído com sucesso!', response);

        closeDeleteConfirmationModal();
        getPlans();
    } catch (error) {
        // Lógica para tratamento de erros
        console.error('Erro na exclusão do produto:', error);
    }
};

return (
    <Fragment>

        <Container fluid={true}>
        <Breadcrumbs mainTitle='Produtos de Invoice' parent='Home' title='Produtos de Invoice' />

            <Btn
                attrBtn={{
                    color: 'primary', className: 'm-b-15 col-2 d-flex justify-content-center', outline: true,
                    onClick: openAddPlanoModal
                }}
            >
                Novo
            </Btn>
            <Row>
                <Col sm='12'>
                    <Card>
                        <DataTable
                            value={products_invoices}
                            paginator
                            rows={15}
                            sortMode='multiple'
                            selectionMode='single'
                            dataKey='id'
                            metaKeySelection={false}
                        >
                            <Column field='id' sortable style={{ width: '10%' }} header='Id' />
                            <Column field='nome' sortable style={{ width: '20%' }} header='Nome' />
                            <Column field='valor' sortable body={(rowData) => formatarValorReais(rowData.valor)} style={{ width: '20%' }} header='Valor' />
                            <Column field='categoria' sortable style={{ width: '15%' }} header='Categoria' />
                            <Column field='unidade_de_medida' sortable style={{ width: '15%' }} header='Unidade de Medida' />
                            <Column header='Ações' body={renderActions} style={{ width: '20%' }} />

                        </DataTable>

                    </Card>
                </Col>
            </Row>
        </Container>
        <CommonModal isOpen={isAddPlanoModalVisible} title={ isEditing ? 'Editar Produto': 'Cadastro de Produto'} toggler={closeAddPlanoModal} size="lg">
            {/* Conteúdo do modal de cadastro */}
            <form onSubmit={handleFormSubmit} className="form-columns">
                <div className="form-column">
                    <div className="form-group">
                        <div className="basic-info">
                            <div className="form-group">
                                <label htmlFor="name" className="form-label">Nome do Produto</label>
                                <input
                                    type="text"
                                    id="nome"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleInputChange}
                                    required
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="categoria" className="form-label">Categoria</label>
                                <div className="input-group">
                                    <select
                                        id="categoria"
                                        name="categoria"
                                        value={formData.categoria}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                    >
                                        <option value="" disabled>Selecione uma categoria...</option>
                                        {/* Mapear as categorias existentes para as opções do select */}
                                        {categoriasExistente.map((categoria) => (
                                            <option key={categoria.id} value={categoria.name}>{categoria.name}</option>
                                        ))}
                                    </select>
                                    <div className="input-group-append"
                                    style={{marginLeft:'0.5em'}}>
                                        <button
                                            type="button"
                                            className="btn btn-outline-success"
                                            onClick={() => handleAddCategoria()}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="unidade_de_medida" className="form-label">Unidade de Medida</label>
                                <input
                                    type="text"
                                    id="unidade_de_medida"
                                    name="unidade_de_medida"
                                    value={formData.unidade_de_medida}
                                    onChange={handleInputChange}
                                    required
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="valor" className="form-label">Valor do Produto</label>
                                <input
                                    type="number"
                                    id="valor"
                                    name="valor"
                                    value={formData.valor}
                                    onChange={handleInputChange}
                                    required
                                    className="form-control"
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <button type="submit" className="d-block mt-2 btn btn-outline-primary">
                    Salvar
                </button>

            </form>

        </CommonModal>
        <CommonModal
            isOpen={isDeleteConfirmationModalVisible}
            title={`Confirmação de Exclusão - ${planToDelete && planToDelete.nome}`}
            toggler={closeDeleteConfirmationModal}
        >
            <div>
                <p>Tem certeza de que deseja excluir o produto: {planToDelete && planToDelete.nome}?</p>
            </div>
            <div>
                <button className="btn btn-outline-secondary" onClick={closeDeleteConfirmationModal}>
                    Cancelar
                </button>
                <button className="btn btn-outline-danger custom-danger" onClick={confirmDelete}>
                    Excluir
                </button>
            </div>

        </CommonModal>
        <CommonModal isOpen={isNewCategoriaModalVisible} title="Nova Categoria" toggler={closeNewCategoriaModal} size="md">
            <div>
                <label htmlFor="novaCategoria" className="form-label">Nome da Nova Categoria</label>
                <input
                    type="text"
                    id="novaCategoria"
                    name="novaCategoria"
                    value={formData.novaCategoria}
                    onChange={(e) => setFormData({ ...formData, novaCategoria: e.target.value })}
                    required
                    className="form-control"
                />
            </div>
            <div className="mt-3 d-flex gap-2">
                <button className="btn btn-outline-secondary " onClick={closeNewCategoriaModal}>
                    Cancelar
                </button>
                <button className="btn btn-outline-success" onClick={saveNovaCategoria}>
                    Salvar
                </button>
            </div>
        </CommonModal>



    </Fragment >
);
}
