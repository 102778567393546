import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Card, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { H6 } from '../../../../../../AbstractElements'

import { API } from '../../../../../../api/API/api';
import '../../../_Commons/Styles/Styles.scss';
import { toast } from "react-toastify";

const status = {
  activated: 'Ativo',
  deactivating: 'Desativando',
  deactivated: 'Desativo'
}

export default function Company() {
  const token = localStorage.getItem("token");
  const { tenant_id } = useParams();

  const [formValues, setFormValues] = useState({
    tenant: {
      name: '',
      domain: '',
      users: 0,
      status: ''
    },
    name: '',
    phone: '',
    is_whatsApp: false,
    whatsapp_message: '',
    address: '',
    zipcode: '',
    cnpj: '',
    email: '',
    service: ''
  });
  const handleInputChange = (e) => {
    let { name, value } = e.target;

    value = ['logo_url'].includes(name) ? e.target.files[0] : value
    console.log(value)

    if (name.startsWith('tenant.')) {
      // Atualizar campos dentro de tenant
      setFormValues((prevValues) => ({
        ...prevValues,
        tenant: {
          ...prevValues.tenant,
          [name.substring(7)]: value
        }
      }));
    } else {
      // Atualizar campos fora de tenant
      if (name === 'domain') {
        // If the input is the 'domain' field, concatenate with '.tutorcasts.app'
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
          tenant: {
            ...prevValues.tenant,
            domain: value + '.tutorcasts.app'
          },
          domain: value + '.tutorcasts.app'
        }));
      } else {
        // For other fields outside 'tenant', update as usual
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      }
    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      if (formValues.logo_url) {
          const config = {
              headers: {
                  'Content-Type': 'multipart/form-data',
              }
          }

          const form = new FormData()
          form.append('logo', formValues.logo_url)
          
          const responseImg = await API.post(`/tenants/${tenant_id}/management/general/upload`, form, config)
          formValues.logo_url = responseImg.data.logo_url
          console.log(formValues)
      }

      await API.put(`/tenants/${tenant_id}/management/general`, formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Dados Atualizados com Sucesso!');

    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(`/tenants/${tenant_id}/management/general`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFormValues(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, [tenant_id, token]);

  return (
    <Fragment>

      <Card className='col-12'
        style={{ marginBottom: "8.5em" }}
      >
        <div className="card-header">
          <h5 className="card-title p-b-0 p-t-0">Dados da Empresa</h5>
        </div>
        <form>
          <Row style={{ marginLeft: '2rem' }}>
            <Col md="4" sm="6" xs="12" className="mb-3">
              <H6>Url do Subminio:</H6>
              <FormGroup>
                <input
                  className="form-control"
                  type="text"
                  name="domain"
                  value={formValues.tenant.domain}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      tenant: { ...formValues.tenant, domain: e.target.value },
                      domain: e.target.value, // Adicione esta linha para manter ambos atualizados
                    })
                  }
                />


              </FormGroup>
            </Col>
            <Col md="4" sm="6" xs="12">
              <H6>Usuários:</H6>
              <span>{formValues.tenant.users}</span>
            </Col>
            <Col md="4" sm="6" xs="12">
              <H6>Status:</H6>
              <span>{status[formValues.tenant.status]}</span>
            </Col>
          </Row>
          <Row style={{ marginLeft: '2rem' }}>
            <Col md="4" sm="6" xs="12" className="mb-3">
              {/* Seção: Informações Pessoais */}
              <div className="section-title"><strong>Informações Pessoais</strong></div>
              <FormGroup>
                <Label className="form-label">Nome</Label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={formValues.name || ''}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label">Telefone</Label>
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  value={formValues.phone || ''}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Row>
                  <div className="section-title"><strong> Opções de Contato </strong></div>
                  <FormGroup check>
                    <Label check>
                      <input
                        type="radio"
                        checked={!formValues.is_whatsApp}
                        onChange={() => setFormValues({ ...formValues, 'is_whatsApp': false, 'whatsapp_message': '' })}
                        name="is_whatsApp"
                        style={{ transform: 'scale(1.2)' }}
                      />
                      <i className="fa fa-phone mr-2" style={{ fontSize: '1.2em', marginLeft: '0.2em' }} aria-hidden="true"></i>
                      <label className="form-label form-label" style={{ fontSize: '1.2em' }}>&nbsp;Telefone</label>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <input
                        type="radio"
                        checked={formValues.is_whatsApp}
                        onChange={() => setFormValues({ ...formValues, 'is_whatsApp': true })}
                        name="is_whatsApp"
                        style={{ transform: 'scale(1.2)' }}
                      />
                      <i className="fa fa-whatsapp mr-2" style={{ fontSize: '1.2em', marginLeft: '0.2em' }} aria-hidden="true"></i>
                      <label className="form-label form-label" style={{ fontSize: '1.2em' }}>&nbsp;Whatsapp</label>
                    </Label>
                  </FormGroup>
                </Row>
                <FormGroup>
                  <Label className="form-label">Mensagem WhatsApp API</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="whatsapp_message"
                    value={formValues.whatsapp_message || ''}
                    onChange={handleInputChange}
                    disabled={!formValues.is_whatsApp}
                  />
                </FormGroup>
              </FormGroup>
              {/* Outras Informações */}
              <FormGroup>
                <Label className="form-label">Informações de Atendimento</Label>
                <input
                  className="form-control"
                  type="text"
                  name="service"
                  value={formValues.service}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>

            {/* Coluna 2 */}
            <Col sm="6" md="6">

              {/* <FormGroup>
                    <Label className="form-label"><h6>URL do domínio</h6></Label>
                    <input className="form-control" type="text" value={formValues.tenant.domain} onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, tenant: {domain: e.target.value, ...prevValues} }))} />
                  </FormGroup> */}

              {/* Seção: Endereço e Contato */}
              <div className="section-title"><strong>Endereço e Contato </strong></div>
              <FormGroup>
                <Label className="form-label">Endereço</Label>
                <input
                  className="form-control"
                  type="text"
                  name="address"
                  value={formValues.address}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label">CEP</Label>
                <input
                  className="form-control"
                  type="text"
                  name="zipcode"
                  value={formValues.zipcode}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label">CNPJ</Label>
                <input
                  className="form-control"
                  type="text"
                  name="cnpj"
                  value={formValues.cnpj}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label">Email de Contato</Label>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup>
                <Label className="form-label">
                  Logo
                  {' '}
                  <span style={{ fontSize: '12px', fontStyle: 'italic' }}>170x80</span>
                </Label>
                <Input type='file' name='logo_url' onChange={handleInputChange} accept="image/*" />
              </FormGroup>
            </Col>
          </Row>
          <Row className="text-right rowCustom">
            <Col className="text-right">
              <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                Salvar alterações
              </button>
            </Col>
          </Row>
        </form>
      </Card>

    </Fragment>
  );
}