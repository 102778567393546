import { Fragment, useEffect, useState } from "react";
import { Card, Row, Col, CardBody, Button, Form, Input, FormGroup, Label } from "reactstrap";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { API } from "../../../../../../api/API/api";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from 'react-toastify';
import { H5, Image } from "../../../../../../AbstractElements";
import { FaCreditCard, FaReceipt } from 'react-icons/fa';
import cardImage from '../../../../../../assets/images/card.png';

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const StatesBrazil = [
  { nome: 'Acre', sigla: 'AC' },
  { nome: 'Alagoas', sigla: 'AL' },
  { nome: 'Amapá', sigla: 'AP' },
  { nome: 'Amazonas', sigla: 'AM' },
  { nome: 'Bahia', sigla: 'BA' },
  { nome: 'Ceará', sigla: 'CE' },
  { nome: 'Distrito Federal', sigla: 'DF' },
  { nome: 'Espírito Santo', sigla: 'ES' },
  { nome: 'Goiás', sigla: 'GO' },
  { nome: 'Maranhão', sigla: 'MA' },
  { nome: 'Mato Grosso', sigla: 'MT' },
  { nome: 'Mato Grosso do Sul', sigla: 'MS' },
  { nome: 'Minas Gerais', sigla: 'MG' },
  { nome: 'Pará', sigla: 'PA' },
  { nome: 'Paraíba', sigla: 'PB' },
  { nome: 'Paraná', sigla: 'PR' },
  { nome: 'Pernambuco', sigla: 'PE' },
  { nome: 'Piauí', sigla: 'PI' },
  { nome: 'Rio de Janeiro', sigla: 'RJ' },
  { nome: 'Rio Grande do Norte', sigla: 'RN' },
  { nome: 'Rio Grande do Sul', sigla: 'RS' },
  { nome: 'Rondônia', sigla: 'RO' },
  { nome: 'Roraima', sigla: 'RR' },
  { nome: 'Santa Catarina', sigla: 'SC' },
  { nome: 'São Paulo', sigla: 'SP' },
  { nome: 'Sergipe', sigla: 'SE' },
  { nome: 'Tocantins', sigla: 'TO' }
];


export default function Payment() {
  const [plan, setPlan] = useState({
    administrator_id: '',
    created_at: "",
    customer_id: "",
    data: { tenancy_db_name: '' },
    finance_id: "",
    id: "",
    owner_id: "",
    partner_id: "",
    payment_method_id: "",
    plan: "",
    service_id: "",
    updated_at: "",
    used_storage_digital_ocean: 0,
    used_storage_vimeo: 0
  });

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    cpf: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'BR'
  });

  const { register, formState: { errors }, handleSubmit } = useForm();

  const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
  };

  const [selectPaymentMethod, setSelectPaymentMethod] = useState('');
  const [cardAlreadyRegistered, setCardAlreadyRegistered] = useState(false);
  const [planPrice, setPlanPrice] = useState();
  const [dataUser, setDataUser] = useState({
    name: '',
    email: ''
  });

  const stripe = useStripe();
  const elements = useElements();

  const handleChangeDataUser= ({ target }) => {
    const { name, value } = target;
    setDataUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { tenant_id } = useParams();
  const token = localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      try {
        const { data } = await API.get(`/tenants/${tenant_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        const plan_id = data.plan;

        const fetchPlan = await API.get(`/tenants/plan/${plan_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        setPlan(data);
        cardAlreadyRegistered(data.payment_method_id === null);
        setPlanPrice(fetchPlan.data.price);
      } catch (error) {
        console.log(error);
      }
    })()
  }, []);

  const addPaymentMethod = async () => {
    try {
      if (!dataUser.name || !dataUser.email) {
        toast.error('Preencha os campos de nome e email')
        return
      }
  
      const { data: { client_secret } } = await API.post('/tenants/payment/create-setup-intent');
        
      const customer = await API.post('/create-customer', {
        email: dataUser.email,
        name: dataUser.name,
      });
  
      const customer_id = customer.data.customer_id;

      const { setupIntent } = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: dataUser.email,
            name: dataUser.name
          }
        },
      });

      const fetchPlan = await API.get(`/tenants/plan/${plan.plan}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      const paymentMethod = setupIntent.payment_method;
      const price_id = `${fetchPlan.data.price}00`;

      const teste = await API.post('/tenants/payment/update-payment', {
        payment_method_id: paymentMethod,
        price_id: price_id,
        customer_id: customer_id,
        id: plan.id,
        email: dataUser.email,
        name: dataUser.name,
        payment_method: 'cartão'
      });

      console.log(teste);
      setCardAlreadyRegistered(false);
      toast.success('Cartão adicionado com sucesso!');
    } catch (error) {
      console.log(error.response.data.detail);
      const message = error.response.data.detail.split(':');
      toast.error(message[1]);
    }
  }

  const paymentTicket = async () => {
    try {
      const customer = await API.post('/create-customer', {
        email: formData.email,
        name: formData.firstName + ' ' + formData.lastName,
      });

      const customerId = customer.data.customer_id;

      const { data } = await API.post('/tenants/payment/create-setup-intent-boleto', {
        cpf: formData.cpf,
        name: formData.firstName + ' ' + formData.lastName,
        email: formData.email,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        postal_code: formData.postalCode,
        country: formData.country
      });

      const paymentMethod = data;

      const fetchPlan = await API.get(`/tenants/plan/${plan.plan}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      const price_id = `${fetchPlan.data.price}00`;

      const ticketPayment = await API.post('/tenants/payment/update-payment', {
        payment_method_id: paymentMethod,
        price_id: `${price_id}`,
        customer_id: customerId,
        id: plan.id,
        name: formData.firstName + ' ' + formData.lastName,
        email: formData.email,
        payment_method: 'boleto'
      });

      setCardAlreadyRegistered(false);
      toast.success('Método de pagamento adicionado!');
    } catch (error) {
      console.log(error.response.data.detail);
      const message = error.response.data.detail.split(':');
      toast.error(message[1]);
    }
  }

  const onSubmit = (data) => {
    if (data !== '') {
        paymentTicket();
    } else {
        errors.alert('add data');
    }
  };

  return (
    <Fragment>
     <Card>
        <CardBody>
          {cardAlreadyRegistered ? (
            <Col>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                  color={selectPaymentMethod === 'cartao' ? 'primary' : 'secondary'}
                  onClick={() => setSelectPaymentMethod('cartao')}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <FaCreditCard style={{ marginRight: '5px' }} />
                  Cartão
                </Button>
                <Button
                  color={selectPaymentMethod === 'boleto' ? 'primary' : 'secondary'}
                  onClick={() => setSelectPaymentMethod('boleto')}
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                  <FaReceipt style={{ marginRight: '5px' }} />
                  Boleto
                </Button>
              </div>

              {selectPaymentMethod === 'cartao' && (
                <Col xxl='8' className='box-col-12'>
                  <div style={{ marginTop: '10px' }}>
                    <CardBody>
                      <Row>
                        <Col md='7'>
                          <Form>
                            <Row>
                              <FormGroup className='mb-3'>
                                <Input
                                  type="text"
                                  name="name"
                                  className='form-control'
                                  value={dataUser.name}
                                  onChange={(event) => handleChangeDataUser(event)}
                                  placeholder="Nome"
                                  required
                                />
                              </FormGroup>
                            </Row>

                            <Row>
                              <FormGroup className='mb-3'>
                                <Input
                                  type="text"
                                  name="email"
                                  className='form-control'
                                  value={dataUser.email}
                                  onChange={(event) => handleChangeDataUser(event)}
                                  placeholder="Email"
                                  required
                                />
                              </FormGroup>
                            </Row>


                            <Row>
                              <CardElement options={cardStyle} />
                            </Row>

                            <Row className="text-end" style={{ marginTop: '15px' }}>
                              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button className="secondary me-2" onClick={() => setCardAlreadyRegistered(false)}>
                                  Cancelar
                                </Button>
                                <Button className="btn-primary" color="primary" onClick={() => addPaymentMethod()}>
                                  Adicionar Cartão
                                </Button>
                              </Col>
                            </Row>

                          </Form>
                        </Col>
                        <Col md="5" className='text-center'>
                          <Image
                            attrImage={{
                              className: 'img-fluid',
                              src: `${cardImage}`,
                              alt: '',
                            }}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                </Col>
              )}

              {selectPaymentMethod === 'boleto' && (
                <Col style={{ marginTop: '18px' }}>
                  <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation" style={{ width: '100%' }}>
                    <Row>
                      <FormGroup className="mb-3 col-sm-6">
                        <input className="form-control" type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="Nome" />
                        <span style={{ color: '#ff5370' }}>{errors.firstName && 'First name is required'}</span>
                      </FormGroup>
                      <FormGroup className="mb-3 col-sm-6">
                        <input className="form-control" type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Sobre Nome" />
                        <span style={{ color: '#ff5370' }}>{errors.lastName && 'Last name is required'}</span>
                      </FormGroup>
                    </Row>

                    <Row>
                      <FormGroup className="mb-3 col-sm-6">
                          <input className="form-control" type="text" name="cpf" value={formData.cpf} onChange={handleChange} placeholder="Cpf" />
                          <span style={{ color: '#ff5370' }}>{errors.cpf && 'Please enter number for cpf.'}</span>
                      </FormGroup>
                      <FormGroup className="mb-3 col-sm-6">
                          <input className="form-control" type="text" name="email" value={formData.email} onChange={handleChange}  placeholder="Email"/>
                          <span style={{ color: '#ff5370' }}>{errors.email && 'Please enter proper email address .'}</span>
                      </FormGroup>
                    </Row>

                    <FormGroup className="mb-3">
                        <Input className="form-control" type="select" name="selectMulti" value={formData.country} onChange={handleChange} placeholder="Pais">
                          <option>Brasil</option>
                        </Input>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <input className="form-control" type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Endereço" />
                        <span style={{ color: '#ff5370' }}>{errors.address && 'Please right your address .'}</span>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <input className="form-control" type="text" name="city" value={formData.city} onChange={handleChange} placeholder="Cidade" />
                        <span style={{ color: '#ff5370' }}>{errors.city && 'select one city'}</span>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Input className="form-control" type="select" name="state" value={formData.state} onChange={handleChange} placeholder="Estado">
                        <option value="">Selecione o estado</option>
                        {StatesBrazil.map((estado) => (
                          <option key={estado.sigla} value={estado.sigla}>{estado.nome}</option>
                        ))}
                      </Input>
                      <span style={{ color: '#ff5370' }}>{errors.state && 'select one state'}</span>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <input className="form-control" type="text" name="postalCode" value={formData.postalCode} onChange={handleChange} placeholder="Cep" />
                        <span style={{ color: '#ff5370' }}>{errors.postalCode && 'Required integer'}</span>
                    </FormGroup>
                    
                    <Row className="text-end">
                      <Col>
                        <Button className="secondary me-2" onClick={() => setCardAlreadyRegistered(false)}>Cancelar</Button>
                        <Button className="primary" color="primary" type='submit'>Adicionar Método de Pagamento</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              )}

            </Col>
          ) : (
            <div className="mb-4">
              <H5>Você já possui um método de pagamento cadastrado</H5>
              <Button className="btn-primary" onClick={() => setCardAlreadyRegistered(true)}>
                Mudar método de pagamento
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </Fragment>
  )
}