import React, { Fragment, useState } from 'react';
import { Btn, H4, P, H6, Image } from '../../../AbstractElements';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import man from "../../../assets/images/dashboard/profile.png";
import { ToastContainer, toast } from "react-toastify";
import { API } from "../../../api/API/api";
import "./Login.scss"

// import logoWhite from '../../../assets/images/logo/logo-tutor.png';
// import logoDark from '../../../assets/images/logo/logo_dark.png';

const LoginForm = ({ logoClassMain }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  const loginAuth = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      console.log('email ou senha não informado')
      return
    }

    try {
      const response = await API.post(`/auth/login`, { email, password })

      const data = response.data
      console.table(data.user);
      localStorage.setItem("Name", data.user.name);
      localStorage.setItem("user_id", data.user.id);
      localStorage.setItem("group_id", data.user.group_id);
      localStorage.setItem("profileURL", data.user.photo || man);
      localStorage.setItem("token", data.access_token);

      history(`/admin/dashboard`);
      toast.success("Logado com sucesso!..");

    } catch (error) {
      toast.error("Email ou Senha incorretos..");
      console.log(error)
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className='login-card'>
        <div>
          {/* <div>
            <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
              <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} style={{width: "25px"}} />
              <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
            </Link>
          </div> */}

          <div className='login-main'>
            <Form className='theme-form login-form'>
              <H4>Login</H4>
              <P>Informe seu email e sua senha para entrar!</P>
              <FormGroup>
                <Label className='col-form-label m-0'>Email</Label>
                <Input className='form-control' type='email' required onChange={(e) => setEmail(e.target.value)} value={email} />
              </FormGroup>
              <FormGroup className='position-relative'>
                <Label className='col-form-label m-0'>Senha</Label>
                <div className='position-relative'>
                  <Input
                    className='form-control'
                    type={togglePassword ? 'text' : 'password'}
                    name='login[password]'
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                    {togglePassword ? (
                      <i className="icofont icofont-eye eye-icon"></i>

                    ) : (
                      <i className="icofont icofont-eye-blocked eye-icon"></i>

                    )}
                  </div>
                </div>
              </FormGroup>

              <FormGroup className='position-relative'>
                <div className='checkbox'>
                  <Input id='checkbox1' type='checkbox' />
                  <Label className='text-muted' for='checkbox1'>
                    Lembrar senha
                  </Label>
                </div>
                <Link className='link' to={`${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`}>
                  Esqueceu sua senha?
                </Link>
              </FormGroup>
              <FormGroup>
                <Btn attrBtn={{ className: 'd-block w-100 mt-2', outline: true, color: 'primary', type: 'submit', onClick: (e) => loginAuth(e) }}>Entrar</Btn>
              </FormGroup>

              <P attrPara={{ className: 'text-center mb-0 ' }}>
                Ainda não possui uma conta?
                <Link className='ms-2' to={`/admin/register`}>
                  Criar uma conta
                </Link>
              </P>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;
