import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap'
import { H6 } from '../../../../../AbstractElements'

import { Image } from '../../_Commons/Inputs/Image'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    order: '',
    thumb_format: 0,
    display_title: true
}

const orderingMethods = [
    {
        label: 'Mais vendidos',
        value: 'bestsellers'
    },
    {
        label: 'Recentes',
        value: 'new'
    },
    {
        label: 'Ordem alfabética',
        value: 'a-z'
    }
]

const thumbFormats = [
    {
        label: 'Retangular',
        value: '0'
    },
    {
        label: 'Quadrada',
        value: '1'
    },
    {
        label: 'Automática',
        value: '2'
    }
]

export default function General() {
    const { tenant_id } = useParams();
    const [formData, setFormData] = useState(formDataDefault);

    const apiPath = `/tenants/${tenant_id}/management/cms/general`

    useEffect(() => {
        async function getData() {
            try {
                const response = await API.get(apiPath);
                setFormData(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, [tenant_id]);


    const handleInputChange = (e) => {
        let { name, value } = e.target

        value = ['image'].includes(name) ? e.target.files[0] : value
        value = ['display_title'].includes(name) ? !formData[name] : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { image, ...payload } = formData

            if (image) {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }

                const form = new FormData()
                form.append('image', image)
                const responseImg = await API.post(`${apiPath}/upload`, form, config)

                payload.image_url = responseImg.data.image_url
            }

            const response = await API.put(`${apiPath}`, payload)
            setFormData(response.data)
            toast.success('Sucesso!')

        } catch (error) {
            console.log(error);
            toast.error('Error')
        }
    };

    return (
        <>
            <Card className='col-12' style={{ marginBottom: "8.5em" }}>
                <div className="card-header">
                    <h5 className="card-title p-b-0 p-t-0">Geral</h5>
                </div>
                <Form>
                    <Row className="m-4">
                        <Col md="12" className="mb-3">
                            <H6>Ordenar por:</H6>
                            <FormGroup>
                                <Input type='select' name='order' id='order' onChange={handleInputChange}>
                                    {orderingMethods.map((method, index) => (
                                        <option key={index} value={method.value} selected={method.value === formData.order}>
                                            {method.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="m-4">
                        <Col md="12" className="mb-3">
                            <H6>Miniatura do curso:</H6>
                            <FormGroup>
                                <Input type='select' name='thumb_format' id='thumb_format' onChange={handleInputChange}>
                                    {thumbFormats.map((method, index) => (
                                        <option key={index} value={method.value} selected={method.value === formData.thumb_format}>
                                            {method.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="m-4">
                        <Col md="12" className="mb-3">
                            <FormGroup>
                                <H6>Exibir título do curso:</H6>
                                <FormGroup switch>
                                    <Input type='switch' name='display_title' id='display_title' onChange={handleInputChange} checked={formData.display_title} />
                                    <Label for='display_title'>{formData.display_title ? 'Ativo' : 'Inativo'}</Label>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="m-4">
                        <Col md="12" className="mb-3">
                            <Image label='Alterar imagem do login 966x496:' id='image' name='image' src={formData.image_url} onChange={handleInputChange} />
                        </Col>
                    </Row>

                    <Row className="text-right rowCustom">
                        <Col className="text-right">
                            <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                                Salvar alterações
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

