import React, { useEffect, useRef, useState, createRef } from "react";
import Files from "react-files";
import { Breadcrumbs, Btn } from "../../../../../../AbstractElements";
import { ToastContainer, toast } from "react-toastify";

import {
  Container,
  Row,
  Col,
  Input,
  Card,
} from "reactstrap";
import "../../LinkTree/styles/linktree.scss";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../../../../../api/API/api";
import CreatedCard from "../../component/CreatedCard";
import CardProduct from "../../component/CardProduct";

const EditorPages = (args) => {
  const navigation = useNavigate();
  const [step, setStep] = useState(3);
  const [linktreeCreated, setLinktreeCreated] = useState(false);
  const [userData, setUserData] = useState([]);

  const [formData, setFormData] = useState({
    name: "Seu nome",
    image: "",
    colorBackground: "#3B65FE",
    colorName: "#000000",
    colorNameLink: "#000000",
    colorBackLink: "#FFFFFF",
  });

  const token = localStorage.getItem("token");
  const inputRefs = useRef(userData.map(() => createRef()));

  useEffect(() => {
    inputRefs.current = userData.map(() => createRef());
  }, [userData]);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const toggleDeleteConfirmationModal = () => setDeleteConfirmationModal(!deleteConfirmationModal);

  const handleNewLink = () => {
    setStep(1);
  };

  const checkIfLinktreeCreated = async () => {
    try {
      const response = await API.get(`/linktree/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Resposta da API:", response);

      if (response && Object.keys(response.data).length > 0) {
        setLinktreeCreated(true);
        setStep(3);
      }
    } catch (error) {
      console.error("Erro ao verificar o status do Linktree:", error);
    }
  };

  useEffect(() => {
    checkIfLinktreeCreated();
  }, []);

  // Case 1

  const handleImageChange = (files) => {
    const file = files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({ ...prevData, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      name: inputValue !== "" ? inputValue : "Seu nome",
    }));
  };

  const handleColorBackground = (event) => {
    const newColor = event.target.value;
    setFormData((prevData) => ({ ...prevData, colorBackground: newColor }));
  };

  const handleColorName = (event) => {
    const newColorName = event.target.value;
    setFormData((prevData) => ({ ...prevData, colorName: newColorName }));
  };

  const handleColorBackLink = (event) => {
    const newColorBackLink = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      colorBackLink: newColorBackLink,
    }));
  };

  const handleColorNameLink = (event) => {
    const newColorNameLink = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      colorNameLink: newColorNameLink,
    }));
  };
  // End Case 1

  // Case 2
  const [inputs, setInputs] = useState([
    { id: 1, text: "Link 1", url: "" },
    { id: 2, text: "Link 2", url: "" },
    { id: 3, text: "Link 3", url: "" },
  ]);

  const handleDeleteDiv = (id) => {
    setInputs((prevInputs) => prevInputs.filter((input) => input.id !== id));
  };

  const handleAddInput = () => {
    if (inputs.length < 4) {
      const newInput = {
        id: inputs.length + 1,
        text: `Link ${inputs.length + 1}`,
      };
      setInputs((prevInputs) => [...prevInputs, newInput]);
    }
  };

  const handleChangeLinkName = (e, input) => {
    const newText = e.target.value;
    setInputs((prevInputs) =>
      prevInputs.map((i) => (i.id === input.id ? { ...i, text: newText } : i))
    );
  };

  const handleChangeLinkSocial = (e, input) => {
    const newUrl = e.target.value;
    setInputs((prevInputs) =>
      prevInputs.map((i) => (i.id === input.id ? { ...i, url: newUrl } : i))
    );
  };

  // End Case 2


  // Case 3
  const [reloadData, setReloadData] = useState(true);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (reloadData) {
      async function getData() {
        setLoading(true);
        try {
          const response = await API.get(`/linktree`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserData(response.data || []);
          console.table(response.data);

          setReloadData(false);
        } catch (error) {
          console.error(error);
          toast.error("Não foi possível exibir seus links criados:", error);
        } finally {
          setLoading(false);
        }
      }
      getData();
    }
  }, [reloadData, token]);

  const handleDeleteLink = async (user_id, slug) => {
    try {
      const token = localStorage.getItem("token");
      const response = await API.delete(`/linktree/${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        toast.success("Link excluído com sucesso!", {
          autoClose: 2000,
          onClose: () => {
            setDeleteConfirmationModal(false);
            setReloadData(true);
          },
        });
      }
    } catch (error) {
      console.error("Erro ao excluir link:", error);
      toast.error("Erro ao excluir link:", error.message);
    }
  };


  const getNumberOfCards = () => {
    return userData.length;
  };

  // End Case 3

  // Bottoms and API

  const combinedFormData = {
    ...formData,
    links: inputs.filter((link) => link.text && link.url),
  };

  const handleSuccessToast = () => {
    toast.success("Link da bio criado com sucesso!", {
      autoClose: 1000,
      onClose: () => setStep(3),
    });
  };

  async function sendDataToAPI() {
    try {
      const response = await API.post(`/linktree`, combinedFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.status);
      if (response.status === 201) {
        handleSuccessToast();
      }
    } catch (error) {
      console.error(error);
      toast.error(`Não foi possível criar seu link: ${error.message}`);
    }
  }

  const handleContinuar = async () => {
    try {
      if (step === 1) {
        if (formData.name !== "" && formData.image !== "") {
          setStep((prevStep) => prevStep + 1);
        } else {
          toast.error("Por favor preencha os campos antes de prosseguir.");
        }
      } else if (step === 2) {
        const combinedFormData = {
          ...formData,
          links: inputs.filter((link) => link.text && link.url),
        };
        await sendDataToAPI();
      }
    } catch (error) {
      console.error("Erro ao prosseguir:", error);
      toast.error("Erro ao prosseguir:", error);
    }
  };

  const handleRetornar = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    } else if (step === 1) {
      navigation("/admin");
    }
  };

  // End Bottoms and API

  const renderStep1Inputs = () => {
    return (<>
      <Breadcrumbs parent="Home" mainTitle="Novo Link da Bio" title="Novo Link Bio " />

      <Row className="test "
        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'space-between' }}
      >
        <Col xs="4">
          <Card className='col-15 p-20'> 
          <label className="LabelsText" htmlFor="Name">
            Nome
          </label>
          <Input
            placeholder="Seu nome"
            name="name"
            value={formData.name === "Seu nome" ? "" : formData.name}
            onChange={handleChange}
            autoComplete="name"
            required
          />

          <label className="LabelsText" htmlFor="fileInput">
            Sua foto
          </label>
          <div className="d-flex align-items-center gap-4 ">
            {formData.image ? (
              <img
                src={formData.image}
                alt="avatar"
                className="avatar-input"
              />
            ) : (
              <img
                src="/image-profile-default.jpg"
                alt="avatar"
                name="fileInput"
                className="avatar-input"
              />
            )}
            <Files
              className="files-dropzone fileContainer"
              onChange={(files) => handleImageChange(files)}
              accepts={["image/*"]}
              multiple={false}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <div className="d-flex justify-content-center">
                <Btn
                  attrBtn={{
                    className: "mt-2",
                    type: "button",
                    color: "primary",
                  }}
                >
                  Enviar foto
                </Btn>
              </div>
            </Files>
          </div>
          <div>
            <h5>Cores</h5>
            <div className="d-flex flex-column gap-5">
              <div className="d-flex gap-5">
                <div className="d-flex flex-column">
                  <label htmlFor="bgColor">Cor de fundo:</label>
                  <input
                    type="color"
                    name="bgColor"
                    value={formData.colorBackground}
                    onChange={handleColorBackground}
                  />
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="colorName">Cor do nome:</label>
                  <input
                    type="color"
                    name="colorName"
                    value={formData.colorName}
                    onChange={handleColorName}
                  />
                </div>
              </div>
              <div className="d-flex gap-4">
                <div className="d-flex flex-column">
                  <label htmlFor="bottonColor">Cor dos botões:</label>
                  <input
                    type="color"
                    name="bottonColor"
                    value={formData.colorBackLink}
                    onChange={handleColorBackLink}
                  />
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="colorText">Cor do texto dos botões:</label>
                  <input
                    type="color"
                    name="colorText"
                    value={formData.colorNameLink}
                    onChange={handleColorNameLink}
                  />
                </div>
              </div>
            </div>
          </div>
          </Card>
        </Col>

        <Col xs="3" style={{ }}>
          <div
            className="repro-content"
            style={{ backgroundColor: formData.colorBackground }}
          >
            <div className="NameAndAvatar">
              {formData.image ? (
                <img
                  src={formData.image}
                  alt="avatar"
                  className="avatar-img"
                />
              ) : (
                <img
                  src="/image-profile-default.jpg"
                  alt="avatar"
                  className="avatar-img"
                />
              )}
              <span style={{ color: formData.colorName }}>
                {formData.name}
              </span>
            </div>
            <div className="list">
              <div
                className="link-list"
                style={{ backgroundColor: formData.colorBackLink }}
              >
                <span style={{ color: formData.colorNameLink }}>Link 01</span>
              </div>
              <div
                className="link-list"
                style={{ backgroundColor: formData.colorBackLink }}
              >
                <span style={{ color: formData.colorNameLink }}>Link 02</span>
              </div>
              <div
                className="link-list"
                style={{ backgroundColor: formData.colorBackLink }}
              >
                <span style={{ color: formData.colorNameLink }}>Link 03</span>
              </div>
              <div
                className="link-list"
                style={{ backgroundColor: formData.colorBackLink }}
              >
                <span style={{ color: formData.colorNameLink }}>Link 04</span>
              </div>
            </div>
          </div>
        </Col>
      </Row></>
    );
  };

  const renderStep2Inputs = () => {
    return (
      <>
          <Container fluid={true}>
          <Breadcrumbs mainTitle='Link na Bio' parent='Home' title='Link na Bio' />
 
        <Row>
          <Col>
            <div>
              <h5>Botões</h5>
              <div className="content-inputs">
                {inputs.map((input) => (
                  <div key={input.id} className="d-flex inputLinks">
                    <Input
                      type="text"
                      name="text"
                      value={input.text || ""}
                      onChange={(e) => handleChangeLinkName(e, input)}
                      placeholder={`Texto do Link ${input.id}`}
                    />
                    <Input
                      type="text"
                      name="url"
                      value={input.url || ""}
                      onChange={(e) => handleChangeLinkSocial(e, input)}
                      placeholder={`URL do Link ${input.id}`}
                    />
                    <i
                      className="icofont icofont-trash icone"
                      onClick={() => handleDeleteDiv(input.id)}
                    ></i>
                  </div>
                ))}
                <button
                  onClick={handleAddInput}
                  className="purchase-btn btn btn-primary btn-hover-effect f-w-500"
                  disabled={inputs.length >= 4}
                >
                  Adicionar
                </button>
              </div>
            </div>
          </Col>

          <Col className="mb-xxl-1 d-flex justify-content-end w-100">
            <div
              className="repro-content"
              style={{ backgroundColor: formData.colorBackground }}
            >
              <div className="NameAndAvatar">
                {formData.image ? (
                  <img
                    src={formData.image}
                    alt="avatar"
                    className="avatar-img"
                  />
                ) : (
                  <img
                    src="https://static-public.klickpages.com.br/uploads/media/file/5310958/foto-03.jpg"
                    alt="avatar"
                    className="avatar-img"
                  />
                )}
                <span style={{ color: formData.colorName }}>
                  {formData.name}
                </span>
              </div>
              <div className="list">
                {inputs.map((input) => (
                  <div
                    key={input.id}
                    className="link-list"
                    style={{ backgroundColor: formData.colorBackLink }}
                  >
                    <span style={{ color: formData.colorNameLink }}>
                      {input.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        </Container>
      </>
    );
  };

  const renderStep3Inputs = () => {
    return (
      <>
        <Container fluid={true} style={{  }}>
          <Breadcrumbs mainTitle='Link na Bio' parent='Home' title='Link na Bio' subParent="Products" />
          <div className="d-flex flex-column" style={{ height: "100%", marginLeft: '10px' }}>


            {/* Primeira Linha */}
            <Row xs="12" className="mb-4" style={{
              //  marginLeft: '1.5em', 
               }}>
              <div>
                <CreatedCard
                  title="Link na Bio: Social Commerce"
                  nameButtom="Criar link"
                  count={getNumberOfCards()}
                  func={handleNewLink}
                />
              </div>
            </Row>

            {/* Segunda Linha */}
            <Row xs="12" style={{ 
              // marginLeft: '1.5em', 
          }}>
              <div className="d-flex gap-2 flex-column">
                {loading ? (
                  <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  userData.map((userDataItem, index) => {
                    return (
                      <CardProduct
                        key={index}
                        image={userDataItem.image}
                        name={userDataItem.name}
                        url={userDataItem.url}
                        user_id={userDataItem.user_id}
                        funcDel={() => handleDeleteLink(userDataItem.user_id, userDataItem.url)}
                        index={index}
                        date={userDataItem.created_at}
                      />
                    );
                  })
                )}
              </div>
            </Row>

          </div>
        </Container>
      </>
    );
  };

  return (
    <>
      {!linktreeCreated && (
        <Row>
          <Col>
            {step === 1 ? renderStep1Inputs() : null}
            {step === 2 ? renderStep2Inputs() : null}
          </Col>
        </Row>
      )}

      {step === 3 && (
        <Row>
          <Col>{renderStep3Inputs()}</Col>
        </Row>
      )}

      {!linktreeCreated && (
        <Row style={{ background: '#f5f9ff' }}>
          <Col>
            {step !== 3 && (
              <div className="d-flex g-sm-2 justify-content-between" style={{ marginBottom: '40px', marginTop: '20px' }}>
                <Link to="/admin" onClick={handleRetornar}>
                  <button className="purchase-btn btn btn-primary btn-hover-effect f-w-500 d-flex align-items-center gap-2 justify-content-center">
                    <i className="icon-arrow-left"></i> Retornar
                  </button>
                </Link>

                <button
                  onClick={handleContinuar}
                  className="purchase-btn btn btn-primary btn-hover-effect f-w-500 d-flex align-items-center gap-2 justify-content-center"
                >
                  {step === 1 ? "Continuar" : "Publicar"}{" "}
                  <i className="icon-arrow-right"></i>
                </button>
              </div>
            )}
          </Col>
        </Row>
      )}
      <ToastContainer />
    </>
  );
};

export default EditorPages;
