import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { API } from '../../../api/API/api';
import { useParams } from 'react-router-dom';
import UserProfile from '../../Bonus-Ui/Tour/UserProfile';
import UserInfo from "../../TutorCasts/Profile/UserInfo"
import SitesInfo from "../../TutorCasts/Profile/SitesInfo";

export default function ClienteDetalhes() {
    const { clientId } = useParams(); // Obtém a ID do cliente da URL
    const [cliente, setCliente] = useState({});

    useEffect(() => {
        async function getCliente() {
            
            try {
                const currentURL = window.location.href;
                console.log(currentURL, clientId);
                const token = localStorage.getItem("token");
                if (clientId) {
                    const response = await API.get(`backoffice/users/${clientId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    const data = response.data;
                    setCliente(data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getCliente();
    }, [clientId]);
    const userProfile = {
        name: cliente.name,
        last_name: cliente.last_name || '',
        role: "Administrador",
        photo: "user-photo.jpg",
    };
    const userInfo = {
        email: cliente.email,
        document: cliente.document,
        address_line: cliente.address_line,
        zip_code: cliente.zip_code,
    };
    const tenants = cliente.tenants || [];
    console.log(cliente);
    return (
        <Fragment>
            <Container fluid="true">
                <Breadcrumbs mainTitle="Perfil" parent="Home" title="Perfil" />
                <Container fluid={true}>
                    <div className="user-profile">
                        <Row>
                            <UserProfile profileData={userProfile} />
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="6">
                                <UserInfo userInfo={userInfo} />

                            </Col>
                            <Col sm="12" md="12" lg="6">
                                <SitesInfo tenants={tenants} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Container>
        </Fragment>
    );
}
    