import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row, Table, Input } from 'reactstrap'
import { Badges, Breadcrumbs, Btn } from '../../../AbstractElements'
import { API } from '../../../api/API/api';
import "./pages.scss"
import "primereact/resources/themes/lara-light-blue/theme.css"
import "primereact/resources/primereact.min.css"

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from 'react-router'
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
// import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'

export default function Instancias() {

  const history = useNavigate()

  const token = localStorage.getItem("token")
  const [tenants, setTenants] = useState([])
  const [users, setUsers] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedOwnerId, setSelectedOwnerId] = useState('');
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);


  const formatDate = (value) => {
    return new Date(value).toLocaleString('pt-BR', { hour12: false });
  };

  const handleSave = async () => {
    try {
      if (selectedInstanceId && selectedOwnerId) {
        const response = await API.put(`/tenants/${selectedInstanceId}/management/owner?created_by=${selectedOwnerId}`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        const data = response.data
        setTenants(data)

      }

      setDisplayModal(false);
    } catch (error) {
      console.error('Erro ao executar PATCH:', error);
    }
  };


  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.created_at);
  };

  const handleBtnClick = (id) => {
    setSelectedInstanceId(id)
    setDisplayModal(true);
  };
  const handleOwnerChange = (newOwnerId) => {
    console.table(newOwnerId);
    setSelectedOwnerId(newOwnerId);
  };

  const getSeverity = (value) => {
    switch (value) {
      case 'activated':
        return 'success';

      case 'deactivating':
        return 'danger';

      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.active === "activated" ? "ativo" : "inativo"} severity={getSeverity(rowData.active)}></Tag>;
  };


  const donoBodyTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '5px' }}>{rowData.owner}</span>

        <Btn
          attrBtn={{
            className: "botao-hamburguer",
            onClick: () => handleBtnClick(rowData.id),
          }}
        >
          <i className="fa fa-refresh" aria-hidden="true" style={{ color: 'green' }}></i>
        </Btn>
      </div>
    );
  };


  async function getUsers() {
    try {
      const response = await API.get("/backoffice/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      const data = response.data;
      console.log(data);
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  }
  async function getTenants() {
    try {
      const response = await API.get("/backoffice/tenants", {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      const data = response.data
      setTenants(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getTenants();
    getUsers();
  }, [])

  console.log(tenants)

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Instâncias' parent='Home' title='Instâncias' />
      <Container fluid="true">
        <Card className='m-b-15 shadow'>

          <Input
            type="text"
            placeholder="Buscar Instancias..."
            defaultValue={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </Card>
        <Row>

          <Col sm='12'>
            <Card>
              <DataTable value={tenants} paginator rows={15}
                sortMode='multiple' selectionMode="single"
                onSelectionChange={(e) => history(`/backoffice/instancias/${e.value.id}/settings`)}
                dataKey="id" metaKeySelection={false}
                globalFilter={globalFilter}
              >
                <Column field="id" sortable style={{ width: '12%' }} header="Id"></Column>
                <Column field="owner" body={donoBodyTemplate} sortable style={{ width: '12%' }} header="Proprietario">
                </Column>

                <Column field="domain" sortable style={{ width: '12%', textAlign: 'center' }} header="Domínio"></Column>
                <Column field="administrator" sortable style={{ width: '12%', textAlign: 'center' }} header="Administrador"></Column>
                <Column field="service" sortable style={{ width: '12%', textAlign: 'center' }} header="Financeiro"></Column>
                <Column field="finance" sortable style={{ width: '12%', textAlign: 'center' }} header="Atendimento"></Column>

                <Column field="created_at" body={dateBodyTemplate} sortable style={{ width: '12' }} header="Criado em"></Column>
                <Column field="active" sortable body={statusBodyTemplate} style={{ width: '12%', textAlign: 'center' }} header="Status"></Column>
              </DataTable>
            </Card>
          </Col>
        </Row>
      </Container>
      <Dialog header='Novo Proprietário' visible={displayModal} style={{ width: '30vw' }} onHide={() => setDisplayModal(false)}>
        <div className='p-fluid'>
          <div className='p-field'>
            <label htmlFor='ownerDropdown'>Selecione um novo Proprietário:</label>
            <Dropdown
              id='ownerDropdown'
              options={users.map(user => ({ label: user.name, value: user.id }))}
              value={selectedOwnerId}
              onChange={(e) => handleOwnerChange(e.value)}
              placeholder='Selecione'
            />
          </div>
          <div className='p-field mt-2'>
            <Btn attrBtn={{ onClick: handleSave }} >Salvar</Btn>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}
