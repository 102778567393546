import React, { Fragment, useState, useEffect } from "react";
import { Btn, H4, H5, Image } from "../../../../AbstractElements";
import { get, useForm } from "react-hook-form";
import { Row, Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Card, CardHeader } from 'reactstrap'
import { API } from "../../../../api/API/api";
import { useNavigate, useParams } from "react-router";
import { toast } from 'react-toastify';
import { useHref } from "react-router-dom";

const ProfileTTC = () => {
  const [user, setUser] = useState({})
  const { clientId } = useParams(); // Obtém a ID do cliente da URL
  const token = localStorage.getItem("token");
  const { handleSubmit, formState: { errors } } = useForm();
  // const [imagemBase64, setImagemBase64] = useState('');
  const history = useNavigate(); // Adicione esta linha para obter o objeto history

  const onEditSubmit = async () => {
    let url; // Declare the variable outside the if-else blocks

    const obtectUserMap = {
      "name": user.name,
      "last_name": user.last_name || "",
      "email": user.email || "",
      "photo": user.photo || "",
      "phone": user.phone || null,
      "document": user.document || null,
      "group_id": user.group_id || 1
    };

    try {
      if (window.location.pathname === "/admin/perfil") {
        url = "profile"; // Assign the value inside the if block
      } else {
        url = "/backoffice/users/" + clientId; // Assign the value inside the else block
      }

      await API.put(url, obtectUserMap, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });

      if (window.location.pathname === "/admin/perfil") {
        history("/admin/perfil/edit");
      } else {
        history(`/backoffice/clientes/${clientId}`);
      }

      toast.success("Cliente Atualizado com Sucesso!");


    } catch (error) {
      console.log(error);
    }
  };


  const inputChange = (key, value) => {
    setUser({ ...user, [key]: value })
  }

  useEffect(() => {
    const getUser = async () => {
      try {

        const currentURL = window.location.href;
        console.log(currentURL, clientId);

        if (clientId) {
          // Se o clientId estiver presente na URL
          const response = await API.get(`backoffice/users/${clientId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = response.data;
          console.log(data);
          setUser(data);
        } else {
          // Se o clientId não estiver na URL, tente obter do localStorage
          const localStorageClientId = localStorage.getItem("user_id");
          if (localStorageClientId) {
            const response = await API.get(`backoffice/users/${localStorageClientId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const data = response.data;
            console.log(data);

            setUser(data);
          } else {
            console.log("clientId não encontrado");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }



    getUser()
  }, []);

  return (
    <Fragment>
      <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
        <CardHeader>
          <H5 attrH5={{ className: "card-title p-b-0 p-t-0" }}>{"Informações Pessoais"}</H5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="6" md="6">
              <FormGroup>
                <Label className="form-label">Nome</Label>
                <input className="form-control" type="text" value={user.name} onChange={(e) => inputChange('name', e.target.value)} /><span style={{ color: "red" }}>{errors.FirstName && 'FirstName is required'} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="6">
              <FormGroup><Label className="form-label">Sobrenome</Label>
                <input className="form-control" type="text" value={user.last_name} onChange={(e) => inputChange('last_name', e.target.value)} /><span style={{ color: "red" }}>{errors.LastName && 'LastName is required'} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="6">
              <FormGroup> <Label className="form-label">Email</Label>
                <input className="form-control" type="email" value={user.email} disabled onChange={(e) => inputChange('email', e.target.value)} /><span style={{ color: "red" }}>{errors.EmailAddress && 'EmailAddress is required'} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="6">
              <FormGroup> <Label className="form-label">CPF</Label>
                <input className="form-control" type="text" value={user.document} onChange={(e) => inputChange('document', e.target.value)} /><span style={{ color: "red" }}>{errors.EmailAddress && 'EmailAddress is required'} </span>
              </FormGroup>
            </Col>
            {/* <Col md="5">
                            <FormGroup><Label className="form-label">{Country}</Label>
                                <Input type="select" name="select" className="form-control btn-square">
                                    {UsersCountryMenu.map((items, i) =>
                                        <option key={i}>{items}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col> */}
            {/* <Col md="12">
                            <div> <Label className="form-label">{AboutMe}</Label>
                                <input type="textarea" className="form-control" rows="5" placeholder="Enter About your description"  onChange={(e) => inputChange('aboutme', e.target.value)} />
                            </div>
                        </Col> */}
          </Row>
        </CardBody>
        <CardHeader>
          <H5 attrH5={{ className: "card-title p-b-0 p-t-0" }}>{"Endereço"}</H5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="6" md="6">
              <FormGroup><Label className="form-label">CEP</Label>
                <input className="form-control" type="text" value={user.zip_code} onChange={(e) => inputChange('zip_code', e.target.value)} />
              </FormGroup>
            </Col>
            <Col sm="6" md="6">
              <FormGroup><Label className="form-label">Estado</Label>
                {/* <input className="form-control" type="text" value={user.state} onChange={(e) => inputChange('state', e.target.value)} /> */}
                <Input type="select" name="select" value={user.state} onChange={(e) => inputChange('state', e.target.value)} className="form-control btn-square digits" >
                  <option value=""></option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup><Label className="form-label">Endereço</Label>
                <input className="form-control" type="text" value={user.address_line} onChange={(e) => inputChange('address_line', e.target.value)} /><span style={{ color: "red" }}>{errors.Address && 'Address is required'} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="6">
              <FormGroup> <Label className="form-label">Cidade</Label>
                <input className="form-control" type="text" value={user.city} onChange={(e) => inputChange('city', e.target.value)} /><span style={{ color: "red" }}>{errors.City && 'City is required'} </span>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: "primary", outline: true, type: "submit" }} >{"Salvar alterações"}</Btn>
        </CardFooter>
      </Form>
    </Fragment>
  )
}
export default ProfileTTC