import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'
import { Image } from '../../../_Commons/Inputs/Image'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    url: '',
    image: null
}

export default function Slides() {
    const { tenant_id } = useParams()
    const [data, setData] = useState([])

    const [modalVisible, setModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const [userDelete, setUserDelete] = useState(0)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const setTableData = (payloads) => ({
        columns: ['Link'],
        rows: payloads.map(item => [
            item.url
        ]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/cms/slides/banners`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (isEditing, data) => {
        setIsEditing(isEditing)
        setFormData(data)
        setModalVisible(true)
    }

    const openModalDelete = ({ id }) => {
        setUserDelete(id)
        setModalDeleteVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, image, ...payload } = formData

            if (image) {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }

                const form = new FormData()
                form.append('image', image)
                const responseImg = await API.post(`${apiPath}/upload`, form, config)

                payload.image_url = responseImg.data.image_url
            }

            const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    const submitModalDeleteForm = async (id) => {
        try {
            const response = await API.delete(`${apiPath}/${id}`)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Error')
        }

        setModalDeleteVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target

        value = ['image'].includes(name) ? e.target.files[0] : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    return (
        <>
            <Table
                header='Slides' data={setTableData(data)}
                onNewClick={() => openModal(false, formDataDefault)}
                onEditClick={(payload) => openModal(true, payload)}
                onDeleteClick={(payload) => openModalDelete(payload)}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Slide' : 'Criar Slide'} onSubmit={() => submitModalForm()} >
                <Form>

                    <FormGroup>
                        <Label for='url'>URL:</Label>
                        <Input type='text' name='url' id='url' onChange={handleModalInputChange} value={formData.url} />
                    </FormGroup>

                    <Image label='Slide 1600x700:' id='image' name='image' src={formData.image_url} onChange={handleModalInputChange} />

                </Form>
            </ModalCreateAndEdit>

            <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
        </>
    )
}

