import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap'

import { Table } from '../../_Commons/Table/Index'

import { API } from '../../../../../api/API/api'

const statusLiteral = {
    Pendente: 'Pendente',
    Ativo: 'Aprovado',
    Inativo: 'Rejeitado'
} 


function AttestsTab({ data, buttons }) {
    const setTableData = (payloads) => ({
        columns: ['Autor(a)', 'Conteúdo', 'Status'],
        rows: payloads.map(item => [item.name, item.text, statusLiteral[item.status]]),
        payloads: payloads
    })


    return (
        <>
            <Table
                header='Depoimentos' data={setTableData(data)}
                customButtons={buttons}
            />
        </>
    )
}


export default function Attests() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        pending: [],
        active: [],
        inactive: []
    })

    const [activeTab, setActiveTab] = useState('pending')

    const apiPath = `/tenants/${tenant_id}/management/cms/attests`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])


    const submit = async (isApproved, data, isDelete) => {
        try {
            const { id, ..._ } = data
            const response = isDelete ? await API.delete(`${apiPath}/${id}`) : await API.put(`${apiPath}/${id}`, { isApproved })

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

    }


    const pendingButtons = ({ payload }) => (
        <>
            <Button color='primary' outline={true} onClick={() => submit(true, payload)}>
                <i class="fa fa-thumbs-o-up" />
            </Button>
            <Button color='secondary' outline={true} onClick={() => submit(false, payload)}>
                <i class="fa fa-thumbs-o-down" />
            </Button>
        </>
    )


    const activeButtons = ({ payload }) => (
        <>
            <Button color='secondary' outline={true} onClick={() => submit(false, payload)}>
                <i class="fa fa-thumbs-o-down" />
            </Button>
        </>
    )


    const inactiveButtons = ({ payload }) => (
        <>
            <Button color='primary' outline={true} onClick={() => submit(true, payload)}>
                <i class="fa fa-thumbs-o-up" />
            </Button>
            <Button color='secondary' outline={true} onClick={() => submit(false, payload, true)}>
                <i className='fa fa-trash-o fa-lg' />
            </Button>
        </>
    )



    return (
        <>
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink active={activeTab === 'pending'} onClick={() => setActiveTab('pending')}>Pendente</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={activeTab === 'active'} onClick={() => setActiveTab('active')}>Aprovado</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={activeTab === 'inactive'} onClick={() => setActiveTab('inactive')}>Rejeitado</NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId='pending'>
                        <AttestsTab data={data.pending} buttons={pendingButtons} />
                    </TabPane>
                    <TabPane tabId='active'>
                        <AttestsTab data={data.active} buttons={activeButtons} />
                    </TabPane>
                    <TabPane tabId='inactive'>
                        <AttestsTab data={data.inactive} buttons={inactiveButtons} />
                    </TabPane>
                </TabContent>
            </div>
        </>
    )
}