import React, { Fragment, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Taptop from './TapTop';
import Header from './Header';
import Sidebar from './Sidebar';
import ThemeCustomize from '../Layout/ThemeCustomizer';
import Footer from './Footer';
import CustomizerContext from '../_helper/Customizer';
import AnimationThemeContext from '../_helper/AnimationTheme';
import ConfigDB from '../Config/ThemeConfig';
import Header2 from './Header2';
import Sidebar2 from './Sidebar/Sidebar2';

const AppLayout = ({ children, classNames, ...rest }) => {
  const { layout } = useContext(CustomizerContext);
  const { sidebarIconType } = useContext(CustomizerContext);

  const layout1 = localStorage.getItem('sidebar_layout') || layout;
  const sideBarIcon = localStorage.getItem('sidebar_icon_type') || sidebarIconType;
  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme = localStorage.getItem('animation') || animation || ConfigDB.data.router_animation;
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 899); // Altere o valor 768 conforme necessário
    };


    // Adiciona um listener para o evento de redimensionamento
    window.addEventListener('resize', handleResize);

    // Inicializa o estado de mobile com o tamanho atual da tela
    handleResize();
    // Remove o listener quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Fragment>
      {/* <Loader /> */}
      {/* <Taptop /> */}
      <div className={`page-wrapper ${layout1}`} sidebar-layout={sideBarIcon} id='pageWrapper'>
        <Header2 />
        <div className='page-body-wrapper'>
          <Sidebar2 />
          {/* <TransitionGroup {...rest}>
              <CSSTransition key={location.key} timeout={100} classNames={animationTheme} unmountOnExit> */}
          <div style={{ marginLeft: isMobile ? "0" : "1.5em", marginRight: isMobile ? "0" : "1.5em" }}>
            <div style={{ marginLeft: isMobile ? "0" : "5.8em", height: "100vh" }}>
              <Outlet />
              <Footer />
            </div>
          </div>
          {/* </CSSTransition>
            </TransitionGroup> */}
        </div>
      </div>
      {/* <ThemeCustomize /> */}
      <ToastContainer />
    </Fragment>
  );
};
export default AppLayout;
