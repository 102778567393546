import React, { Fragment, useState } from "react";
import Layout from "../Layout";
import { Navbar } from "../_Commons/Navbar/Index";
import DataFlat from "./Tabs/DataFlat";
import Payment from "./Tabs/Payment";

const items = [
  {
    id: 'flat',
    label: 'Plano',
    tabs: [
      {
        label: 'Plano',
        component: DataFlat
      }
    ]
  },
  {
    id: 'payment',
    label: 'Pagamento',
    tabs: [
      {
        label: 'Pagamento',
        component: Payment
      }
    ]
  }
]

function Flat() {
  const [formValues, setFormValues] = useState({
    tenant: {
      name: 'instance-plan'
    }
  })

  return (
    <Fragment>
      <Layout id="instance-plan" site_name={formValues.tenant.name}>
        <Navbar items={items} />
      </Layout>
    </Fragment>
  )
}

export default React.memo(Flat)