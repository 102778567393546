import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import { FaSortDown } from 'react-icons/fa'

export default function NavDropdown({ item, active, onClick }) {    
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen)

    return (
        <>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='down'>
                <DropdownToggle className={active ? 'active' : ''} nav>{item.label} <FaSortDown /></DropdownToggle>
                <DropdownMenu>
                    { item.tabs.map(tab => {
                        return (
                            <DropdownItem onClick={() => onClick(item.id, tab.label)}>
                                {tab.label}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
        </>
    )
}
