import React, { Fragment, useState } from 'react';
import { Btn } from '../../../../../../AbstractElements';
// import { ModalTitle, Simple } from '../../../../../Constant';
import CommonModal from '../../../../../UiKits/Modals/common/modal';

// src/Components/UiKits/Modals/
// import { Col, Form, FormGroup, Label } from 'reactstrap';
import "./Activated.scss"
import { API } from '../../../../../../api/API/api';
import {ReactComponent as WarningIcon} from "../../../../../../assets/tutorcastsIcon/warning.svg"

const ActivatedBtn = ({id}) => {
  const [ tenant_id, setTenantId] = useState()
  const token = localStorage.getItem("token");

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2)
  
  function redirect(){
    window.location.href = `/admin/sites/${id}/settings`
  }
  async function desativar(tenant_id) {
    try {
      // Certifique-se de que 'id' e 'token' estão definidos corretamente
      console.log(`ID: ${tenant_id}, Token: ${token}`);
      const status = 'deactivating'
      const response = await API.patch(`/tenants/${tenant_id}`, 
      null, {
        params: {
            status: 'deactivating',
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    // Se o status da resposta for 200, faça o reload na página
    if (response.status === 200) {
      console.log('Reloading the page...');
      window.location.reload(true); // O argumento true força um reload do cache
    }
      // Exiba detalhes sobre a solicitação e a resposta
      console.log('Request:', response.config);
      console.log('Response:', response.data);
    } catch (error) {
      // Se ocorrer um erro, exiba detalhes específicos do Axios
      if (error.response) {
        // O servidor retornou um código de status diferente de 2xx
        console.log('Error Response Data:', error.response.data);
        console.log('Error Response Status:', error.response.status);
        console.log('Error Response Headers:', error.response.headers);
      } else if (error.request) {
        // A solicitação foi feita, mas não recebeu resposta
        console.log('Error Request:', error.request);
      } else {
        // Algo aconteceu ao configurar a solicitação que desencadeou um erro
        console.log('Error Message:', error.message);
      }
    }
  }
  
  
  

  return (
    <Fragment>
      <div className='buttonsCard'>
        <Btn attrBtn={{ className: 'buttns',color: 'primary', onClick: redirect, outline: true }}>
        <i className="fa fa-edit fa-lg"></i>
        </Btn>
        <Btn attrBtn={{ className: 'buttns', color: 'secondary', onClick: toggle2, outline: true }}>
          <i className="fa fa-trash-o fa-lg"></i>
        </Btn>
      </div>

      <CommonModal isOpen={modal2} title="" toggler={toggle2}>
        <div className='warning'>
          <div className='warning__icon'>
            <svg className='warning__icon-svg' ><WarningIcon /></svg>
          </div>
          <div className='warning__text'>
            <h4>Seu site será desativado!</h4>
            <p>{(id)}</p>
            <p>Ao clicar em <strong>Concordo</strong> seu site será desativado num prazo de 30 dias. Tem certeza que deseja prosseguir com a desativação?</p>
          </div>
          <div className='warning__buttonCont'>
          <Btn attrBtn={{ color: 'secondary', outline: true, className: "warning__buttonCont-btn", onClick: () => desativar(id) }}>Concordo</Btn>
          </div>
        </div>
      </CommonModal>
    </Fragment>
  );
};

export default ActivatedBtn;