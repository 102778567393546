import React, { useState } from 'react';
import { Card, Container, Input, Row, Col } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Btn } from '../../../../../AbstractElements';
import GenericInvoice from '../../../../Application/Ecommerce/Invoice/ItemDescription';

const GenericList = ({ data, columns, onDelete, searchName }) => {
    const [globalFilter, setGlobalFilter] = useState('');
    const [itemToDelete, setItemToDelete] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [displayInvoiceModal, setDisplayInvoiceModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setDisplayConfirmationModal(true);
    };
    const handlePagarClick = (rowData) => {
        setSelectedInvoice(rowData);
        setDisplayInvoiceModal(true);
    };

    const handleDeleteConfirm = async () => {
        if (itemToDelete) {
            await onDelete(itemToDelete); // Pass the entire item to the onDelete function
            setItemToDelete(null);
            setDisplayConfirmationModal(false);
        }
    };

    const renderAlert = (alerta) => {
        let alertColor = '';
        switch (alerta.status) {
            case 'aberta':
                alertColor = 'bg-primary';
                break;
            case 'Pedente':
                alertColor = 'bg-warning';
                break;
            case 'Pago':
                alertColor = 'bg-success';
                break;
            default:
                alertColor = 'bg-secondary'; // Default color
        }
        return <div className={` alert ${alertColor}  d-flex justify-content-center align-items-center`} style={{ borderRadius: "50%", width: '1.5em', height: '1.5em' }}>
            {/* You can add any content here, such as an icon or text */}
        </div>
    };



    function renderCampo1Body(rowData) {
        // Lógica de renderização personalizada para campo1
        return <span>{rowData.campo1}</span>;
    }
    function renderValidade(rowData) {
        // Lógica de renderização personalizada para campo2
        const validade = rowData.validade;
        const data = new Date(validade);
        const dia = data.getDate();
        const mes = data.getMonth() + 1;
        const ano = data.getFullYear();
        return <div>{dia}/{mes}/{ano}</div>;
    }
    function renderPagamento(rowData) {
        // Lógica de renderização personalizada para campo2
        const pagamento = rowData.pagamento;
        const contrato = rowData.contrato;
        const stripe_pagamento_id = rowData.stripe_pagamento_id;
        console.log("stripe_pagamento_id:", stripe_pagamento_id);

        if (contrato === "Assinado" && pagamento !== "Pago") {
            return (
                <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={() => handlePagarClick(rowData)}
                >
                    <i className="icon-credit-card"></i> Pagar
                </button>
            );
        } else if (contrato === "Assinado" && pagamento === "Pago") {
            return (
                <div>
                    {pagamento}
                </div>
            );
        } else if (stripe_pagamento_id !== "") {
            return (
                <div>
                    {stripe_pagamento_id}
                </div>
            );
        } else {
            return (
                <div>
                    {pagamento}
                </div>
            );
        }
    }
    function renderProdutos(rowData) {
        // Lógica de renderização personalizada para campo2
        const produtos = rowData.produtos;
        const uniqueProductNames = new Set();

        return (
            <div>
                <ul>
                    {produtos.reduce((array, produto) => {
                        if (!uniqueProductNames.has(produto.nome)) {
                            array.push(produto);
                            uniqueProductNames.add(produto.nome);
                        }
                        return array;
                    }, []).map((produto) => (
                        <li key={produto.id}>
                            <span className="ml-2">
                                <i className="fa fa-check-circle"></i>
                            </span>
                            &nbsp;
                            {produto.nome}
                            {/* Adicionar a bolinha */}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }




    const renderActions = (rowData) => {
        return (
            <div className="row">
                <button type="button" className="btn btn-outline-secondary" onClick={() => handleDeleteClick(rowData)}>
                    <i className="fa fa-trash-o fa-lg"></i>
                </button>
            </div>
        );
    };


    const formatarValorReais = (valor, casasDecimais) => {
        if (!isNaN(valor)) {
            return '' + valor.toLocaleString("pt-BR", {
                currency: "BRL",
                style: "currency",
                minimumFractionDigits: casasDecimais,
                maximumFractionDigits: casasDecimais,
            });
        } else {
            return 'Valor indisponível';
        }
    };

    const renderTotal = (rowData) => {
        const total = rowData.total
        const formatadoValor = formatarValorReais(total);
        return (
            <div className="row">
                {formatadoValor}
            </div>
        );
    };
    const objt = {
        "alerta": renderAlert,
        "validade": renderValidade,
        "produto": renderProdutos,
        "total": renderTotal,
        "pagamento": renderPagamento
    };

    function renderCustomBody(field) {
        // Check if the field exists in the objt object
        if (objt.hasOwnProperty(field)) {
            return objt[field];
        } else {
            return undefined;
        }
    }
    return (
        <Container fluid="true">
            <Card className="m-b-15 shadow">
                <Input
                    type="text"
                    placeholder={`Buscar por ${searchName}`} // Use template literals here
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                />
            </Card>
            <Row>
                <Col sm="12">
                    <Card>
                        <DataTable value={data} globalFilter={globalFilter}>
                            {columns.map((column, index) => (
                                <Column
                                    key={`${column.field}_${index}`}
                                    field={column.field}
                                    header={column.header}
                                    body={renderCustomBody(column.field)}
                                />
                            ))}
                            <Column key="actions" header="Ações" body={renderActions}></Column>
                        </DataTable>

                    </Card>
                </Col>
            </Row>
            <Dialog
                header={`Confirmar a Exclusão de ${itemToDelete ? itemToDelete.name : ''} ? `}
                visible={displayConfirmationModal}
                style={{ width: '30vw' }}
                onHide={() => setDisplayConfirmationModal(false)}
            >
                <div>
                    <p>Tem certeza de que deseja excluir permanentemente os dados de {itemToDelete ? `"${itemToDelete.name}"` : ''}? Está ação não poderá ser desfeita.</p>

                    <div className='d-flex mt-2 gap-2'>
                        <Btn attrBtn={{ onClick: () => setDisplayConfirmationModal(false) }}>Não</Btn>
                        <Btn attrBtn={{ onClick: handleDeleteConfirm }}>Sim</Btn>
                    </div>
                </div>
            </Dialog>
            {selectedInvoice && (
                <Dialog
                    showHeader="false"
                    visible={displayInvoiceModal}
                    style={{ width: '80vw' }}
                    onHide={() => setDisplayInvoiceModal(false)}
                >
                    <GenericInvoice cart={selectedInvoice.produtos} dadosGerais={selectedInvoice} symbol="R$" />
                </Dialog>
            )}
        </Container>
    );
};

export default GenericList;
