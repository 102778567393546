import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, InputGroup, InputGroupText, Input } from 'reactstrap'

import { Table } from '../../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../../_Commons/Modal/Delete'
import { Dropdown } from '../../../_Commons/Dropdown/Index'
import { Image } from '../../../_Commons/Inputs/Image'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    name: '',
    description: '',
    courses: [],
    percentage_discount: 0,
    bank_slip_discount: 0,
    status: true,
    image_url: ''
}

export default function Combos() {
    const { tenant_id } = useParams()
    const [data, setData] = useState({
        combos: [],
        courses: []
    })

    const [modalVisible, setModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const [userDelete, setUserDelete] = useState(0)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const setTableData = (payloads) => ({
        columns: ['Nome', 'Status'],
        rows: payloads.map(item => [item.name, (item.status ? 'Ativo' : 'Inativo')]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/academic/course_program/combos`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (isEditing, data) => {
        setIsEditing(isEditing)
        setFormData(data)
        setModalVisible(true)
    }

    const openModalDelete = ({ id }) => {
        setUserDelete(id)
        setModalDeleteVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, image, ...payload } = formData

            if (image) {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }

                const form = new FormData()
                form.append('image', image)
                const responseImg = await API.post(`${apiPath}/upload`, form, config)

                payload.image_url = responseImg.data.image_url
            }

            const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    const submitModalDeleteForm = async (id) => {
        try {
            const response = await API.delete(`${apiPath}/${id}`)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Error')
        }

        setModalDeleteVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target
        value = ['status', 'display'].includes(name) ? !formData[name] : value
        value = ['image'].includes(name) ? e.target.files[0] : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const handleModalDropdown = (e) => {
        const { name } = e.target
        const value = JSON.parse(e.target.value)

        setFormData(prevFormData => {
            const payload = prevFormData[name] || []
            const index = payload.findIndex(item => item.id === value.id)
            index !== -1 ? payload.splice(index, 1) : payload.push(value)

            return {
                ...prevFormData,
                [name]: payload,
            };
        });
    }

    const setPlaceholder = (array) => {
        const items = array.map(item => item.name);
        return items.join('\n')
    }

    return (
        <>
            <Table
                header='Combos' data={setTableData(data.combos)}
                onNewClick={() => openModal(false, formDataDefault)}
                onEditClick={(payload) => openModal(true, payload)}
                onDeleteClick={(payload) => openModalDelete(payload)}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Combo' : 'Criar Combo'} onSubmit={() => submitModalForm()} >
                <Form>
                    <FormGroup>
                        <Label for='name'>Nome:</Label>
                        <Input type='text' name='name' id='name' value={formData.name} onChange={handleModalInputChange} required />
                    </FormGroup>

                    <FormGroup>
                        <Label for='description'>Descrição:</Label>
                        <Input type='textarea' name='description' id='description' value={formData.description} onChange={handleModalInputChange} required />
                    </FormGroup>

                    <Dropdown id='courses' name='courses' label='Cursos do combo' items={data.courses} selectedItems={setPlaceholder(formData.courses)} onClick={handleModalDropdown}/>

                    <FormGroup>
                        <Label for='percentage_discount'>Desconto percentual:</Label>
                        <InputGroup>
                            <Input type='number' min='0' name='percentage_discount' id='percentage_discount' value={formData.percentage_discount} onChange={handleModalInputChange} required />
                            <InputGroupText>%</InputGroupText>
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label>Status:</Label>
                        <FormGroup switch>
                            <Input type='switch' name='status' id='status' onChange={handleModalInputChange} checked={formData.status} />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label for='bank_slip_discount'>Desconto boleto:</Label>
                        <InputGroup>
                            <Input type='number' min='0' name='bank_slip_discount' id='bank_slip_discount' value={formData.bank_slip_discount} onChange={handleModalInputChange} required />
                            <InputGroupText>%</InputGroupText>
                        </InputGroup>
                    </FormGroup>

                    <Image label='Imagem (396x230):' id='image' name='image' src={formData.image_url} onChange={handleModalInputChange} />

                </Form>
            </ModalCreateAndEdit>

            <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
        </>
    )
}

