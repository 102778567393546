import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap'
import { H6 } from '../../../../../../AbstractElements'

import { API } from '../../../../../../api/API/api'

const formDataDefault = {
    new_layout_enabled: 0,
    number_of_featured_posts: 0,
    max_visible_courses: 0,
    max_visible_recent_posts: 0
}

export default function Settings() {
    const { tenant_id } = useParams();
    const [formData, setFormData] = useState(formDataDefault);

    const apiPath = `/tenants/${tenant_id}/management/cms/homepage/settings`

    useEffect(() => {
        async function getData() {
            try {
                const response = await API.get(apiPath);
                setFormData(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, [tenant_id]);


    const handleInputChange = (e) => {
        let { name, value } = e.target

        if (name === 'new_layout_enabled') {
            value = formData[name] === 1 ? 0 : 1
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await API.put(apiPath, formData);
            toast.success('Sucesso!')

        } catch (error) {
            console.log(error);
            toast.error('Error')
        }
    };

    return (
        <>
            <Card className='col-12' style={{ marginBottom: "8.5em" }}>
                <div className="card-header">
                    <h5 className="card-title p-b-0 p-t-0">Configurações da Página Inicial</h5>
                </div>
                <Form>
                    <Row className="m-4">
                        <Col md="12" className="mb-3">
                            <FormGroup>
                                <H6>Layout:</H6>
                                <FormGroup switch>
                                    <Input type='switch' name='new_layout_enabled' id='new_layout_enabled' onChange={handleInputChange} checked={formData.new_layout_enabled} />
                                    <Label for='new_layout_enabled'>{formData.new_layout_enabled ? 'clássico' : 'news'}</Label>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="m-4">
                        <Col md="12" className="mb-3">
                            <H6>Número de posts em destaque:</H6>
                            <FormGroup>
                                <Input min='0' type="number" name="number_of_featured_posts" value={formData.number_of_featured_posts} onChange={handleInputChange}  disabled={!formData.new_layout_enabled} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="m-4">
                        <Col md="12" className="mb-3">
                            <H6>Máximo de cursos visíveis:</H6>
                            <FormGroup>
                                <Input min='0' type="number" name="max_visible_courses" value={formData.max_visible_courses} onChange={handleInputChange} disabled={!formData.new_layout_enabled} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="m-4">
                        <Col md="12" className="mb-3">
                            <H6>Máximo de posts recentes visíveis:</H6>
                            <FormGroup>
                                <Input min='0' type="number" name="max_visible_recent_posts" value={formData.max_visible_recent_posts} onChange={handleInputChange} disabled={!formData.new_layout_enabled} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="text-right rowCustom">
                        <Col className="text-right">
                            <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                                Salvar alterações
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

