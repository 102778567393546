import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap'
import { Btn, H5, H6, P } from '../../../AbstractElements'
import "./Profile.scss"
import { API } from '../../../api/API/api'
import { Link, useParams } from 'react-router-dom'

export default function UserInfo({ userInfo }) {
  const token = localStorage.getItem("token");
  const [profile, setProfile] = useState([]);
  const { clientId } = useParams(); // Obtém a ID do cliente da URL

  function navegar() {
    if (window.location.pathname === "/admin/perfil") {
      window.location.href = "/admin/perfil/edit";
    }
    else {
      window.location.href = "/backoffice/clientes/" + clientId + "/edit";

    }
  }


  useEffect(() => {
    async function getProfile() {
      try {

        const currentURL = window.location.href;
        console.log(currentURL, clientId);

        if (clientId) {
          // Se o clientId estiver presente na URL
          const response = await API.get(`backoffice/users/${clientId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = response.data;
          console.log(data);
          setProfile(data);
        } else {
          // Se o clientId não estiver na URL, tente obter do localStorage
          const localStorageClientId = localStorage.getItem("user_id");
          if (localStorageClientId) {
            const response = await API.get(`backoffice/users/${localStorageClientId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const data = response.data;
            console.log(data);

            setProfile(data);
          } else {
            console.log("clientId não encontrado");
          }
        }
      } catch (error) {
        console.log(error);
      }

    }
    getProfile()
  }, [])


  return (
    <Fragment>
      <Container fluid={true}>
        <Col>
          <Card className='hovercard'>
            <CardHeader>
              <Row>
                <Col md="9">
                  <H5>Minhas Informações</H5>
                </Col>
                <Col md="3">
                  <Btn attrBtn={{ className: 'btnEdit', color: 'primary', onClick: navegar, outline: true }}>
                    <span>Editar</span>
                    <i className="fa fa-edit fa-lg"></i>
                  </Btn>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className='p-b-10'>
                <Col md="3">
                  <H6>Email:</H6>
                </Col>
                <Col md="9">
                  <P>{userInfo.email || 'Não informado'}</P>
                </Col>
              </Row>
              <Row className='p-b-10'>
                <Col md="3">
                  <H6>Senha:</H6>
                </Col>
                <Col md="7">
                  <P>*********</P>
                </Col>
                {/* <Col md="2">
                  <Link to="">Alterar</Link>
                </Col> */}
              </Row>
              <Row className='p-b-10'>
                <Col md="3">
                  <H6>CPF:</H6>
                </Col>
                <Col md="9">
                  <P>{userInfo.document || "Não Informado"}</P>
                </Col>
              </Row>
              <Row className='p-b-10'>
                <Col md="3">
                  <H6>Endereço:</H6>
                </Col>
                <Col md="9">
                  <P>{userInfo.address_line === null ? `Não informado` : `${userInfo.address_line}`}</P>
                </Col>
              </Row>
              <Row className='p-b-10'>
                <Col md="3">
                  <H6>CEP:</H6>
                </Col>
                <Col md="9">
                  <P>{userInfo.zip_code === null ? `Não informado` : `${userInfo.zip_code}`}</P>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  )
}
