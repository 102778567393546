import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, Btn, H5 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Row, Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Card, CardHeader, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useParams } from "react-router";
import { API } from "../../../api/API/api";
import axios from "axios";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Switch from "react-switch";
const PaymentSettings = () => {
    const initialPaymentSettings = {
        descricao_na_fatura: 'Sua Descricão',
        parcelamento_cartao_recorrente: '24',
        parcelamento_cartao: '12',
        parcelamento_boleto: '18',
        dias_vencimento_boleto: 3,
        dias_bloqueio_curso: 3,
        dias_bloqueio_extensao: 5,
        valor_minimo_parcela: 60,
        taxa_imposto: 16.5,
        habilitar_solicitacao_bolsa: true,
        aceitar_boleto_bancario: true,
        aceitar_pix: true,
        aceitar_cartao_credito: true,
        aceitar_cartao_credito_recorrente: true,
    };

    const history = useNavigate();
    const { clientId } = useParams();
    const token = localStorage.getItem("token");
    const { handleSubmit, formState: { errors } } = useForm();
    const [activeTab, setActiveTab] = useState('1');
    const [ufs, setUfs] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedUf, setSelectedUf] = useState("0");
    const [selectedCity, setSelectedCity] = useState("0");
    const [selectedGateway, setSelectedGateway] = useState(null);

    const parcelamentoBoletoOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]; // Replace with your actual options for "Parcelamento Boleto"
    const parcelamentoCartaoOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; // Replace with your actual options for "Parcelamento Cartão"
    const parcelamentoCartaoRecurrentOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48]; // Replace with your actual options for "Parcelamento Cartão Recorrente"
    const [paymentSettings, setPaymentSettings] = useState(initialPaymentSettings);
    const [gatewaySettings, setGatewaySettings] = useState({
        gateway_id: 'stripe',
        gateway_configuration: {
            api_key: '',
            webhook_secret: '',
            publishable_key: '',
        },
    });

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const onSaveSettings = async () => {
        console.log("Formulário enviado. Dados:", paymentSettings);
        const combinedSettings = {
            ...paymentSettings,
            gateway_id: gatewaySettings.gateway_id,
            gateway_configuration: {
                ...gatewaySettings.gateway_configuration
            }
        };

        // Aqui você pode adicionar a lógica de salvamento, se necessário...

        // Exemplo de requisição usando axios para enviar os dados ao servidor:
        try {
            const response = await API.put(`/central/payment_settings/1`, combinedSettings, {
                headers: {
                    Authorization: `Bearer ${token}`, // Adapte conforme necessário
                },
            });

            // Lógica de tratamento da resposta, se necessário...
            console.log("Resposta do servidor:", response.data);

            // Exemplo de notificação de sucesso com toast:
            toast.success("Configurações salvas com sucesso!");

            // Redirecionar para a página desejada após o salvamento
            // history.push('/sua-pagina-desejada');
        } catch (error) {
            // Lógica de tratamento de erro, se necessário...
            console.error("Erro ao salvar configurações:", error);

            // Exemplo de notificação de erro com toast:
            toast.error("Erro ao salvar configurações. Por favor, tente novamente.");
        }
    };
    const handleInputChange = (key, value) => {
        setPaymentSettings({ ...paymentSettings, [key]: value });
    };
    const onSaveGatewaySettings = async () => {
        console.log("Formulário final enviado. Dados:", {
            ...paymentSettings,
            gateway_id: gatewaySettings.gateway_id,
            gateway_configuration: {
                ...gatewaySettings.gateway_configuration
            }
        });
        onSaveSettings();

        // Redirecionar para a página desejada após o salvamento
        // history.push('/sua-pagina-desejada');
    };
    const onFinalSave = async () => {
        console.log("Formulário final enviado. Dados:", {
            ...initialPaymentSettings,
            gateway: selectedGateway,
        });

        // Lógica de salvamento final aqui...

        // Exemplo de notificação de sucesso com toast:
        toast.success("Configurações finais salvas com sucesso!");

        // Redirecionar para a página desejada após o salvamento
        // history.push('/sua-pagina-desejada');
    };

    // ... (existing code)

    const handlePaymentSettingsChange = (key, value) => {
        setPaymentSettings({ ...paymentSettings, [key]: value });
    };
    const handleGatewaySettingsChange = (fieldName, value) => {
        console.log(gatewaySettings);

        if (fieldName === 'gateway_id') {
            setGatewaySettings((prevSettings) => ({
                ...prevSettings,
                [fieldName]: value,
            }));
        } else {
            setGatewaySettings((prevSettings) => ({
                ...prevSettings,
                gateway_configuration: {
                    ...prevSettings.gateway_configuration,
                    [fieldName]: value,
                },
            }));
        }
    };





    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await API.get(`/central/payment_settings`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(response.data[0]);
                const objeto = {
                    gateway_id:response.data[0].gateway_id,
                    gateway_configuration:response.data[0].gateway_configuration
                }
                setPaymentSettings(response.data[0]);
                setGatewaySettings(objeto);
            } catch (error) {
                console.error("Erro ao buscar configurações:", error);
            }
        };

        fetchData();
    }, [clientId, token]);

    const gatewayOptions = [
        { value: 'stripe', label: 'Stripe' },
        { value: 'paypal', label: 'PayPal' },
        // Adicione mais opções conforme necessário
    ];
    // ... (existing code)

    // Otimizado
    const handleSaveClick = () => {
        onSaveGatewaySettings();
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Configurações de Pagamento" parent="Admin" title="Configurações de Pagamento" />
            <Container fluid={true}>
                <Form className="card" onSubmit={handleSubmit(onSaveSettings)}>
                    <CardHeader>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={`${activeTab === '1' ? 'active' : ''}`}
                                    onClick={() => toggleTab('1')}
                                >
                                    Configurações Gerais
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={`${activeTab === '2' ? 'active' : ''}`}
                                    onClick={() => toggleTab('2')}
                                >
                                    Gateway de Pagamento
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <CardBody>
                                <Row>
                                    {/* ... outros campos */}
                                    <Col md="12">
                                        <FormGroup>
                                            <Label className="form-label">Descrição na Fatura</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                value={paymentSettings.descricao_na_fatura}
                                                onChange={(e) => handleInputChange('descricao_na_fatura', e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="form-label">Parcelamento Cartão Recorrente</Label>
                                            <Input
                                                className="form-control"
                                                type="select"
                                                value={paymentSettings.parcelamento_cartao_recorrente}
                                                onChange={(e) => handleInputChange('parcelamento_cartao_recorrente', e.target.value)}
                                            >
                                                {parcelamentoCartaoRecurrentOptions.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option} x
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="form-label">Parcelamento Cartão</Label>
                                            <Input
                                                className="form-control"
                                                type="select"
                                                value={paymentSettings.parcelamento_cartao}
                                                onChange={(e) => handleInputChange('parcelamento_cartao', e.target.value)}
                                            >
                                                {parcelamentoCartaoOptions.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option} x
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="form-label">Parcelamento Boleto</Label>
                                            <Input
                                                className="form-control"
                                                type="select"
                                                value={paymentSettings.parcelamento_boleto}
                                                onChange={(e) => handleInputChange('parcelamento_boleto', e.target.value)}
                                            >
                                                {parcelamentoBoletoOptions.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option} x
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="form-label">Valor Minimo de Parcela</Label>
                                            <Input
                                                className="form-control"
                                                type="number"
                                                value={paymentSettings.valor_minimo_parcela}
                                                onChange={(e) => handleInputChange('valor_minimo_parcela', e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label className="form-label">Taxa Imposto</Label>
                                            <Input
                                                className="form-control"
                                                type="number"
                                                value={paymentSettings.taxa_imposto}
                                                onChange={(e) => handleInputChange('taxa_imposto', e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label className="form-label">Número dias vencimento boleto</Label>
                                            <Input
                                                className="form-control"
                                                type="number"
                                                value={paymentSettings.dias_vencimento_boleto}
                                                onChange={(e) => handleInputChange('dias_vencimento_boleto', e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label className="form-label">Número dias bloquear curso</Label>
                                            <Input
                                                className="form-control"
                                                type="number"
                                                value={paymentSettings.dias_bloqueio_curso}
                                                onChange={(e) => handleInputChange('dias_bloqueio_curso', e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="4">
                                        <FormGroup>
                                            <Label className="form-label">Número dias bloquear extensão do curso</Label>
                                            <Input
                                                className="form-control"
                                                type="number"
                                                value={paymentSettings.dias_bloqueio_extensao}
                                                onChange={(e) => handleInputChange('dias_bloqueio_extensao', e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {/* ... outros campos */}
                                    <Col md="4">
                                        <FormGroup>
                                            <Label className="form-label">Habilitar solicitação de bolsa</Label>
                                            <Switch
                                                checked={paymentSettings.habilitar_solicitacao_bolsa}
                                                onChange={(checked) => handleInputChange('habilitar_solicitacao_bolsa', checked)}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={24}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label className="form-label">Aceitar boleto bancário</Label>
                                            <Switch
                                                checked={paymentSettings.aceitar_boleto_bancario}
                                                onChange={(checked) => handleInputChange('aceitar_boleto_bancario', checked)}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={24}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label className="form-label">Aceitar Pix</Label>
                                            <Switch
                                                checked={paymentSettings.aceitar_pix}
                                                onChange={(checked) => handleInputChange('aceitar_pix', checked)}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={24}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label className="form-label">Aceitar cartão de crédito</Label>
                                            <Switch
                                                checked={paymentSettings.aceitar_cartao_credito}
                                                onChange={(checked) => handleInputChange('aceitar_cartao_credito', checked)}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={24}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label className="form-label">Aceitar cartão de crédito recorrente</Label>
                                            <Switch
                                                checked={paymentSettings.aceitar_cartao_credito_recorrente}
                                                onChange={(checked) => handleInputChange('aceitar_cartao_credito_recorrente', checked)}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={24}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                    </Col>
                                    {/* ... outros campos */}
                                </Row>
                                <CardFooter className="text-end">
                                    <Btn attrBtn={{ color: "primary", outline: true, type: "submit" }}>{"Salvar Configurações"}</Btn>
                                </CardFooter>
                            </CardBody>

                        </TabPane>
                        <TabPane tabId="2">
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="form-label">Selecionar Gateway</Label>
                                            <Input
                                                className="form-control"
                                                type="select"
                                                name="gateway_id"
                                                value={gatewaySettings.gateway_id}
                                                onChange={(e) => handleGatewaySettingsChange('gateway_id', e.target.value)}
                                            >
                                                {gatewayOptions.map((option, index) => (
                                                    <option key={index} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="form-label">Chave da API</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                value={gatewaySettings.gateway_configuration.api_key}
                                                onChange={(e) => handleGatewaySettingsChange('api_key', e.target.value)}
                                            />


                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="form-label">Segredo do Webhook</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                value={gatewaySettings.gateway_configuration.webhook_secret}
                                                onChange={(e) => handleGatewaySettingsChange('webhook_secret', e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className="form-label">Chave Publicável</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                value={gatewaySettings.gateway_configuration.publishable_key}
                                                onChange={(e) => handleGatewaySettingsChange('publishable_key', e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="text-end">

                                <Btn attrBtn={{ color: "primary", outline: true, onClick: handleSaveClick }} >
                                    {"Salvar Configurações"}
                                </Btn>
                            </CardFooter>

                        </TabPane>

                    </TabContent>
                </Form>
            </Container>
        </Fragment>
    );
};

export default PaymentSettings;
