import { Table } from "../../../../../_Commons/Table/Index"

export const StudentsCourse = ({
  data,
  searchQuery,
  setSearchQuery,
  handleSearch,
  currentPage,
  setCurrentPage,
  handleChangePagination,
  itemsPerPage,
  setItemsPerPage,
  lastPage,
  handleSort,
  sortColumn,
  descendingSort,
  courseName
 }) => {

  const setTableData = (payloads) => ({
    columns: ['Id', 'Nome', 'Email', 'CPF', 'Cidade', 'Estado'],
    rows: payloads.map(item => [item.id, item.name, item.email, item.cpf, item.city, item.state]),
    payloads: payloads
  });

  return (
    <Table
      header={ `Alunos do curso ${courseName}` }
      data={setTableData(data)}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      search={true}
      handleSearch={handleSearch}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      handleChangePagination={handleChangePagination}
      itemsPerPage={itemsPerPage}
      setItemsPerPage={setItemsPerPage}
      pagination={true}
      lastPage={lastPage}
      handleSort={handleSort}
      sortColumn={sortColumn}
      sortDirection={descendingSort}
    />
  )
}