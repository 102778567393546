import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label, Input } from 'reactstrap'

import { Table } from '../../_Commons/Table/Index'
import { ModalCreateAndEdit } from '../../_Commons/Modal/CreateAndEdit'
import { ModalDelete } from '../../_Commons/Modal/Delete'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    id: 0,
    title: '',
    url: '',
    text: '',
    display: true,
    position: 0,
}

const menu_positions = Array.from({ length: 7 }, (_, i) => i)

export default function Lectures() {
    const { tenant_id } = useParams()
    const [data, setData] = useState([])

    const [modalVisible, setModalVisible] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [formData, setFormData] = useState(formDataDefault)

    const [userDelete, setUserDelete] = useState(0)
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

    const setTableData = (payloads) => ({
        columns: ['Título', 'Mostrar na página inicial?'],
        rows: payloads.map(item => [item.title, (item.display ? 'Sim' : 'Não')]),
        payloads: payloads
    })

    const apiPath = `/tenants/${tenant_id}/management/cms/lectures`

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await API.get(apiPath)
                setData(response.data)

            } catch (error) { console.error(error) }
        }

        getData()
    }, [])

    const openModal = (isEditing, data) => {
        setIsEditing(isEditing)
        setFormData(data)
        setModalVisible(true)
    }

    const openModalDelete = ({ id }) => {
        setUserDelete(id)
        setModalDeleteVisible(true)
    }

    const submitModalForm = async () => {
        try {
            const { id, ...payload } = formData
            const response = isEditing ? await API.put(`${apiPath}/${id}`, payload) : await API.post(apiPath, payload)

            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.log(error)
            toast.error('Error')
        }

        setModalVisible(false)
    }

    const submitModalDeleteForm = async (id) => {
        try {
            const response = await API.delete(`${apiPath}/${id}`)
            setData(response.data)
            toast.success('Sucesso!')
        } catch (error) {
            console.error(error)
            toast.error('Error')
        }

        setModalDeleteVisible(false)
    }

    const handleModalInputChange = (e) => {
        let { name, value } = e.target
        value = ['display'].includes(name) ? !formData[name] : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    return (
        <>
            <Table
                header='Palestras' data={setTableData(data)}
                onNewClick={() => openModal(false, formDataDefault)}
                onEditClick={(payload) => openModal(true, payload)}
                onDeleteClick={(payload) => openModalDelete(payload)}
            />

            <ModalCreateAndEdit isOpen={modalVisible} setIsOpen={setModalVisible} title={isEditing ? 'Editar Palestra' : 'Criar Palestra'} onSubmit={() => submitModalForm()} >
                <Form>
                    <FormGroup>
                        <Label for='title'>Título:</Label>
                        <Input type='text' name='title' id='title' value={formData.title} onChange={handleModalInputChange} required />
                    </FormGroup>

                    <FormGroup>
                        <Label for='url'>URL do vídeo (com http://):</Label>
                        <Input type='text' name='url' id='url' value={formData.url} onChange={handleModalInputChange} required />
                    </FormGroup>

                    <FormGroup>
                        <Label for='text'>Texto:</Label>
                        <Input type='textarea' name='text' id='text' value={formData.text} onChange={handleModalInputChange} required />
                    </FormGroup>

                    <FormGroup>
                        <Label for='display'>Exibir na página inicial:</Label>
                        <FormGroup switch>
                            <Input type='switch' name='display' id='display' onChange={handleModalInputChange} checked={formData.display} />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label for='position'>Ordenar na página inicial:</Label>
                        <Input type='select' name='position' id='position' onChange={handleModalInputChange}>

                            {menu_positions.map((i) => (
                                <option key={i} value={i} selected={i === formData.position}>{i === 0 ? '' : `Início ${i}`}</option>
                            ))}
                        </Input>
                    </FormGroup>

                </Form>
            </ModalCreateAndEdit>

            <ModalDelete isOpen={modalDeleteVisible} setIsOpen={setModalDeleteVisible} onSubmit={() => submitModalDeleteForm(userDelete)} />
        </>
    )
}

