import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Container, Row, Input } from 'reactstrap'
import { ReactComponent as Globe } from "../../../../../assets/tutorcastsIcon/site.svg"
import { API, API2 } from '../../../../../api/API/api'
import { Breadcrumbs, Btn, Spinner } from '../../../../../AbstractElements'
import CreationCard from './CreationCard';
import ActivatedBtn from './Buttons/Activated.jsx'
import DeactivatingBtn from './Buttons/Deactivating.jsx'

import "../Styles/Tenants.scss"
import CommonModal from '../../../../UiKits/Modals/common/modal.jsx'
import FormWizard from './FormWizard1.jsx'
import { MarginRight } from '../../../../../Constant/index.jsx'

export default function Tenants() {
  const [tenants, setTenants] = useState([])
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("token");
  const [searchQuery, setSearchQuery] = useState('');
  const [Large, setLarge] = useState(false);

  const LargeModaltoggle = (version) => {
    setLarge(!Large)
  };

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true)
        const response = await API.get(`/tenants`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
        )

        const data = response.data.sites
        setTenants(data)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [])


  if (loading) {
    return (
      <Fragment>
        <Container fluid={true}>

          <Breadcrumbs mainTitle='Meus Sites' parent='Home' title='Meus Sites' />

          <Row>
            <Col sm="12">
              <div className='loader-box spinner'>
                <Spinner attrSpinner={{ className: 'loader-3' }} />
              </div>
            </Col>
          </Row></Container>
      </Fragment>
    )
  }
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const sortedTenants = tenants.slice().sort((a, b) => {
    if (a.status === "deactivating" && b.status === "activated") {
      return 1;
    } else if (a.status === "activated" && b.status === "deactivating") {
      return -1;
    } else {
      return 0;
    }
  });
  const filteredTenants = sortedTenants.filter((tenant) =>
    tenant.domain.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs mainTitle='Meus Sites' parent='Home' title='Meus Sites' subParent="Products" />
        {/* Adcionar Barra de Pesquisa aqui para filtrar sites */}

        <Btn attrBtn={{ className: "purchase-btn btn btn-primary btn-hover-effect f-w-500 m-r-5", onClick: () => LargeModaltoggle(false) }}>
          <span>Criar seu site 4.5</span>
        </Btn>

        <Card className='m-b-15 shadow'>
          <Input
            type="text"
            placeholder="Search sites..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </Card>

        <CommonModal isOpen={Large} title='Crie seu site' toggler={LargeModaltoggle} size="xl">
          <FormWizard />
        </CommonModal>
        <Row>
          {filteredTenants.length > 0 &&
            filteredTenants.map((t, i) => (

              <Card key={i} className={`m-b-15 shadow ${t.status === "deactivating" && "disabled"}`}>
                <Row>
                  <Col lg="5">
                    <div className={`tenantsCard ${t.status === "deactivating" && "disabled"}`} >
                      <div className='tenantsCard__icon'>
                        <svg><Globe /></svg>
                      </div>
                      <div className='tenantsCard__info'>
                        <a href={`https://${t.domain}`} target='_blank' rel="noreferrer">{t.domain}</a>
                      </div>
                    </div>
                  </Col>
                  <Col lg="5">
                    <div className='tenantsCard' >
                      <div className='tenantsCard__icon'>
                        <i className="fa fa-database"></i>
                      </div>
                      <div className='tenantsCard__info'>
                        <p>{t.storage.used}/5 GB usados</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="2">
                    <div className='tenantsCard' >
                      {t.status === 'activated' ? <ActivatedBtn id={t.id} /> : t.status === 'deactivating' ? <DeactivatingBtn id={t.id} timestamp={t.disabled_at} /> : console.log(t.status)}
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}

        </Row>
      </Container>
    </Fragment>
  )
}
