import React from 'react'
import { Card, Table as Trs, Button, Input,  InputGroup, InputGroupText, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faChevronLeft, faChevronRight, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

function Buttons({ onEditClick, onDeleteClick, payload, onDownloadClick }) {
    return (
        <>
            {onEditClick &&
                <Button color='primary' outline={true} onClick={() => onEditClick(payload)}>
                    <i className='fa fa-edit fa-lg' />
                </Button>}
            {onDownloadClick && (
                <Button color='primary' outline={true} onClick={() => onDownloadClick(payload)}>
                    <i className='fa fa-download fa-lg' style={{ color: 'blue' }} />
                </Button>
            )}
            {onDeleteClick &&
                <Button color='secondary' outline={true} onClick={() => onDeleteClick(payload)}>
                    <i className='fa fa-trash-o fa-lg' />
                </Button>
            }
        </>
    )
}

export function Table({ header, data, onNewClick, onEditClick, onDeleteClick, customButtons, searchQuery, setSearchQuery, search, handleSearch, currentPage, setCurrentPage, itemsPerPage, setItemsPerPage, pagination, lastPage, handleChangePagination, handleSort, sortColumn, sortDirection, onDownloadClick }) {
    return (
        <>
            <Card className='col-12 p-20'>
                {onNewClick &&
                    <Button color='primary' className='col-2 d-flex justify-content-center' outline={true} onClick={() => onNewClick()}>
                        Novo
                    </Button>
                }
                <div className='card-header' style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <h5 className='card-title p-b-0 p-t-0'>{header}</h5>
                
                        {search && (
                            <Col>
                                <InputGroup>
                                    <InputGroupText onClick={() => handleSearch()}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroupText>
                                    <Input type='text' placeholder='Pesquisar...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                </InputGroup>
                            </Col>
                        )}

                        {pagination && (
                            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', gap: '10px', marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                        <span>Página</span>
                                    </div>
                                    <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1} style={{ border: 'none', height: '30px' }}>
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                                    {/* <InputGroup style={{ width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px' }}>
                                        <Input type='number' value={currentPage} onChange={(e) => handleChangePagination(e)} min="1" max={lastPage} style={{ width: '100%', textAlign: 'center', height: '100%' }} />
                                    </InputGroup> */}
                                    <span>{currentPage}</span>
                                    <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage >= lastPage} style={{ border: 'none', height: '30px' }}>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </button>
                                    <span>de {lastPage} páginas</span>
                                </div>

                                <div style={{ display: 'flex', gap: '10px', marginTop: '10px', alignItems: 'center' }}>
                                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span>Itens por Página</span>
                                    </div>
                                    <div style={{ width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px' }}>
                                        <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} style={{ width: '100%', textAlign: 'center', height: '100%' }}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                    {/* <InputGroup style={{ width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px' }}>
                                        <InputGroupText>Itens por página</InputGroupText>
                                        <Input type='number' value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} min="1" style={{ width: '100%', textAlign: 'center', height: '100%' }} />
                                    </InputGroup> */}
                                </div>
                            </Col>
                        )}
                    
                </div>
                <Trs borderless>
                    <thead>
                        <tr>
                            {data.columns.map((col, index) => (
                                <th key={index} style={{ minWidth: '40px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>
                                        {handleSort && (
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <FontAwesomeIcon
                                                        icon={faSortUp}
                                                        onClick={() => handleSort(index, 'asc')}
                                                        style={{ cursor: 'pointer', color: 'rgba(0,0,0,0.2)' }}
                                                        />
                                                <FontAwesomeIcon
                                                        icon={faSortDown}
                                                        onClick={() => handleSort(index, 'desc')}
                                                        style={{ cursor: 'pointer', color: 'rgba(0,0,0,0.2)' }}
                                                        />
                                            </div>
                                        )}
                                        {col}
                                    </div>
                                </th>
                            ))}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.rows.map((row, index) => (
                            <tr key={index}>
                                {row.map((item, index2) => <td className='align-middle' key={`${index}-${index2}`}>{item}</td>)}
                                <td>
                                    <div className='buttonsCard'>
                                        {customButtons ?
                                            customButtons({ payload: data.payloads[index] }) :
                                            <Buttons onEditClick={onEditClick} onDownloadClick={onDownloadClick} onDeleteClick={onDeleteClick} payload={data.payloads[index]} />
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Trs>
            </Card>
        </>
    )
}
