import { CardElement, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Fragment, useEffect, useState } from "react";
import { Col, Row, Button, Label, FormGroup, Input, Form, CardBody } from "reactstrap";
import { H6 } from "../../../../../AbstractElements";
import '../Styles/TenantForm.scss';
import { API } from "../../../../../api/API/api";
import CheckoutTableData from "./CheckoutTableData";
import CheckoutPaymentCard from "./CheckoutPaymentCard";

export default function BllingData({ setSteps, setFormdata, formdata }) {
  const [dataUser, setDataUser] = useState({
    name: '',
    email: ''
  });

  const [card, setCard] = useState({
    cardNumber: '',
    expDate: '',
    cvc: ''
  });

  const [selectedPaymentMethod, setSelectPaymentMethod] = useState('');
  const [planPrice, setPlanPrice] = useState(0);

  const [customerId, setCustomerId] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleChangeDataUser= ({ target }) => {
    const { name, value } = target;
    setDataUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeCardInputs = ({ target }) => {
    const {name, value} = target;

    setCard((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  useEffect(() => {
    (async () => {
      setPlanPrice(formdata.price);
      console.log(formdata.price);
      if (formdata.price === 0) setSteps((prev) => prev + 1);
    })()
  }, []);

  const onSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (!dataUser.name || !dataUser.email) {
      return
    }

    try {
      const { data } = await API.post('/tenants/payment/create-setup-intent');

      const customer = await API.post('/create-customer', {
        email: dataUser.email,
        name: dataUser.name,
      });

      const customer_id = customer.data.customer_id;

      const { setupIntent } = await stripe.confirmCardSetup(data.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: dataUser.email,
            name: dataUser.name
          },
        },
      });

      const paymentMethod = setupIntent.payment_method;
      const price_id = `${planPrice}00`;

      setFormdata({
        ...formdata,
        'customerId': customer_id,
        'priceId': price_id,
        'paymentMethodId': paymentMethod,
        'paymentMethod': 'cartão',
        'name': dataUser.name,
        'email': dataUser.email,
      });

      setCustomerId(customer_id);
      setSteps((prev) => prev + 1);
      return;
    } catch (error) {
      console.log(error);
    }

  };

  return (
    <Fragment>
      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '25px' }}>
        <Label>
          <H6>Método de Pagamento:</H6>
        </Label>

        <div>
          <Button
            color={selectedPaymentMethod === 'cartao' ? 'primary' : 'secondary'}
            onClick={() => setSelectPaymentMethod('cartao')}
            style={{ marginRight: '10px' }}
          >
            Cartão
          </Button>
          <Button
            color={selectedPaymentMethod === 'boleto' ? 'primary' : 'secondary'}
            onClick={() => setSelectPaymentMethod('boleto')}
          >
            Boleto
          </Button>
        </div>
      </Col>

      {selectedPaymentMethod === 'cartao' && (
        <CheckoutPaymentCard
          dataUser={dataUser}
          handleChangeDataUser={handleChangeDataUser}
          setSteps={setSteps}
          onSubmit={onSubmit}
          card={card}
          handleChangeCardInputs={handleChangeCardInputs}
        />
      )}

      {selectedPaymentMethod === 'boleto' && (
        <FormGroup style={{ marginBottom: '50px' }}>
          <div>
            <CheckoutTableData setSteps={setSteps} setFormDataTenants={setFormdata} />
          </div>
        </FormGroup>
      )}

    </Fragment>
  )
}