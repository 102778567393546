import React from 'react';
import { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { P } from '../../AbstractElements';

const Footer = () => {
  return (
    <footer className="footer" style={{ marginTop: 'auto', width: '100vw', height: '5vh', position: 'fixed', bottom: 0 }}>
      <Container fluid={true}>
        <Row className="justify-content-center">
          <Col md="12" className="footer-copyright text-center d-flex align-items-center w-100">
            <P
              attrPara={{
                className: "mb-0 w-100",
                style: {
                  height: '5vh',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                },
              }}
            >
              {'Copyright © 1998 - 2023 Tutorcasts v3.5.0'}
            </P>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
