import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

export function ModalCreateAndEdit({ size, isOpen, setIsOpen, title, children, onSubmit, onClose = undefined, fullscreen = false }) {

    const closeModal = () => {
        setIsOpen(false)
        if (onClose) onClose()
    }

    return (
        <Modal isOpen={isOpen} toggle={closeModal} size={size} centered fullscreen={fullscreen}>
            <ModalHeader toggle={closeModal}>{title}</ModalHeader>

            <ModalBody>
                {children}
            </ModalBody>

            {onSubmit &&
                <ModalFooter>
                    <Button color='secondary' onClick={closeModal}>Cancelar</Button>
                    <Button color='primary' onClick={onSubmit}>Confirmar</Button>
                </ModalFooter>
            }
        </Modal>
    )
}
