import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { API } from '../../../../api/API/api'

import { Navbar } from '../_Commons/Navbar/Index'
import Layout from "../Layout"
import Renegotiations from "./Tabs/Renegotiations"
import Requests from "./Tabs/Requests"

const items = [
  {
    id: 'requests',
    label: 'Pedidos',
    tabs: [
      {
        label: 'Pedidos',
        component: Requests,
      }
    ],
  },
  {
    id: 'renegotiations',
    label: 'Acordos',
    tabs: [
      {
        label: 'Acordos',
        component: Renegotiations,
      }
    ],
  },
]


export default function Financial() {
  const { tenant_id } = useParams()

  const [formValues, setFormValues] = useState({
    tenant: {
      name: ''
    }
  })

  useEffect(() => {
    async function getData() {
      try {
        const response = await API.get(`/tenants/${tenant_id}/management/general`)
        setFormValues(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [tenant_id])

  return (
    <Fragment>
      <Layout id="financial" site_name={formValues.tenant.name}>
        <Navbar items={items} />
      </Layout>
    </Fragment>
  )
}
