import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Card, Row, Col, Form, FormGroup, InputGroup, InputGroupText, Input, Label, Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { H6 } from '../../../../../AbstractElements'

import { Image } from '../../_Commons/Inputs/Image'

import { API } from '../../../../../api/API/api'

const formDataDefault = {
    image: '',
    status: false,
    link: '',
    height: 0,
    countdown: false,
    date: '',
    time: '',
    time_format: '',
    style: '',
    color: ''
}

export default function BannerTop() {
    const { tenant_id } = useParams();
    const [formData, setFormData] = useState(formDataDefault);
    const [modalVisible, setModalVisible] = useState(false)

    const apiPath = `/tenants/${tenant_id}/management/cms/banner_top`

    useEffect(() => {
        async function getData() {
            try {
                const response = await API.get(apiPath);
                setFormData(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, [tenant_id]);


    const handleInputChange = (e) => {
        let { name, value } = e.target
        value = ['image'].includes(name) ? e.target.files[0] : value
        value = ['status', 'countdown'].includes(name) ? !formData[name] : value

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const { image, ...payload } = formData

            if (image) {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }

                const form = new FormData()
                form.append('image', image)
                const responseImg = await API.post(`${apiPath}/upload`, form, config)

                payload.image_url = responseImg.data.image_url
            }

            await API.put(apiPath, payload);
            toast.success('Sucesso!')

        } catch (error) {
            console.log(error);
            toast.error('Error')
        }
    };

    return (
        <>
            <Card className='col-12' style={{ marginBottom: "8.5em" }}>
                <div className="card-header">
                    <h5 className="card-title p-b-0 p-t-0">Editar Banner de Topo</h5>
                </div>
                <Form>
                    <Row className="m-4">
                        <Col md="12" className="mb-3">

                            <FormGroup>
                                <H6>Status:</H6>
                                <FormGroup switch>
                                    <Input type='switch' name='status' id='status' onChange={handleInputChange} checked={formData.status} />
                                    <Label for='status'>{formData.status ? 'Ativo' : 'Inativo'}</Label>
                                </FormGroup>
                            </FormGroup>

                            <FormGroup>
                                <H6>Link do banner:</H6>
                                <Input type='text' name='link' id='link' value={formData.link} onChange={handleInputChange} required />
                            </FormGroup>

                            <FormGroup>
                                <H6>Altura do banner:</H6>
                                <InputGroup>
                                    <Input type='number' min='0' name='height' id='height' value={formData.height} onChange={handleInputChange} required />
                                    <InputGroupText>px</InputGroupText>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <H6>Cor:</H6>
                                <Input type='color' name='color' id='color' value={formData.color} onChange={handleInputChange} required />
                            </FormGroup>

                            <FormGroup>
                                <H6>Contagem:</H6>
                                <FormGroup switch>
                                    <Input type='switch' name='countdown' id='countdown' onChange={handleInputChange} checked={formData.countdown} />
                                    <Label for='countdown'>{formData.countdown ? 'Ativo' : 'Inativo'}</Label>
                                </FormGroup>
                            </FormGroup>

                            <FormGroup>
                                <H6>Data:</H6>
                                <InputGroup>
                                    <Input type='date' name='date' id='date' value={formData.date} onChange={handleInputChange} required />
                                    <Input type='time' name='time' id='time' value={formData.time} onChange={handleInputChange} required />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <H6>Documentação da formatação:</H6>
                                <InputGroup>
                                    <InputGroupText><i class="fa fa-file-code-o" /></InputGroupText>
                                    <Input type='text' name='time_format' id='time_format' value={formData.time_format} onChange={handleInputChange} required />
                                    <Button color='primary' onClick={() => setModalVisible(true)}>doc</Button>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <H6>Estilo da contagem:</H6>
                                <InputGroup>
                                    <InputGroupText><i class="fa fa-file-code-o" /></InputGroupText>
                                    <Input type='text' name='style' id='style' value={formData.style} onChange={handleInputChange} required />
                                </InputGroup>
                            </FormGroup>

                            <Image label='Imagem de fundo:' id='image' name='image' src={formData.image_url} onChange={handleInputChange} />

                        </Col>
                    </Row>

                    <Row className="text-right rowCustom">
                        <Col className="text-right">
                            <button type="submit" className="btn btn-outline-primary m-3" onClick={handleSubmit}>
                                Salvar alterações
                            </button>
                        </Col>
                    </Row>
                </Form>

                <Modal isOpen={modalVisible} size='xl' toggle={() => setModalVisible(false)} centered>
                    <ModalHeader toggle={() => setModalVisible(false)}>Documentação da formatação</ModalHeader>
                    <ModalBody>
                        <p>Formata a data de deslocamento de acordo com as diretivas na string de formato indicada. A diretiva consiste em
                            um <code class="highlighter-rouge">%</code> caractere percentual. Qualquer texto não listado como uma diretiva
                            será passado para a seqüência de saída.</p>
                        <div class="language-javascript highlighter-rouge">
                            <pre class="highlight">
                                <code>
                                    <span class="s1">\'%W semana(s) %-d dia(s) %-H hora(s) %M minutos %S segundos\'</span>
                                    <span class="c1">resultado// =&gt; 1 semana(s) 2 day(s) 3 hora(s) 04 mininutos 05 segundos</span>
                                </code>
                            </pre>
                        </div>
                        <p>Todas as diretivas contêm as versões zero a esquerda (01, 02, 03,…, 10) e sem os zeros a esqueda (1, 2, 3,…, 10),
                            para usar este último, por favor use o <code class="highlighter-rouge">-</code> modificador traço .</p>
                        <p>O formatador também é capaz de lidar com a pluralização através do <code class="highlighter-rouge">!</code>
                            modificador bang , por padrão sempre retorna o <code class="highlighter-rouge">s</code> caractere, se você precisa
                            de uma lógica complexa por favor leia o tópico <strong>Pluralização</strong> desta seção.</p>
                        <table class="table table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Modificador</th>
                                    <th>Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><code>-</code></td>
                                    <td>Preenchimento em branco</td>
                                </tr>
                                <tr>
                                    <td><code>!</code></td>
                                    <td>Plugin de pluralização</td>
                                </tr>
                            </tbody>
                        </table>
                        <h4 id="directives">Diretivas</h4>
                        <table class="table table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Directiva</th>
                                    <th>Em branco acolchoado</th>
                                    <th>Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><code>%Y</code></td>
                                    <td><code>%-Y</code></td>
                                    <td>Anos restantes *</td>
                                </tr>
                                <tr>
                                    <td><code>%m</code></td>
                                    <td><code>%-m</code></td>
                                    <td>Meses restantes *</td>
                                </tr>
                                <tr>
                                    <td><code>%n</code></td>
                                    <td><code>%-n</code></td>
                                    <td>Dias restantes até o próximo mês completo *</td>
                                </tr>
                                <tr>
                                    <td><code>%w</code></td>
                                    <td><code>%-w</code></td>
                                    <td>Semanas restantes</td>
                                </tr>
                                <tr>
                                    <td><code>%W</code></td>
                                    <td><code>%-W</code></td>
                                    <td>Semanas restantes até o próximo mês completo *</td>
                                </tr>
                                <tr>
                                    <td><code>%d</code></td>
                                    <td><code>%-d</code></td>
                                    <td>Dias restantes (tirando semanas)</td>
                                </tr>
                                <tr>
                                    <td><code>%H</code></td>
                                    <td><code>%-H</code></td>
                                    <td>Horas restantes</td>
                                </tr>
                                <tr>
                                    <td><code>%M</code></td>
                                    <td><code>%-M</code></td>
                                    <td>Minutos restantes</td>
                                </tr>
                                <tr>
                                    <td><code>%S</code></td>
                                    <td><code>%-S</code></td>
                                    <td>Segundos restantes</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            <small class="text-center">* Devido às suas restrições não lineares, o cálculo não tem precisão e finge ser usado
                                como aproximação ou não uso.
                            </small>
                        </p>
                        <p>Há outro conjunto de diretivas que retorna a contagem total do componente de tempo até o final da contagem:</p>
                        <table class="table table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Directiva</th>
                                    <th>Em branco acolchoado</th>
                                    <th>Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><code>%D</code></td>
                                    <td><code>%-D</code></td>
                                    <td>Contagem total de dias até o final</td>
                                </tr>
                                <tr>
                                    <td><code>%I</code></td>
                                    <td><code>%-I</code></td>
                                    <td>Contagem total de horas até o final</td>
                                </tr>
                                <tr>
                                    <td><code>%N</code></td>
                                    <td><code>%-N</code></td>
                                    <td>Contagem total de minutos até o final</td>
                                </tr>
                                <tr>
                                    <td><code>%T</code></td>
                                    <td><code>%-T</code></td>
                                    <td>Contagem total de segundos até o final</td>
                                </tr>
                            </tbody>
                        </table>
                        <h4 id="pluralization">Pluralização</h4>
                        <p>O suporte para pluralização é construído no formatador adicionando o <code class="highlighter-rouge">!</code>modificador
                            (bang) à diretiva, o comportamento padrão é retornar o caractere <strong>s</strong> , também é possível customizar
                            o retorno usando o sufixo <code class="highlighter-rouge">:...;</code>.</p>
                        <p>A tabela abaixo mostra os casos de uso suportados do plugin de pluralização.</p>
                        <table class="table table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Directiva</th>
                                    <th>Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><code>%!H</code></td>
                                    <td>Retornar <strong>s</strong> quando a hora for diferente de 1</td>
                                </tr>
                                <tr>
                                    <td><code>%!S:plural;</code></td>
                                    <td>Devolva o <strong>plural</strong> quando os segundos forem diferentes de 1</td>
                                </tr>
                                <tr>
                                    <td><code>%!d:singular,plural;</code></td>
                                    <td>Retorno <strong>singular</strong> quando o dia é 1 e <strong>plural de</strong> outra forma</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="language-javascript highlighter-rouge">
                            <pre class="highlight">
                                <code>
                                    <span class="s1">\'%-D dia%!D %H:%M:%S\'</span>
                                    <span class="c1">// =&gt; 1 dia 23:45:56 (ou) 2 dias 23:45:56</span>
                                    <span class="s1">\'%-D segundo%!D:e; %H:%M:%S\'</span>
                                    <span class="c1">// =&gt; 1 segundo 23:45:56 (ou) 2 segundos 23:45:56</span>
                                    <span class="s1">\'%S %!S:dia,dias;\'</span>
                                    <span class="c1">// =&gt; 01 dia (ou) 02 dias</span>
                                </code>
                            </pre>
                        </div>
                    </ModalBody>
                </Modal>
            </Card >
        </>
    )
}

